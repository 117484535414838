export const isBiggerWord = (arr) => {
  let biggestIndex = 0;
  let biggestWord = 0;
  let totalWords = 0;

  arr.forEach((item, index) => {
    let temp = item.split(" ");
    totalWords += temp.length;
    if (temp.length >= biggestWord) {
      biggestWord = temp.length;
      biggestIndex = index;
    }
  });

  if (totalWords >= 100) {
    arr = arr.filter((item, ind) => ind !== biggestIndex);
  }
  return arr;
};

export const cometChatMessageSortAndMerge = (arr) => {
  let duplicateTracker = {};
  let tempArr = [...arr];

  tempArr.sort((a, b) => b.sentAt - a.sentAt);

  let trimmedArr = [];

  tempArr.forEach((item) => {
    if (!duplicateTracker[item.conversationId]) {
      duplicateTracker[item.conversationId] = {
        ...item,
        messageCount: 1,
      };
    } else {
      duplicateTracker[item.conversationId] = {
        ...duplicateTracker[item.conversationId],
        messageCount: duplicateTracker[item.conversationId].messageCount + 1,
      };
    }
  });

  for (let key in duplicateTracker) {
    trimmedArr.push(duplicateTracker[key]);
  }
  return trimmedArr;
};
