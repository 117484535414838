import React, { useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Common.css";
import { ThemeContext } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

const CommonSlider = (props) => {
  const { setSelectedTags, selectedTags, tagsData } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { strategies, categories, crypto, currency, commodity } = tagsData;
  const tags = [strategies, categories, crypto, currency, commodity].flat();

  var settings = {
    focusOnSelect: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSelectTag = (tag) => {
    if (selectedTags === tag) {
      setSelectedTags(() => "");
      navigate("/");
    } else {
      setSelectedTags(() => tag);
      navigate(`/tagged-content/${tag}`);
    }
  };

  const handleAllTag = () => {
    navigate("/");
  };

  return (
    <div
      className="slaider_main"
      style={{ width: "auto", 
      marginLeft: "24px", 
       marginRight: "24px" 
    }}
    >
      <Slider {...settings}>
        <div className="tagsDiv">
          <button
            id="slider_newid"
            className={
              props.selectedTag ? "unselectedTagButton" : "selectedTagButton"
            }
            onClick={handleAllTag}
          >
            All
          </button>
        </div>
        {props.selectedTag && (
          <div className="tagsDiv">
            <button
              id="slider_newid_selected"
              className="selectedTagButton"
              onClick={() => handleSelectTag(props.selectedTag)}
            >
              {props.selectedTag}
            </button>
          </div>
        )}

        {tags &&
          tags.length > 0 &&
          tags
            ?.filter((tag) => tag !== selectedTags)
            .sort()
            .map((item, idx) => (
              <div className="tagsDiv" key={idx}>
                {item ? (
                  <button
                    id="slider_newid"
                    className="unselectedTagButton"
                    onClick={() => handleSelectTag(item)}
                  >
                    {item}
                  </button>
                ) : (
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={30}
                    style={{ marginRight: "5px" }}
                  />
                )}
              </div>
            ))}
      </Slider>
    </div>
  );
};

export default CommonSlider;
