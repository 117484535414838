import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../context/ThemeContext";
import Allsuggestion from "../../Pages/all-suggestion-page";
import { useNavigate } from "react-router-dom";
import AllNews from "../fundu-news/all-news";
import AllMostPopularStocks from "../all-stocks-tab/all-stocks";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "#313342",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SuggestionTabs() {
  const [value, setValue] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);
  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(sessionStorage.getItem("user"));
  const { newsData, stocksData } = React.useContext(StockNewsContext);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 1:
        navigate("/suggestion/post");
        break;
      case 2:
        navigate("/suggestion/news");
        break;
      case 3:
        navigate("/suggestion/stocks");
        break;
      default:
        navigate("/suggestion/users");
    }
  };

  return (
    <>
      <div className="slaider_div_new_new" style={theme ? light : dark}>
        <Box
          sx={{ width: "100%", marginTop: "10px" }}
          className={theme ? "rightPanel_mainDiv" : "rightPanel_theme_change"}
        >
          {/* ------------------tabs box----------- */}
          <Box sx={{ border: "none" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* ------import tabs-------- */}

              <Tab
                label="User"
                {...a11yProps(0)}
                className={
                  theme ? "tab_different_styles" : "tab_different_styles_theme"
                }
              />

              <Tab
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#372B47",
                }}
                label="Post"
                {...a11yProps(1)}
                className={
                  theme ? "tab_different_styles" : "tab_different_styles_theme"
                }
              />
              <Tab
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#372B47",
                }}
                label="News"
                {...a11yProps(2)}
                className={
                  theme ? "tab_different_styles" : "tab_different_styles_theme"
                }
              />

              <Tab
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#372B47",
                }}
                label="Stocks"
                {...a11yProps(3)}
                className={
                  theme ? "tab_different_styles" : "tab_different_styles_theme"
                }
              />
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
            className="tabPanel_mostbought"
            style={{ marginTop: "30px" }}
          >
            <Allsuggestion />
            <br></br>
            <br></br>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className="tabPanel_mostbought"
            style={{ marginTop: "30px" }}
          ></TabPanel>
          <TabPanel
            value={value}
            index={2}
            className="tabPanel_mostbought"
            style={{ marginTop: "30px" }}
          >
            <AllNews data={newsData} />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            className="tabPanel_mostbought"
            style={{ marginTop: "30px" }}
          >
            <AllMostPopularStocks data={stocksData} />
          </TabPanel>
        </Box>
      </div>
    </>
  );
}
