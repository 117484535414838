import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import SendIcon from "@mui/icons-material/Send";
import "../../../../index.css";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import { capitalFirstLetter } from "../../../../../common-functions/FormatDate";
import { getRequest } from "../../../../../../services/request";
import ImgPlaceholder from "../../../../../../assets/image_placeholder.png";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { AiOutlineCloseCircle } from "react-icons/ai";

const schema = Yup.object().shape({
  comment: Yup.string().required("Comment is a required field"),
});

const CommentInput = ({
  postId,
  handleCommentPost,
  loading,
  taggedStock,
  taggedUser,
  setTaggedStock,
  setTaggedUser,
  setShowBox,
  showBox,
  imagePreview,
  handleDeleteImage,
  validateImg,
}) => {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [inputValue, setInputValue] = useState("");
  const [showUsersBox, setShowUsersBox] = useState(false);
  const [showStockBox, setShowStockBox] = useState(false);
  const [suggestionbox, setSuggestionbox] = useState(false);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [suggestedStocks, setSuggestedStocks] = useState([]);

  const handleInputValueChange = (inputValue) => {
    let trimmedVal = inputValue.split(" ");
    trimmedVal = trimmedVal[trimmedVal.length - 1];
    if (trimmedVal.includes("@")) {
      setShowUsersBox(true);
      const username = trimmedVal.split("@")[1];
      if (username) {
        const getSearchedUsers = async () => {
          const response = await getRequest({
            url: `/get-searched-users?search=${username}`,
          });
          setSuggestedUsers(response.data?.searchedUsers);
        };
        getSearchedUsers();
      }
    } else if (trimmedVal.includes("$")) {
      setShowStockBox(true);
      const stock = trimmedVal.split("$")[1];

      if (stock) {
        const getSearchedStocks = async () => {
          const response = await getRequest({
            url: `/get-searched-stocks?search=${stock}`,
          });
          setSuggestedStocks(response.data?.stocks);
        };
        getSearchedStocks();
      }
    } else {
      setSuggestedUsers([]);
      setSuggestedStocks([]);
      setShowUsersBox(false);
      setShowStockBox(false);
    }
  };

  useEffect(() => {
    handleInputValueChange(inputValue);
  }, [inputValue]);

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ comment: "" }}
      onSubmit={(values, formikHelper) => {
        handleCommentPost(values.comment);
        formikHelper.resetForm();
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
            mt={3}
          >
            <Avatar
              className="comment_profile_img"
              sx={{ width: 40, height: 40 }}
              src={
                (user && user.userpic_url) ||
                "https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&w=600"
              }
            />

            <TextField
              className="comment_input"
              type="text"
              id="comment"
              placeholder="Add a comment..."
              name="comment"
              autoComplete="off"
              onChange={(event) => {
                handleInputValueChange(event.target.value);
                handleChange(event);
              }}
              value={values.comment}
              sx={{
                "& .MuiOutlinedInput-root": {
                  minHeight: 30,
                  maxHeight: 100,
                  borderRadius: "8px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {imagePreview ? (
                      <Box className="image_preview_div">
                        <img
                          src={imagePreview || ""}
                          alt="image_preview"
                          style={{
                            width: "120px",
                            height: "100px",
                            padding: "7px",
                            borderRadius: "8px",
                          }}
                        />

                        <AiOutlineCloseCircle
                          className="image_delete_btn"
                          style={{ marginLeft: "12px" }}
                          onClick={handleDeleteImage}
                        />
                      </Box>
                    ) : (
                      ""
                    )}

                    {showBox && (
                      <Box>
                        <label htmlFor="image-upload">
                          <ImageRoundedIcon
                            style={{
                              color: "var(--fundu_color)",
                              fontSize: "22px",
                              marginLeft: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                        <input
                          type="file"
                          id="image-upload"
                          hidden
                          accept="image/png, image/jpeg"
                          onChange={validateImg}
                        />
                      </Box>
                    )}

                    <LoadingButton
                      disableShrink
                      loading={loading}
                      variant="text"
                      type="submit"
                      sx={{
                        textTransform: "none",
                        border: "none !important",
                      }}
                      style={{ color: "red !important" }}
                      endIcon={
                        !loading && (
                          <SendIcon
                            sx={{ color: "#5D20D2", fontSize: "26px" }}
                          />
                        )
                      }
                    ></LoadingButton>
                  </>
                ),
              }}
              inputProps={{
                style: {
                  fontSize: 12,
                  height: 10,
                  borderRadius: "16px",
                },
              }}
              error={errors.comment ? true : false}
              helperText={errors.comment ? errors.comment : null}
            />
          </Box>

          {showUsersBox && suggestedUsers.length === 0 && (
            <Box
              sx={{
                minWidth: "150px",
                maxWidth: "200px",
                ml: 5,
                display: "grid",
                boxShadow: "0px 4px 4px #F7F6F9",
                borderRadius: "8px",
                p: 1,
              }}
            >
              <Box>
                {[
                  {
                    First_Name: "Kunal Anand",
                    Image:
                      "https://lh3.googleusercontent.com/a/AGNmyxaT83O_LltKP2qnJBVHTDasI0d_oRL0fwgrsGE97g=s96-c",
                    username: "kunalanand",
                  },
                  {
                    First_Name: "Kesar chawala",
                    username: "kesC8c",
                    Image:
                      "http://res.cloudinary.com/djfgbivby/image/upload/v1676034464/g0namfhsu4zmdqh4bn0s.jpg",
                  },
                  {
                    First_Name: "Md.Khan",
                    Image:
                      "https://randomuser.me/api/portraits/med/women/89.jpg",

                    username: "md.A16",
                  },
                ].map((user) => (
                  <Box
                    sx={{
                      backgroundColor: "#F7F6F9",
                      borderRadius: "8px",
                      p: 0.5,
                      mb: 0.5,
                    }}
                    onClick={() => {
                      let arr = values?.comment.split(" ");
                      arr = arr.slice(0, arr.length - 1);
                      setFieldValue(
                        `comment`,
                        `${arr.join(" ")} @${user?.username}`
                      );
                      setTaggedUser([...taggedUser, user.username]);
                      setShowUsersBox(false);
                    }}
                    key={user.id}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Avatar
                        sx={{
                          width: 26,
                          height: 26,
                          border: "1px solid #D8D1E3",
                        }}
                        src={user.Image}
                      />
                      <Box>
                        <Typography
                          letterSpacing={0.4}
                          sx={{ fontSize: 12, fontWeight: 600 }}
                        >
                          {user.First_Name}
                        </Typography>
                        <Typography
                          letterSpacing={0.4}
                          sx={{ fontSize: 12, mt: -0.5 }}
                        >
                          {" "}
                          {user.username}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {showUsersBox && suggestedUsers.length > 0 && (
            <Box
              sx={{
                width: "200px",
                display: "grid",
                boxShadow: "0px 4px 4px #F7F6F9",
                borderRadius: "8px",
                p: 1,
                ml: 5,
              }}
            >
              <>
                {suggestedUsers.slice(0, 3).map((user) => (
                  <Box
                    sx={{
                      backgroundColor: "#F7F6F9",
                      borderRadius: "8px",
                      p: 0.5,
                      mb: 0.5,
                    }}
                    onClick={() => {
                      let arr = values?.comment.split(" ");
                      arr = arr.slice(0, arr.length - 1);
                      setFieldValue(
                        "comment",
                        `${arr.join(" ")} @${user?.username}`
                      );
                      setTaggedUser(() =>
                        !taggedUser?.includes(user.username)
                          ? [...taggedUser, user.username]
                          : taggedUser
                      );
                      setShowUsersBox(false);
                    }}
                    key={user.id}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Avatar
                        sx={{
                          width: 26,
                          height: 26,
                          border: "1px solid #D8D1E3",
                        }}
                        src={user.userpic_url || ImgPlaceholder}
                      />

                      <Box>
                        <Typography
                          letterSpacing={0.4}
                          sx={{ fontSize: 12, fontWeight: 600 }}
                        >
                          {" "}
                          {`${capitalFirstLetter(
                            user.First_Name
                          )} ${capitalFirstLetter(user.last_name)}`}
                        </Typography>
                        <Typography
                          letterSpacing={0.4}
                          sx={{ fontSize: 12, mt: -0.5 }}
                        >
                          {" "}
                          {user.username}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            </Box>
          )}
          {showStockBox && suggestedStocks.length === 0 && (
            <Box
              sx={{
                ml: 5,
                width: "150px",
                display: "grid",
                boxShadow: "0px 4px 4px #F7F6F9",
                borderRadius: "8px",
                p: 1,
              }}
            >
              {showStockBox && suggestedStocks.length === 0 && (
                <>
                  {[
                    { SYMBOLS: "ICT" },
                    { SYMBOLS: "TCS" },
                    { SYMBOLS: "AARTIIND" },
                  ].map((stock) => (
                    <Box
                      sx={{
                        backgroundColor: "#F7F6F9",
                        borderRadius: "8px",
                        p: 0.5,
                        mb: 0.5,
                      }}
                      onClick={() => {
                        let arr = values?.comment.split(" ");
                        // console.log("desc val", arr);
                        arr = arr.slice(0, arr.length - 1);
                        // console.log("desc val", arr);
                        setFieldValue(
                          "comment",
                          `${arr.join(" ")} $${stock.SYMBOLS}`
                        );
                        setTaggedStock(() =>
                          !taggedStock.includes(stock.SYMBOLS)
                            ? [...taggedStock, stock.SYMBOLS]
                            : taggedStock
                        );
                        setShowStockBox(false);
                      }}
                      key={stock.id}
                    >
                      <Typography
                        letterSpacing={0.4}
                        sx={{ fontSize: 12, fontWeight: 600, pl: 2, mt: 0.4 }}
                      >
                        {stock.SYMBOLS}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          )}
          {showStockBox && suggestedStocks.length > 0 && (
            <Box
              sx={{
                ml: 5,
                width: "150px",
                display: "grid",
                boxShadow: "0px 4px 4px #F7F6F9",
                borderRadius: "8px",
                p: 1,
              }}
            >
              {showStockBox && suggestedStocks.length > 0 && (
                <>
                  {suggestedStocks.slice(0, 3).map((stock) => (
                    <Box
                      sx={{
                        backgroundColor: "#F7F6F9",
                        borderRadius: "8px",
                        p: 0.5,
                        mb: 0.5,
                      }}
                      onClick={() => {
                        let arr = values?.comment.split(" ");
                        // console.log("desc val", arr);
                        arr = arr.slice(0, arr.length - 1);
                        // console.log("desc val", arr);
                        setFieldValue(
                          "comment",
                          `${arr.join(" ")} $${stock.SYMBOLS}`
                        );
                        setTaggedStock(() =>
                          !taggedStock.includes(stock.SYMBOLS)
                            ? [...taggedStock, stock.SYMBOLS]
                            : taggedStock
                        );
                        setShowStockBox(false);
                      }}
                      key={stock.id}
                    >
                      <Typography
                        letterSpacing={0.4}
                        sx={{ fontSize: 12, fontWeight: 600, pl: 2, mt: 0.4 }}
                      >
                        {stock.SYMBOLS}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          )}
        </>
      )}
    </Formik>
  );
};

CommentInput.propTypes = {
  postId: PropTypes.string,
  handleCommentPost: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default CommentInput;
