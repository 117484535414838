import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import Before from "../../suggestion-tabs/components/Before";
import { Link } from "react-router-dom";
import {
  capitalFirstLetter,
  checkFollow,
} from "../../common-functions/FormatDate";
import { backend_url } from "../../../http-common";
import { Cookies } from "react-cookie";
import { Avatar, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ImgPlaceholder from "../../../assets/image_placeholder.png";
import FuButton from "../../shared/FuButton";
import { BsCheck } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const UserTab = ({ searchInput }) => {
  const { t } = useTranslation();
  const { theme, followingUsers, getFollowingPosts } = useContext(ThemeContext);
  const searchedUsers = JSON.parse(localStorage.getItem("searchedUsers"));
  const [usersData, setUsersData] = useState(searchedUsers);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const handleFollowUser = (id, username) => {
    let newData = usersData.map((item, idx) => {
      if (id === idx) {
        return { ...item, isFollowing: !item.isFollowing };
      }

      return item;
    });

    setUsersData(newData);

    if (!followingUsers.includes(username)) {
      followingUsers.push(username);
      updateFollowing();
    } else {
      let userIdx = followingUsers.indexOf(username);
      followingUsers.splice(userIdx, 1);
      updateFollowing();
    }
  };

  const updateFollowing = () => {
    const payloadjson = JSON.stringify(followingUsers);
    fetch(`${backend_url}/update-following/${user.username}`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        getFollowingPosts(user?.username);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {searchedUsers.length === 0 && (
        <h3 style={{ textAlign: "center", marginTop: "35%" }}>
          {t("No people found")}
        </h3>
      )}
      <Card>
        {searchedUsers.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              pl: 3,
              mt: 2,
            }}
          >
            <Box display={"flex"}>
              <Avatar
                src={item.userpic_url || ImgPlaceholder}
                alt="img"
                sx={{ width: 48, height: 48 }}
              />

              <Box ml={1.5}>
                <Link
                  to={`/user/${item.username}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {`${capitalFirstLetter(item.First_Name)} ${capitalFirstLetter(
                    item.last_name
                  )}`}
                  <br />
                  <Typography
                    letterSpacing={0.4}
                    sx={{ color: "rgb(112, 112, 123)", fontSize: 12 }}
                  >{`@${item.username}`}</Typography>
                </Link>
              </Box>
            </Box>

            <FuButton
              onClick={() => handleFollowUser(idx, item.username)}
              style={{ height: 32 }}
            >
              {checkFollow(followingUsers, item.username) ? (
                <BsCheck fontSize={22} />
              ) : (
                t("Follow")
              )}
            </FuButton>
          </Box>
        ))}
      </Card>
    </div>
  );
};

export default UserTab;
