const languageCode = {
  English: "en",
  Hindi: "hi",
  Bengali: "bn",
  Marathi: "mr",
  Gujrati: "gu",
  Tamil: "ta",
  Telugu: "te",
};

const newSummaryLanguage = {
  hi: "hindi_",
  gu: "gujurati_",
  mr: "marathi_",
  bn: "bengali_",
  ta: "tamil_",
  te: "telugu_",
};

export const getLanguageCode = (language) => {
  return languageCode[language];
};

export const setLanguageCodeToStorage = (language) => {
  let lcode = getLanguageCode(language);
  if (!lcode) {
    lcode = "en";
  }
  localStorage.setItem("i18next", lcode);
};

export const getNewsSummaryLanguage = (lcode) => {
  return newSummaryLanguage[lcode];
};
