import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Typography, Stack, Grid, Avatar, Box } from "@mui/material";
import { BsCheck } from "react-icons/bs";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  capitalFirstLetter,
  checkFollow,
} from "../../../common-functions/FormatDate";
import ImgPlaceholder from "../../../../assets/image_placeholder.png";
import Before from "../../../suggestion-tabs/components/Before";
import FuButton from "../../../shared/FuButton";

const Suggestion = () => {
  const { usersData, myFollowings, handleFollow } =
    React.useContext(ThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Memoize the capitalFirstLetter function

  const handleUserClick = useCallback(
    (username) => {
      navigate(`/user/${username}`);
    },
    [navigate]
  );

  return (
    <Box>
      <Card
        sx={{
          p: 1,
          mb: 2,
          boxShadow: "none",
          border: "1px solid #e0e0e0",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          p={0.8}
        >
          <Typography
            letterSpacing={0.4}
            sx={{ fontSize: 16, fontWeight: 600, color: "#372B47" }}
          >
            {t("Suggestion For You")}
          </Typography>

          <Typography
            letterSpacing={0.4}
            sx={{
              cursor: "pointer",
              fontSize: 12,
              color: "var(--fundu_color)",
            }}
            onClick={() => {
              navigate(`/suggestion/users`);
            }}
          >
            {t("See All")}
          </Typography>
        </Stack>
        {usersData.length === 0 && <Before />}
        {usersData.slice(0, 4).map((item, idx) => (
          <Grid
            container
            spacing={2}
            p={1}
            justifyContent={"space-between"}
            columns={12}
            key={`suggestions-${idx}`}
          >
            <Grid item xs={8} display={"flex"}>
              <Avatar
                sx={{ width: 44, height: 44 }}
                src={item.userpic_url || ImgPlaceholder}
                alt="img"
              />
              <Box
                sx={{ display: "grid", ml: 1, cursor: "pointer" }}
                onClick={() => handleUserClick(item.username)}
              >
                <Typography letterSpacing={0.4} sx={{ fontSize: 14 }}>
                  {`${capitalFirstLetter(item.First_Name)} ${capitalFirstLetter(
                    item.last_name
                  )}`}
                </Typography>
                <Typography
                  letterSpacing={0.4}
                  display="block"
                  color="#8e9399"
                  fontSize={12}
                  gutterBottom
                  mt={-0.6}
                >
                  {`@${item.username}`}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} mr={-0.5}>
              <FuButton onClick={() => handleFollow(idx, item.username)}>
                {checkFollow(myFollowings, item.username) ? (
                  <BsCheck fontSize={22} />
                ) : (
                  t("Follow")
                )}
              </FuButton>
            </Grid>
          </Grid>
        ))}
      </Card>
    </Box>
  );
};

export default React.memo(Suggestion);
