import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { Cookies } from "react-cookie";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import UserPosts from "../../user-posts-tabs/components/user-posts";
import PortfolioHighlights from "../../portfolio/portfolio-highlights";
import Portfolio from "../../portfolio";
import PortfolioStocks from "../../portfolio/portfolio-stocks";
import WatchlistStocks from "../../watchlist-stocks";
import { useParams } from "react-router-dom";
import { backend_url } from "../../../http-common";
import LinkBroker from "../../link-broker";
import { useTranslation } from "react-i18next";
import { capitalFirstLetter } from "../../common-functions/FormatDate";
import { getRequest } from "../../../services/request";

function useUserDetailsTabs() {
  const [data, setData] = useState([]);
  const { stocksData, portfolioStocksData } = useContext(StockNewsContext);
  const [portfolioStocksData2, setPortfolioStocksData2] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isFyersConnected, setIsFyersConnected] = useState(false);
  const { username } = useParams();
  const { t } = useTranslation();
  const cookie = new Cookies();
  const user = cookie.get("user");

  let getData = useCallback(() => {
    fetch(`${backend_url}/posts-by-user/${username}`)
      .then((res) => res.json())
      .then((d) => {
        setData(() => d.posts);
      });
  }, [username]);

  const getUserData = useCallback(() => {
    fetch(`${backend_url}/get-user-by-name/${username}`)
      .then((res) => res.json())
      .then((d) => {
        setUserData(() => d.response);
        if (
          d.response[0]?.isFyersConnected &&
          d.response[0]?.isFyersConnected === true
        ) {
          setIsFyersConnected(true);
        } else {
          setIsFyersConnected(false);
        }
      });
  }, [username]);

  const fetchUserPortfolioStocks = useCallback(() => {
    getRequest({
      url: `/stocks/get-portfolio-stocks/${username}`,
    })
      .then((res) => {
        setPortfolioStocksData2(res.data.allStocks);
        // console.log("stocks 2 data in data", res.data);
      })
      .catch((err) => {
        console.log("something went wrong, not found");
        setPortfolioStocksData2([]);
      });
  }, [username]);

  useEffect(() => {
    getData();
    getUserData();
    fetchUserPortfolioStocks();
  }, [getData, username, getUserData]);

  const tabs = useMemo(
    () => [
      {
        name: t("Posts"),
        component: <UserPosts data={data} getData={getData} />,
      },
      {
        name: t("Portfolio"),
        component: isFyersConnected ? (
          portfolioStocksData2?.length > 0 ? (
            <div>
              <PortfolioHighlights />
              <LinkBroker />
              <Portfolio profileUsername={username} />
              <PortfolioStocks data={portfolioStocksData2} />
            </div>
          ) : user && user.username === username ? (
            <LinkBroker />
          ) : (
            `${capitalFirstLetter(
              userData[0]?.First_Name
            )} have no stock in the portfolio`
          )
        ) : user && user.username === username ? (
          <LinkBroker />
        ) : (
          `${capitalFirstLetter(
            userData[0]?.First_Name
          )}'s portfolio not disclosed`
        ),
      },
      user && user.username === username
        ? {
            name: t("Watchlist"),
            component: <WatchlistStocks />,
          }
        : null,
    ],
    [
      data,
      getData,
      stocksData,
      userData,
      isFyersConnected,
      username,
      portfolioStocksData,
    ]
  );

  return tabs;
}

export default useUserDetailsTabs;
