import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import Before from "../../suggestion-tabs/components/Before";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { Box, Stack, Typography } from "@mui/material";
import "../index.css";
import DefaultNewsCard from "../../layout/base/default";
import { MobileDefaultNewsCard } from "../../layout/mobile_news";
import { useTranslation } from "react-i18next";

export const TrendingNewsCard = () => {
  const { newsData } = useContext(StockNewsContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          backgroundColor: "white",
          mt: 2,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          pt={1}
          pl={2}
          pr={2}
          borderBottom={"1px solid #e0e0e0"}
        >
          <Typography
            letterSpacing={0.4}
            sx={{ fontSize: 16, fontWeight: 600, color: "#372B47" }}
          >
            {t("News")}
          </Typography>
          <Typography
            letterSpacing={0.4}
            sx={{
              cursor: "pointer",
              fontSize: 12,
              color: "var(--fundu_color)",
            }}
            onClick={() => {
              navigate(`/news`);
            }}
          >
            {" "}
            {t("See All")}
          </Typography>
        </Stack>
        {newsData.length === 0 && <Before />}
        {newsData.slice(0, 4).map((news, idx) => (
          <MobileDefaultNewsCard
            news={news}
            key={`mobile-default-news-card-${idx}`}
          />
        ))}
      </Box>
    </>
  );
};
export default TrendingNewsCard;
