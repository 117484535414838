import React, { useContext } from "react";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import AllNews from "../../fundu-news/all-news";

const NewsTab = () => {
  const { savedNews, savedPosts } = React.useContext(StockNewsContext);

  return (
    <div>
      {savedNews.length === 0 && (
        <h3 style={{ textAlign: "center", marginTop: "35%" }}>No news found</h3>
      )}

      <AllNews data={savedNews} />
    </div>
  );
};

export default NewsTab;
