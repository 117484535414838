import React, { useContext, useEffect, memo, useCallback } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Cookies } from "react-cookie";
import Box from "@mui/material/Box";
import CreatePost from "../../Components/create-post";
import UserPostsTabs from "../../Components/user-posts-tabs";
import Suggestion from "../../Components/common/components/suggestion";
import LayoutDefault from "../../Components/layout/page/default";
import useScroll from "../../Components/common-functions/useScroll";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPost,
  fetchUpdatedPost,
  updateNoData,
  updatePage,
  updateTop,
} from "../../store/postSlice";
import { useTranslation } from "react-i18next";

const LandingPage = (props) => {
  const {
    userVotes,
    fetchFollowers,
    followingUsers,
    alertsData,
    fetchAllAlerts,
    fetchAllTags,
    tagsData,
    getFollowingPosts,
  } = useContext(ThemeContext);
  const { theme, currentPostsTab, filterValue, isTop } =
    useContext(ThemeContext);
  const dispatch = useDispatch();
  let { page, noData, posts } = useSelector((state) => state.posts);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { width } = useViewPort();
  const { t } = useTranslation();

  useEffect(() => {
    if (posts.length === 0) {
      dispatch(fetchPost());
    } else {
      dispatch(updateTop(isTop));
      dispatch(updatePage({ page: 1 }));
      dispatch(updateNoData(false));
      dispatch(fetchPost());
    }
  }, [isTop]);

  const getNormalPosts = useCallback(
    async (
      setLastList,
      setPrevPage,
      currPage,
      setLoading,
      setScroll,
      scroll
    ) => {
      if (noData) {
        setLastList(true);
      }
      if (scroll) {
        page = currPage <= page ? page + 1 : currPage;
        let payload = {
          page,
        };
        currPage && setPrevPage(page);
        currPage && setLoading(false);
        currPage && setScroll(false);
        dispatch(updatePage(payload));
        dispatch(fetchUpdatedPost());
      }
    },
    [filterValue, isTop, noData]
  );

  const { handleScroll, loading } = useScroll(getNormalPosts, filterValue);
  const { handleScroll: handleFollowingScroll, loading: loadingFollowing } =
    useScroll(getFollowingPosts, filterValue);

  useEffect(() => {
    if (
      user &&
      !userVotes?.length &&
      !followingUsers?.length &&
      !alertsData?.length &&
      !tagsData?.length
    ) {
      fetchFollowers(user);
      fetchAllAlerts();
      fetchAllTags();
    }
  }, [
    // alertsData?.length,
    // fetchAllAlerts,
    // fetchAllTags,
    // fetchFollowers,
    // followingUsers?.length,
    // tagsData?.length,
    // user,
    // userVotes?.length,
    user && user.username,
  ]);

  return (
    <>
      <Box>
        {width < BREAKPOINT ? (
          <MobileDefault
            middleContent={
              <div>
                {/* {!user ? <MainBanner /> : ""} */}
                {user ? <CreatePost /> : ""}
                <UserPostsTabs
                  loading={
                    currentPostsTab == t("Following")
                      ? loadingFollowing
                      : loading
                  }
                />
              </div>
            }
            handleScroll={
              currentPostsTab == t("Following")
                ? handleFollowingScroll
                : handleScroll
            }
          />
        ) : (
          <LayoutDefault
            // Banner={<div> {!user ? <MainBanner /> : ""}</div>}
            mainContent={
              <div>
                {user ? <CreatePost /> : ""}
                <UserPostsTabs
                  loading={
                    currentPostsTab == t("Following")
                      ? loadingFollowing
                      : loading
                  }
                />
              </div>
            }
            rightPanelContent={
              <div>
                {user ? <Suggestion /> : ""}
                <MostPopularStocksRight />
                <TrendingNewsCard />
              </div>
            }
            handleScroll={
              currentPostsTab == t("Following")
                ? handleFollowingScroll
                : handleScroll
            }
          />
        )}
      </Box>
    </>
  );
};

export default memo(LandingPage);
