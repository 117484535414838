import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState, useContext } from "react";
// import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeContext } from "../../../../context/ThemeContext";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { capitalFirstLetter } from "../../../common-functions/FormatDate";
import PropTypes from "prop-types";
import { useCallback } from "react";

const AddTagsChart = ({ selectedTags, setSelectedTags }) => {
  const { theme, tagsData } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setSelectedTags([]);
    setOpen(false);
  }, [setSelectedTags]);

  const handleDone = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(
    (tag) => {
      if (selectedTags?.includes(tag)) {
        const index = selectedTags?.indexOf(tag);
        selectedTags?.splice(index, 1);
        setSelectedTags([...selectedTags]);
      } else {
        setSelectedTags([...selectedTags, tag]);
      }
    },
    [setSelectedTags, selectedTags]
  );

  return (
    <Box>
      <Chip
        label="Add tags"
        sx={{ fontSize: "14px" }}
        onClick={handleClickOpen}
      />

      <Dialog open={open} onClose={handleClose} style={{ textAlign: "center" }}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: 24,
                ml: 2,
                fontWeight: 700,
                color: !theme ? "white" : "black",
              }}
            >
              Add Tags
            </Typography>
            <CloseIcon
              onClick={handleClose}
              style={{
                color: "black",
              }}
            />
          </Box>
        </DialogTitle>
        <Divider color={"black"} />
        <DialogContent>
          {tagsData &&
            Object.keys(tagsData).map((category, index) => (
              <Box key={index}>
                <DialogContentText sx={{ fontSize: 18 }}>
                  {capitalFirstLetter(category)}
                </DialogContentText>
                {tagsData[category].map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    variant={
                      selectedTags?.includes(tag) ? "filled" : "outlined"
                    }
                    values={selectedTags}
                    onClick={() => handleClick(tag)}
                    sx={{
                      marginRight: "8px",
                      marginBottom: "8px",
                      backgroundColor: selectedTags?.includes(tag)
                        ? "#5d20d2"
                        : "default",
                      color: selectedTags?.includes(tag) ? "white" : "black",
                    }}
                  />
                ))}
              </Box>
            ))}
        </DialogContent>
        <Divider />
        <DialogTitle>
          {selectedTags?.length > 6 && (
            <Typography letterSpacing={0.4} color="red">
              {" "}
              You can add maximum six tags only.{" "}
            </Typography>
          )}

          <Button
            variant="outlined"
            className="add_tags_done"
            onClick={handleDone}
            sx={{
              color: "white",
              border: " 1px solid var(--fundu_color)",
              background: "var(--fundu_color)",

              ":hover": {
                backgroundColor: "white",
                color: "#5D20D2",
                border: " 1px solid var(--fundu_color)",
              },
            }}
            disabled={
              selectedTags?.length > 6 || selectedTags?.length === 0
                ? true
                : false
            }
          >
            Done
          </Button>
        </DialogTitle>
      </Dialog>
    </Box>
  );
};

export default AddTagsChart;
