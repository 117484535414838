import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { BiPlus } from "react-icons/bi";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import logo from "../../../assets/askfunduLogo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Zerodha from "../assest/image1.jpeg";
import icici from "../assest/image2.jpeg";
import dhan from "../assest/image3.jpeg";
import f from "../assest/image4.jpeg";
import { Cookies } from "react-cookie";

export default function SelectBrokerPopup() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("public");
  const cookie = new Cookies();
  const user = cookie.get("user");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          border: "none",
          textTransform: "none",
          ":hover": { border: "none", textTransform: "none" },
        }}
      >
        <BiPlus
          style={{ fontSize: 20, marginRight: "20px", marginTop: "-10px" }}
        />
        <Box display={"grid"}>
          <Typography letterSpacing={0.4} fontSize={14}>
            Link your Broker
          </Typography>
          <Typography letterSpacing={0.4} fontSize={14}>
            Link your broker to compare
          </Typography>
        </Box>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <img
            style={{
              height: "45px",
              width: "140px",
            }}
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            alt="logo"
          />
        </DialogTitle>
        <DialogContent p={4}>
          <Box display={"flex"}>
            <Checkbox
              checked={selectedOption === "public"}
              onChange={handleOptionChange}
              value="public"
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography letterSpacing={0.4} fontSize={14} mt={1}>
              Make Portfolio Public
            </Typography>
          </Box>

          <Box display={"flex"}>
            <Checkbox
              checked={selectedOption === "private"}
              onChange={handleOptionChange}
              value="private"
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography letterSpacing={0.4} fontSize={14} mt={1}>
              Link Privately
            </Typography>
          </Box>

          <Box
            sx={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              m: 5,
            }}
          >
            <Typography letterSpacing={0.4} fontSize={16} fontWeight={600}>
              Select Your Broker
            </Typography>
            <Box display={"flex"} gap={5} mt={5}>
              <Button
                sx={{
                  border: "2px solid transparent",
                  boxShadow: "0px 6px 4px #D8D1E3",
                  width: "150px",
                  borderImage:
                    "linear-gradient(45deg, rgba(93, 32, 210, 1), rgba(216, 19, 233, 1)) 1",
                  background: "transparent",
                }}
              >
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "15px",
                  }}
                  src={Zerodha}
                  alt="Z"
                />
                Zerodha
              </Button>
              <a
                target="_blank"
                href={`https://api.icicidirect.com/apiuser/login?api_key=37927J26oR126080056_%2BG4384077t5%5E&sessionToken=${user.username}`}
              >
                <Button
                  sx={{
                    border: "2px solid transparent",

                    boxShadow: "0px 6px 4px #D8D1E3",
                    width: "150px",
                    borderImage:
                      "linear-gradient(45deg, rgba(93, 32, 210, 1), rgba(216, 19, 233, 1)) 1",
                    background: "transparent",
                  }}
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "15px",
                    }}
                    src={icici}
                    alt="Z"
                  />
                  ICICI Direct
                </Button>
              </a>
            </Box>
            <Box display={"flex"} gap={5} mt={5}>
              <Button
                sx={{
                  border: "2px solid transparent",
                  boxShadow: "0px 6px 4px #D8D1E3",
                  width: "150px",

                  borderImage:
                    "linear-gradient(45deg, rgba(93, 32, 210, 1), rgba(216, 19, 233, 1)) 1",
                  background: "transparent",
                }}
              >
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "15px",
                  }}
                  src={dhan}
                  alt="Z"
                />
                Dhan
              </Button>
              {user?.isFyersConnected ? (
                <a
                  target="_blank"
                  href={`https://api.fyers.in/api/v2/generate-authcode?client_id=FA56UEC63I-102&redirect_uri=https://web.askfundu.com/fyers/auth&response_type=code&state=sample_state=${user.username}`}
                >
                  <Button
                    // disabled={user?.isFyersConnected}
                    sx={{
                      border: "2px solid transparent",
                      boxShadow: "0px 6px 4px #D8D1E3",
                      width: "150px",

                      borderImage:
                        "linear-gradient(45deg, rgba(93, 32, 210, 1), rgba(216, 19, 233, 1)) 1",
                      background: "transparent",
                      fontSize: "12.5px",
                    }}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "15px",
                      }}
                      src={f}
                      alt="Z"
                    />
                    {}
                    Refresh data
                  </Button>
                </a>
              ) : (
                <a
                  target="_blank"
                  href={`https://api.fyers.in/api/v2/generate-authcode?client_id=FA56UEC63I-102&redirect_uri=https://web.askfundu.com/fyers/auth&response_type=code&state=sample_state=${user.username}`}
                >
                  <Button
                    sx={{
                      border: "2px solid transparent",
                      boxShadow: "0px 6px 4px #D8D1E3",
                      width: "150px",

                      borderImage:
                        "linear-gradient(45deg, rgba(93, 32, 210, 1), rgba(216, 19, 233, 1)) 1",
                      background: "transparent",
                    }}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "15px",
                      }}
                      src={f}
                      alt="Z"
                    />
                    Fyers
                  </Button>
                </a>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
