import React, { useEffect, useState } from "react";
import AllMostPopularStocks from "../../all-stocks-tab/all-stocks";
import { backend_url } from "../../../http-common";

const StocksTab = ({ searchInput }) => {
  // const { filterStocks } = useSearchData(searchInput);
  const [sdata, setSdata] = useState([]);
  const searchedStocks = JSON.parse(localStorage.getItem("searchedStocks"));

  const fetchdata = async () => {
    try {
      let rr = await fetch(
        `${backend_url}/get-searched-stocks?search=${searchInput}`
      );
      let res = await rr.json();
      setSdata(res.stocks);
    } catch (e) {
      setSdata([]);
    }
  };

  useEffect(() => {
    fetchdata();
  }, [searchInput]);

  return (
    <div>
      <AllMostPopularStocks data={sdata} />

      {sdata?.length === 0 && (
        <h3 style={{ textAlign: "center", marginTop: "25%" }}>
          No stocks found
        </h3>
      )}
    </div>
  );
};

export default StocksTab;
