import React from "react";
import { Box } from "@mui/material";
import { Cookies } from "react-cookie";
import LoginBanner from "../../../login-banner";

const LayoutDefaultNew = ({
  mainContent,
  rightPanelContent,
  handleScroll,
  Banner,
}) => {
  const drawerWidth = 180;
  const cookie = new Cookies();
  const user = cookie.get("user");

  return (
    <>
      <Box
        style={{
          display: "flex",
          height: "100vh",
          bgcolor: "background.default",
        }}
      >
        <Box
          className="mainBodyContent"
          onScroll={handleScroll}
          justifyContent="center"
          id="scrolltop"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "80vw",
                mr: 5,
              }}
            >
              {mainContent}
            </Box>
          </Box>
        </Box>
      </Box>

      {!user && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: "100000",
          }}
        >
          <LoginBanner />
        </Box>
      )}
    </>
  );
};

export default LayoutDefaultNew;
