import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { backend_url } from "../../../http-common";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Card } from "@mui/material";
import { useTranslation } from "react-i18next";

function StockDetails({ symbol }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchStock();
  }, [symbol]);

  const fetchStock = () => {
    fetch(`${backend_url}/get-stocks-fundamental/${symbol}`)
      .then((res) => res.json())
      .then((d) => {
        setData(() => [d.stockFundamentals]);
      })
      .catch((err) => console.log(err));
  };

  function formatMarketCap(number) {
    if (number >= 1e8) {
      return (number / 1e8).toFixed(2) + " crore";
    }
    if (number >= 1e6) {
      return (number / 1e6).toFixed(2) + " million";
    }
    if (number >= 1e3) {
      return (number / 1e3).toFixed(2) + " thousand";
    }
    return number;
  }

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      {data.length === 0 ? (
        <img
          className="loader_img"
          src="https://reactnativeexample.com/content/images/2019/02/android-spinner.gif"
          alt="loader_img"
        />
      ) : (
        data.map((item, idx) => (
          <>
            <Typography
              letterSpacing={0.4}
              sx={{ p: 1, fontWeight: "600", fontSize: 18 }}
            >
              {item?.longName}
            </Typography>

            <Card
              sx={{
                flexGrow: 1,
                p: 2,
                boxShadow: "none",
                border: "1px solid #e0e0e0",
              }}
            >
              <strong>{t("Fundamental Information")}</strong>
              <hr />
              <Grid container spacing={2} columns={{ md: 12 }}>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("Trailing PE")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {" "}
                    {`${(+item?.trailingPE)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("EPS Trailing 12 Months")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {" "}
                    {`${(+item?.epsTrailingTwelveMonths)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("Shares Outstanding")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {formatMarketCap(item?.sharesOutstanding)}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("Book Value")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {`${(+item?.bookValue)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("Price To Book")}:
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {`${(+item?.priceToBook)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("Market Cap")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {formatMarketCap(item?.marketCap)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <br />
            <Card
              sx={{
                flexGrow: 1,
                p: 2,
                boxShadow: "none",
                border: "1px solid #e0e0e0",
              }}
            >
              <strong>{t("Price Information")}</strong>
              <hr />
              <Grid container spacing={2}>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("Regular Market Day High")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {`${(+item?.regularMarketDayHigh)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("Regular Market Day Low")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {" "}
                    {`${(+item?.regularMarketDayLow)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("52 Week Low")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {" "}
                    {`${(+item?.fiftyTwoWeekLow)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("52 Week High")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {`${(+item?.fiftyTwoWeekHigh)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ display: "flex" }}>
                  {t("50 Day Average")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {`${(+item?.fiftyDayAverage)?.toFixed(2)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sx={{ display: "flex" }}>
                  {t("200 Day Average")}:{" "}
                  <Typography letterSpacing={0.4} color="primary" ml={1}>
                    {" "}
                    {`${(+item?.twoHundredDayAverage)?.toFixed(2)}`}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </>
        ))
      )}
    </Box>
  );
}

export default StockDetails;
