export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

export const BREAKPOINT = 1080;

export const EDIT = "EDIT";
export const CREATE = "CREATE";
export const GOOGLE_CLIENT_ID =
  "477666885602-4iqsbjjrv34uhuval0vn1bt2u6cvcnv7.apps.googleusercontent.com";
// export const GOOGLE_CLIENT_ID =
//   "455704082717-sdjqtust369b8e9nupv13lb84qrn7996.apps.googleusercontent.com";
