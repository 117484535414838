/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DountChart from "../dount-chart";
import { getRequest } from "../../../services/request";
import { capitalFirstLetter } from "../../common-functions/FormatDate";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const PortfolioHighlights = ({ profileUsername }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    function PEaverage() {
      getRequest({ url: `/users/get-portfolio-series/${profileUsername}` })
        .then((res) => {
          // const { pe_average } = res?.data || {};
          setData(res?.data);
          // console.log("pe_average:", pe_average);
        })
        .catch((e) => {
          setData(null);
          console.error("Error:", e);
        });
    }
    PEaverage();
  }, []);
  return (
    <>
      <Typography
        letterSpacing={0.4}
        sx={{
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {" "}
        Portfolio Statistics
      </Typography>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
        }}
      >
        {data?.series && data.series?.length > 0 ? (
          <Grid container spacing={2} columns={16}>
            {data !== null && (
              <>
                <Grid item xs={4}>
                  <Item
                    sx={{
                      boxShadow: "none",
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                    }}
                  >
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4V3C12 2.46957 11.7893 1.96086 11.4142 1.58579C11.0391 1.21071 10.5304 1 10 1H8C7.46957 1 6.96086 1.21071 6.58579 1.58579C6.21071 1.96086 6 2.46957 6 3V4M12 4H15C15.5304 4 16.0391 4.21071 16.4142 4.58579C16.7893 4.96086 17 5.46957 17 6V16C17 16.5304 16.7893 17.0391 16.4142 17.4142C16.0391 17.7893 15.5304 18 15 18H14M12 4H6M6 4H3C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H4M14 18H4M14 18V19M4 18V19M6 8V14M12 14V8"
                        stroke="#5D20D2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        color: "black",
                        mt: 1,
                      }}
                    >
                      {data?.pe_average}
                    </Typography>
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      P/E Ratio
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item
                    sx={{
                      boxShadow: "none",
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                    }}
                  >
                    <Box>
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 9H6L10 17V1L14 9H19"
                          stroke="#5D20D2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Box>
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        color: "black",
                        mt: 1,
                      }}
                    >
                      {data?.pb_average}
                    </Typography>
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      P/B Ratio
                    </Typography>
                  </Item>
                </Grid>
              </>
            )}

            <Grid item xs={4}>
              <Item
                sx={{
                  boxShadow: "none",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                }}
              >
                <Box>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C19.994 15.52 15.52 19.994 10 20ZM10 2C8.41775 2 6.87103 2.46919 5.55544 3.34824C4.23984 4.22729 3.21446 5.47672 2.60896 6.93853C2.00346 8.40034 1.84504 10.0089 2.15372 11.5607C2.4624 13.1126 3.22433 14.538 4.34315 15.6569C5.46197 16.7757 6.88743 17.5376 8.43928 17.8463C9.99113 18.155 11.5997 17.9965 13.0615 17.391C14.5233 16.7855 15.7727 15.7602 16.6518 14.4446C17.5308 13.129 18 11.5822 18 10C17.9976 7.879 17.154 5.84555 15.6542 4.34578C14.1544 2.846 12.121 2.00238 10 2ZM15 11H9V5H11V9H15V11Z"
                      fill="#5D20D2"
                    />
                  </svg>
                </Box>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    color: "black",
                    mt: 1,
                  }}
                >
                  {data?.portfolio_returns} %
                </Typography>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 14,
                    color: "black",
                  }}
                >
                  Returns
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item
                sx={{
                  boxShadow: "none",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                }}
              >
                <Box>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" fill="white" />
                    <path
                      d="M19.5 12.0002C19.4999 10.7935 19.2065 9.60486 18.645 8.5367L17.5238 9.6587C17.8389 10.3991 18.0009 11.1956 18 12.0002H19.5ZM17.5605 7.5002L16.5 6.4397L12.9638 9.9752C12.6635 9.82879 12.3341 9.75188 12 9.7502C11.555 9.7502 11.12 9.88216 10.75 10.1294C10.38 10.3766 10.0916 10.728 9.92128 11.1392C9.75098 11.5503 9.70642 12.0027 9.79324 12.4392C9.88006 12.8756 10.0943 13.2765 10.409 13.5912C10.7237 13.9059 11.1246 14.1202 11.5611 14.207C11.9975 14.2938 12.4499 14.2492 12.861 14.0789C13.2722 13.9086 13.6236 13.6202 13.8708 13.2502C14.118 12.8802 14.25 12.4452 14.25 12.0002C14.2483 11.6661 14.1714 11.3367 14.025 11.0365L17.5605 7.5002ZM12 12.7502C11.8517 12.7502 11.7067 12.7062 11.5833 12.6238C11.46 12.5414 11.3639 12.4243 11.3071 12.2872C11.2503 12.1502 11.2355 11.9994 11.2644 11.8539C11.2934 11.7084 11.3648 11.5748 11.4697 11.4699C11.5746 11.365 11.7082 11.2936 11.8537 11.2646C11.9992 11.2357 12.15 11.2505 12.287 11.3073C12.4241 11.3641 12.5412 11.4602 12.6236 11.5835C12.706 11.7069 12.75 11.8519 12.75 12.0002C12.7498 12.1991 12.6707 12.3897 12.5301 12.5303C12.3895 12.6709 12.1989 12.75 12 12.7502ZM12 6.0002C12.8046 5.99937 13.6011 6.16137 14.3415 6.47645L15.4635 5.35445C14.3212 4.75636 13.0439 4.46331 11.7551 4.50367C10.4663 4.54403 9.2098 4.91642 8.10716 5.58481C7.00453 6.25319 6.09312 7.19493 5.46118 8.31885C4.82924 9.44277 4.49817 10.7108 4.50001 12.0002H6.00001C6.00199 10.4095 6.63477 8.88454 7.75956 7.75975C8.88435 6.63496 10.4093 6.00219 12 6.0002Z"
                      fill="#5D20D2"
                    />
                    <path
                      d="M12 22.5C9.9233 22.5 7.89323 21.8842 6.16652 20.7304C4.4398 19.5767 3.09399 17.9368 2.29927 16.0182C1.50455 14.0996 1.29661 11.9884 1.70176 9.95156C2.1069 7.91476 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91476 2.1069 9.95156 1.70176C11.9884 1.29661 14.0996 1.50455 16.0182 2.29927C17.9368 3.09399 19.5767 4.4398 20.7304 6.16652C21.8842 7.89323 22.5 9.9233 22.5 12C22.4968 14.7838 21.3896 17.4527 19.4211 19.4211C17.4527 21.3896 14.7838 22.4968 12 22.5ZM12 3C10.22 3 8.47992 3.52785 6.99987 4.51678C5.51983 5.50571 4.36628 6.91132 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C20.9972 9.61391 20.0481 7.32634 18.3609 5.63911C16.6737 3.95189 14.3861 3.00278 12 3Z"
                      fill="#5D20D2"
                    />
                  </svg>
                </Box>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    color: "black",
                    mt: 1,
                  }}
                >
                  {data?.portfolio_risk} %
                </Typography>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 14,
                    color: "black",
                  }}
                >
                  Risk
                </Typography>
              </Item>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              backgroundColor: "white",
              textAlign: "center",
              width: "100%",
            }}
          >
            {capitalFirstLetter(profileUsername)} have no stock in the portfolio
          </Box>
        )}
      </Box>

      <Box>
        <Typography
          letterSpacing={0.4}
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            mt: 3,
          }}
        >
          Portfolio Highlights
        </Typography>
        <Box>
          <DountChart />
        </Box>
      </Box>
    </>
  );
};

export default PortfolioHighlights;
