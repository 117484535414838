import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDchKJkOdPG2WM2zGZ1A8B056w79WCz8jA",
  authDomain: "askfundu-81afa.firebaseapp.com",
  projectId: "askfundu-81afa",
  storageBucket: "askfundu-81afa.appspot.com",
  messagingSenderId: "1057085703935",
  appId: "1:1057085703935:web:59433081bcf517fff9aeca",
  measurementId: "G-6PB0GXS87H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
