import React from "react";
import AllNews from "../../fundu-news/all-news";

const NewsTab = ({ searchInput }) => {
  const searchedNews = JSON.parse(localStorage.getItem("searchedNews")) || [];
  return (
    <>
      {searchedNews && searchedNews.length === 0 && (
        <h3 style={{ textAlign: "center", marginTop: "35%" }}>No news found</h3>
      )}

      <AllNews data={searchedNews} search={true} />
    </>
  );
};

export default NewsTab;
