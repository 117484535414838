import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../context/ThemeContext";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import useSettingsTabs from "./hooks/useSettingsTabs";
import MobileTabsDropdown from "../mobile-tabs-dropdown";
import { useMediaQuery } from "@mui/material";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "#313342",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SettingsTabs() {
  const [value, setValue] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);
  const { width } = useViewPort();
  const { tabs } = useSettingsTabs();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      sx={{
        mb: 2,
        height: 49,
        border: isMobile ? "" : "1px solid #e0e0e0",
        borderRadius: "4px",
      }}
      style={theme ? light : dark}
    >
      {" "}
      {width < BREAKPOINT ? (
        <MobileTabsDropdown tabs={tabs} />
      ) : (
        <Box>
          <Box sx={{ border: "none" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {tabs?.map((tab, index) => {
                return (
                  <Tab
                    label={tab?.name}
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#372B47",
                    }}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </Box>
          {tabs?.map((tab, index) => {
            return (
              <TabPanel
                value={value}
                index={index}
                className="tabPanel_mostbought"
                style={{ marginTop: "20px" }}
              >
                {tab.component}
                <br />
                <br />
              </TabPanel>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
