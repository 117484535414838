import { React, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import Slider from "./slider";
import { Box, Card, DialogContent } from "@mui/material";
import MobileDefault from "../layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";

export default function Guide({
  selectedPreferredTags,
  handleSelectPreferredTags,
  selectedExperience,
  handleSelectedExperience,
  selectedQuestions,
  handleSelectedQuestions,
  setPhoneNumber,
  phoneNumber,
  handleDone,
  selectLanguage,
  setSelectLanguage,
  setRefer,
  refer,
}) {
  const [open, setOpen] = useState(true);
  const { width } = useViewPort();

  return (
    <>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <Box open={open}>
              <Card
                style={{ backgroundColor: "#F7F6F9" }}
                PaperProps={{
                  style: {
                    boxShadow: "none",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100vh",
                  },
                }}
              >
                <Slider
                  selectedPreferredTags={selectedPreferredTags}
                  handleSelectPreferredTags={handleSelectPreferredTags}
                  selectedExperience={selectedExperience}
                  handleSelectedExperience={handleSelectedExperience}
                  selectedQuestions={selectedQuestions}
                  handleSelectedQuestions={handleSelectedQuestions}
                  setPhoneNumber={setPhoneNumber}
                  phoneNumber={phoneNumber}
                  handleDone={handleDone}
                  selectLanguage={selectLanguage}
                  setSelectLanguage={setSelectLanguage}
                  setRefer={setRefer}
                  refer={refer}
                />
              </Card>
            </Box>
          }
        />
      ) : (
        <Dialog open={open}>
          <DialogContent
            style={{ backgroundColor: "#F7F6F9" }}
            PaperProps={{
              style: {
                boxShadow: "none",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                maxWidth: 750,
              },
            }}
          >
            <Slider
              selectedPreferredTags={selectedPreferredTags}
              handleSelectPreferredTags={handleSelectPreferredTags}
              selectedExperience={selectedExperience}
              handleSelectedExperience={handleSelectedExperience}
              selectedQuestions={selectedQuestions}
              handleSelectedQuestions={handleSelectedQuestions}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              handleDone={handleDone}
              selectLanguage={selectLanguage}
              setSelectLanguage={setSelectLanguage}
              setRefer={setRefer}
              refer={refer}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
