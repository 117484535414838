import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { useMediaQuery } from "@mui/material";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MobileTabsDropdown from "../mobile-tabs-dropdown";
import useNewTabs from "./hooks/useNewTabs";
const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "black",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AllNewsTab({ loading }) {
  const [value, setValue] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);
  const { newsData } = React.useContext(StockNewsContext);
  const navigate = useNavigate();
  const { width } = useViewPort();
  const { tabs } = useNewTabs(loading);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 1:
        navigate("/news/positive");
        break;
      case 2:
        navigate("/news/negative");
        break;
      case 3:
        navigate("/news/neutral");
        break;
      default:
        navigate("/news");
    }
  };

  const positive = newsData?.filter((item) => item.Sentiment === "Positive");
  const negative = newsData?.filter((item) => item.Sentiment === "Negative");
  const neutral = newsData?.filter((item) => item.Sentiment === "Neutral");
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      sx={{
        height: 50,
        border: isMobile ? "" : "1px solid #e0e0e0",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        maxWidth: 900,
      }}
      style={theme ? light : dark}
    >
      {width < BREAKPOINT ? (
        <Box sx={{ width: "96vw" }}>
          <MobileTabsDropdown tabs={tabs} />
        </Box>
      ) : (
        <Box>
          <Box sx={{ border: "none", maxWidth: 900 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{ maxWidth: 900 }}
            >
              {tabs?.map((tab, index) => {
                return (
                  <Tab
                    key={`news-page-tabs1-${index}`}
                    label={tab?.name}
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#372B47",
                      maxWidth: 900,
                    }}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </Box>
          {tabs?.map((tab, index) => {
            return (
              <>
                <TabPanel
                  key={`news-page-tabpanel-${index}`}
                  value={value}
                  index={index}
                  style={{
                    marginTop: "10px",
                    maxWidth: 900,
                    width: "100%",
                  }}
                >
                  {tab?.component}
                  <br />
                  <br />
                </TabPanel>
              </>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
