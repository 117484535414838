import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import FuLoader from "./Components/shared/FuLoader";
import User from "./Components/common-functions/User";

const AppRoutes = ({ routes }) => {
  const user = User();
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={`Route-${index}`}
          path={route.path}
          element={
            user &&
            (route.path === "/login" ||
              route.path === "/signup" ||
              route.path === "/welcome" ||
              route.path === "/reset-password") ? (
              <Navigate to="/" />
            ) : (
              <Suspense fallback={<FuLoader />}>
                <route.component />
              </Suspense>
            )
          }
        />
      ))}
    </Routes>
  );
};

AppRoutes.propTypes = {
  routes: PropTypes.array,
};

export default AppRoutes;
