import React, { useContext, useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import "../index.css";
import { backend_url } from "../../../http-common";
import { capitalFirstLetter } from "../../common-functions/FormatDate";
import ImgPlaceholder from "../../../assets/image_placeholder.png";
import { useParams } from "react-router-dom";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { ThemeContext } from "../../../context/ThemeContext";
import { Cookies } from "react-cookie";
import { ImUpload } from "react-icons/im";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";

const EditProfile = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  // const { username } = useParams();
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { setOpenConfirmationBox } = useContext(StockNewsContext);
  const { setShowAlertBox } = useContext(ThemeContext);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const fetchUser = () => {
    fetch(`${backend_url}/get-user-by-name/${user?.username}`)
      .then((res) => res.json())
      .then((d) => {
        setData(() => d.response[0]);
        setFirstName(d.response[0]?.First_Name);
        setLastName(d.response[0]?.last_name);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchUser();
  }, [user?.username]);
  const handleEdit = () => {
    setEdit(true);
  };

  const handleEditConfirmation = (id) => {
    if (
      data?.First_Name === firstName &&
      data?.last_name === lastName &&
      image === null
    ) {
      setEdit(false);
    } else {
      let update = {
        status: true,
        text: "Do you want to update your profile?",
        fnc: handleEditProfile,
        id: id,
      };
      setOpenConfirmationBox(update);
    }
  };

  const handleEditProfile = async () => {
    let url;
    if (image) {
      url = await uploadImage(image);
    }

    if (firstName !== "" && lastName !== "" && image !== null) {
      setEdit(false);
      const form = {
        First_Name: firstName,
        last_name: lastName,
        username: data?.username,
        Password: data?.Password,
        userpic_url: url ? url : data?.userpic_url,
        Followers: data?.Followers,
        Followings: data?.Followings,
        number_of_posts: data?.number_of_posts,
      };

      editUser(form.username, form);
    } else {
      alert("All fields are required.");
    }
  };

  const editUser = (username, form) => {
    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/edit-user/${username}`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log("editUser", res);
        let newStatus = {
          status: res.status,
          from: "Profile",
          as: "updated",
        };
        setShowAlertBox(() => newStatus);
        fetchUser();
      })
      .catch((err) => console.log(err));
  };

  const handleCancelConfirmation = (id) => {
    if (
      data?.First_Name === firstName &&
      data?.last_name === lastName &&
      image === null
    ) {
      setEdit(false);
    } else {
      let update = {
        status: true,
        text: "Do you want to discard changes?",
        fnc: handleCancelEdit,
        id: id,
      };
      setOpenConfirmationBox(update);
      setFirstName(data?.First_Name);
      setLastName(data?.last_name);
    }
  };

  const handleCancelEdit = () => {
    setEdit(false);
  };

  const validateImg = (e) => {
    const file = e.target.files[0];
    if (file.size >= 1048576) {
      return alert("Max file size is 1MB.");
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  async function uploadImage() {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "h41kkssr");

    try {
      let res = await fetch(
        `https://api.cloudinary.com/v1_1/djfgbivby/image/upload`,
        {
          method: "post",
          body: data,
        }
      );
      const urlData = await res.json();

      return urlData.url;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="EditProfile">
      <div>
        <div className="ProfileImg_edit">
          <div className="EditProfilebutton_div">
            <span>{t("Profile Picture")}</span>
            {!edit ? (
              <div
                style={{
                  marginLeft: "-25spx",
                  cursor: "pointer",
                }}
                onClick={handleEdit}
              >
                <EditIcon
                  style={{
                    fontSize: "22px",
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {edit && imagePreview ? (
            <img src={imagePreview} alt="preview" />
          ) : edit ? (
            <div>
              <label htmlFor="image-upload">
                <ImUpload
                  style={{
                    color: "var(--fundu_color)",
                    marginLeft: "6px",
                    marginRight: "3px",
                    fontSize: "25px",
                    marginTop: "3px",
                  }}
                />
                <p style={{ marginTop: "5px" }}>Upload profile picture</p>
              </label>
              <input
                type="file"
                id="image-upload"
                hidden
                accept="image/png, image/jpeg"
                onChange={validateImg}
              />
            </div>
          ) : (
            <img
              src={data ? data.userpic_url : ImgPlaceholder}
              alt="profile_pic"
            />
          )}
        </div>
        <hr />
        <div className="BioEdit">
          <div className="EditProfilebutton_div">
            <span>{t("First Name")}</span>
          </div>
          {edit ? (
            <div>
              <input
                value={firstName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length > 15) {
                    setFirstNameError(true);
                  } else {
                    setFirstNameError(false);
                    setFirstName(inputValue);
                  }
                }}
                maxLength="`15`"
              />
              {firstNameError && (
                <p style={{ color: "#dc3545", fontSize: "12px" }}>
                  Your first name should be only 15 characters.
                </p>
              )}
            </div>
          ) : (
            <p>{`${capitalFirstLetter(firstName)}`}</p>
          )}
        </div>
        <hr />
        <div className="BioEdit">
          <div className="EditProfilebutton_div">
            <span>{t("Last Name")}</span>
          </div>
          {edit ? (
            <div>
              <input
                id="lastName"
                value={lastName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length > 15) {
                    setLastNameError(true);
                  } else {
                    setLastNameError(false);
                    setLastName(inputValue);
                  }
                }}
                maxLength="`15`"
              />
              {lastNameError && (
                <p style={{ color: "#dc3545", fontSize: "12px" }}>
                  Your last name should be only 15 characters.
                </p>
              )}
            </div>
          ) : (
            <p>{`${capitalFirstLetter(lastName)}`}</p>
          )}
        </div>
        <br />

        {edit ? (
          <div className="Edit_profile_save_button">
            <button onClick={handleEditConfirmation}>Save</button>
            <button onClick={handleCancelConfirmation}>Cancel</button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EditProfile;
