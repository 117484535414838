import React, { createRef, useContext, useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { getRequest } from "../../../services/request";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(2),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  color: "#a0a0a6",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "0.8rem",
  },
}));

const SearchStocks = () => {
  const [searchedStocksData, setSearchedStocksData] = useState([]);
  const [inputText, setInputText] = useState();
  const [open, setOpen] = useState(false);

  const getSearchedStocks = async () => {
    if (inputText) {
      const response = await getRequest({
        url: `/get-searched-stocks?search=${inputText}`,
      });
      setSearchedStocksData(response.data?.stocks);
      localStorage.setItem(
        "searchedStocks",
        JSON.stringify(response.data?.stocks)
      );
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debounceGetSearchedStocks = debounce(getSearchedStocks, 1000);

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    debounceGetSearchedStocks();
  };
  const containerRef = createRef();

  useEffect(() => {
    document.addEventListener("click", onDocumentClick);
    return () => document.removeEventListener("click", onDocumentClick);
  });

  const onDocumentClick = (event) => {
    if (!containerRef.current.contains(event.target)) {
      setInputText(() => "");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box ref={containerRef}>
      <Search component="form">
        <SearchIconWrapper>
          <SearchIcon
            style={{
              marginLeft: "2px",
              color: "rgb(112, 112, 123)",
              height: 18,
            }}
          />
        </SearchIconWrapper>
        <StyledInputBase placeholder="Search stocks " onChange={inputHandler} />
      </Search>
      <Box
        onClose={handleClose}
        sx={{
          width: "280px",
          overflowY: "scroll",
          mt: 2,
          mb: 2,
          zIndex: "100",
          backgroundColor: "white",
          position: "absolute",
          scrollbarWidth: "none", // Hide scrollbar in Firefox
          msOverflowStyle: "none", // Hide scrollbar in Internet Explorer

          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {inputText && (
          <Box>
            {searchedStocksData.map((stock, idx) => (
              <Link
                to={`/stock/${stock.SYMBOLS}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Grid container spacing={2} pl={2} p={2}>
                  <Grid item xs={7.8} display="grid" ml={1}>
                    <Typography
                      letterSpacing={0.4}
                      variant="p"
                      sx={{ fontSize: "13px" }}
                    >
                      {stock.longName}
                    </Typography>
                    <Typography
                      letterSpacing={0.4}
                      variant="p"
                      sx={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {stock.SYMBOLS}
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchStocks;
