import React, { useContext, useState } from "react";
import { memo } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdContentCopy } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditPost from "../../../user-posts-tabs/components/edit-post";
import { backend_url } from "../../../../http-common";
import { ThemeContext } from "../../../../context/ThemeContext";
import { v4 as uuidv4 } from "uuid";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";
import copy from "copy-to-clipboard";
import { Link, useLocation } from "react-router-dom";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import { Cookies } from "react-cookie";

function Dropdownmaterial({
  postData,
  postId,
  post_id,
  news,
  newsId,
  getData,
  post_type,
}) {
  const ITEM_HEIGHT = 48;
  const {
    setOpenConfirmationBox,
    setSavedNews,
    savedNews,
    savedPosts,
    removeSavedPost,
    addSavedPost,
  } = useContext(StockNewsContext);
  const { userPosts, setUserPosts, getPosts, setShowAlertBox } =
    useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(sessionStorage.getItem("user"));
  const cookie = new Cookies();
  const user = cookie.get("user");
  const location = useLocation();
  const path = location.pathname;
  // console.log("postData", postData);
  // const copyConfirmation = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   let update = {
  //     status: true,
  //     from: "post",
  //     as: "copy",
  //     fnc: copyToClipboard,
  //     id: e,
  //   };
  //   setShowAlertBox(() => update);
  // };
  const copyToClipboard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // copy(`https://my-app-manishhansal.vercel.app${path}`);
    // `${window.location.origin.toString()}
    // console.log("id", postData.post.unique_id);
    // console.log("base_url", window.location.origin.toString());
    let route = news ? `news/${newsId}` : `post/${postId}`;
    copy(`${window.location.origin.toString()}/${route}`);
    let temp = news ? "News" : "Post";
    let update = {
      status: true,
      from: temp,
      as: "copy",
      fnc: copyToClipboard,
      id: e,
    };
    setShowAlertBox(() => update);
  };

  const handleDeletePostConfirmation = (id) => {
    let update = {
      status: true,
      text: "Do you want to delete this post?",
      fnc: handleDeletePost,
      id: id,
      from: "delete",
      // type:post_type,
    };
    setOpenConfirmationBox(update);
  };

  const handleDeletePost = (id) => {
    // let newPostsData = userPosts.filter((item) => item.unique_id !== id);

    // setUserPosts(newPostsData);
    handleDelete(id);
  };

  const handleDelete = (id) => {
    fetch(`${backend_url}/delete-post/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        let newStatus = {
          status: res.status,
          from: "Post",
          as: "deleted",
        };
        getPosts();
        getData();
        setShowAlertBox(() => newStatus);
      })
      .catch((err) => console.log(err));
  };

  const handleSaveItems = () => {
    if (news) {
      if (!savedNews.includes(newsId)) {
        setSavedNews((d) => [...d, newsId]);
        let newStatus = {
          status: 200,
          from: "News",
          as: "saved",
        };
        setShowAlertBox(() => newStatus);
        updateSavedItems();
      } else {
        let idx = savedNews.indexOf(newsId);
        savedNews.splice(idx, 1);
        setSavedNews(savedNews);
        let newStatus = {
          status: 200,
          from: "News",
          as: "removed from saved items",
        };
        setShowAlertBox(() => newStatus);
        updateSavedItems();
      }
    } else {
      if (checkedIfSavedPostExists(post_id)) {
        removeSavedPost(post_id);
      } else {
        addSavedPost(post_id);
      }
    }
  };

  const updateSavedItems = () => {
    const form = {
      unique_id: "unique123",
      user_id: "rakFs1",
      posts: savedPosts,
      news: savedNews,
      stocks: [],
    };

    const payloadjson = JSON.stringify(form);

    fetch(`${backend_url}/update-savedItems/rakFs1`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log("savedItems response", response);
      })
      .catch((err) => console.log(err));
  };

  const checkedIfSavedPostExists = (post_id) => {
    let exists = false;
    savedPosts?.forEach((item) => {
      if (item._id == post_id) {
        exists = true;
        return;
      }
    });
    return exists;
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            border: "1px solid #e0e0e0",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {user?.username !== postData?.user && (
          <MenuItem onClick={() => [handleSaveItems(), handleClose()]}>
            <TurnedInNotIcon
              style={{
                fontSize: "20px",
              }}
            />
            <span style={{ marginLeft: "10px" }}>
              {" "}
              {savedNews?.includes(newsId) || checkedIfSavedPostExists(post_id)
                ? "Saved"
                : "Save"}{" "}
            </span>
          </MenuItem>
        )}

        {/* {user ? (
          <MenuItem onClick={handleReshare}>
            <BsShareFill
              style={{
                fontSize: "15px",
              }}
            />
            <span style={{ marginLeft: "15px", fontSize:"14px", }}> Reshare </span>
          </MenuItem>
        ) : null} */}

        {/* <MenuItem>
          <BiEdit style={{ fontSize: "20px" }} />
          <span style={{ marginLeft: "10px" }}>Edit</span>
        </MenuItem>  */}
        {user?.username === postData?.user && (
          <MenuItem>
            <BiEdit
              style={{
                fontSize: "20px",
                marginTop: "-5px",
                marginBottom: "-8px",
              }}
            />
            <span
              style={{
                fontSize: "14px",
                marginTop: "-5px",
                marginLeft: "-1px",
                marginBottom: "-8px",
              }}
            >
              <EditPost
                post_data={postData}
                close={handleClose}
                post_type={post_type}
              />
            </span>
          </MenuItem>
        )}

        {user?.username === postData?.user && (
          <MenuItem
            onClick={() => [
              handleDeletePostConfirmation(postData?.unique_id),
              handleClose(),
            ]}
          >
            <RiDeleteBin6Line style={{ fontSize: "20px" }} />
            <span style={{ marginLeft: "10px" }}>Delete</span>
          </MenuItem>
        )}

        <MenuItem onClick={(e) => [copyToClipboard(e), handleClose()]}>
          <MdContentCopy
            style={{
              fontSize: "18px",
              marginLeft: "2px",
            }}
          />
          <span style={{ marginLeft: "8px", fontSize: "14px" }}>
            {" "}
            Copy Link{" "}
          </span>
        </MenuItem>
        {/* <MenuItem>
          <MdFlag
            style={{ fontSize: "20px",}} />
            <span style={{marginLeft:"10px",}}>Report</span>
            </MenuItem> */}
      </Menu>
    </div>
  );
}

export default memo(Dropdownmaterial);
