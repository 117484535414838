import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const ResultForPosts = ({ searchInput, searchedPostsData }) => {
  const navigate = useNavigate();
  const [showAllResults, setShowAllResults] = useState(false);
  const [numResults, setNumResults] = useState(4);

  const toggleShowResults = () => {
    setShowAllResults(!showAllResults);
    if (!showAllResults) {
      setNumResults(searchedPostsData.length);
    } else {
      setNumResults(4);
    }
  };

  return (
    <Box>
      {searchInput.length !== 0 && searchedPostsData?.length === 0 && (
        <Box
          sx={{
            p: 1,
            pl: 3,
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          No posts found
        </Box>
      )}
      {searchInput.length !== 0 &&
        searchedPostsData?.slice(0, numResults)?.map((item, idx) => (
          <Box
            sx={{
              color: "black",
            }}
          >
            <Box
              sx={{
                ml: 2,
                p: 1,
                mt: 2,
              }}
              key={idx}
              onClick={() => {
                navigate(`/post/${item.unique_id}`);
              }}
            >
              <Box>
                <Typography
                  letterSpacing={0.4}
                  style={{
                    marginBottom: "10px",
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  {item.Title}
                </Typography>
                <Typography
                  letterSpacing={0.4}
                  display="block"
                  gutterBottom
                  mt={-1}
                  fontSize={14}
                >
                  {" "}
                  {item.Text}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {searchInput.length !== 0 && (
          <Button
            onClick={toggleShowResults}
            sx={{
              textTransform: "none",
              fontSize: 14,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {showAllResults ? "Show Less" : "Show More"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

ResultForPosts.propTypes = {
  searchInput: PropTypes.string.isRequired,
};

export default ResultForPosts;
