import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../../context/ThemeContext";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { Cookies } from "react-cookie";
import { backend_url } from "../../../http-common";
import useViewPort from "../../../hooks/useViewPort";
import useSavedTabs from "../hooks/useSavedTabs";
import { BREAKPOINT } from "../../../constants/constants";
import MobileTabsDropdown from "../../mobile-tabs-dropdown";
import { useMediaQuery } from "@mui/material";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "#313342",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SavedTab() {
  const [value, setValue] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { savedNews, savedPosts } = React.useContext(StockNewsContext);
  const [data, setData] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const { width } = useViewPort();
  const { tabs } = useSavedTabs();
  React.useEffect(() => {
    fetchPosts();
    fetchNews();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchPosts = async () => {
    if (savedPosts.length > 0) {
      const posts = await Promise.all(
        savedPosts.map(async (postId) => {
          const temp = fetch(`${backend_url}/post-by-postId/${postId}`)
            .then((res) => res.json())
            .then((d) => d.new_res);

          return temp;
        })
      );

      setData(posts);
    } else {
    }
  };

  const fetchNews = async () => {
    if (savedNews.length > 0) {
      const news = await Promise.all(
        savedNews.map(async (id) => {
          const temp = fetch(`${backend_url}/getNewsById/${id}`)
            .then((res) => res.json())
            .then((d) => d.news[0]);

          return temp;
        })
      );

      setNews(news);
    } else {
    }
  };
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <Box
        sx={{
          border: isMobile ? "" : "1px solid #e0e0e0",
          boxShadow: "none",
          height: 48,
          backgroundColor: "white",
        }}
      >
        {width < BREAKPOINT ? (
          <MobileTabsDropdown tabs={tabs} />
        ) : (
          <Box>
            <Box sx={{ border: "none" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {tabs?.map((tab, index) => {
                  return (
                    <Tab
                      label={tab?.name}
                      sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#372B47",
                      }}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {tabs?.map((tab, index) => {
              return (
                <TabPanel
                  value={value}
                  index={index}
                  style={{ marginTop: "20px" }}
                >
                  {tab.component}
                  <br />
                  <br />
                </TabPanel>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
}
