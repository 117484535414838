import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FiSend, FiShare } from "react-icons/fi";
import CloseIcon from "@mui/icons-material/Close";

import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookShareCount,
  LinkedinIcon,
  FacebookIcon,
  InstapaperShareButton,
  TelegramIcon,
} from "react-share";
import { FaInstagram } from "react-icons/fa";
import { Box, Divider, Typography } from "@mui/material";

export default function ShareDialog({ unique_id }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const url = `https://web.askfundu.com/post/${unique_id}`;

  return (
    <div>
      <Button
        sx={{ textTransform: "none", display: "flex", gap: 1, color: "black" }}
        onClick={handleClickOpen}
      >
        <FiSend style={{ fontSize: 18, color: "#797979" }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: 18,
                mt: 1,
                fontWeight: 700,
                color: "black",
              }}
            >
              Share Post
            </Typography>
            <Button onClick={handleClose}>
              <CloseIcon
                style={{
                  color: "black",
                  marginLeft: "50px",
                  marginTop: "10px",
                }}
              />
            </Button>
          </Box>
        </DialogTitle>

        <Divider color={"black"} />
        <DialogContent sx={{ display: "flex", gap: 3, p: 6 }}>
          <TwitterShareButton url={url}>
            <TwitterIcon size={27} squre={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={27} squre={true} />
          </WhatsappShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={27} squre={true} />
          </LinkedinShareButton>

          <FacebookShareButton url={url}>
            <FacebookIcon size={27} squre={true} />
            <FacebookShareCount url={url}>
              {(shareCount) => (
                <span className="myShareCountWrapper">{shareCount}</span>
              )}
            </FacebookShareCount>
          </FacebookShareButton>
          <TelegramShareButton url={url}>
            <TelegramIcon size={27} squre={true} />
          </TelegramShareButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}
