import { React, useContext } from "react";
import Suggestion from "../../Components/common/components/suggestion";
import { ThemeContext } from "../../context/ThemeContext";
import { Cookies } from "react-cookie";
import AllNewsTab from "../../Components/fundu-news";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import useScroll from "../../Components/common-functions/useScroll";
import { BREAKPOINT } from "../../constants/constants";
import MobileDefault from "../../Components/layout/mobile";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import { Helmet } from "react-helmet";
import LayoutMerge from "../../Components/layout/page/merge";

const AllNewsPage = () => {
  const { theme } = useContext(ThemeContext);
  const { getNews } = useContext(StockNewsContext);
  const { handleScroll, loading } = useScroll(getNews);
  const { width } = useViewPort();
  const cookie = new Cookies();
  const user = cookie.get("user");

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      <Helmet>
        <title>News in Bullet Points</title>
        <description>
          Join the ranks of successful traders by staying ahead of the game with
          our cutting-edge financial news platform. Our news summarization
          feature delivers bite-sized, 50-60 word summaries of the latest market
          developments, giving you the insights you need to make profitable
          trades. With top traders to follow and lively discussions to engage
          in, our social trading community is the perfect place to hone your
          skills and build your portfolio. Don't miss out on the action - sign
          up today and see what all the buzz is about!
        </description>
      </Helmet>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <>
              <AllNewsTab loading={loading} />
            </>
          }
          handleScroll={handleScroll}
        />
      ) : (
        // <LayoutDefault
        //   mainContent={
        //     <>
        //       <AllNewsTab loading={loading} />
        //     </>
        //   }
        //   rightPanelContent={
        //     <div>
        //       {user && <Suggestion />}
        //       <MostPopularStocksRight />
        //     </div>
        //   }
        //   handleScroll={handleScroll}
        // />

        <LayoutMerge
          mainContent={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <AllNewsTab loading={loading} />
            </div>
          }
          handleScroll={handleScroll}
        />
      )}
    </Box>
  );
};

export default AllNewsPage;
