import { React, useEffect, useState, useContext } from "react";
import PostDetailsPage from "../../Components/post-details-page";
import ViewProfile from "../../Components/post-details-page/view-profile";
import { useParams } from "react-router-dom";
import { backend_url } from "../../http-common";
import { ThemeContext } from "../../context/ThemeContext";
import Suggestion from "../../Components/common/components/suggestion";
import ProfileDetailsTabs from "../../Components/post-details-page/profile-details-tab";
import { Cookies } from "react-cookie";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";

const PostDetailsMainPage = () => {
  const { theme } = useContext(ThemeContext);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const { postId } = useParams();
  const cookie = new Cookies();
  const { width } = useViewPort();
  const user1 = cookie.get("user");

  useEffect(() => {
    fetchPost();
  }, []);

  let fetchPost = () => {
    fetch(`${backend_url}/post-by-postId/${postId}`)
      .then((res) => res.json())
      .then((d) => {
        // console.log("data from api", d.posts);
        setData(() => d.posts);
      });
  };

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <div>
              <PostDetailsPage data={data} fetchPost={fetchPost} />
              <ProfileDetailsTabs />
              <br></br>
              <br></br>
            </div>
          }
        />
      ) : (
        <LayoutDefault
          mainContent={
            <div>
              <PostDetailsPage data={data} fetchPost={fetchPost} />
              <ProfileDetailsTabs />
              <br></br>
              <br></br>
            </div>
          }
          rightPanelContent={
            <div>
              <br></br>
              <ViewProfile user={data && data[0]?.userDetails} />
              {user1 && <Suggestion />}
              <MostPopularStocksRight />
              <TrendingNewsCard />
            </div>
          }
        />
      )}
    </Box>
  );
};

export default PostDetailsMainPage;
