import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkFollow,
  capitalFirstLetter,
} from "../../../common-functions/FormatDate";
import ImgPlaceholder from "../../../../assets/image_placeholder.png";
import PropTypes from "prop-types";
import { ThemeContext } from "../../../../context/ThemeContext";
import FuButton from "../../../shared/FuButton";
import { BsCheck } from "react-icons/bs";

const ResultForUsers = ({ searchInput, searchedUsersData }) => {
  const navigate = useNavigate();
  const { myFollowings, handleFollow } = useContext(ThemeContext);
  const [showAllResults, setShowAllResults] = useState(false);
  const [numResults, setNumResults] = useState(4);

  const toggleShowResults = () => {
    setShowAllResults(!showAllResults);
    if (!showAllResults) {
      setNumResults(searchedUsersData.length);
    } else {
      setNumResults(4);
    }
  };

  return (
    <Box>
      {searchInput.length !== 0 && searchedUsersData?.length === 0 && (
        <Box
          sx={{
            p: 2,
            pl: 3,
            fontWeight: 600,
            fontSize: 14,
            color: "#372B47",
          }}
        >
          No people found
        </Box>
      )}

      {searchInput.length !== 0 &&
        searchedUsersData?.slice(0, numResults).map((item, idx) => (
          <Box
            sx={{
              p: 1,
              ml: 2,
              cursor: "pointer",
            }}
            key={idx}
          >
            <Grid
              container
              spacing={2}
              justifyContent={"space-between"}
              columns={12}
            >
              <Grid item xs={8} display={"flex"}>
                <Avatar
                  src={item.userpic_url || ImgPlaceholder}
                  alt="img"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
                <Box
                  onClick={() => {
                    navigate(`/user/${item.username}`);
                  }}
                  sx={{ display: "grid", ml: 1, fontSize: 12 }}
                >
                  <Typography
                    letterSpacing={0.4}
                    sx={{ fontSize: 14 }}
                  >{`${capitalFirstLetter(
                    item.First_Name
                  )} ${capitalFirstLetter(item.last_name)}`}</Typography>
                  <Typography
                    letterSpacing={0.4}
                    display="block"
                    color="#c5c7c7"
                    fontSize="11px"
                    ml={0.5}
                    gutterBottom
                    mt={-1}
                  >
                    {`@${item.username}`}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <FuButton
                  onClick={() => handleFollow(idx, item.username)}
                  style={{ height: 25 }}
                >
                  {checkFollow(myFollowings, item.username) ? (
                    <BsCheck fontSize={22} />
                  ) : (
                    "Follow"
                  )}
                </FuButton>
              </Grid>
            </Grid>
          </Box>
        ))}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {searchInput.length !== 0 && (
          <Button
            onClick={toggleShowResults}
            sx={{
              textTransform: "none",
              fontSize: 14,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {showAllResults ? "Show Less" : "Show More"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

ResultForUsers.propTypes = {
  searchInput: PropTypes.string.isRequired,
};

export default ResultForUsers;
