import React from "react";
import "./index.css";
import logo from "../../assets/askfunduLogo.png";

const PrivacyPolicy = () => {
  return (
    <div className="Privacy_div">
      <div className="privacy_first_div">
        <img
          style={{
            height: "80px",
            width: "250px",

            marginBottom: "-20px",
          }}
          src={logo}
          alt="logo"
        />
        <h1>Privacy Policy</h1>
        <div className="privacy_subhead">Updated 12/05/2023</div>
      </div>
      <div className="Privacy_second_div">
        <h1 className="privicy_head">Privacy Policy</h1>
        <p>
          askFundu.com is a product by Fundu Fantasy Games Private Limited
          (hereinafter referred to as askFundu ). askFundu Fantasy Games is
          committed to safeguarding any information submitted to us on our
          website and or services. The privacy notice provides you with a
          summary of the type of information collected by us, policy regarding
          the use of that information, and measures taken to safeguard that
          information.
        </p>

        <br />
        <h1 className="privicy_head">User Consent</h1>

        <p>
          By submitting Personal Information through our Site or Services, you
          agree to the terms of this Privacy Policy and you expressly give your
          voluntary consent to the collection, use, and disclosure of the
          Personal Information in accordance with this Privacy Policy.
        </p>
        <br />

        <h1 className="privicy_head">Information you provided to us</h1>
        <p>
          If you provide us feedback or contact us via e-mail, we will collect
          your name and e-mail address, as well as any other content included in
          the e-mail, in order to send you a reply. When you participate in one
          of our surveys, we may collect additional information. We may collect
          Personal Information from you, such as your first and last name,
          phone, e-mail address, age etc. when you apply for creating/opening an
          account with one of our partners - brokers, advisors etc.
        </p>
        <br />
        <p>
          We may also collect Personal Information on the Site or through the
          Services when we make clear that we are collecting it and you
          voluntarily provide the same, for example when you submit an
          application for employment.
        </p>

        <br />
        <p>
          You must ensure to recheck the information or data being provided to
          us. In case if any of the data/information is found to be inaccurate
          or incorrect, the same shall be informed to us for immediate
          amendment. We shall not be responsible for the authenticity of any
          information including personal information submitted to us.
        </p>
        <h1 className="privicy_head">Information collected via technology</h1>
        <br />

        <p>
          To make our Site and Services more useful to you, our servers (which
          may be hosted by a third-party service provider) collect information
          from you, including your browser type, operating system, Internet
          Protocol (IP) address (a number that is automatically assigned to your
          computer when you use the Internet, which may vary from session to
          session), and domain name.
        </p>
        <br />
        <p>
          We may use third-party service providers to help us analyze certain
          online activities. For example, these service providers may help us
          analyze visitor activity on the Site. We may permit these service
          providers to use cookies and other technologies to perform these
          services for us. Use of personal information In general, Personal
          Information you submit to us regarding you or your company is used
          either to respond to requests that you make or to aid us in serving
          you better. We use such Personal Information in the following ways:
        </p>
        <p>1. To identify you as a user in our system;</p>
        <p>2. To provide improved administration of our Site and Services;</p>
        <p>3. To provide the Services you request;</p>
        <p>
          4. To improve the quality of experience when users interact with our
          Site and
        </p>
        <p>5. Services</p>
        <p>6. To send you e-mail notifications</p>
        <p>
          7. To send newsletters, surveys, offers, and other promotional
          materials related to our Services and for other marketing purposes.
        </p>
        <p>8. Protect our services and our users.</p>
        <p>9. Market and customer analysis, market research, and statistics.</p>
        <p>
          10. To prevent and detect fraud or abuse of our services as well as to
          prevent, detect and investigate any potentially unlawful or prohibited
          activities.
        </p>
        <h1>Disclosure to third parties</h1>
        <p>
          We may be required from time to time to disclose any personal
          information collected by us to governmental or judicial bodies or
          agencies or our regulators based on their requirement to comply with
          the applicable laws including cyber laws.
        </p>
        <br />

        <p>
          We may share your information including personal information to third
          parties to carry out technical, logistical, and other functions on our
          behalf. (eg. Sending emails or newsletters to users).
        </p>
        <br />
        <h1>Creation of anonymous information</h1>
        <p>
          We may create Anonymous Information records from Personal Information
          by excluding information (such as the name and email), We use this
          Anonymous Information to analyze request and usage patterns so that we
          may enhance the content of our Services and improve Site navigation.
          askFundu Fantasy Games reserves the right to use Anonymous Information
          for any purpose and disclose Anonymous Information to third parties at
          its sole discretion.
        </p>

        <br />
        <h1>Third party websites</h1>
        <p>
          When you click on a link to any other website or location, you will
          leave our Site and go to another site and another entity may collect
          Personal Information or Anonymous
        </p>
        <br />
        <p>
          Information from you. We have no control over, do not review, and
          cannot be held responsible for, these outside websites or their
          content. Please be aware that the terms of this Privacy Policy do not
          apply to these outside websites or content, or to any collection of
          information after you click on links to such outside websites.
        </p>
        <br />

        <h1>Cookies</h1>
        <br />
        <p>
          "Cookies" are pieces of information that a browser can record after
          visiting a website. We may use cookies for technical purposes such as
          to enable better navigation through our site or to store user
          preferences for interacting with the site. If you turn off the option
          to have cookies stored on your browser, it will affect your experience
          on askFundu.com
        </p>
        <br />

        <h1>Security of your information</h1>

        <p>
          askFundu Fantasy Games is committed to protecting the security and
          privacy of your Personal Information. We endeavor to have suitable
          technical, operational, and physical security controls and measures to
          protect information collected by us that are commensurate with the
          nature of our business at all times. We use a variety of
          industry-standard security technologies and procedures to help protect
          your Personal Information from unauthorized access, use, or
          disclosure.
        </p>

        <br />

        <p>
          Further, despite our efforts to protect your information, if
          unauthorized persons breach security control measures and illegally
          use such sensitive/personal data or information, askFundu
          Technologies, its affiliates, and directors shall not be held
          responsible/liable.
        </p>
        <br />
        <p></p>

        <h1>Retention of information/data</h1>
        <br />

        <p>
          It may be noted that we may retain the data as may be provided to us
          till such time as may be provided under the law and as required to
          efficiently provide service to our users
        </p>
        <br />

        <h1>Amendments/Modifications</h1>
        <br />
        <p>
          This Privacy Policy available on our website is current and valid.
          However, askFundu Technologies reserves the right to amend/modify any
          of the sections of this policy at any time and the general public are
          requested to keep themselves updated for changes by reading the same
          from time to time
        </p>
        <br />
        <h1>Contact Information</h1>
        <p>
          askFundu welcomes your comments or questions regarding this Privacy
          Policy. Please e-mail us at info@askFundu.com or contact us at the
          following address:
        </p>
        <p>Fundu Fantasy Games Private Limited</p>
        <p>Gurgaon</p>
        <p>Definitions</p>
        <p>
          Anonymous Information - Anonymous information means information that
          does not directly or indirectly identify, and cannot reasonably be
          used to identify, an individual guest / user Internet Protocol (IP) -
          Internet Protocol (IP) is the principal set (or communications
          protocol) of digital message formats and rules for exchanging messages
          between computers across a single network or a series of
          interconnected networks, using the Internet Protocol Suite (often
          referred to as TCP/IP). Messages are exchanged as datagrams, also
          known as data packets or just packets.
        </p>
        <br />
        <p>
          Personal Information - Personal Information means any information that
          relates to a natural person, which, either directly or indirectly, in
          combination with any other information available, is capable of
          identifying such person.
        </p>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
