import { lazy } from "react";

const MostPopularStockPageRoute = [
  {
    path: "/stocks",
    component: lazy(() => import("./index")),
  },
  {
    path: "/stocks/indices",
    component: lazy(() => import("./index")),
  },
  // {
  //   path: "/stocks/sentiment/sell",
  //   component: lazy(() => import("./index")),
  // },
  // {
  //   path: "/stocks/sentiment/neutral",
  //   component: lazy(() => import("./index")),
  // },
];

export default MostPopularStockPageRoute;
