export const sliderData = {
  firstSlide: [
    {
      top_title: "FundU Social",
      title:
        "Welcome to askFundu, the place where money meets the fun! We're stoked to have you here, and we can't wait to see what kind of finance adventures we'll go on together.",
      title2:
        "  Get ready to ask questions, share your tips and tricks, and join in on some epic money discussions. With our community, finance is never boring - so, lets have some fun and make that money work for us!",
      guide_img: "https://i.ibb.co/TKcH5sy/userguide2.jpg",
    },
    // {
    //   top_title: "FundU Rooms",
    //   title: "Here you can create rooms with various traders for discusssions.",
    //   guide_img: "https://i.ibb.co/hs2BSmd/userguide3.jpg",
    // },
    // {
    //   top_title: "FundU Games",
    //   title:
    //     "We have a game section related to stocks for better understanding.",
    //   guide_img: "https://i.ibb.co/bg3fb22/userguide4.jpg",
    // },
  ],
  secondSlide: {
    strategies: [
      "Stocks",
      "Bonds",
      "Gold",
      "Government Securities",
      "Real Estate",
      "Commodity",
      "Mutual Funds",
      "Exchange-Trade Funds (ETFs)",
      "Real Estate Investment Trusts (REITs)",
      "Certificate of Deposits (CDs)",
      "Healthcare",
      "EV",
      "Information Technology",
      "Pharma",
      "Core Economy sectors",
      "Power Sectors",
      "Finance Sector",
      "Automobile",
      "Crypto",
      "Web 3",
      "NFT",
      "DeFi",
    ],
  },

  firstQuestion: {
    options: ["No Experience", "<1 Year", "1-2 Year", "2-5 Year", "5+ Year"],
  },

  secondQuestion: {
    options: [
      "Asking Questions",
      "Answering Queries",
      "Market Analysis",
      "Economic Indicators",
      "Geopolitics",
      "Fundamental Analysis",
      "Tecnical Trading",
      "News Summaries",
    ],
  },
};
