import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { ThemeContext } from "../../context/ThemeContext";
import { Snackbar } from "@mui/material";

const AlertBox = ({ message }) => {
  const [open, setOpen] = React.useState(true);
  const { showAlertBox, setShowAlertBox } = React.useContext(ThemeContext);

  const handleCloseAlert = () => {
    setOpen(false);
    let newStatus = {
      status: "",
      from: "",
      as: "",
    };
    setShowAlertBox(() => newStatus);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {showAlertBox.from === "addTags" ? (
          <Alert onClose={handleCloseAlert} icon={false} color="warning">
            You can only add maximum six tags.
          </Alert>
        ) : (
          <Alert
            onClose={handleCloseAlert}
            style={{ backgroundColor: "#5D20D2", color: "white" }}
            icon={false}
          >
            {`${showAlertBox.from} ${showAlertBox.as} successfully`}
          </Alert>
        )}
      </Snackbar>
    </Box>
  );
};

export default AlertBox;
