import React, { useContext, useMemo } from "react";
import { AiFillSetting, AiOutlineBell } from "react-icons/ai";
import { BsLaptop } from "react-icons/bs";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import Avatar from "@mui/material/Avatar";
import { Cookies } from "react-cookie";
import Badge from "@mui/material/Badge";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { FiHelpCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function UseMore() {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { alertCount } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const { username } = useParams();

  const USE_MORE = useMemo(
    () => [
      {
        title: t("Profile"),
        icon: (
          <Avatar
            sx={{ width: 24, height: 24, border: "1px solid #D8D1E3" }}
            src={user?.userpic_url}
          />
        ),
        link: user?.isExpert
          ? `/rooms/${user?.username}`
          : `/user/${user?.username}`,
        type: "profile",
      },
      {
        title: t("Saved"),
        icon: <TurnedInNotIcon />,
        link: "/saved",
        type: "saved",
      },

      {
        title: t("Help"),
        icon: <FiHelpCircle />,
        link: "/help",
        type: "help",
      },

      {
        title: t("Settings"),
        icon: <AiFillSetting />,
        link: "/settings",
        type: "settings",
      },

      {
        title: t("Sign Out"),
        icon: <FaSignOutAlt />,
        type: "signout",
      },
    ],
    [
      alertCount,
      user?.username,
      user?.userpic_url,
      i18n?.language,
      user?.isExpert,
    ]
  );
  return React.useMemo(() => {
    return {
      USE_MORE,
    };
  }, [USE_MORE, i18n?.language]);
}

export default UseMore;
