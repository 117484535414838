import React, { useContext, useState, memo, useCallback } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { capitalFirstLetter } from "../../Components/common-functions/FormatDate";
import { Link } from "react-router-dom";
import Before from "../../Components/suggestion-tabs/components/Before";
import ImgPlaceholder from "../../assets/image_placeholder.png";
import { Cookies } from "react-cookie";
import { Avatar, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FuButton from "../../Components/shared/FuButton";
import { checkFollow } from "../../Components/common-functions/FormatDate";
import { BsCheck } from "react-icons/bs";
import "./index.css";

export const Allsuggestion = () => {
  const { theme, myFollowings, handleFollow, usersData } =
    useContext(ThemeContext);
  const [data, setData] = useState([...usersData]);
  const cookie = new Cookies();

  return (
    <>
      <Card>
        {data.length === 0 && <Before />}
        {data.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              pl: 3,
            }}
          >
            <Box display={"flex"}>
              <Avatar
                src={item.userpic_url || ImgPlaceholder}
                alt="img"
                sx={{ width: 48, height: 48 }}
              />

              <Box ml={1.5}>
                <Link
                  to={`/user/${item.username}`}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {`${capitalFirstLetter(item.First_Name)} ${capitalFirstLetter(
                    item.last_name
                  )}`}
                  <br />
                  <Typography
                    letterSpacing={0.4}
                    sx={{ color: "rgb(112, 112, 123)", fontSize: 12 }}
                  >{`@${item.username}`}</Typography>
                </Link>
              </Box>
            </Box>

            <FuButton
              onClick={() => handleFollow(idx, item.username)}
              style={{ height: 33 }}
            >
              {checkFollow(myFollowings, item.username) ? (
                <BsCheck fontSize={22} />
              ) : (
                "Follow"
              )}
            </FuButton>
          </Box>
        ))}
      </Card>
    </>
  );
};
export default memo(Allsuggestion);
