import React from "react";

const Progressbar = ({ agreePercent }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "25px",
        borderRadius: "5px",
        display: "flex",
        gap: "2px",
        fontSize: "12px",
        padding: "2px",
        boxSizing: "border-box",
        color: "white",
        marginTop:"10px"
      }}
    >
      <div
        style={{
          width: `${agreePercent}%`,
          backgroundColor: "#58BD7D",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          padding: "1px",
        }}
      >
        <p style={{ marginLeft: "2px" }}>{`Agree ${agreePercent}%`}</p>
      </div>
      <div
        style={{
          width: `${100 - agreePercent}%`,
          backgroundColor: "#e65b5d",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          padding: "1px",
        }}
      >
        <p style={{ float: "right", marginRight: "2px" }}>{`${
          100 - agreePercent
        }% Disagree`}</p>
      </div>
    </div>
  );
};

export default Progressbar;
