import React from "react";
import Chart from "@qognicafinance/react-lightweight-charts"; // new chart library
import { useState } from "react";
import { useEffect } from "react";
import { getRequest } from "../../../services/request";
import { capitalFirstLetter } from "../../common-functions/FormatDate";
import { Box } from "@mui/material";

const TimeSeries = ({ headline, profileUsername }) => {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState("All");
  const [linechartData, setLinechartData] = useState([]);

  useEffect(() => {
    function fetchData() {
      getRequest({ url: `/users/get-portfolio-series/${profileUsername}` })
        .then((res) => {
          setLinechartData(res?.data?.series);
        })
        .catch((e) => {
          setLinechartData([]);
        });
    }

    fetchData();
  }, []);
  const graphColor = data
    ? data[data.length - 1]?.LAST - data[data.length - 2]?.LAST
    : "";

  const config = {
    data,
    color: graphColor < 0 ? "#ff6899" : "#58bd7d",
    padding: "auto",
    // xField: "Date",
    // yField: "Average Price",
    xField: "Date",
    yField: "LAST",
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
  };

  // options for new line chart
  let options = {
    alignLabels: true,

    timeScale: {
      rightOffset: 12,
      barSpacing: 3,
      lockVisibleTimeRangeOnResize: true,
      rightBarStaysOnScroll: true,
      borderVisible: false,
      borderColor: "#fff000",
      visible: true,
      timeVisible: true,
      secondsVisible: true,
      fixLeftEdge: true,
      fixRightEdge: true,
      tickMarkFormatter: (time, tickIndex, ticks) => {
        let date;
        if (current === "1D") {
          date = new Date(time * 1000).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
            hour: "2-digit",
            minute: "2-digit",
          });
        } else if (current === "1W") {
          date = new Date(time * 1000);
          const day = date.toLocaleDateString("en-US", {
            timeZone: "Asia/Kolkata",
          });
          let temp = date.toLocaleDateString("en-US", {
            timeZone: "Asia/Kolkata",
            month: "numeric",
            day: "numeric",
          });
          date = `${temp}`;
        } else {
          date = new Date(time);
          date = date.toLocaleDateString("en-IN", {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            // weekday: "numeric",
            month: "numeric",
            // day: "numeric",
          });
        }
        return date;
      },
    },
    watermark: {
      visible: true,
      fontSize: 34,
    },
  };

  let areaSeriesOptions = {
    lineColor:
      linechartData[0]?.value - linechartData[linechartData.length - 1]?.value >
      0
        ? "#ff6899"
        : "#58bd7d",
    lineWidth: 1,
    autoScale: true,
    autoWidth: true,
    topColor:
      linechartData[0]?.value - linechartData[linechartData.length - 1]?.value >
      0
        ? "#f7dae3"
        : "#d2fce1",
    bottomColor:
      linechartData[0]?.value - linechartData[linechartData.length - 1]?.value >
      0
        ? "#ffeff4"
        : "#e1f7e9",
  };

  return (
    <>
      <div>
        {headline && (
          <>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "50px",
              }}
            >
              <h1>{headline}</h1>
            </div>
            {linechartData?.length > 0 && (
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginTop: "50px",
                  marginBottom: "32px",
                }}
              >
                {linechartData?.length > 0
                  ? `${capitalFirstLetter(
                      profileUsername
                    )}'s portfolio performance`
                  : `${capitalFirstLetter(
                      profileUsername
                    )} have no stock in the portfolio`}
              </div>
            )}
          </>
        )}
        {linechartData?.length > 0 ? (
          <Chart
            options={options}
            areaSeries={[
              {
                data: linechartData,
                options: areaSeriesOptions,
              },
            ]}
            autoScale={true}
            autoWidth
            height={320}
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "white",
              textAlign: "center",
              width: "100%",
            }}
          >
            {capitalFirstLetter(profileUsername)} have no stock in the portfolio
          </Box>
        )}
      </div>
    </>
  );
};

export default TimeSeries;
