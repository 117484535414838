import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { auth } from "../../../../Firebase";
import { Box } from "@mui/material";
import { BsFacebook } from "react-icons/bs";
import { backend_url } from "../../../../http-common";
import { t } from "i18next";

const provider = new FacebookAuthProvider();

function FacebookLogin({ handleSignin }) {
  const signin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        createUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createUser = async (user) => {
    let payload = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL,
    };
    try {
      let response = await fetch(`${backend_url}/users/create-fb-user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      response = await response.json();
      handleSignin(response);
    } catch (e) {
      console.log({ msg: "something went wrong", err: e.message });
    }
  };

  return (
    <Box>
      <button
        className="googlelogin_button"
        onClick={signin}
        style={{
          fontSize: "16px",
          fontWeight: "500",
          marginTop: "10px",
        }}
      >
        <BsFacebook size={22} style={{ marginRight: "5px", color: "blue" }} />
        {t("Continue with Facebook")}
      </button>
    </Box>
  );
}

export default FacebookLogin;
