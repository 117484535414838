import { useMemo } from "react";
import UserTab from "../user-tab";
import PostsTab from "../posts-tab";
import NewsTab from "../news-tab";
import StocksTab from "../stocks-tab";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useSeeAllResultTabs = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchInput = useMemo(
    () => searchParams.get("q")?.toString() || "",
    [searchParams]
  );
  const tabs = useMemo(
    () => [
      {
        name: t("User"),
        component: <UserTab tabName="User" searchInput={searchInput} />,
      },
      {
        name: t("Posts"),
        component: <PostsTab tabName="Post" searchInput={searchInput} />,
      },
      {
        name: t("News"),
        component: <NewsTab tabName="News" searchInput={searchInput} />,
      },
      {
        name: t("Stocks"),
        component: <StocksTab tabName="Stocks" searchInput={searchInput} />,
      },
    ],
    [searchInput, i18n.language]
  );

  return {
    tabs,
  };
};

export default useSeeAllResultTabs;
