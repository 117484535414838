import * as React from "react";
import { useMemo } from "react";
import AllNews from "../all-news";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function useNewTabs(loading) {
  const { newsData } = React.useContext(StockNewsContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const positive = newsData?.filter((item) => item.Sentiment === "Positive");
  const negative = newsData?.filter((item) => item.Sentiment === "Negative");
  const neutral = newsData?.filter((item) => item.Sentiment === "Neutral");
  const tabs = useMemo(
    () => [
      {
        name: t("News"),
        component: <AllNews data={newsData} loading={loading} />,
      },
      {
        name: t("Positive"),
        component: <AllNews data={positive} loading={loading} />,
      },
      {
        name: t("Negative"),
        component: <AllNews data={negative} loading={loading} />,
      },
      {
        name: t("Neutral"),
        component: <AllNews data={neutral} loading={loading} />,
      },
    ],
    [negative, neutral, newsData, positive]
  );

  return {
    tabs,
  };
}

export default useNewTabs;
