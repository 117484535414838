import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function LinkPreviewCard({ linkDetails }) {
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBlock: "15px",
        border: "0.5px solid #b7b7b7",
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography letterSpacing={0.4} fontSize="12px" fontWeight={600}>
          {linkDetails?.title}
        </Typography>
        <Typography
          letterSpacing={0.4}
          variant="subtitle1"
          component="div"
          fontSize="12px"
          mt="10px"
          sx={{ textDecoration: "none" }}
        >
          {linkDetails?.description}
        </Typography>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151, textDecoration: "none" }}
        image={linkDetails?.image}
        alt={linkDetails?.title}
      />
    </Card>
  );
}
