import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Divider, Typography } from "@mui/material";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";
import { getAuthenticatedRequest, getRequest } from "../../../services/request";
import { CometChatUI } from "../../../cometchat-pro-react-ui-kit/CometChatWorkspace/src";
import CloseIcon from "@mui/icons-material/Close";
import useMakeCometChatProfile from "../../rooms-new/hooks/useMakeCometChatProfile";
import FuLoader from "../../shared/FuLoader";
import {
  convertTimeTo12HourFormat,
  hasTimePassed,
  isFifteenMinutesAway,
} from "../../common-functions/FormatDate";
import { useTranslation } from "react-i18next";

export const Messages = () => {
  const [translate, setTranslate] = useState(false);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [isUserExpert, setIsUserExpert] = useState(false);
  const [isCometLoggedIn, setIsCometLoggedIn] = useState(false);
  const [chatWithcometChatUser, setChatWithCometChatUser] =
    useState("imgovind24");
  const [connectingTochat, setConnectingToChat] = useState(false);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const updateTranslate = () => {
    setTranslate(!translate);
  };
  const { t } = useTranslation();
  const { loginCommetChatUser, logoutCommetChatUser } =
    useMakeCometChatProfile();

  const loginForCometChat = (expert_username) => {
    setConnectingToChat(true);
    loginCommetChatUser(user)
      .then((res) => {
        console.log(res, "cometchat user loggedin");
        setIsCometLoggedIn(true);
        setConnectingToChat(false);
        setChatWithCometChatUser(expert_username);
      })
      .catch((e) => {
        setIsCometLoggedIn(false);
        setConnectingToChat(false);
      });
  };

  const logoutForCometChat = () => {
    logoutCommetChatUser().then((res) => {
      console.log("logout from cometchat", res);
      setChatWithCometChatUser(null);
    });
    setIsCometLoggedIn(false);
  };

  const getBookedSlotsByUser = async () => {
    try {
      let currUser = await getRequest({
        url: `/get-user-by-name/${user.username}`,
      });
      if (!currUser?.data?.response[0]?.isExpert) {
        setIsUserExpert(false);
        let response = await getAuthenticatedRequest({
          url: `/experts/get-booked-slot-by-username/${user.username}`,
        });
        setBookedSlots(response?.data?.slots);
        // console.log("data completed", response);
      } else {
        setIsUserExpert(true);
        let response = await getAuthenticatedRequest({
          url: `/experts/get-expert-slot-by-username/${user.username}`,
        });
        setBookedSlots(response?.data?.slots);
      }
    } catch (e) {
      setBookedSlots([]);
    }
  };

  useEffect(() => {
    getBookedSlotsByUser();
  }, []);

  return (
    <Box
      sx={{
        height: "0",
        position: "fixed",
        zIndex: "1000",
        bottom: "0",
        right: "0",
        overflow: "visible",
        display: "flex",
        flexDirection: "row-reverse",
        flexWrap: "nowrap",
        alignItems: "flex-end",
        width: "28.3%",
        cursor: "pointer",
        marginRight: "15px",
      }}
    >
      <Box
        sx={{
          height: "calc(100vh - 60px)",
          transform: translate
            ? "translateY(0)"
            : "translateY(100%) translateY(-55px)",
          width: "100%",
          background: "white",
          boxShadow: "none",
          border: "1px solid #e0e0e0",
          borderRadius: "8px 8px 0px 0px",
          animation: "fade-in 167ms cubic-bezier(.4,0,1,1)",
          overflowY: translate ? "scroll" : "hidden",
          scrollbarWidth: "none", // Hide scrollbar in Firefox
          msOverflowStyle: "none", // Hide scrollbar in Internet Explorer
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          display="flex"
          p={1}
          gap={1}
          top={0}
          justifyContent={"space-between"}
        >
          <Box display="flex" gap={2}>
            <Avatar
              src={user?.userpic_url}
              sx={{
                width: 34,
                height: 34,
              }}
            />
            <Typography
              letterSpacing={0.4}
              sx={{ fontWeight: 600, mt: 1, fontSize: 14, color: "#372B47" }}
            >
              {t("Appointment")}
            </Typography>
          </Box>

          <IconButton aria-label="settings" onClick={() => updateTranslate()}>
            {translate ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>

        <Divider color={"black"} />
        {isCometLoggedIn && (
          <Typography
            letterSpacing={0.4}
            onClick={logoutForCometChat}
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "right",
              ml: -2,
            }}
          >
            <CloseIcon
              sx={{
                color: "black",
                fontSize: 16,
              }}
            />
          </Typography>
        )}

        {connectingTochat ? (
          <FuLoader />
        ) : isCometLoggedIn ? (
          <Box sx={{ height: "100vh", width: "100wh", textAlign: "center" }}>
            <CometChatUI chatWithUser={chatWithcometChatUser} />
          </Box>
        ) : (
          <Box sx={{ height: "70vh", width: "100wh", textAlign: "center" }}>
            {bookedSlots?.map((item) => (
              <Box
                key={item._id}
                sx={{
                  m: 2,
                }}
              >
                {item?.booked_slots
                  ?.filter(
                    (slotT) =>
                      !hasTimePassed(
                        isUserExpert ? item?.booking_day : item?.booked_date,
                        slotT.timing?.to.join(":")
                      )
                  )
                  .map((slot) => (
                    <Box
                      key={slot._id}
                      sx={{
                        background: "white",
                        p: 2,
                        mb: 2,
                        // border: "4px solid #ba08f2 ",
                        // borderRadius: "8px",
                        // boxShadow: "0px 4px 4px #D8D1E3",

                        border: "1px solid #e0e0e0 ",
                        borderRadius: "8px",
                        boxShadow: "none",
                      }}
                    >
                      <Typography
                        letterSpacing={0.4}
                        fontSize={14}
                        fontWeight={600}
                        display={"flex"}
                      >
                        {isUserExpert ? "Booked By : " : "Expert : "}{" "}
                        <Link
                          to={`/user/${
                            isUserExpert
                              ? slot?.booked_by_user
                              : slot.expert_username
                          }`}
                          style={{
                            textDecoration: "none",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 500,
                              marginLeft: "10px",
                              color: "var(--fundu_color)",
                            }}
                          >
                            {isUserExpert
                              ? slot?.booked_by_user
                              : slot.expert_username}
                          </p>
                        </Link>
                      </Typography>
                      <Typography
                        letterSpacing={0.4}
                        fontSize={14}
                        fontWeight={600}
                        display={"flex"}
                      >
                        Timings:{" "}
                        <p style={{ fontWeight: 500, marginLeft: "10px" }}>
                          {convertTimeTo12HourFormat(
                            slot.timing?.from.join(":")
                          )}{" "}
                          -{" "}
                          {convertTimeTo12HourFormat(slot.timing?.to.join(":"))}
                        </p>
                      </Typography>
                      <Typography
                        letterSpacing={0.4}
                        fontSize={14}
                        fontWeight={600}
                        display={"flex"}
                      >
                        Booked Date:{" "}
                        <p style={{ fontWeight: 500, marginLeft: "10px" }}>
                          {isUserExpert ? item.booking_day : item?.booked_date}
                        </p>
                      </Typography>

                      <Box display={"flex"} justifyContent={"center"}>
                        <Button
                          sx={{
                            borderRadius: "8px",
                            backgroundColor: "#5D20D2",
                            color: "white",
                            fontSize: 12,
                            textTransform: "none",
                            pl: 2,
                            pr: 2,
                          }}
                          disabled={
                            !isFifteenMinutesAway(
                              `${
                                isUserExpert
                                  ? item?.booking_day
                                  : item?.booked_date
                              } ${slot.timing?.from.join(":")}`
                            )
                          }
                          onClick={() => {
                            // loginForCometChat(
                            //   isUserExpert
                            //     ? slot?.booked_by_user
                            //     : slot.expert_username
                            // )
                            window.open(slot?.meet_link, "_blank");
                          }}
                        >
                          Join
                        </Button>
                      </Box>
                    </Box>
                  ))}
              </Box>
            ))}

            {bookedSlots.length === 0 && (
              <Typography
                letterSpacing={0.4}
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "var(--fundu_color)",
                  textAlign: "center",
                  mt: 23,
                  p: 3,
                }}
              >
                Appointment will be available soon
              </Typography>
            )}
          </Box>
        )}

        {/* <Box
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: 14, letterSpacing: 0.6 }}>
            Book slots
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};
export default Messages;
