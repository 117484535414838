import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BasicRating from "./rating";
import { Box, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Newlogo from "./../../assets/askfunduLogo.png";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ReviewRate() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [text, setText] = useState("");

  const handleSubmit = () => {
    // console.log(text);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Rate
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box justifyContent={"center"} textAlign={"center"}>
            <img
              style={{ width: 130 }}
              onClick={() => {
                navigate("/");
              }}
              src={Newlogo}
              alt="logo"
            />
          </Box>
          <Box mt={4} p={2} justifyContent={"center"} textAlign={"center"}>
            <Typography letterSpacing={0.4} fontSize={24}>
              Rate this Call!
            </Typography>
            <BasicRating />
          </Box>
          <Box mt={4} p={2} display={"grid"}>
            <TextField
              label="write here"
              id="outlined-size-small"
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
            <Box justifyContent={"center"} textAlign={"center"} mt={2}>
              <Button
                sx={{
                  background:
                    "linear-gradient(90deg, #5D20D2 -4.65%, #D220CB 103.49%)",
                  boxShadow: "0px 4px 4px #D8D1E3",
                  color: "white",
                  fontSize: 12,
                  textTransform: "none",
                  ml: 4,
                  width: "120px",
                  height: "40px",
                  borderRadius: "8px",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
