import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../context/ThemeContext";
import UserTab from "./user-tab";
import PostsTab from "./posts-tab";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import NewsTab from "./news-tab";
import StocksTab from "./stocks-tab";
import { useMemo } from "react";
import useViewPort from "../../hooks/useViewPort";
import useSeeAllResultTabs from "./hooks/useSeeAllResultTabs";
import { BREAKPOINT } from "../../constants/constants";
import MobileTabsDropdown from "../mobile-tabs-dropdown";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "#313342",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SeeAllResultsTabs({ handleWichTabChange }) {
  const [value, setValue] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);
  const { width } = useViewPort();
  const { tabs } = useSeeAllResultTabs();
  const navigate = useNavigate();
  // const { searchInput } = useParams();
  const [searchParams] = useSearchParams();
  const searchInput = useMemo(
    () => searchParams.get("q")?.toString() || "",
    [searchParams]
  );

  const handleChange = (event, newValue) => {
    handleWichTabChange(newValue);
    setValue(newValue);
    switch (newValue) {
      case 1:
        navigate(`/result/post?q=${searchInput}`);
        break;
      case 2:
        navigate(`/result/news?q=${searchInput}`);
        break;
      case 3:
        navigate(`/result/stocks?q=${searchInput}`);
        break;
      default:
        navigate(`/result/users?q=${searchInput}`);
    }
  };

  return (
    <>
      <Typography
        letterSpacing={0.4}
        fontSize={14}
      >{`Showing results for "${searchInput}"`}</Typography>
      <Box
        sx={{
          boxShadow: "none",
          border: "1px solid #e0e0e0",
          height: 48,
          mt: 0.5,
        }}
        style={theme ? light : dark}
      >
        {width < BREAKPOINT ? (
          <MobileTabsDropdown tabs={tabs} />
        ) : (
          <Box>
            <Box sx={{ border: "none" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {tabs?.map((tab, index) => {
                  return (
                    <Tab
                      label={tab?.name}
                      sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#372B47",
                      }}
                      {...a11yProps(index)}
                      className={
                        theme
                          ? "tab_different_styles"
                          : "tab_different_styles_theme"
                      }
                    />
                  );
                })}
              </Tabs>
            </Box>
            {tabs?.map((tab, index) => {
              return (
                <TabPanel
                  value={value}
                  index={index}
                  className="tabPanel_mostbought"
                  style={{ marginTop: "10px" }}
                >
                  {tab.component}
                  <br />
                  <br />
                </TabPanel>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
}
