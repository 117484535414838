import { React, useContext, useEffect, useState } from "react";
import "../index.css";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import DefaultNewsCard from "../../layout/base/default";
import FuLoader from "../../shared/FuLoader";

export const AllNews = ({ data, loading }) => {
  const { selectedTagsNews, mobileNewsNumber, setMobileNewsNumber, getNews } =
    useContext(StockNewsContext);
  const [prevPage, setPrevPage] = useState(1);
  const isMobile = window.innerWidth <= 600;
  const [tempPage, setTempPage] = useState(1);
  const changePage = (index, lastIndex) => {
    if (tempPage === data.length - 1 || data.length === 0) {
      let page = data.length / 10 + 1;
      setPrevPage(page - 1);
      if (data.length === 0) page = 1;

      getNews("", setTempPage, page, "", "", true, true);
    }
  };

  useEffect(() => {
    if (isMobile) {
      changePage();
    }
  }, [tempPage]);

  return (
    <>
      <Stack
        direction="column"
        spacing={1}
        alignItems="center"
        sx={{
          pt: 1,
        }}
      >
        {selectedTagsNews && data.length === 0 && (
          <Typography variant="h5" color="text.secondary">
            No news are available in this category label
          </Typography>
        )}
        {loading && <FuLoader />}

        {data.map((news, idx) => (
          <Box key={idx}>
            <DefaultNewsCard news={news} />
          </Box>
        ))}

        {loading && <FuLoader />}
        <br />
        <br />
      </Stack>
    </>
  );
};
export default AllNews;
