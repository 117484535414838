import { Link } from "react-router-dom";
import logo from "../../../../assets/askfunduLogo.png";

export const LeftSidePannelWithLogo = () => {
  return (
    <div className="left_side_logo" style={{background:"linear-gradient(180deg, rgba(142, 83, 255, 0.2) 0%, rgba(249, 6, 254, 0.186) 100%)"}}>
      <Link to="/">
        {/* _-----Rightsidebarpaned and logo ------------------ */}
        <div >
          <img src={logo} alt="logo" style={{ height:"150px", color:""}}/>
          {/* logo upload image----------------------- */}
        </div>
      </Link>
    </div>
  );
};
