import { lazy } from "react";

const SuggestionPageRoute = [
  {
    path: "/suggestion/users",
    component: lazy(() => import("./index")),
  },
  {
    path: "/suggestion/post",
    component: lazy(() => import("./index")),
  },
  {
    path: "/suggestion/news",
    component: lazy(() => import("./index")),
  },
  {
    path: "/suggestion/stocks",
    component: lazy(() => import("./index")),
  },
];

export default SuggestionPageRoute;
