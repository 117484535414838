import React, { useContext, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ThemeContext } from "../../../../context/ThemeContext";
import useScroll from "../../../common-functions/useScroll";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  fetchPost,
  updateFilter,
  updateNoData,
  updatePage,
} from "../../../../store/postSlice";

const FilterPostDropdown = () => {
  const [value, setValue] = useState("All");
  const { setFilterValue } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
    setFilterValue(event.target.value);

    dispatch(updateFilter({ type: event.target.value }));
    dispatch(updatePage({ page: 1 }));
    dispatch(updateNoData(false));
    dispatch(fetchPost());
  };
  const { t } = useTranslation();

  return (
    <FormControl
      variant="standard"
      disableRipple
      sx={{ minWidth: 40, border: "none", mt: 0.6, boxShadow: "none" }}
      size="small"
    >
      <Select
        disableUnderline
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={handleChange}
        boxShadow={"none"}
      >
        <MenuItem value={"All"}>{t("All")}</MenuItem>
        <MenuItem value={"1"}>{t("Normal")}</MenuItem>
        <MenuItem value={"2"}>{t("Poll")}</MenuItem>
        <MenuItem value={"3"}>{t("Query")}</MenuItem>
        <MenuItem value={"4"}>{t("Prediction")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterPostDropdown;
