import { useMemo } from "react";
import EditProfile from "../edit-profile";
import { useTranslation } from "react-i18next";

const useSettingsTabs = () => {
  const { t, i18n } = useTranslation();
  const tabs = useMemo(
    () => [
      {
        name: t("General"),
        component: <EditProfile />,
      },
    ],
    [i18n.language]
  );

  return {
    tabs,
  };
};

export default useSettingsTabs;
