import React, { useState } from "react";
import {
  capitalFirstLetter,
  time_difference,
} from "../../../../../common-functions/FormatDate";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ImgPlaceholder from "../../../../../../assets/image_placeholder.png";
import { Avatar, Box, Chip, Stack, TextField, Typography } from "@mui/material";
import CommentDropdown from "../comment-dropdown";
import { Cookies } from "react-cookie";
import { backend_url } from "../../../../../../http-common";
import FuLoader from "../../../../../shared/FuLoader";
import LikeDislikeAnswer from "../like-dislike-answer";
import { BsDot } from "react-icons/bs";

const CommentsList = ({
  commentData,
  setCommentData,
  updateCommentCount,
  loading,
  fetchPost,
}) => {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [openEditCommentInput, setOpenEditCommentInput] = useState({
    id: "",
    status: false,
  });
  const [commentEditText, setCommentEditText] = useState("");

  const makeTextHighlighted = (textToHighlight) => {
    return (
      <>
        {textToHighlight?.split(" ").map((text) =>
          text?.includes("@") ? (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={`/user/${text.split("@")[1].split(",")[0]}`}
            >
              <span
                style={{
                  color: "#5d20d2",
                }}
              >
                {" "}
                {text}{" "}
              </span>
            </Link>
          ) : text?.includes("$") ? (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={`/stock/${text.split("$")[1].split(",")[0]}`}
            >
              <span
                style={{
                  color: "#ec6391",
                }}
              >
                {" "}
                {text}{" "}
              </span>
            </Link>
          ) : (
            " " + text + " "
          )
        )}
      </>
    );
  };

  const handleOpenEditCommentInput = (id) => {
    let update = { id: id, status: true };
    setOpenEditCommentInput(() => update);
    let text = commentData.filter((item) => item.comment_unique_id === id)[0]
      .comment_text;

    setCommentEditText(text);
  };

  function handleEditComment(id) {
    let newCommentArray = commentData.map((item) => {
      if (item.comment_unique_id === openEditCommentInput.id) {
        return {
          ...item,
          comment_text: commentEditText,
          Comment_edited_at: Date.now(),
        };
      } else {
        return item;
      }
    });

    setCommentData(newCommentArray);

    let commentId = openEditCommentInput.id;

    let update = { id: "", status: false };
    setOpenEditCommentInput(() => update);

    handleEditCommentBackend(commentId);
  }

  const handleEditCommentBackend = (id) => {
    let comment = commentData.filter(
      (item) => item.comment_unique_id === id
    )[0];
    const form = {
      comment_unique_id: comment.comment_unique_id,
      comment_user_id: comment.comment_user_id,
      comment_post_id: comment.comment_post_id,
      comment_text: commentEditText,
      Comment_created_at: comment.Comment_created_at,
      comment_type: comment.comment_type,
      Comment_edited_at: Date.now(),
    };

    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/edit-comment/${id}`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => console.log(err));
  };

  const handleCancelEdit = () => {
    let update = { id: "", status: false };
    setOpenEditCommentInput(() => update);
  };

  // console.log("comment data", commentData);
  return (
    <Box mt={2}>
      {loading ? (
        <FuLoader height={"10px"} />
      ) : (
        commentData?.reverse().map((item, idx) => (
          <Box className="comment_List1" sx={{ mb: 2 }} key={idx}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
            >
              <Box sx={{ display: "flex" }}>
                {" "}
                <Avatar
                  src={item.userDetails?.userpic_url || ImgPlaceholder}
                  alt="profilePic"
                  sx={{ width: 33, height: 33 }}
                />
                <Link
                  to={`/user/${item.comment_user_id}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    marginLeft: "13px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      letterSpacing={0.4}
                      sx={{ fontSize: 14, fontWeight: 600 }}
                    >
                      {`${capitalFirstLetter(
                        item.userDetails?.First_Name
                      )} ${capitalFirstLetter(item.userDetails?.last_name)} `}
                    </Typography>
                    <BsDot
                      style={{
                        color: "#8b908f",
                        fontSize: "23px",
                        marginLeft: "-2px",
                      }}
                    />
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#8e9399",
                        marginTop: "3px",
                        marginLeft: "-2px",
                      }}
                    >
                      {item.Comment_edited_at
                        ? `${time_difference(item.Comment_edited_at).join(
                            ""
                          )} ago (edited)`
                        : `${time_difference(item.Comment_created_at).join(
                            ""
                          )} ago`}
                    </Typography>
                  </Box>
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      color: "#8e9399",
                      fontWeight: "500",
                      fontSize: "12px",
                      mt: -0.6,
                    }}
                  >
                    @{item.comment_user_id}
                  </Typography>
                </Link>
              </Box>
              {user?.username === item.comment_user_id && (
                <Box mt={-0.5}>
                  <CommentDropdown
                    commentId={item.comment_unique_id}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    updateCommentCount={updateCommentCount}
                    handleOpenEditCommentInput={handleOpenEditCommentInput}
                    fetchPost={fetchPost}
                  />
                </Box>
              )}
            </Box>

            <Box sx={{ ml: 6.5 }}>
              {openEditCommentInput &&
              item.comment_unique_id === openEditCommentInput.id ? null : (
                <Typography
                  letterSpacing={0.4}
                  multiline
                  maxRows={4}
                  sx={{ width: "100%", pr: 3, fontSize: 14, mt: -0.6 }}
                >
                  {makeTextHighlighted(item.comment_text)}
                </Typography>
              )}

              {item?.Image ? (
                <Box className="posts_img">
                  <img src={item?.Image} alt="img" />
                </Box>
              ) : null}

              {item.comment_unique_id === openEditCommentInput.id &&
                openEditCommentInput.status && (
                  <Box>
                    <TextField
                      className="comment_input"
                      multiline
                      maxRows={8}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          height: 35,
                          padding: "0 14px",
                          borderRadius: "16px",
                        },
                      }}
                      type="text"
                      placeholder="Edit comment..."
                      value={commentEditText}
                      onChange={(e) => setCommentEditText(e.target.value)}
                      required
                    />

                    <Stack direction="row" spacing={1} mt={2} mb={2}>
                      <Chip
                        label="Save changes"
                        variant="filled"
                        disabled={
                          item.comment_text === commentEditText ||
                          commentEditText === ""
                            ? true
                            : false
                        }
                        onClick={handleEditComment}
                        sx={{ backgroundColor: "#5d20d2", color: "white" }}
                      />
                      <Chip
                        label="Cancel"
                        variant="filled"
                        sx={{
                          backgroundColor: "white",
                          color: "#5D20D2",
                          border: "1px solid #5D20D2",
                        }}
                        onClick={handleCancelEdit}
                      />
                    </Stack>
                  </Box>
                )}

              {/* {item.comment_type === 2 && <LikeDislikeAnswer />} */}
            </Box>
            <Box sx={{ p: 2, pl: 5, mt: -2 }}>
              <LikeDislikeAnswer comment_id={item._id} />
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};

CommentsList.propTypes = {
  commentData: PropTypes.array.isRequired,
  setCommentData: PropTypes.func.isRequired,
  updateCommentCount: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fetchPost: PropTypes.func,
};

export default CommentsList;
