import { React, useContext, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {
  capitalFirstLetter,
  checkFollow,
  time_difference,
} from "../common-functions/FormatDate";
import { Link, useNavigate } from "react-router-dom";

export const Alert = () => {
  const {
    newFetchAllAlerts,
    allAlerts,
    fetchAlertsCount,
    handleFollow,
    myFollowings,
  } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    newFetchAllAlerts();
    fetchAlertsCount();
  }, []);

  if (!allAlerts || allAlerts.length === 0) {
    // Display the default image when there's no notification data.
    return (
      <Box justifyContent={"center"} textAlign={"center"}>
        <img
          src="https://i.ibb.co/BgrwsKg/no-notification-4064369-3363930.webp"
          style={{ height: 300 }}
          alt="No Notifications"
        />
        <Box mt={-5}>
          <Typography fontWeight={600} fontSize={18}>
            No Notification Yet
          </Typography>
          <Typography>
            Check this section for update, news <br />
            and genaral notification
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Card
      sx={{
        width: "100wh",
        mb: 5,
        boxShadow: "none",
        backgroundColor: "#f7f6f9",
        mt: 1.3,
      }}
    >
      <>
        {allAlerts &&
          allAlerts?.map((item, index) => {
            if (item?.type == undefined || item?.type == null) {
              const msg = item?.msg;
              return (
                <>
                  <Box
                    sx={{
                      display: "grid",
                      p: 2,
                      pl: 2,
                      mb: 1,
                      borderRadius: "4px",
                      backgroundColor: "white",
                      border: "1px solid #e0e0e0",
                      fontSize: 14,
                    }}
                    key={`Alert-${index}`}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box display={"flex"}>
                        <Avatar
                          sx={{ width: 40, height: 40 }}
                          src={item?.userpic_url}
                        />
                        <Typography
                          letterSpacing={0.4}
                          color="primary"
                          sx={{ ml: 2, fontSize: 14, display: "flex" }}
                        >
                          <Link
                            to={item?.link}
                            style={{
                              textDecoration: "none",
                              display: "flex",
                            }}
                          >
                            <strong
                              style={{
                                color: "black",
                                marginLeft: "5px",
                                fontWeight: "500",
                                fontSize: 15,
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{ __html: msg }}
                              ></div>
                            </strong>
                          </Link>
                        </Typography>
                      </Box>

                      <Typography
                        letterSpacing={0.4}
                        sx={{ color: "#cecece", fontSize: 12, pr: 1 }}
                      >
                        {time_difference(item?.updated_at)} ago
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            } else if (item?.type == "follow") {
              return (
                <>
                  <Box
                    sx={{
                      display: "grid",
                      p: 2,
                      pl: 2,
                      mb: 1,
                      borderRadius: "8px",
                      backgroundColor: "#F7F6F9",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box display={"flex"}>
                        <Avatar
                          alt={item?.user_details?.username}
                          src={item?.user_details?.userpic_url}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Typography
                          letterSpacing={0.4}
                          color="primary"
                          sx={{ ml: 2, fontSize: 14, display: "flex" }}
                        >
                          <Link
                            to={`/user/${item?.user_details?.username}`}
                            style={{
                              textDecoration: "none",
                              display: "flex",
                            }}
                          >
                            <strong>
                              {capitalFirstLetter(
                                item?.user_details?.First_Name
                              ) +
                                " " +
                                capitalFirstLetter(
                                  item?.user_details?.last_name
                                )}
                            </strong>
                          </Link>
                          <strong
                            style={{
                              color: "black",
                              marginLeft: "5px",
                              fontWeight: "500",
                            }}
                          >
                            started following you.
                          </strong>
                        </Typography>
                      </Box>

                      <Typography
                        letterSpacing={0.4}
                        sx={{ color: "#cecece", fontSize: 12, pr: 1 }}
                      >
                        {time_difference(item?.created_at)} ago
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 7, gap: 1, display: "flex" }}>
                      <Button
                        sx={{
                          border: "1px solid var(--fundu_color)",
                          textTransform: "none",
                          fontSize: 12,
                          height: "20px",
                        }}
                        onClick={() =>
                          navigate(`/user/${item?.user_details?.username}`)
                        }
                      >
                        View Profile
                      </Button>

                      <Button
                        sx={{
                          border: "1px solid var(--fundu_color)",
                          textTransform: "none",
                          fontSize: 12,
                          height: "20px",
                        }}
                        onClick={() =>
                          handleFollow("", item?.user_details?.username)
                        }
                      >
                        {checkFollow(myFollowings, item?.user_details?.username)
                          ? "Following"
                          : "Follow back"}
                      </Button>
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            } else if (item?.type == "upvote") {
              return (
                <>
                  <Box
                    sx={{
                      display: "grid",
                      p: 2,
                      pl: 2,
                      mt: 1,
                      mb: 1,
                      borderRadius: "8px",
                      backgroundColor: "#F7F6F9",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box display={"flex"}>
                        <Avatar
                          sx={{ width: 40, height: 40 }}
                          alt={item?.user_details?.username}
                          src={item?.user_details?.userpic_url}
                        />
                        <Typography
                          letterSpacing={0.4}
                          color="primary"
                          sx={{ ml: 2, fontSize: 14, display: "flex" }}
                        >
                          <Link
                            to={`/user/${item?.user_details?.username}`}
                            style={{
                              textDecoration: "none",
                              display: "flex",
                            }}
                          >
                            <strong>
                              {capitalFirstLetter(
                                item?.user_details?.First_Name
                              ) +
                                " " +
                                capitalFirstLetter(
                                  item?.user_details?.last_name
                                )}
                            </strong>
                          </Link>
                          <strong
                            style={{
                              color: "black",
                              marginLeft: "5px",
                              fontWeight: "500",
                            }}
                          >
                            upvoted on your post.
                          </strong>
                        </Typography>
                      </Box>

                      <Typography
                        letterSpacing={0.4}
                        sx={{ color: "#cecece", fontSize: 12, pr: 1 }}
                      >
                        {time_difference(item?.created_at)} ago
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            }
          })}
      </>
    </Card>
  );
};
export default Alert;
