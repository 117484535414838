import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

const useScroll = (fnc, filter) => {
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  const [lastFilter, setLastFilter] = useState("All");
  const [loading, setLoading] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (lastFilter !== filter) {
      setCurrPage(1);
      setPrevPage(0);
      setLastFilter(filter);
      setLastList(false);
    }
    if (scroll && !lastList && prevPage !== currPage) {
      setLoading(true);
      fnc(
        setLastList,
        setPrevPage,
        currPage,
        setLoading,
        setScroll,
        scroll,
        filter,
        loading
      );
    }
  }, [currPage, filter, fnc, lastList, scroll, loading]);

  // const handleScroll = useCallback(
  //   debounce((event) => {
  //     console.log("is it triggering or not", currPage, event);
  //     const listboxNode = event.currentTarget;
  //     console.log(listboxNode, "listboxnode");
  //     const position = listboxNode.scrollTop + listboxNode.clientHeight;
  //     if (listboxNode.scrollHeight - position <= 1) {
  //       console.log("is it reaching in if condition?");
  //       setCurrPage(currPage + 1);
  //       setScroll(true);
  //     }
  //   }, 1000),
  //   [currPage]
  // );

  const handleScroll = useCallback(
    debounce((event) => {
      const listboxNode = event.target; // Use 'event.target' instead of 'event.currentTarget'
      const position = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - position <= 1) {
        if (prevPage >= currPage) {
          setCurrPage(prevPage + 1);
        } else {
          setCurrPage(currPage + 1);
        }
        setScroll(true);
      }
    }, 100),
    [currPage]
  );

  return { handleScroll, loading };
};

export default useScroll;
