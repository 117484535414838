import { React, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import SettingsTabs from "../../Components/settings-tab";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";

const SettingsPage = () => {
  const { theme } = useContext(ThemeContext);
  const { width } = useViewPort();

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <div>
              <SettingsTabs />
            </div>
          }
        />
      ) : (
        <LayoutDefault
          mainContent={
            <div>
              <SettingsTabs />
            </div>
          }
          rightPanelContent={
            <div>
              <MostPopularStocksRight />
              <TrendingNewsCard />
              <br />
              <br />
              <br />
            </div>
          }
        />
      )}
    </Box>
  );
};

export default SettingsPage;
