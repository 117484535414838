import { Box, Stack } from "@mui/system";
import React, { useContext } from "react";
import { useState } from "react";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { getRequest } from "../../../services/request";
import FuButton from "../../shared/FuButton";
import FuLoader from "../../shared/FuLoader";
import UserPosts from "../../user-posts-tabs/components/user-posts";

const PostsTab = ({ searchInput, tabName }) => {
  const [page, setPage] = useState(2);
  const [isPostcomplete, setIsPostcomplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const { searchedPostsData, setSearchedPostsData, fetchsearchPosts } =
    useContext(StockNewsContext);

  const paginateSearch = async () => {
    setLoading(true);
    if (searchInput) {
      const response = await getRequest({
        url: `/get-searched-posts?search=${searchInput}&pageNo=${page}`,
      });
      if (response.data?.searchedPosts.length === 0) {
        setIsPostcomplete(true);
        setLoading(false);
        return;
      }
      setSearchedPostsData((prev) => [
        ...prev,
        ...response.data?.searchedPosts,
      ]);
      setLoading(false);
      setPage(page + 1);
    }
  };

  return (
    <Box paddingBottom={"2rem"}>
      {searchedPostsData.length === 0 && (
        <h3 style={{ textAlign: "center", marginTop: "35%" }}>No post found</h3>
      )}
      <UserPosts data={searchedPostsData} search={true} />
      {searchedPostsData.length > 0 && loading ? (
        <FuLoader />
      ) : !isPostcomplete && searchedPostsData.length > 0 ? (
        <Stack>
          <FuButton onClick={paginateSearch}>Load More</FuButton>
        </Stack>
      ) : null}
    </Box>
  );
};

export default PostsTab;
