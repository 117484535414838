import { CometChat } from "@cometchat-pro/chat";
import { COMET_CHAT_AUTH_KEY } from "../../../services/constants";

const usernameCheck = (username) => {
  if (username.includes(".")) {
    const [f, s] = username.split(".");
    const num = f.length + s.length;
    username = f + "_" + num + "_" + s;
  }
  return username;
};

export default function useMakeCometChatProfile() {
  const createCommetChatUser = async (usertomake) => {
    try {
      let uid = usernameCheck(usertomake?.username);
      console.log(uid);
      let name = usertomake?.First_Name + " " + usertomake?.last_name;
      let avtar = usertomake?.userpic_url;

      let user = new CometChat.User(uid);
      user.setName(name);
      user.setAvatar(avtar);
      await CometChat.createUser(user, COMET_CHAT_AUTH_KEY);
      return true;
    } catch (error) {
      console.log("error creating user", error);
      return false;
    }
  };

  const loginCommetChatUser = async (usertomake) => {
    try {
      let uid = usernameCheck(usertomake?.username);
      console.log(uid);
      let user = await CometChat.login(uid, COMET_CHAT_AUTH_KEY);
      return user;
    } catch (e) {
      console.log("error login user", e);
      return e;
    }
  };

  const logoutCommetChatUser = () => {
    return CometChat.logout(COMET_CHAT_AUTH_KEY).then(
      (res) => {
        console.log("cometchat logout", res);
        return true;
      },
      (error) => {
        return error;
      }
    );
  };

  const checkUserStatus = async (username) => {
    let loggedIn = true;
    try {
      username = usernameCheck(username);
      await CometChat.getUser(username);
      loggedIn = true;
    } catch (e) {
      if (e.code === "ERR_UID_NOT_FOUND") {
        loggedIn = false;
      }
    }
    return loggedIn;
  };

  return {
    createCommetChatUser,
    loginCommetChatUser,
    logoutCommetChatUser,
    checkUserStatus,
  };
}
