import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Followers from "../followers";
import Following from "../following";
import { ThemeContext } from "../../../../context/ThemeContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FollowingTabs({ handleClose }) {
  const [value, setValue] = React.useState(0);
  const { theme, followUsers, followingUsers } = React.useContext(ThemeContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* -----tabs--------- */}

            <Tab
              label={`${
                followUsers && followUsers.length > 0 ? followUsers.length : 0
              } Followers`}
              sx={{ textTransform: "none", fontSize: "16px", fontWeight: 600 }}
              {...a11yProps(0)}
            />
            <Tab
              label={`${
                followingUsers && followingUsers.length > 0
                  ? followingUsers.length
                  : 0
              } Following`}
              sx={{ textTransform: "none", fontSize: "16px", fontWeight: 600 }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Followers handleClose={handleClose} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Following handleClose={handleClose} />
        </TabPanel>
      </Box>
    </>
  );
}
