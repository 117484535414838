import { React, useContext, useState, useEffect } from "react";
import { Avatar, Box, Button, Card } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";
import CreatePoll from "../create-poll";
import CreateChart from "../create-chart";
import CreatePrediction from "../create-prediction";
import Tooltip from "@mui/material/Tooltip";
import ImgPlaceholder from "../../assets/image_placeholder.png";
import { Cookies } from "react-cookie";
import SocialPostForm from "../common/components/social-post-form";
import { capitalFirstLetter } from "../common-functions/FormatDate";
import { CREATE } from "../../constants/constants";
import Stack from "@mui/material/Stack";
import { TbLink } from "react-icons/tb";
import PollIcon from "@mui/icons-material/Poll";
import { ColorModeContext } from "../../DarkModeContext/DarkModeContext";
import { t } from "i18next";

const CreatePost = () => {
  const { theme, tagsData } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [openPoll, setOpenPoll] = useState(false);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { mode } = useContext(ColorModeContext);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPoll = () => {
    setOpenPoll(true);
  };

  const handleClosePoll = () => {
    setOpenPoll(false);
  };

  useEffect(() => {
    if (tooltipIsOpen) {
      const timeoutId = setTimeout(() => setTooltipIsOpen(false), 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [tooltipIsOpen]);

  return (
    <Card
      sx={{
        borderRadius: "4px",
        mb: 2,
        boxShadow: "none",
        border: "1px solid #e0e0e0",
      }}
    >
      <Box
        sx={{
          p: 1.5,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={1}
          sx={{
            mt: 1,
            mb: 1.5,
          }}
        >
          <Avatar
            src={user?.userpic_url || ImgPlaceholder}
            alt="img"
            sx={{
              border: "1px solid #D8D1E3",
              width: 42,
              height: 42,
            }}
          />
          <Box
            style={{
              width: "90%",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClickOpen}
              style={{
                width: "100%",
                border: "1px solid #d3d8e7 ",
                borderRadius: "0.5rem",
                textTransform: "none",
                justifyContent: "left",
                padding: "7px",
              }}
            >
              {capitalFirstLetter(t("What do you want to talk about?"))}
            </Button>

            <SocialPostForm
              open={open}
              handleClose={handleClose}
              setOpen={setOpen}
              from={CREATE}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0.5}
          sx={{
            mt: 1.5,
          }}
        >
          <Button
            onClick={handleClickOpenPoll}
            sx={{
              textTransform: "none",
              color: "#676767",
              "& .MuiButton-startIcon": {
                marginRight: "5px",
                color: "#c37d16",
              },
            }}
            startIcon={<PollIcon fontSize="small" />}
          >
            {t("Poll")}
          </Button>
          <CreatePoll
            fontSize="medium"
            open={openPoll}
            handleClose={handleClosePoll}
            setOpen={setOpenPoll}
          />
          <CreatePrediction fontSize="medium" />
          <CreateChart fontSize="medium" />
          <Button
            onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
            sx={{
              textTransform: "none",
              color: "#676767",
              "& .MuiButton-startIcon": {
                marginRight: "8px",
                color: "#378fe9",
              },
            }}
            startIcon={<TbLink fontSize="small" />}
          >
            {t("LinkTrade")}
          </Button>
        </Stack>
        <Tooltip
          placement="bottom-end"
          sx={{ mt: 2 }}
          open={tooltipIsOpen}
          onOpen={() => setTooltipIsOpen(true)}
          title="This feature will be available soon"
        >
          <p></p>
        </Tooltip>
      </Box>
    </Card>
  );
};

export default CreatePost;
