import { useMemo, useContext, useState, useCallback, useEffect } from "react";
import AllStocks from "../all-stocks";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import AllIndices from "../../indices/all-indices";
import { useTranslation } from "react-i18next";
import WatchlistStocks from "../../watchlist-stocks";
import { capitalFirstLetter } from "../../common-functions/FormatDate";
import PortfolioHighlights from "../../portfolio/portfolio-highlights";
import LinkBroker from "../../link-broker";
import Portfolio from "../../portfolio";
import PortfolioStocks from "../../portfolio/portfolio-stocks";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { Cookies } from "react-cookie";
import { getRequest } from "../../../services/request";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import WatchlistMobile from "../../watchlist-stocks/watchlistMobile";

const useAllStocksTabs = (loading) => {
  const { stocksData } = useContext(StockNewsContext);
  const buy = stocksData.filter((item) => item["Technical Rating"] === "Buy");
  const sell = stocksData.filter((item) => item["Technical Rating"] === "Sell");
  const [portfolioStocksData2, setPortfolioStocksData2] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isFyersConnected, setIsFyersConnected] = useState(false);
  const { username } = useParams();
  const { t } = useTranslation();
  const cookie = new Cookies();
  const user = cookie.get("user");
  const location = useLocation();
  const navigate = useNavigate();

  const fetchUserPortfolioStocks = useCallback(() => {
    getRequest({
      url: `/stocks/get-portfolio-stocks/${username}`,
    })
      .then((res) => {
        setPortfolioStocksData2(res.data.allStocks);
      })
      .catch((err) => {
        console.log("something went wrong, not found");
        setPortfolioStocksData2([]);
      });
  }, [username]);

  useEffect(() => {
    fetchUserPortfolioStocks();
  }, [username]);

  const neutral = stocksData.filter(
    (item) => item["Technical Rating"] === "Neutral"
  );
  const isMobile = useMediaQuery("(max-width: 600px)");

  const tabs = useMemo(
    () => [
      {
        name: t("Watchlist"),
        component: isMobile ? <WatchlistMobile /> : <WatchlistStocks />,
      },

      {
        name: t("Portfolio"),
        component: user ? ( // Check if the user is logged in
          isFyersConnected ? (
            portfolioStocksData2?.length > 0 ? (
              <div>
                <PortfolioHighlights profileUsername={username} />
                <LinkBroker />
                <Portfolio profileUsername={username} />
                <PortfolioStocks data={portfolioStocksData2} />
              </div>
            ) : user.username === username ? (
              <LinkBroker />
            ) : (
              <Typography
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {capitalFirstLetter(userData[0]?.First_Name)} has no stock in
                the portfolio
              </Typography>
            )
          ) : user.username === username ? (
            <LinkBroker />
          ) : (
            <Typography
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {capitalFirstLetter(user?.First_Name)}'s portfolio not disclosed
            </Typography>
          )
        ) : (
          <Box
            sx={{
              justifyContent: "center",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                justifyContent: "center",
                display: "flex",
              }}
              to={"/welcome"}
            >
              <img
                src="https://i.ibb.co/vQ1gMFd/360-F-339709166-k-KKqi-QFyn-WG7b-Ekl3-Lis-H3sa-Rr-EB0-HGa-removebg-preview.png"
                alt="Not Logged In"
              />
            </Link>
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography>Please sign-in to add portfolio</Typography>
              <Typography
                sx={{
                  ml: 1,
                  color: "#5D20D2",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                component={Link}
                to="/welcome"
              >
                Login
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        name: t("Stocks"),
        component: <AllStocks data={stocksData} loading={loading} />,
      },
      {
        name: t("Index"),
        component: <AllIndices />,
      },
    ],
    [buy, loading, neutral, sell, stocksData]
  );

  return {
    tabs,
  };
};

export default useAllStocksTabs;
