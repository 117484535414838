import { getRequest } from "../../services/request";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

export const fetchPost = createAsyncThunk(
  "posts/fetchPosts",
  async (arg, { getState }) => {
    const {
      posts: { page, filter, search, perPage, top },
    } = getState();
    let response = await getRequest({
      url: `/posts/get-all-posts?page=${page}&filter=${filter.type}&top=${top}`,
    });
    return response?.data?.data?.posts;
  }
);
export const fetchUpdatedPost = createAsyncThunk(
  "posts/fetchUpdatedPost",
  async (arg, { getState }) => {
    const {
      posts: { page, filter, search, perPage, top },
    } = getState();
    let response = await getRequest({
      url: `/posts/get-all-posts?page=${page}&filter=${filter.type}&top=${top}`,
    });
    return response?.data?.data?.posts;
  }
);

const postSlice = createSlice({
  name: "post",
  initialState: {
    posts: [],
    filter: {
      type: "All",
    },
    search: "",
    page: 1,
    perPage: 20,
    top: false,
    noData: false,
  },
  reducers: {
    updatePage: (state, action) => {
      state.page = action.payload.page;
    },
    updateFilter: (state, action) => {
      state.filter.type = action.payload.type;
    },
    updateSearch: (state, action) => {
      state.search = action.payload.search;
    },
    updateNoData: (state, action) => {
      state.search = action.payload;
    },
    updateTop: (state, action) => {
      state.top = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPost.fulfilled, (state, action) => {
      state.noData = false;
      state.posts = action.payload;
    });
    builder.addCase(fetchUpdatedPost.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.noData = true;
      }
      if (!state.noData) {
        state.posts = [...state.posts, ...action.payload];
      }
    });
  },
});

export const {
  updatePage,
  updateFilter,
  updateSearch,
  updateNoData,
  updateTop,
} = postSlice.actions;
export default postSlice.reducer;
