import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import IdleTimeOutModal from "./Components/session-timeout/idle-time-out-modal";
import IdleTimerContainer from "./Components/session-timeout/idle-timer-container";
import { useNavigate } from "react-router-dom";
import ConfirmationBox from "./Components/confirmation-box/ConfirmationBox";
import AlertBox from "./Components/alert-box";
import { ThemeContext } from "./context/ThemeContext";
import { Cookies, useCookies } from "react-cookie";
import AppRoutes from "./app-routes";
import AllRoutes from "./AllRoutes/Routes";
import ROUTES from "./Pages/routes";
import useViewPort from "./hooks/useViewPort";
import { useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { backend_url } from "./http-common";
import {
  GoogleLogin,
  googleLogout,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { useAnalytics } from "@segment/analytics-react";
import { CometChat } from "@cometchat-pro/chat";
import Notification from "./Notification";
import { Helmet } from "react-helmet";
import { setLanguageCodeToStorage } from "./Components/common-functions/getLanguage";
import ReviewRate from "./Components/review-rate";
import Messages from "./Components/Appointment/appointment-box";
import { Typography } from "@mui/material";

function App() {
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [isTimedout, setIsTimedout] = useState(false);
  const navigate = useNavigate();
  const { theme, showAlertBox } = useContext(ThemeContext);
  const { width } = useViewPort();
  const [cookies, setCookies] = useCookies(["user"]);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const location = useLocation();
  const path = location.pathname;
  const [open, setOpen] = useState(false);
  const { track } = useAnalytics();

  // useEffect(() => {
  //   analytics.initialize("DNLxBz1sSxiLHUpVxYdiTiyMOErwavxQ");
  // }, []);

  // useEffect(() => {
  //   if (user) {
  //     track(user.id, {
  //       username: user?.username,
  //     });
  //   }
  // }, [user.username]);

  const isMobile = window.innerWidth <= 768;

  const logoutHandler = () => {
    setShowTimeoutModal(false);
    // sessionStorage.clear();
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("token");
    googleLogout();
    cookie.remove("user");
    navigate("/welcome");
    // window.location.reload();
  };

  const handleClose2 = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setShowTimeoutModal(false);
    setIsTimedout(false);
  };

  const timeoutModalHandler = () => {
    // setShowTimeoutModal(true);
    logoutHandler();
  };
  const isWelcomeRoute = location.pathname === "/welcome";
  const isPreferedRoute = location.pathname === "/preferred-tags";
  const isTermsdRoute = location.pathname === "/terms";
  const isCopyrightRoute = location.pathname === "/copyright";
  const isPrivacyRoute = location.pathname === "/privacy";

  const createUser = (form) => {
    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/users/create-user`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          handleSignin(response);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSignin = (response) => {
    if (response.status === 200) {
      if (response.message === "success") {
        sessionStorage.setItem("isLoggedIn", true);
        sessionStorage.setItem("token", response.token);
        setCookies("user", JSON.stringify(response.user), {
          path: "/",
        });
        setLanguageCodeToStorage(response.user?.language);
        if (response.user?.preferred_tags?.length === 0) {
          navigate("/preferred-tags");
        } else {
          window.location.reload();
          navigate("/");
        }
      }
    } else if (response.status === 401) {
      // setShowAlert({
      //   status: true,
      //   message: response.message,
      // });
    }
    // .catch((err) => console.log(err));
  };

  return (
    <>
      {user?.username ? (
        <Helmet>
          <title>askFundu Social</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>askFundu</title>
        </Helmet>
      )}
      {/* {!user && (
        <GoogleLogin
          onError={(error) => console.log(error)}
          onSuccess={(response) => responseGoogle(response)}
          // googleAccountConfigs={{
          //   client_id: GOOGLE_CLIENT_ID,
          // }}
          useOneTap
        />
      )} */}
      <>
        <IdleTimeOutModal
          showModal={showTimeoutModal}
          handleClose={handleClose}
          handleLogout={logoutHandler}
        />
        <IdleTimerContainer
          handleLogout={logoutHandler}
          timeout={isTimedout}
          timeoutModal={timeoutModalHandler}
          timedoutHandler={(bool) => {
            setIsTimedout(bool);
          }}
        />
        <div
          style={{
            zIndex: "2000",
          }}
        >
          {showAlertBox.status ? <AlertBox /> : ""}
        </div>
        <ConfirmationBox />

        <div className={theme ? "main_light" : "main_dark"}>
          <AppRoutes routes={ROUTES} />
          {user &&
            !isWelcomeRoute &&
            !isPreferedRoute &&
            !isTermsdRoute &&
            !isCopyrightRoute &&
            !isPrivacyRoute &&
            (isMobile ? null : <Messages />)}
          <CookieConsent
            buttonText={"Allow Cookies"}
            buttonStyle={{
              background: "var(--fundu_color)",
              color: "white",
              borderRadius: "8px",
            }}
            style={{
              backgroundColor: "#c7a9fd",
              position: "fixed",
              color: "black",
              padding: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              bottom: "0",
              width: "100%",
              zIndex: "99999999999",
            }}
          >
            <p>
              <Typography letterSpacing={0.4}>
                We use cookies to operate this website, improve usability,
                personalize your experience, and improve our marketing. Your
                privacy is important to us, and we will never sell your data.
              </Typography>
              <a href="/privacy" style={{ color: "white", fontWeight: 600 }}>
                {" "}
                Learn more.
              </a>
            </p>
          </CookieConsent>
          {/* <Userprofile /> no turning on */}
          <Notification />
        </div>
      </>
      {/* )}  */}
    </>
  );
}

export default App;
