import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import { FcGoogle } from "react-icons/fc";
import { ThemeContext } from "../../context/ThemeContext";
import { LeftSidePannelWithLogo } from "../../Components/login-components/components/leftside-panel-with-logo";
import { backend_url } from "../../http-common";
import AlertComponent from "../../Components/login-components/components/alert-component";
import { useCookies } from "react-cookie";
import { Grid, Box, Typography, useMediaQuery } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import useViewPort from "../../hooks/useViewPort";
import { setLanguageCodeToStorage } from "../../Components/common-functions/getLanguage";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Newlogo from "../../assets/askfunduLogo.png";
import { useDispatch } from "react-redux";
import { fetchLoggedInUser, setUsername } from "../../store/userSlice";
import SignIn from "../../Components/login-components";
import { getAuth } from "firebase/auth";
import { doRequest } from "../../services/request";
import FacebookLogin from "../../Components/login-components/components/facebook-login";
import { Helmet } from "react-helmet";

function Loginlanding() {
  const [cookies, setCookie] = useCookies(["user"]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
  });
  const { setData, fetchMyFollowings } = useContext(ThemeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => createUser(tokenResponse),
    flow: "auth-code",
    onError: (errorResponse) => console.log(errorResponse),
    scope: "openid email profile",
  });

  const loginFromBackend = async (email) => {
    try {
      let res = await doRequest({
        url: `/users/login`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      handleSignin(res.data);
    } catch (e) {
      console.log("error", e.message);
    }
  };

  useEffect(() => {
    const authUser = getAuth();
    if (authUser.currentUser) {
      loginFromBackend(authUser.currentUser.email);
    }
  }, []);

  const createUser = (form) => {
    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/users/create-user`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          handleSignin(response);
          // console.log(response);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSignin = (response) => {
    if (response.status === 200) {
      setShowAlert({
        status: true,
        message: response.message,
      });

      if (response.message === "success") {
        sessionStorage.setItem("isLoggedIn", true);
        sessionStorage.setItem("token", response.token);
        dispatch(setUsername(response.user.username));
        dispatch(fetchLoggedInUser());
        fetchMyFollowings();
        setCookie("user", JSON.stringify(response.user), {
          path: "/",
        });
        setLanguageCodeToStorage(response.user?.language);
        const authUser = getAuth();
        let provider = authUser?.currentUser?.providerData[0]?.providerId;
        if (
          authUser.currentUser &&
          provider === "password" &&
          !authUser.currentUser.emailVerified
        ) {
          navigate("/email-verification");
        } else if (response.user?.preferred_tags?.length === 0) {
          navigate("/preferred-tags");
        } else {
          let urlOfLastPage = localStorage.getItem("urlOfLastPage") || null;
          if (urlOfLastPage !== null) {
            window.location.href = urlOfLastPage;
            localStorage.removeItem("urlOfLastPage");
          } else {
            navigate(location?.state?.from || -1, { replace: true });
          }
        }
      }
    } else if (response.status === 401) {
      console.log("response in 401", response);
      setShowAlert({
        status: true,
        message: response.message,
      });
    }
  };

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            height: "100vh",
            backgroundImage: `url(${"https://i.ibb.co/6BhjndZ/Mobile-LOgin121415111.png"})`,
            backgroundColor: "white",
            backgroundRepeat: "no-repeat",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            backgroundPosition: "center",
            backgroundSize: "cover",
            boxShadow: "none",
            border: "1px solid #e0e0e0",
            width: "100%",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              ml: 4,
              mr: 4,
              borderRadius: "8px",
            }}
          >
            <img
              onClick={() => {
                navigate("/");
              }}
              style={{
                width: "140px",
                height: 50,
              }}
              src={Newlogo}
              alt="logo"
            />
            <Typography letterSpacing={0.4} sx={{ fontSize: 20, mt: 1 }}>
              {t("Welcome! Sign in to get started")}
            </Typography>
            <SignIn loginFromBackend={loginFromBackend} />
            <button
              className="googlelogin_button"
              onClick={googleLogin}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <FcGoogle size={22} style={{ marginRight: "5px" }} />
              {t("Continue with Google")}
            </button>
            <FacebookLogin handleSignin={handleSignin} />
            <Typography letterSpacing={0.4} fontSize={16} mt={2}>
              {t("By signing up, you agree to our")}
            </Typography>
            <Box style={{ marginTop: "10px" }}>
              <a
                href="/terms"
                className="lz3b66v terms_of_service"
                target="_blank"
                rel="nofollow noopener noreferrer"
                style={{ fontSize: 16 }}
              >
                {t("Terms of Service")},
              </a>
              &nbsp;
              <a
                href="/refund"
                className="lz3b66v copyright_policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                style={{ fontSize: 16 }}
              >
                {t("Refund Policy")}
              </a>
              &nbsp; and &nbsp;
              <a
                href="/privacy"
                className="lz3b66v privacy_policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {t("Privacy Policy")}
              </a>
            </Box>
          </Box>

          {showAlert.status && <AlertComponent message={showAlert.message} />}
        </Box>
      ) : (
        <Grid className="loginLangdingContainer">
          <LeftSidePannelWithLogo />
          <Grid className="loginLandingRight">
            <Box
              className="again_landing_right"
              sx={{
                height: "100vh",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ justifyContent: "center", textAlign: "center" }}>
                <Typography letterSpacing={0.4} sx={{ fontSize: 20, mt: 1 }}>
                  {t("Welcome! Sign in to get started")}
                </Typography>
                <SignIn loginFromBackend={loginFromBackend} />
                <hr />
                <button
                  className="googlelogin_button"
                  onClick={googleLogin}
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: "10px",
                  }}
                >
                  <FcGoogle size={22} style={{ marginRight: "5px" }} />
                  {t("Continue with Google")}
                </button>
                <FacebookLogin handleSignin={handleSignin} />
                <Typography letterSpacing={0.4} fontSize={16} mt={2}>
                  {t("By signing up, you agree to our")}
                </Typography>
                <Box style={{ marginTop: "10px" }}>
                  <a
                    href="/terms"
                    className="lz3b66v terms_of_service"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    style={{ fontSize: 16 }}
                  >
                    {t("Terms of Service")},
                  </a>
                  &nbsp;
                  <a
                    href="/refund"
                    className="lz3b66v copyright_policy"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    style={{ fontSize: 16 }}
                  >
                    {t("Refund Policy")}
                  </a>
                  &nbsp; and &nbsp;
                  <a
                    href="/privacy"
                    className="lz3b66v privacy_policy"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {t("Privacy Policy")}
                  </a>
                </Box>
              </Box>

              {showAlert.status && (
                <AlertComponent message={showAlert.message} />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Loginlanding;
