import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CommentInput from "./components/comment-input";
import PropTypes from "prop-types";
import CommentsList from "./components/comments-list";
import { Cookies } from "react-cookie";
import { ThemeContext } from "../../../../context/ThemeContext";
import { backend_url } from "../../../../http-common";
import { v4 as uuidv4 } from "uuid";
import { getRequest } from "../../../../services/request";

const PostComments = ({ postId, from, fetchPost }) => {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { userPosts, setUserPosts } = useContext(ThemeContext);
  const [taggedStock, setTaggedStock] = useState([]);
  const [taggedUser, setTaggedUser] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [open, setOpen] = useState(false);
  const [showBox, setShowBox] = useState(true);

  // image

  const validateImg = (e) => {
    setShowBox(false);
    const file = e.target.files[0];
    if (file.size >= 1048576) {
      return alert("Max file size is 1MB.");
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  async function uploadImage() {
    // console.log("console image", image);
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "h41kkssr");
    try {
      let res = await fetch(
        `https://api.cloudinary.com/v1_1/djfgbivby/image/upload`,
        {
          method: "post",
          body: data,
        }
      );
      const urlData = await res.json();

      return urlData.url;
    } catch (error) {
      // console.log(error);
    }
  }

  const handleDeleteImage = () => {
    setImagePreview(() => null);
  };
  //

  useEffect(() => {
    const getComments = async () => {
      setLoadingComments(true);
      const response = await getRequest({
        url: `/get-comments/${postId}`,
      });
      setCommentData(() => response.data?.comments);
      setLoadingComments(false);
    };
    getComments();
  }, [postId]);

  const updateCommentCount = (commentCount) => {
    let postD = userPosts?.filter((item) => item.unique_id === postId)[0];

    let obj = {
      ...postD,
      Comment_count: commentCount,
    };

    let newPostsArray = userPosts.map((item) => {
      if (item.unique_id === postId) {
        return { ...item, Comment_count: commentCount };
      } else {
        return item;
      }
    });

    setUserPosts(newPostsArray);

    const payloadjson = JSON.stringify(obj);
    fetch(`${backend_url}/edit-post/${postId}`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {})
      .catch((err) => console.log(err));
  };
  const handleCommentPost = async (commentText) => {
    setOpen(false);

    let url = await uploadImage(image);
    setLoading(true);
    setImage(null);
    setImagePreview(null);

    const userDetails = {
      First_Name: user.First_Name,
      username: user.username,
      last_name: user.last_name,
      userpic_url: user.userpic_url,
    };
    const form = {
      comment_unique_id: uuidv4(),
      comment_text: commentText,
      comment_post_id: postId,
      comment_user_id: user.username,
      Comment_created_at: Date.now(),
      comment_type: from === "query" ? 2 : 1,
      userDetails: userDetails,
      taggedUser: taggedUser,
      taggedStock: taggedStock,
      Image: url,
    };
    // console.log("hello url", url);

    setCommentData((d) => [form, ...d]);

    updateCommentCount(commentData.length + 1);

    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/add-comment/${postId}`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        fetchPost();
      })
      .catch((err) => console.log(err));
    setShowBox(true);
  };

  return (
    <Box className="comment_main_div">
      {user && (
        <CommentInput
          handleCommentPost={handleCommentPost}
          loading={loading}
          taggedStock={taggedStock}
          setTaggedStock={setTaggedStock}
          taggedUser={taggedUser}
          setTaggedUser={setTaggedUser}
          imagePreview={imagePreview}
          handleDeleteImage={handleDeleteImage}
          validateImg={validateImg}
          setShowBox={setShowBox}
          showBox={showBox}
        />
      )}

      <CommentsList
        commentData={commentData}
        setCommentData={setCommentData}
        updateCommentCount={updateCommentCount}
        loading={loadingComments}
        from={from}
        fetchPost={fetchPost}
      />
    </Box>
  );
};

PostComments.propTypes = {
  postId: PropTypes.string,
  from: PropTypes.string,
  fetchPost: PropTypes.func,
};

export default PostComments;
