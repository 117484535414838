import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  capitalFirstLetter,
  checkFollow,
} from "../../../common-functions/FormatDate";
import { Link } from "react-router-dom";
import ImgPlaceholder from "../../../../assets/image_placeholder.png";
import { Cookies } from "react-cookie";
import { Avatar, Typography } from "@mui/material";

export const Followers = ({ followers, handleClose }) => {
  const {
    theme,

    followUsers,
    myFollowings,
    handleFollow,
  } = useContext(ThemeContext);

  const [data, setData] = useState([]);
  const cookie = new Cookies();
  const user = cookie.get("user");

  useEffect(() => {
    if (followUsers.length > 0) {
      fetchUser(followUsers);
    }
  }, [followUsers]);

  const fetchUser = (usernameArray) => {
    usernameArray?.forEach((item, idx) => {
      let modifiedData = {
        first_name: item?.userId?.First_Name,
        last_name: item?.userId?.last_name,
        user_pic: item?.userId?.userpic_url,
        username: item?.userId?.username,
        isFollowing: false,
      };

      setData((d) => [...d, modifiedData]);
    });
  };

  const sortArrayOfObject = (array) => {
    array?.sort(function (a, b) {
      if (a.first_name < b.first_name) {
        return -1;
      }
      if (a.first_name > b.first_name) {
        return 1;
      }
      return 0;
    });

    return array;
  };

  return (
    <div>
      {data?.length === 0 || followUsers.length <= 0 ? (
        <div>No followers</div>
      ) : (
        sortArrayOfObject([
          ...new Map(data.map((item) => [item["username"], item])).values(),
        ]).map((item, idx) => (
          <div className="follower_main_div" key={`followers-popup-${idx}`}>
            <div className="follower_name_div">
              <div className="follower_image_div">
                <Avatar
                  src={item.user_pic || ImgPlaceholder}
                  alt="img"
                  sx={{
                    width: 42,
                    height: 42,
                  }}
                />
              </div>
              <div className="grid_div">
                <div>
                  <Link
                    to={`/user/${item.username}`}
                    onClick={handleClose}
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <Typography fontSize={14}>
                      {`${capitalFirstLetter(
                        item.first_name
                      )} ${capitalFirstLetter(item.last_name)}`}
                    </Typography>

                    <Typography
                      fontSize={12}
                      color={"#c5c7c7"}
                      mt={-0.7}
                    >{`@${item.username}`}</Typography>
                  </Link>
                </div>
              </div>
            </div>
            <div className="follower_follow_btn">
              <button
                className="following_btn"
                onClick={() => handleFollow(idx, item.username)}
              >
                {checkFollow(myFollowings, item.username)
                  ? "Following"
                  : "Follow"}
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
export default Followers;
