import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../context/ThemeContext";
import { Button, Divider } from "@mui/material";
import AllNews from "../fundu-news/all-news";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import { useTranslation } from "react-i18next";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "black",
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NewsDetailsTab() {
  const [value, setValue] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);
  const { newsData } = React.useContext(StockNewsContext);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSeeMoreClick = () => {
    window.location.href = "/news"; // Replace "/" with the home page URL
  };

  return (
    <>
      <Box
        sx={{
          mt: 1,
          mb: 2,
          height: 49,
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
        }}
        style={theme ? light : dark}
      >
        <Box sx={{ borderColor: "#6b7280" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* -----tabs--------- */}
            <Tab
              label={t("Related News")}
              style={{ marginLeft: "30px" }}
              sx={{ textTransform: "none", fontSize: "16px", fontWeight: 600 }}
              {...a11yProps(0)}
            />
          </Tabs>
          <Divider />
        </Box>
        <TabPanel
          value={value}
          index={0}
          className="tabPanel_mostbought"
          style={{ marginTop: "10px" }}
        >
          <AllNews data={newsData} />
        </TabPanel>
      </Box>
    </>
  );
}
