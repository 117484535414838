import React, { useContext, useState } from "react";
import { Box, Button, Card } from "@mui/material";

const WatchlistMobileDefault = ({ handleScroll, middleContent }) => {
  return (
    <>
      <Box
        onScroll={handleScroll}
        sx={{
          backgroundColor: "#f7f6f9",
          height: "100vh",
          width: "100wh",
          position: "relative",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {middleContent}
      </Box>
    </>
  );
};

export default WatchlistMobileDefault;
