import React, { useContext } from "react";
import Suggestion from "../../Components/common/components/suggestion";
import { ThemeContext } from "../../context/ThemeContext";
import { useParams } from "react-router-dom";
import SliderForStocks from "../../Components/all-stocks-tab/slider-for-stocks";
import { stocksTags } from "../../Components/all-stocks-tab/Constant";
import FilterStocks from "../../Components/filter-stocks-main/filter-stocks";
import { Cookies } from "react-cookie";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";

const FilterStocksPage = () => {
  const { theme } = useContext(ThemeContext);
  const { tag } = useParams();
  const cookie = new Cookies();
  const { width } = useViewPort();
  const user = cookie.get("user");
  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <div>
              {/* <SliderForStocks value={stocksTags} selectedTagStocks={tag} /> */}

              <FilterStocks />
            </div>
          }
        />
      ) : (
        <LayoutDefault
          mainContent={
            <div>
              <SliderForStocks value={stocksTags} selectedTagStocks={tag} />
              <br></br>
              <FilterStocks />
            </div>
          }
          rightPanelContent={
            <div>
              {user ? <Suggestion /> : ""}
              {/* <StocksTab />
            <NewsTab />
            <Fundutab /> */}
              <MostPopularStocksRight />
              <TrendingNewsCard />
              {/* <Fundugame /> */}
            </div>
          }
        />
      )}
    </Box>
  );
};

export default FilterStocksPage;
