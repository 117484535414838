import * as React from "react";
import { useMemo } from "react";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { Link, useNavigate } from "react-router-dom";
import SavedPost from "../saved-post";
import NewsTab from "../news-tab";
import { useTranslation } from "react-i18next";

function useSavedTabs() {
  const { t } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        name: t("Posts"),
        component: <SavedPost />,
      },
      {
        name: t("News"),
        component: <NewsTab />,
      },
    ],
    []
  );

  return {
    tabs,
  };
}

export default useSavedTabs;
