import "../App.css";
import { Routes, Route } from "react-router-dom";
import LandingPage from "../Pages/landing-page";
import Loginlanding from "../Pages/login-landing";
import Signup from "../Pages/signup-page";
import UserProfilePage from "../Pages/user-profile-page";
import PostDetailsMainPage from "../Pages/post-details-main-page";
import SettingPage from "../Pages/setting-page";
import AllNewsPage from "../Pages/all-news-page";
import SeeAllResults from "../Pages/see-all-results";
import MostPopularStock from "../Pages/most-popular-stock-page/index";
import FilterPostsMain from "../Pages/filter-posts-page";
import FilterNewsPage from "../Pages/filter-news-page";
import FilterStocksPage from "../Pages/filter-stocks-page";
import SuggestionAll from "../Pages/all-suggestion-page";
import SuggestionPage from "../Pages/suggestion-page";
import Guide from "../Components/user-guide";
import NewsDetailsPage from "../Pages/news-details-page";
import StocksDetailsPage from "../Pages/stocks-details-page";
import NotFoundPage from "../Pages/not-found-page";
import SavedPage from "../Pages/saved-page";
import Terms from "../Pages/terms-of-services";
import Privacy from "../Pages/privacy-policy";
import Copyright from "../Pages/copyright-policy";
import AlertPage from "../Pages/alert-page";
import Fyers from "../Pages/fyers";
import { useTranslation } from "react-i18next";

function AllRoutes() {
  const userGuide = JSON.parse(sessionStorage.getItem("userGuide"));
  const isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"));
  const { i18n } = useTranslation();

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage isLoggedIn={isLoggedIn} userGuide={userGuide} />
          }
        />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/welcome" element={<Loginlanding />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/user/:username" element={<UserProfilePage />} />
        <Route path="/post/:postId" element={<PostDetailsMainPage />} />
        <Route path="/alert" element={<AlertPage />} />
        <Route path="/news" element={<AllNewsPage />} />
        <Route path="/news/positive" element={<AllNewsPage />} />
        <Route path="/news/negative" element={<AllNewsPage />} />
        <Route path="/news/neutral" element={<AllNewsPage />} />
        <Route path="/stocks" element={<MostPopularStock />} />
        <Route path="/stocks/sentiment/buy" element={<MostPopularStock />} />
        <Route path="/stocks/sentiment/sell" element={<MostPopularStock />} />
        <Route
          path="/stocks/sentiment/neutral"
          element={<MostPopularStock />}
        />
        <Route path="/suggestionall" element={<SuggestionAll />} />
        <Route path="/suggestion/users" element={<SuggestionPage />} />
        <Route path="/suggestion/post" element={<SuggestionPage />} />
        <Route path="/suggestion/news" element={<SuggestionPage />} />
        <Route path="/suggestion/stocks" element={<SuggestionPage />} />
        <Route path="/result/:searchInput" element={<SeeAllResults />} />
        <Route path="/result/post" element={<SeeAllResults />} />
        <Route path="/result/news" element={<SeeAllResults />} />
        <Route path="/result/stocks" element={<SeeAllResults />} />
        <Route path="/settings" element={<SettingPage />} />
        <Route path="/saved" element={<SavedPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/copyright" element={<Copyright />} />
        <Route path="/tagged-content/:tag" element={<FilterPostsMain />} />
        <Route path="/news/:tag" element={<FilterNewsPage />} />
        <Route path="/stocks/category/:tag" element={<FilterStocksPage />} />
        <Route path="/stock/:symbol" element={<StocksDetailsPage />} />
        <Route
          path={`/news/:newsId?lan=${i18n.language || "en"}`}
          element={<NewsDetailsPage />}
        />
        <Route path="/fyers/auth" element={<Fyers />} />
      </Routes>
    </div>
  );
}

export default AllRoutes;
