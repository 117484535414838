import React, { useState } from "react";
import { sliderData } from "../constant";
import "../index.css";
import Button from "@mui/material/Button";
import bg from "./../../../assets/cardwinnerbg.jpeg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import Card from "@mui/material/Card";
import {
  Chip,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/askfunduLogo.png";
import { BREAKPOINT } from "../../../constants/constants";
import useViewPort from "../../../hooks/useViewPort";

const Slider = ({
  handleClose,
  selectedPreferredTags,
  handleSelectPreferredTags,
  selectedExperience,
  handleSelectedExperience,
  selectedQuestions,
  handleSelectedQuestions,
  setPhoneNumber,
  phoneNumber,
  handleDone,
  selectLanguage,
  setSelectLanguage,
  setRefer,
  refer,
}) => {
  const [current, setCurrent] = useState(0);
  const [slideIndex, setSlideIndex] = useState(1);
  const length = 5;
  const navigate = useNavigate();
  const { width } = useViewPort();

  const handleChange = (event) => {
    setSelectLanguage(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleReferChange = (event) => {
    setRefer(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // alert(`Mobile number submitted: ${phone}`);
    // alert(`Phone number submitted: ${phoneNumber}`);
  };

  const nextSlide = useCallback(() => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }, [current, length]);

  const prevSlide = useCallback(() => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  }, [current, length]);

  const moveDot = useCallback((index) => {
    setSlideIndex(index);
  }, []);

  const checkTags = useCallback(() => {
    let strategiesTagsCount = 0;
    let optionsCount = 0;

    selectedPreferredTags.forEach((tag) => {
      if (sliderData.secondSlide.strategies.includes(tag)) {
        strategiesTagsCount++;
      } else if (sliderData.secondQuestion.options.includes(tag)) {
        optionsCount++;
      }
    });

    if (strategiesTagsCount === 2 && optionsCount === 2) {
      return false; // Enable next button
    } else {
      return true; // Disable next button
    }
  }, [selectedPreferredTags]);

  return (
    <>
      {width < BREAKPOINT ? (
        <Box
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box>
            {0 === current && (
              <Box
                sx={{
                  backgroundImage: `url(${bg})`,
                  backgroundColor: "white",
                  backgroundRepeat: "no-repeat",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#372B47",
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  HOLA! we’re so excited you are a part of FundU.
                </Typography>

                <Typography
                  letterSpacing={0.4}
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #5511D6 0%, #FD10F3 100%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontSize: 30,
                    fontWeight: "600",
                    mt: 3,
                  }}
                >
                  Welcome
                </Typography>
                <br />
                <Box>
                  {sliderData.firstSlide.map((slide, index) => {
                    return (
                      <Box
                        sx={{
                          maxWidth: "100%",
                          p: 2,
                          display: "flex",
                          mt: -4,
                        }}
                      >
                        <Typography
                          letterSpacing={0.4}
                          sx={{
                            fontSize: 12,
                            color: "#372B47",
                            fontWeight: 600,
                            lineHeight: "32px",
                            p: 2,
                            textAlign: "left",
                          }}
                        >
                          {slide.title}
                          <br />
                          {slide.title2}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
            {1 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#372B47",
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Select Your Interests
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 3,
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: "white",
                      p: 1,
                    }}
                  >
                    {sliderData.secondSlide.strategies.map((tag, idx) => {
                      return (
                        <>
                          <Chip
                            key={idx}
                            label={tag}
                            variant={
                              selectedPreferredTags?.includes(tag)
                                ? "filled"
                                : "outlined"
                            }
                            values={selectedPreferredTags}
                            onClick={() => handleSelectPreferredTags(tag)}
                            sx={{
                              marginRight: "8px",
                              marginBottom: "8px",
                              width: 150,
                              height: 29,
                              border: "0.1px solid #5d20d2",
                              borderRadius: "14px",
                              fontSize: 11,
                              backgroundColor: selectedPreferredTags?.includes(
                                tag
                              )
                                ? "#5d20d2"
                                : "default",
                              color: selectedPreferredTags?.includes(tag)
                                ? "white"
                                : "#5d20d2",
                            }}
                          />
                        </>
                      );
                    })}
                  </Card>
                </Box>
              </>
            )}

            {2 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#372B47",
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Please answer these preliminary questions so we could help you
                  get started <br />
                  (Please select all that applies)
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: "white",
                      p: 1,
                      pt: 4,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#5D20D2",
                        textAlign: "center",
                      }}
                    >
                      Prior Investment Experience
                    </Typography>
                    {sliderData.firstQuestion.options.map((tag, idx) => {
                      return (
                        <Box
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Chip
                            key={idx}
                            label={tag}
                            variant={
                              selectedExperience === tag ? "filled" : "outlined"
                            }
                            onClick={() => handleSelectedExperience(tag)}
                            sx={{
                              marginRight: "8px",
                              marginBottom: "8px",
                              width: 150,
                              height: 29,
                              border: "0.1px solid #5d20d2",
                              borderRadius: "14px",
                              fontSize: 11,
                              backgroundColor:
                                selectedExperience === tag
                                  ? "#5d20d2"
                                  : "default",
                              color:
                                selectedExperience === tag
                                  ? "white"
                                  : "#5d20d2",
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Card>

                  <Card
                    sx={{
                      backgroundColor: "white",
                      width: "100vw",
                      p: 1,
                      pt: 4,
                    }}
                  >
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#5D20D2",
                        textAlign: "center",
                        mt: -2,
                      }}
                    >
                      How will FundU help you?
                    </Typography>
                    {sliderData.secondQuestion.options.map((tag, idx) => {
                      return (
                        <Box
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Chip
                            key={idx}
                            label={tag}
                            variant={
                              selectedQuestions?.includes(tag)
                                ? "filled"
                                : "outlined"
                            }
                            values={selectedQuestions}
                            onClick={() => handleSelectedQuestions(tag)}
                            sx={{
                              marginRight: "8px",
                              marginBottom: "8px",
                              width: 150,
                              height: 25,
                              border: "0.1px solid #5d20d2",
                              borderRadius: "14px",
                              fontSize: 11,
                              backgroundColor: selectedQuestions?.includes(tag)
                                ? "#5d20d2"
                                : "default",
                              color: selectedQuestions?.includes(tag)
                                ? "white"
                                : "#5d20d2",
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Card>
                </Box>
              </>
            )}

            {3 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#372B47",
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Choose your language
                </Typography>
                <Box
                  sx={{
                    height: "50vh",
                    width: "100vw",
                    p: 1,
                    pt: 2,
                    pb: 2,
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <RadioGroup
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={selectLanguage}
                    onChange={handleChange}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        mb: 1,
                        alignItems: "center",
                        pl: 3,
                        height: 45,
                        boxShadow: "0px 4px 4px #D8D1E3",
                        borderRadius: "8px",
                        mt: 3,
                      }}
                    >
                      <Typography letterSpacing={0.4} fontSize={14}>
                        हिंदी - Hindi
                      </Typography>
                      <FormControlLabel value="Hindi" control={<Radio />} />
                    </Card>

                    <Card
                      sx={{
                        display: "flex",
                        gap: 25,
                        textAlign: "center",
                        justifyContent: "space-between",
                        mb: 1,
                        alignItems: "center",
                        height: 45,
                        boxShadow: "0px 4px 4px #D8D1E3",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography letterSpacing={0.4} fontSize={14}>
                        English - English
                      </Typography>
                      <FormControlLabel value="English" control={<Radio />} />
                    </Card>
                  </RadioGroup>
                </Box>
              </>
            )}

            {4 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    color: "#372B47",
                    fontWeight: 600,
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Please add your mobile number
                </Typography>
                <Card
                  sx={{
                    backgroundColor: "white",
                    height: "50vh",
                    width: "100vw",
                    p: 1,
                    mr: 2,
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      type="tel"
                      value={phoneNumber}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "36px",
                          width: 320,
                          borderRadius: "8px",
                        },
                      }}
                      onChange={handlePhoneNumberChange}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ mt: 0.1, color: "black" }}
                          >
                            <img
                              style={{ width: 25, height: 25 }}
                              src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWTKuI8-Mb1PYEIS1Kk-i4hRj_nUOQ4ZjKojyBn5ouIi-5-OdLVMAQee1DqEI4GbNQFX0&usqp=CAU"
                              }
                              alt="img"
                            />{" "}
                            +91
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        pattern: "[0-9]{10}",
                        title: "Please enter 10 numbers",
                      }}
                    />

                    <TextField
                      label="Refer Code"
                      variant="outlined"
                      type="text"
                      size="small"
                      value={refer}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          width: 320,
                          borderRadius: "8px",
                        },
                      }}
                      onChange={handleReferChange}
                      required
                    />
                  </form>
                </Card>
              </>
            )}
          </Box>
          <Box className="container-dots">
            {Array.from({ length: 5 }).map((item, index) => (
              <Box
                onClick={moveDot}
                className={index === current ? "dot active" : "dot"}
              ></Box>
            ))}
          </Box>

          <Box className="slider_button">
            {current !== 0 && (
              <Button
                sx={{
                  borderRadius: 24,
                  textTransform: "none",
                  fontSize: "12px",
                  width: "160px",
                  height: "40px",
                  backgroundColor: "#5D20D2",
                  ":hover": {
                    backgroundColor: "#5D20D2",
                    color: "white",
                  },
                }}
                variant="contained"
                onClick={prevSlide}
              >
                Prev
              </Button>
            )}

            {current !== 4 && (
              <Button
                sx={{
                  borderRadius: 24,
                  textTransform: "none",
                  fontSize: "12px",
                  width: "160px",
                  height: "40px",
                  backgroundColor: "#5D20D2",
                  ":hover": {
                    backgroundColor: "#5D20D2",
                    color: "white",
                  },
                }}
                disabled={
                  current !== 0 &&
                  ((current === 1 &&
                    selectedPreferredTags &&
                    selectedPreferredTags?.length < 2) ||
                    (current === 2 &&
                      selectedQuestions &&
                      selectedQuestions?.length > 2) ||
                    (current === 2 &&
                      selectedExperience &&
                      selectedExperience?.length < 1))
                }
                variant="contained"
                onClick={nextSlide}
              >
                {" "}
                Next
              </Button>
            )}

            {current === 4 && (
              <Button
                sx={{
                  borderRadius: 24,
                  textTransform: "none",
                  fontSize: "12px",
                  width: "160px",
                  height: "40px",
                  backgroundColor: "#5D20D2",
                  ":hover": {
                    backgroundColor: "#5D20D2",
                    color: "white",
                  },
                }}
                variant="contained"
                onClick={handleDone}
              >
                Launch
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box>
            <img
              style={{
                height: "45px",
                width: "140px",
              }}
              onClick={() => {
                navigate("/");
              }}
              src={logo}
              alt="logo"
            />
            {0 === current && (
              <Box
                sx={{
                  backgroundImage: `url(${bg})`,
                  backgroundColor: "white",
                  backgroundRepeat: "no-repeat",
                  maxHeight: "100%",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#372B47",
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  HOLA! we’re so excited you are a part of FundU.
                </Typography>

                <Typography
                  letterSpacing={0.4}
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #5511D6 0%, #FD10F3 100%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontSize: 30,
                    fontWeight: "600",
                    mt: 3,
                  }}
                >
                  Welcome
                </Typography>
                <br />
                <Box>
                  {sliderData.firstSlide.map((slide, index) => {
                    return (
                      <Box
                        sx={{
                          maxWidth: "100%",
                          p: 2,
                          display: "flex",
                          mt: -4,
                        }}
                      >
                        <Typography
                          letterSpacing={0.4}
                          sx={{
                            fontSize: 12,
                            color: "#372B47",
                            fontWeight: 600,
                            lineHeight: "32px",
                            p: 2,
                            textAlign: "left",
                          }}
                        >
                          {slide.title}
                          <br />
                          {slide.title2}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
            {1 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Select Your Interests
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 3,
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: "white",
                      width: 520,
                      height: 330,
                      p: 1,
                      pt: 4,
                    }}
                  >
                    {sliderData.secondSlide.strategies.map((tag, idx) => {
                      return (
                        <>
                          <Chip
                            key={idx}
                            label={tag}
                            variant={
                              selectedPreferredTags?.includes(tag)
                                ? "filled"
                                : "outlined"
                            }
                            values={selectedPreferredTags}
                            onClick={() => handleSelectPreferredTags(tag)}
                            sx={{
                              marginRight: "8px",
                              marginBottom: "8px",
                              width: 150,
                              height: 29,
                              border: "0.1px solid #5d20d2",
                              borderRadius: "14px",
                              fontSize: 11,
                              backgroundColor: selectedPreferredTags?.includes(
                                tag
                              )
                                ? "#5d20d2"
                                : "default",
                              color: selectedPreferredTags?.includes(tag)
                                ? "white"
                                : "#5d20d2",
                            }}
                          />
                        </>
                      );
                    })}
                  </Card>
                </Box>
              </>
            )}

            {2 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Please answer these preliminary questions so we could help you
                  get started <br />
                  (Please select all that applies)
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: "white",
                      width: 260,
                      height: 320,
                      p: 1,
                      pt: 4,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#5D20D2",
                        textAlign: "center",
                        mt: -2,
                      }}
                    >
                      Prior Investment Experience
                    </Typography>
                    {sliderData.firstQuestion.options.map((tag, idx) => {
                      return (
                        <Box
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Chip
                            key={idx}
                            label={tag}
                            variant={
                              selectedExperience === tag ? "filled" : "outlined"
                            }
                            onClick={() => handleSelectedExperience(tag)}
                            sx={{
                              marginRight: "8px",
                              marginBottom: "8px",
                              width: 150,
                              height: 29,
                              border: "0.1px solid #5d20d2",
                              borderRadius: "14px",
                              fontSize: 11,
                              backgroundColor:
                                selectedExperience === tag
                                  ? "#5d20d2"
                                  : "default",
                              color:
                                selectedExperience === tag
                                  ? "white"
                                  : "#5d20d2",
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Card>

                  <Card
                    sx={{
                      backgroundColor: "white",
                      width: 260,
                      height: 320,
                      p: 1,
                      pt: 4,
                    }}
                  >
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#5D20D2",
                        textAlign: "center",
                        mt: -2,
                      }}
                    >
                      How will FundU help you?
                    </Typography>
                    {sliderData.secondQuestion.options.map((tag, idx) => {
                      return (
                        <Box
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Chip
                            key={idx}
                            label={tag}
                            variant={
                              selectedQuestions?.includes(tag)
                                ? "filled"
                                : "outlined"
                            }
                            values={selectedQuestions}
                            onClick={() => handleSelectedQuestions(tag)}
                            sx={{
                              marginRight: "8px",
                              marginBottom: "8px",
                              width: 150,
                              height: 25,
                              border: "0.1px solid #5d20d2",
                              borderRadius: "14px",
                              fontSize: 11,
                              backgroundColor: selectedQuestions?.includes(tag)
                                ? "#5d20d2"
                                : "default",
                              color: selectedQuestions?.includes(tag)
                                ? "white"
                                : "#5d20d2",
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Card>
                </Box>
              </>
            )}

            {3 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Choose your language
                </Typography>
                <Box
                  sx={{
                    width: 520,
                    p: 1,
                    pt: 2,
                    pb: 2,
                    mr: 2,
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <RadioGroup
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={selectLanguage}
                    onChange={handleChange}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        mb: 1,
                        alignItems: "center",
                        pl: 3,
                        height: 45,
                        boxShadow: "0px 4px 4px #D8D1E3",
                        borderRadius: "8px",
                        mt: 3,
                      }}
                    >
                      <Typography letterSpacing={0.4} fontSize={14}>
                        हिंदी - Hindi
                      </Typography>
                      <FormControlLabel value="Hindi" control={<Radio />} />
                    </Card>

                    <Card
                      sx={{
                        display: "flex",
                        gap: 40,
                        textAlign: "center",
                        justifyContent: "space-between",
                        mb: 1,
                        alignItems: "center",
                        pl: 3,
                        height: 45,
                        boxShadow: "0px 4px 4px #D8D1E3",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography letterSpacing={0.4} fontSize={14}>
                        English-English
                      </Typography>
                      <FormControlLabel value="English" control={<Radio />} />
                    </Card>
                  </RadioGroup>
                </Box>
              </>
            )}

            {4 === current && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Please add your mobile number
                </Typography>
                <Card
                  sx={{
                    backgroundColor: "white",
                    width: 520,
                    height: 240,
                    p: 1,
                    mr: 2,
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      type="tel"
                      value={phoneNumber}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "36px",
                          width: 320,
                          borderRadius: "8px",
                        },
                      }}
                      onChange={handlePhoneNumberChange}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ mt: 0.1, color: "black" }}
                          >
                            <img
                              style={{ width: 25, height: 25 }}
                              src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWTKuI8-Mb1PYEIS1Kk-i4hRj_nUOQ4ZjKojyBn5ouIi-5-OdLVMAQee1DqEI4GbNQFX0&usqp=CAU"
                              }
                              alt="img"
                            />{" "}
                            +91
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        pattern: "[0-9]{10}",
                        title: "Please enter 10 numbers",
                      }}
                    />
                    <br />
                    <br />

                    <TextField
                      label="Refer Code"
                      variant="outlined"
                      type="text"
                      size="small"
                      value={refer}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          width: 320,
                          borderRadius: "8px",
                        },
                      }}
                      onChange={handleReferChange}
                      required
                    />
                  </form>
                </Card>
              </>
            )}
          </Box>
          <Box className="container-dots">
            {Array.from({ length: 5 }).map((item, index) => (
              <Box
                onClick={moveDot}
                className={index === current ? "dot active" : "dot"}
              ></Box>
            ))}
          </Box>

          <Box className="slider_button">
            {current !== 0 && (
              <Button
                sx={{
                  borderRadius: 24,
                  textTransform: "none",
                  fontSize: "12px",
                  width: "160px",
                  height: "40px",
                  backgroundColor: "#5D20D2",
                  ":hover": {
                    backgroundColor: "#5D20D2",
                    color: "white",
                  },
                }}
                variant="contained"
                onClick={prevSlide}
              >
                Prev
              </Button>
            )}

            {current !== 4 && (
              <Button
                sx={{
                  borderRadius: 24,
                  textTransform: "none",
                  fontSize: "12px",
                  width: "160px",
                  height: "40px",
                  backgroundColor: "#5D20D2",
                  ":hover": {
                    backgroundColor: "#5D20D2",
                    color: "white",
                  },
                }}
                disabled={
                  current !== 0 &&
                  ((current === 1 &&
                    selectedPreferredTags &&
                    selectedPreferredTags?.length < 2) ||
                    (current === 2 &&
                      selectedQuestions &&
                      selectedQuestions?.length > 2) ||
                    (current === 2 &&
                      selectedExperience &&
                      selectedExperience?.length < 1))
                }
                variant="contained"
                onClick={nextSlide}
              >
                {" "}
                Next
              </Button>
            )}

            {current === 4 && (
              <Button
                sx={{
                  borderRadius: 24,
                  textTransform: "none",
                  fontSize: "12px",
                  width: "160px",
                  height: "40px",
                  backgroundColor: "#5D20D2",
                  ":hover": {
                    backgroundColor: "#5D20D2",
                    color: "white",
                  },
                }}
                variant="contained"
                onClick={handleDone}
                // disabled={
                //   // selectedPreferredTags?.length < 1 ||
                //   // selectedPreferredTags?.length > 10
                //   //   ? true
                //   //   : false
                //   checkTags()
                // }
              >
                Launch
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
export default Slider;
