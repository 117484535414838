import { Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { AiTwotoneLike, AiTwotoneDislike } from "react-icons/ai";
import { backend_url } from "../../../../../../http-common";
import useSessionToken from "../../../../../../hooks/useSessionToken";

const LikeDislikeAnswer = ({ comment_id }) => {
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  // const { token } = useSessionToken();
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const { token } = useSessionToken();

  function fetchLikeDislike() {
    fetch(`${backend_url}/posts/get-comment-reactions?comment_id=${comment_id}`)
      .then((res) => res.json())
      .then((res) => {
        // console.log("hello from like dislike", res, comment_id);
        setLikeCount(res.data.like_count);
        setDislikeCount(res.data.dislike_count);
      })
      .catch((e) => console.log(e, "like"));
  }
  // console.log("hello dislike", token);

  const likeComment = () => {
    let payload = JSON.stringify({ comment_id: comment_id });
    fetch(`${backend_url}/posts/comment/like`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then((res) => res.json())
      .then((res) => {
        fetchLikeDislike();
        // console.log(res);
      });
  };

  const dislikeComment = () => {
    let payload = JSON.stringify({ comment_id: comment_id });

    fetch(`${backend_url}/posts/comment/dislike`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then((res) => res.json())
      .then((res) => {
        fetchLikeDislike();
        // console.log(res);
      });
  };

  useEffect(() => {
    fetchLikeDislike();
  }, []);

  const handleLike = () => {
    likeComment();
    if (like) {
      setLike(false);
      setDislike(false);
    } else {
      setLike(true);
      setDislike(false);
    }
  };

  const handleDislike = () => {
    dislikeComment();
    if (dislike) {
      setDislike(false);
      setLike(false);
    } else {
      setDislike(true);
      setLike(false);
    }
  };
  // console.log(like, dislike, "hello hello")
  return (
    <Box sx={{ display: "flex" }}>
      <Button sx={{ border: "none", gap: 1 }} onClick={handleLike}>
        {like ? (
          <AiTwotoneLike style={{ fontSize: "20px" }} />
        ) : (
          <AiOutlineLike style={{ fontSize: "20px" }} />
        )}{" "}
        {likeCount}
      </Button>

      <Button sx={{ border: "none", gap: 1 }} onClick={handleDislike}>
        {dislike ? (
          <AiTwotoneDislike style={{ fontSize: "20px" }} />
        ) : (
          <AiOutlineDislike style={{ fontSize: "20px" }} />
        )}
        {dislikeCount}
      </Button>
    </Box>
  );
};

export default LikeDislikeAnswer;
