import React, { useContext, useMemo } from "react";
import { AiFillSetting, AiOutlineBell } from "react-icons/ai";
import { BsLaptop } from "react-icons/bs";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { Cookies } from "react-cookie";
import Badge from "@mui/material/Badge";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Profiler } from "react";
import { FaSign } from "react-icons/fa";
import { FiUser } from "react-icons/fi";

function UseLoggedInMenu() {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { alertCount } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const { username } = useParams();

  const LOGGEDIN_MENU = useMemo(
    () => [
      {
        title: t("RiskProfile"),
        icon: <FiUser />,
        link: "/risk-profile",
        type: "riskprofile",
      },
      {
        title: t("Appointment"),
        icon: <BsFillPersonPlusFill />,
        link: "/appointment",
        type: "appointment",
      },

      {
        title: t("Notifications"),
        icon: (
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                padding: "0 4px",
                backgroundColor: "#dc3545",
              },
            }}
            badgeContent={alertCount}
          >
            <AiOutlineBell />
          </Badge>
        ),
        link: "/alert",
        type: "notification",
      },
    ],
    [
      alertCount,
      user?.username,
      user?.userpic_url,
      i18n?.language,
      user?.isExpert,
    ]
  );
  return React.useMemo(() => {
    return {
      LOGGEDIN_MENU,
    };
  }, [LOGGEDIN_MENU, i18n?.language]);
}

export default UseLoggedInMenu;
