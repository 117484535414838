import { React, useState, useContext } from "react";
import UserDetails from "../../Components/user-details";
import UserDetailsPostsTabs from "../../Components/user-details/user-details-posts-tabs";
import { ThemeContext } from "../../context/ThemeContext";
import { Cookies } from "react-cookie";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import Suggestion from "../../Components/common/components/suggestion";

function UserProfilePage() {
  const [search, setSearch] = useState("");
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { theme, showAlertBox } = useContext(ThemeContext);
  const [activeFollow, setActiveFollow] = useState(false);
  const [activeFollowing, setActiveFollowing] = useState(false);
  const { width } = useViewPort();

  const handleActiveFollow = () => {
    setActiveFollow(() => !activeFollow);
  };

  const handleActiveFollowing = () => {
    setActiveFollowing(() => !activeFollowing);
  };

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <div>
              <br />
              <UserDetails
                handleActiveFollow={handleActiveFollow}
                handleActiveFollowing={handleActiveFollowing}
              />
              <br />
              <UserDetailsPostsTabs input={search} />
              <br></br>
            </div>
          }
        />
      ) : (
        <LayoutDefault
          mainContent={
            <div>
              <UserDetails
                handleActiveFollow={handleActiveFollow}
                handleActiveFollowing={handleActiveFollowing}
              />
              <br />
              <UserDetailsPostsTabs input={search} />
              <br></br>
            </div>
          }
          rightPanelContent={
            <div>
              {/* <InvestmentAdd /> */}
              {/* {user ? <ActivityRight /> : null} */}
              {user ? <Suggestion /> : ""}

              <br></br>
            </div>
          }
        />
      )}
    </Box>
  );
}

export default UserProfilePage;
