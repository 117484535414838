import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StocksDetailsTab from "../stock-details";
import LineChart from "../line-chart";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import LayoutDefaultNew from "../layout/page/default/ForPopup";
import { Divider, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WatchlistMobileDefault from "../layout/mobile/Watchlish-mobile";
import { useTheme } from "@emotion/react";

export default function StocksDetailsPopUp({ stocks }) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("1900px");
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { width } = useViewPort();
  return (
    <React.Fragment>
      <Button sx={{ fontSize: 11 }} onClick={handleClickOpen}>
        {stocks}
      </Button>
      <Dialog
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {stocks && (
            <Typography letterSpacing={0.4} fontSize={16} fontWeight={600}>
              {stocks} Stocks Details
            </Typography>
          )}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              m: "auto",
              width: "fit-content",
            }}
          >
            {width < BREAKPOINT ? (
              <WatchlistMobileDefault
                middleContent={
                  <div>
                    <LineChart symbol={stocks} />
                    <br />
                    <StocksDetailsTab symbol={stocks} />
                  </div>
                }
              />
            ) : (
              <LayoutDefaultNew
                mainContent={
                  <div>
                    <LineChart symbol={stocks} />
                    <br />
                    <StocksDetailsTab symbol={stocks} />
                    <br />
                  </div>
                }
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
