import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

export default function OverDropdown({over, handleChange}) {

  return (
    <FormControl sx={{ minWidth: 150 }} size="small">
      <Select
        labelId="demo-select-small"
        input={<OutlinedInput />}
        id="demo-select-small"
        value={over}
        onChange={handleChange}
        sx={{fontSize:14}}
      >
        <MenuItem value={"Over"}>Over</MenuItem>
        <MenuItem value={"Under"}>Under</MenuItem>
      </Select>
    </FormControl>
  );
}
