import React, { useContext, useEffect, useState } from "react";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import UserPosts from "../../user-posts-tabs/components/user-posts";

const SavedPost = () => {
  const { savedPosts } = useContext(StockNewsContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(savedPosts);
  }, [savedPosts]);

  return (
    <div>
      {data?.length === 0 && (
        <h3 style={{ textAlign: "center", marginTop: "35%" }}>
          No posts found
        </h3>
      )}
      <UserPosts data={data} />
    </div>
  );
};

export default SavedPost;
