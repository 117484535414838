import React, { useContext, useEffect, useState } from "react";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import AddStocksPopup from "./components/add-stocks-popup";
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Cookies } from "react-cookie";
import { doRequest, getAuthenticatedRequest } from "../../services/request";
import DeleteIcon from "@mui/icons-material/Delete";
import { readableImage } from "../common-functions/FormatDate";
import StocksDetailsPopUp from "../NewStocks";
import { Link, useLocation, useNavigate } from "react-router-dom";

const WatchlistStocks = ({ data }) => {
  const { setOpenConfirmationBox } = useContext(StockNewsContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [watchlistdata, setWatchlistdata] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDeleteStockConfirmation = (e, stock) => {
    e.preventDefault();
    e.stopPropagation();
    let update = {
      status: true,
      text: "Do you want to remove this stock from watchlist?",
      fnc: handleDeleteStock,
      id: stock,
    };
    setOpenConfirmationBox(update);
  };

  const handleDeleteStock = (e, stock) => {
    let payload = { SYMBOLS: e.SYMBOLS };
    doRequest({
      url: `/stocks/delete-watchlist/${user?.username}`,
      method: "POST",
      body: JSON.stringify(payload),
    }).then((res) => fetchWatchlistStocks());
  };

  const fetchWatchlistStocks = () => {
    getAuthenticatedRequest({
      url: `/stocks/get-watchlist/${user?.username}`,
      method: "GET",
    })
      .then((res) => {
        setWatchlistdata(res.data?.wishlists?.stocks);
      })
      .catch((e) => {
        console.log("err", e.message);
      });
  };
  useEffect(() => {
    fetchWatchlistStocks();
  }, []);

  return (
    <div className="watchlist">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography letterSpacing={0.4} sx={{ fontSize: 18 }}></Typography>
        <Box>
          <AddStocksPopup fetchWatchlistStocks={fetchWatchlistStocks} />
        </Box>
      </Stack>
      {!user ? (
        <Box
          sx={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
          <button
            style={{
              textDecoration: "none",
              border: 0,
              background: "transparent",
            }}
            onClick={() =>
              navigate("/welcome", { state: { from: location.pathname } })
            }
          >
            <img
              src="https://i.ibb.co/vQ1gMFd/360-F-339709166-k-KKqi-QFyn-WG7b-Ekl3-Lis-H3sa-Rr-EB0-HGa-removebg-preview.png"
              alt="Not Logged In"
              style={{ maxWidth: "100%", height: "auto" }}
            />

            <Typography fontWeight={600}> Go To Login</Typography>
          </button>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Stock
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Sector
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Spotted Date
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Spotted Price
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Chart
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Target Price
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Current Price
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {watchlistdata?.map((stock, idx) => (
                <TableRow>
                  <TableCell>
                    <StocksDetailsPopUp stocks={stock.SYMBOLS} />
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: 11 }}>
                      {stock.Sector}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: 11 }}>
                      {stock.spottedDate}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: 11 }}>
                      {stock.spottedPrice}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {stock.longName && stock.SYMBOLS && (
                      <img
                        src={readableImage(stock.graph?.data)}
                        alt="graph"
                        style={{ width: "50px", height: "40px" }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: 11 }}>
                      {stock.targetPrice}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {stock.longName && stock.SYMBOLS && (
                      <Typography letterSpacing={0.4} sx={{ fontSize: 11 }}>
                        {"\u20B9"} {stock.live_stock_data?.LAST}
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell>
                    {/* {user?.username === username && ( */}
                    <Button
                      variant="text"
                      sx={{
                        color: "#5D20D2",
                        fontSize: "20px",
                        border: "none",
                        backgroundColor: "white",
                        height: "15px",
                        width: 20,
                      }}
                      onClick={(e) => handleDeleteStockConfirmation(e, stock)}
                    >
                      <DeleteIcon style={{ fontSize: 16 }} />
                    </Button>
                    {/* )} */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default WatchlistStocks;
