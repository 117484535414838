import React, { useContext, useState, memo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Newlogo from "../../../../assets/askfunduLogo.png";
import { Cookies } from "react-cookie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import UseMenu from "./hooks/useMenu";
import UseLoggedInMenu from "./hooks/useLoggedInMenu";
import FuIconButton from "../../../shared/FuIconButton";
import { ThemeContext } from "../../../../context/ThemeContext";
import { Avatar, Typography } from "@mui/material";
import { googleLogout } from "@react-oauth/google";
import { CgProfile } from "react-icons/cg";
import { FaBlog } from "react-icons/fa";
import { HiLanguage } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import SelectLanguage from "./components/SelectLanguage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { signOut } from "firebase/auth";
import { auth } from "../../../../Firebase";

import UseMore from "./hooks/useMore";
import { useDispatch } from "react-redux";
import { clearUser } from "../../../../store/userSlice";
const drawerWidth = 160;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [languageModalopen, setLanguageModalOpen] = React.useState(false);
  const handleLanguageModalOpen = () => setLanguageModalOpen(true);
  const handleLanguageModalClose = () => setLanguageModalOpen(false);
  const { MENU } = UseMenu();
  const { LOGGEDIN_MENU } = UseLoggedInMenu();
  const { USE_MORE } = UseMore();
  const { setUnread } = useContext(ThemeContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  const { username } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const handleMoreOptionsClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSignout = async () => {
    await signOut(auth);
    sessionStorage.removeItem("token");
    googleLogout();
    cookie.remove("user");
    navigate("/");
    dispatch(clearUser());
    path === "/" && navigate(0);
  };
  const markAsRead = (link) => {
    navigate(link);
    setUnread(0);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isCurrentUserAndExpert = () => {
    if (user && user?.username && user?.isExpert) {
      if (user.username === username && user.isExpert) {
        return true;
      }
    }

    return false;
  };

  let profileUrl = `/user/${user?.username}`;
  if (isCurrentUserAndExpert()) {
    profileUrl = `/rooms/${user?.username}`;
  }

  const drawer = (
    <>
      {/* <Toolbar /> */}
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          zIndex: "00000",
          justifyContent: "space-between",
          direction: "column",

          scrollbarWidth: "none", // Hide scrollbar in Firefox
          msOverflowStyle: "none", // Hide scrollbar in Internet Explorer
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <List
          sx={{
            padding: 0,
          }}
        >
          <ListItem disablePadding>
            <ListItemButton
              style={{
                justifyContent: "center",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Typography letterSpacing={0.4} variant="h1">
                <img
                  style={{
                    width: "100%",
                  }}
                  src={Newlogo}
                  alt="logo"
                />
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider color="black" />
        <List mt={2}>
          {MENU.map((menu, index) => (
            <ListItem
              disablePadding
              onClick={() => {
                navigate(menu.link);
              }}
              key={`sidebar-menu-${index}`}
            >
              <ListItemButton
                sx={{
                  mt: -1.3,

                  ":hover": {
                    color: "#680ef7",
                  },
                }}
              >
                <ListItemIcon>
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: "22px",
                      color:
                        path === menu.link ? "var(--fundu_color)" : "#313342",
                    }}
                  >
                    {menu.icon}
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ml: "-10px",
                  }}
                  primary={
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontWeight: path === menu.link ? 600 : 500,
                        fontSize: 14,
                        color:
                          path === menu.link ? "var(--fundu_color)" : "#313342",
                      }}
                    >
                      {menu.title}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {user && (
          <List sx={{ mt: "-16px" }}>
            {LOGGEDIN_MENU.map((menu, index) => (
              <ListItem disablePadding key={`loggedin-menu-${index}`}>
                <ListItemButton
                  sx={{
                    mt: -1.3,
                    ":hover": {
                      color: "var(--fundu_color)",
                    },
                  }}
                  onClick={
                    menu.type === "signout"
                      ? handleSignout
                      : menu.title === "Alert"
                      ? () => markAsRead(menu.link)
                      : () => navigate(menu.link)
                  }
                >
                  <ListItemIcon>
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "22px",
                        color:
                          path === menu.link ? "var(--fundu_color)" : "#313342",
                      }}
                    >
                      {menu.icon}
                    </Typography>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ml: "-10px",
                      color:
                        path === menu.link ? "var(--fundu_color)" : "#313342",
                    }}
                    primary={
                      <Typography
                        letterSpacing={0.4}
                        sx={{
                          fontWeight: path === menu.link ? 600 : 500,
                          fontSize: 14,
                          color:
                            path === menu.link
                              ? "var(--fundu_color)"
                              : "#313342",
                        }}
                      >
                        {menu.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}

        <List sx={{ mt: "-22px" }}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={
                languageModalopen
                  ? handleLanguageModalClose
                  : handleLanguageModalOpen
              }
            >
              <ListItemIcon sx={{ fontSize: "24px", color: "#313342" }}>
                <HiLanguage />
              </ListItemIcon>
              <ListItemText
                sx={{ ml: "-10px", textTransform: "none" }}
                primary={
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {t("Language")}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>

        {user?.isExpert && (
          <List sx={{ mt: "-22px" }}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/service");
                }}
              >
                <ListItemIcon sx={{ fontSize: "24px", color: "#313342" }}>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{ ml: "-10px", textTransform: "none" }}
                  primary={
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {t("Service")}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}

        {user && (
          <List sx={{ mt: "-12px" }}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  mt: -1.3,
                  ":hover": {
                    color: "var(--fundu_color)",
                  },
                }}
                onClick={handleMoreOptionsClick}
              >
                <ListItemIcon>
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: 20,
                    }}
                  >
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ml: "-10px",
                  }}
                  primary={
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {isExpanded ? t("Less") : t("More")}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}

        {/* {isExpanded && ( */}

        {/* )} */}

        {isExpanded && (
          <List sx={{ mt: "-12px" }}>
            <a
              href="https://blog.askfundu.com/en"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    mt: -1.3,
                    ":hover": {
                      color: "var(--fundu_color)",
                    },
                  }}
                >
                  <ListItemIcon>
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: 20,
                      }}
                    >
                      <FaBlog />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ml: "-10px",
                    }}
                    primary={
                      <Typography
                        letterSpacing={0.4}
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        {t("Blog")}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </a>
          </List>
        )}
        {isExpanded && (
          <List sx={{ mt: "-16px" }}>
            {USE_MORE.map((menu, index) => (
              <ListItem disablePadding key={`more-menu-${index}`}>
                <ListItemButton
                  sx={{
                    mt: -1.3,
                    ":hover": {
                      color: "var(--fundu_color)",
                    },
                  }}
                  onClick={
                    menu.type === "signout"
                      ? handleSignout
                      : menu.title === "Alert"
                      ? () => markAsRead(menu.link)
                      : () => navigate(menu.link)
                  }
                >
                  <ListItemIcon>
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "22px",
                        color:
                          path === menu.link ? "var(--fundu_color)" : "#313342",
                      }}
                    >
                      {menu.icon}
                    </Typography>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ml: "-10px",
                      color:
                        path === menu.link ? "var(--fundu_color)" : "#313342",
                    }}
                    primary={
                      <Typography
                        letterSpacing={0.4}
                        sx={{
                          fontWeight: path === menu.link ? 600 : 500,
                          fontSize: 14,
                          color:
                            path === menu.link
                              ? "var(--fundu_color)"
                              : "#313342",
                        }}
                      >
                        {menu.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}

        {!user && (
          <List
            sx={{ mt: "-15px" }}
            onClick={() => {
              navigate("/welcome");
            }}
          >
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  ":hover": {
                    color: "var(--fundu_color)",
                  },
                }}
              >
                <ListItemIcon>
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: 20,
                    }}
                  >
                    <CgProfile />
                  </Typography>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ml: "-10px",
                  }}
                  primary={
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {t("SignIn")}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}
        <SelectLanguage
          languageModalopen={languageModalopen}
          handleLanguageModalOpen={handleLanguageModalOpen}
          handleLanguageModalClose={handleLanguageModalClose}
        />
      </Box>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <Toolbar>
        <FuIconButton onClick={handleDrawerToggle}>
          <MenuIcon style={{ marginTop: "-12px" }} />
        </FuIconButton>
      </Toolbar>
      <Box>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            zIndex: mobileOpen ? "1000" : "inherit", // Apply zIndex for mobileOpen
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowY: "scroll",
            },
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            msOverflowStyle: "none", // Hide scrollbar in Internet Explorer
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default memo(ResponsiveDrawer);
