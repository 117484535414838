import React, { useContext, useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { v4 as uuidv4 } from "uuid";
import { backend_url } from "../../../../http-common";
import { Cookies } from "react-cookie";
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { ThemeContext } from "../../../../context/ThemeContext";

const PostPoll = ({
  poll_question,
  poll_options_text,
  postId,
  poll_options_count,
}) => {
  const [value, setValue] = useState("");
  const [data, setData] = useState(null);
  const { userPosts, setUserPosts } = useContext(ThemeContext);

  const cookie = new Cookies();
  const user = cookie.get("user");

  useEffect(() => {
    getPostPoll();
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    handleCreatePoll(event.target.value);
  };

  const updatePollCount = (pollCountArr) => {
    let postD = userPosts?.filter((item) => item.unique_id === postId)[0];

    let obj = {
      ...postD,
      poll_options_count: pollCountArr,
    };

    let newPostsArray = userPosts.map((item) => {
      if (item.unique_id === postId) {
        return { ...item, poll_options_count: pollCountArr };
      } else {
        return item;
      }
    });

    setUserPosts(newPostsArray);

    const payloadjson = JSON.stringify(obj);
    fetch(`${backend_url}/edit-post/${postId}`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {})
      .catch((err) => console.log(err));
  };

  const handleCreatePoll = (val) => {
    const form = {
      unique_id: uuidv4(),
      poll_post_id: postId,
      poll_user_id: user?.username,
      option_number: poll_options_text.indexOf(val) + 1,
      created_at: Date.now(),
    };

    poll_options_count[poll_options_text.indexOf(val)] += 1;

    updatePollCount(poll_options_count);

    const payloadjson = JSON.stringify(form);

    fetch(`${backend_url}/create-post-poll`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        getPostPoll();
      })
      .catch((err) => console.log(err));
  };

  const getPostPoll = () => {
    fetch(
      `${backend_url}/get-post-poll?postId=${postId}&userId=${user?.username}`
    )
      .then((res) => res.json())
      .then((d) => {
        if (d.response !== null) {
          setData(d.response);
        }
      })
      .catch((err) => console.log(err));
  };

  const totalVotes = poll_options_count.reduce((a, b) => a + b, 0);

  const removeVote = () => {
    poll_options_count[data?.option_number - 1] -= 1;

    updatePollCount(poll_options_count);

    fetch(`${backend_url}/delete-post-poll/${data?.unique_id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((response) => {
        setData(null);
        setValue("");
        getPostPoll();
      })
      .catch((err) => console.log(err));
  };

  return (
    <FormControl
      sx={{
        backgroundColor: "#F7F6F9",
        p: 2,
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <FormLabel id="demo-controlled-radio-buttons-group">
        {poll_question}
      </FormLabel>
      {data === null ||
      data?.poll_user_id !== user?.username ||
      data?.poll_post_id !== postId ? (
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {poll_options_text.map((item, index) => (
            <FormControlLabel
              value={item}
              control={<Radio />}
              label={item}
              disabled={!user || value !== "" ? true : false}
            />
          ))}
        </RadioGroup>
      ) : (
        <div>
          {poll_options_text.map((item, index) => (
            <ListItem
              key={index}
              disableGutters
              secondaryAction={
                <Typography
                  letterSpacing={0.4}
                  variant="text"
                  sx={{ fontSize: 14 }}
                >
                  {poll_options_count[index]}
                </Typography>
              }
              sx={{ width: "100%", borderRadius: "8px" }}
            >
              <ListItemButton
                selected={index + 1 === data?.option_number}
                disableRipple
                disableTouchRipple
                sx={{ borderRadius: "8px", height: 30 }}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
          {totalVotes > 0 && (
            <>
              <Typography letterSpacing={0.4} variant="text">
                {totalVotes === 1
                  ? `${totalVotes} vote`
                  : `${totalVotes} votes`}
              </Typography>
              <Button
                variant="text"
                onClick={removeVote}
                sx={{
                  textTransform: "none",
                }}
              >
                Remove vote
              </Button>
            </>
          )}
        </div>
      )}
    </FormControl>
  );
};

export default PostPoll;
