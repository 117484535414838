import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import postReducer from "./postSlice";
import roomSlice from "./roomSlice";
// import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import userSlice from "./userSlice";
import cometchatMessageReducer from "./cometchatMessageSlice";

// const persistConfig = {
//   key: "root",
//   storage,
// };

const rootReducer = combineReducers({
  posts: postReducer,
  rooms: roomSlice,
  user: userSlice,
  cometchatMessages: cometchatMessageReducer,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

// export const persistor = persistStore(store);
