import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { BiUpvote } from "react-icons/bi";
import { BiDownvote } from "react-icons/bi";
import { Cookies } from "react-cookie";
import { backend_url } from "../../../../http-common";
import PropTypes from "prop-types";
import useSessionToken from "../../../../hooks/useSessionToken";
import { useDispatch, useSelector } from "react-redux";
import {
  pushUserLikedPost,
  pushUserDislikedPost,
  popUserLikedPost,
  popUserDislikedPost,
} from "../../../../store/userSlice";

const UpvoteDownvoteButtons = ({
  data,
  postId,
  post_id,
  username,
  upvote,
  downvote,
}) => {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [tempUpvoteCount, setTempUpvoteCount] = useState(upvote);
  const [tempDownvoteCount, setTempDownvoteCount] = useState(downvote);
  const { token } = useSessionToken();
  const currentLoggedInUser = useSelector((store) => store.user.user);
  const dispatch = useDispatch();

  const createUpVote = (id) => {
    console.log("post id is -> ", post_id, data[0]._id);
    let payload = JSON.stringify({ post_id: post_id });
    fetch(`${backend_url}/posts/upvote`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then((res) => {
        // whoVotedArr();
        tempUpvoteDownvoteController("upvote");
        return res.json();
      })
      .then((res) => console.log(res));
    // .catch((e) => console.log("err", e.message));
  };

  const tempUpvoteDownvoteController = (type) => {
    // top level controller
    if (type === "upvote") {
      if (!checkIfupdated("upvote")) {
        setTempUpvoteCount((prev) => (prev ? prev + 1 : 1));
        dispatch(pushUserLikedPost(data[0]._id));
        // this check is for removing if id is in disliked_post
        if (checkIfupdated("downvote")) {
          dispatch(popUserDislikedPost(data[0]._id));
          setTempDownvoteCount((prev) => (prev ? prev - 1 : 1));
        }
      } else {
        dispatch(popUserLikedPost(data[0]._id));
        setTempUpvoteCount((prev) => (prev ? prev - 1 : 1));
      }
    } else {
      if (!checkIfupdated("downvote")) {
        setTempDownvoteCount((prev) => (prev ? prev + 1 : 1));
        dispatch(pushUserDislikedPost(data[0]._id));
        // this check is for removing if id is in disliked_post
        if (checkIfupdated("upvote")) {
          dispatch(popUserLikedPost(data[0]._id));
          setTempUpvoteCount((prev) => (prev ? prev - 1 : 1));
        }
      } else {
        dispatch(popUserDislikedPost(data[0]._id));
        setTempDownvoteCount((prev) => (prev ? prev - 1 : 1));
      }
    }
  };

  const createDownVote = (id) => {
    let payload = JSON.stringify({ post_id: post_id });
    fetch(`${backend_url}/posts/downvote`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then((res) => {
        // whoVotedArr();
        tempUpvoteDownvoteController("downvote");
        return res.json();
      })
      .then((res) => console.log(res));
    // .catch((e) => console.log(e.message));
  };

  const handleUpvoteDownvote = async (post_id, type) => {
    if (type === "upvote") {
      try {
        createUpVote(post_id);
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      try {
        createDownVote(post_id);
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const checkIfupdated = (type) => {
    if (!currentLoggedInUser) return false;

    if (type === "upvote") {
      if (!currentLoggedInUser?.liked_posts) return false;
      if (currentLoggedInUser.liked_posts.includes(data[0]._id.toString()))
        return true;
    } else {
      if (!currentLoggedInUser?.disliked_posts) return false;
      if (currentLoggedInUser.disliked_posts.includes(data[0]._id.toString()))
        return true;
    }

    return false;
  };

  return (
    <>
      <Button
        disableRipple
        onClick={(e) => {
          if (user) {
            handleUpvoteDownvote(postId, "upvote");
          } else {
            window.location.href = "/welcome";
          }
        }}
        className={checkIfupdated("upvote") ? "#5d20d2" : "#3a4044"}
      >
        <BiUpvote
          style={{
            fontSize: "19px",
            marginTop: "-6px",
            color: checkIfupdated("upvote") ? "#5d20d2" : "#3a4044",
          }}
        />
        <Typography
          sx={{
            color: checkIfupdated("upvote") ? "#5d20d2" : "#3a4044",
          }}
        >
          {tempUpvoteCount || 0}
        </Typography>
      </Button>

      <Button
        disableRipple
        onClick={(e) => {
          if (user) {
            handleUpvoteDownvote(postId, "downvote");
          } else {
            window.location.href = "/welcome";
          }
        }}
        className={checkIfupdated("donwvote") ? "#5d20d2" : "#3a4044"}
      >
        <BiDownvote
          style={{
            fontSize: "19px",
            marginLeft: "-7px",
            marginTop: "-4px",
            color: checkIfupdated("donwvote") ? "#5d20d2" : "#3a4044",
          }}
        />
        <Typography
          sx={{
            color: checkIfupdated("donwvote") ? "#5d20d2" : "#3a4044",
          }}
        >
          {" "}
          {tempDownvoteCount || 0}
        </Typography>
      </Button>
    </>
  );
};

UpvoteDownvoteButtons.propTypes = {
  data: PropTypes.array,
  postId: PropTypes.string,
};

export default UpvoteDownvoteButtons;
