import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Cookies } from "react-cookie";
import LoginBanner from "../../../login-banner";
import SearchBar from "../../../common/components/search-bar";
import TopSlider from "../../../common/components/top-slider";
import MySidebar from "../../../common/components/my-sidebar";

const LayoutMerge = ({ mainContent, handleScroll }) => {
  const drawerWidth = 160;
  const cookie = new Cookies();
  const user = cookie.get("user");
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box style={{ display: "flex", height: "100vh" }}>
      <Box
        sx={{
          width: isMobile ? "" : 160,
        }}
      >
        <MySidebar />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          pt: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
          }}
        >
          <SearchBar /> <TopSlider />
        </Box>
        <Box
          className="mainBodyContent"
          onScroll={handleScroll}
          justifyContent="center"
        >
          <Box
            sx={{
              mt: 1,

              minWidth: "calc((100% - ${drawerWidth}px)*0.12)",
              maxWidth: "calc((100% - ${drawerWidth}px)*0.64)",
            }}
          >
            {mainContent}
          </Box>
        </Box>
      </Box>

      {!user && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: "100000",
          }}
        >
          <LoginBanner />
        </Box>
      )}
    </Box>
  );
};

export default LayoutMerge;
