import { React, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import AddTags from "../../../create-post/components/add-tags";
import { ThemeContext } from "../../../../context/ThemeContext";
import { capitalFirstLetter } from "../../../common-functions/FormatDate";
import { v4 as uuidv4 } from "uuid";
import { backend_url } from "../../../../http-common";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";
import ImgPlaceholder from "../../../../assets/image_placeholder.png";
import { Cookies } from "react-cookie";
import { Avatar, Box, Chip, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useFormikContext } from "formik";
import { uploadImage, validateImg } from "../../../common-functions/Common";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {} from "react-icons/ai";
import {} from "react-icons/fi";
import LoadingButton from "@mui/lab/LoadingButton";
import LinkPreviewCard from "./LinkPreviewCard";
import { getRequest } from "../../../../services/request";
import ReactPlayer from "react-player";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";

const CreateEditPostPopup = ({
  from,
  open,
  handleClose,
  setOpen,
  post_data,
  close,
}) => {
  const { setShowAlertBox, setUserPosts, getPosts, userPosts } =
    useContext(ThemeContext);
  const { setOpenConfirmationBox } = useContext(StockNewsContext);
  const [createLoading, setCreateLoading] = useState(false);
  const [linkPreviewData, setLinkPreviewData] = useState({});
  const [previousUrl, setPreviousUrl] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [inputValue, setInputValue] = useState("");
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [suggestedStocks, setSuggestedStocks] = useState([]);
  const [showUsersBox, setShowUsersBox] = useState(false);
  const [showStockBox, setShowStockBox] = useState(false);
  const [taggedStock, setTaggedStock] = useState([]);
  const [taggedUser, setTaggedUser] = useState([]);
  const { values, setFieldValue, resetForm, errors } = useFormikContext();
  const { t } = useTranslation();

  const handleCut = () => {
    if (
      values?.title !== "" ||
      values?.description !== "" ||
      values?.image !== null ||
      values?.tags.length !== 0
    ) {
      let update = {
        status: true,
        text: "Do you want to discard this post?",
        fnc: handleClose,
        id: false,
      };
      setOpenConfirmationBox(update);
    } else {
      setOpen(false);
    }
  };

  const handleCreatePost = async () => {
    let url;
    if (values?.image) {
      url = await uploadImage(values?.image);
    }

    const userD = {
      First_Name: `${user?.First_Name}`,
      Followers: `${user?.Followers}`,
      Followings: `${user?.Followings}`,
      last_name: `${user?.last_name}`,
      username: `${user?.username}`,
      userpic_url: `${user?.userpic_url}`,
      id: `${user?.id}`,
    };

    const form = {
      unique_id: uuidv4(),
      user: `${user?.username}`,
      Title: values?.title,
      Text: values?.description,
      Image: url,
      Tag: values?.tags,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
      post_type: 1,
      Created_at: Date.now(),
      newsDetails: linkPreviewData,
      taggedStocks: taggedStock,
      taggedUsers: taggedUser,
    };

    const newObj = {
      unique_id: uuidv4(),
      user: `${user?.username}`,
      Title: values?.title,
      Text: values?.description,
      Image: url,
      Tag: values?.tags,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
      post_type: 1,
      Created_at: Date.now(),
      userDetails: userD,
    };

    setUserPosts((d) => [newObj, ...d]);

    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/create-post`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const newStatus = {
          status: response.status,
          from: "Post",
          as: "Created",
        };
        getPosts();
        setCreateLoading(false);
        setOpen(false);
        resetForm();

        setShowAlertBox(() => newStatus);
      })
      .catch((err) => console.log(err));

    setLinkPreviewData({});
    setPreviousUrl("");
    setTaggedUser([]);
    setTaggedStock([]);
  };

  const handleCreate = () => {
    if (values?.description !== "") {
      setCreateLoading(true);
      // setOpen(false);
      handleCreatePost();
      // resetForm();
    }
  };

  const handleDeleteImage = () => {
    setFieldValue("imagePreview", null);
  };

  const handleDelete = (tag) => {
    const index = values?.tags?.indexOf(tag);
    values?.tags?.splice(index, 1);
    setFieldValue("tags", [...values?.tags]);
  };

  const getLinkPreviewData = (value) => {
    // console.log(previousUrl, value);
    if (value === "") {
      setLinkPreviewData("");
      return;
    }
    if (previousUrl === value) {
      return;
    }
    setPreviousUrl(value);
    getRequest({ url: `/users/get-preview?url=${value}` }).then((res) => {
      setLinkPreviewData(res.data.response);
    });
  };

  // for checking if the url is of youtube
  const isYoutubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  const handleFieldChange = (target) => {
    if (
      values?.description?.split(" ")[
        values?.description
          ?.split(" ")
          .findIndex((item) => item.includes("https://"))
      ]
    ) {
      getLinkPreviewData(
        values?.description?.split(" ")[
          values?.description
            ?.split(" ")
            .findIndex((item) => item.includes("https://"))
        ]
      );
    }
    setFieldValue(target?.name, target?.value);
    setInputValue(target?.value);
  };

  const handleEditPostConfirmation = (id) => {
    let update = {
      status: true,
      text: "Do you want to edit this post?",
      fnc: handleEdit,
      id: id,
      from: "",
      type: 1,
    };
    setOpenConfirmationBox(update);
  };

  const handleEdit = () => {
    setCreateLoading(true);
    // setOpen(false);
    close();
    let postsArray = userPosts?.map((item) => {
      if (item.unique_id === post_data?.unique_id) {
        return {
          ...item,
          Title: values?.title,
          Text: values?.description,
          Image: values?.imagePreview,
          Tag: values?.tags,
          Updated_at: new Date(),
        };
      } else {
        return item;
      }
    });

    setUserPosts(postsArray);
    handleEditPost();
  };

  const handleEditPost = async () => {
    let url;
    if (values?.image) {
      url = await uploadImage(values?.image);
    }

    const form = {
      ...post_data,
      Title: values?.title,
      Text: values?.description,
      Image: url,
      Tag: values?.tags,
      Updated_at: Date.now(),
    };

    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/edit-post/${post_data?.unique_id}`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        let newStatus = {
          status: response.status,
          from: "Post",
          as: "Edited",
        };
        getPosts();
        setCreateLoading(false);
        setOpen(false);

        setShowAlertBox(() => newStatus);
      })
      .catch((err) => console.log(err));
    resetForm();
  };

  useEffect(() => {
    let trimmedVal = inputValue.split(" ");
    trimmedVal = trimmedVal[trimmedVal.length - 1];
    if (trimmedVal.includes("@")) {
      setShowUsersBox(true);
      const username = trimmedVal.split("@")[1];
      if (username) {
        const getSearchedUsers = async () => {
          const response = await getRequest({
            url: `/get-searched-users?search=${username}`,
          });
          setSuggestedUsers(response.data?.searchedUsers);
        };
        getSearchedUsers();
      }
    } else if (trimmedVal.includes("$")) {
      setShowStockBox(true);
      const stock = trimmedVal.split("$")[1];

      if (stock) {
        const getSearchedStocks = async () => {
          const response = await getRequest({
            url: `/get-searched-stocks?search=${stock}`,
          });
          setSuggestedStocks(response.data?.stocks);
        };
        getSearchedStocks();
      }
    } else {
      setSuggestedUsers([]);
      setSuggestedStocks([]);
      setShowUsersBox(false);
      setShowStockBox(false);
    }
  }, [inputValue]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <Box>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                letterSpacing={0.4}
                sx={{
                  fontSize: "18px",
                  textAlign: "center",
                  color: "black",
                  fontWeight: 700,
                  ml: -0.5,
                }}
              >
                {from === "CREATE" ? "Share an idea" : "Edit post"}
              </Typography>

              <CloseIcon
                onClick={from === "CREATE" ? handleCut : handleClose}
                sx={{
                  color: "black",
                }}
              />
            </Box>
          </DialogTitle>
          <Divider color="black" />

          <Box
            sx={{
              display: "flex",
              pl: 1.2,
              ml: 1,
              mt: 2,
            }}
          >
            <Avatar
              alt="profile"
              style={{
                border: "0.1px solid white",
              }}
              src={user?.userpic_url || ImgPlaceholder}
              sx={{ width: 48, height: 48 }}
            />
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: "#372B47",
                ml: 1.2,
                mt: 1,
              }}
            >
              {`${capitalFirstLetter(user?.First_Name)} ${capitalFirstLetter(
                user?.last_name
              )}`}
            </Typography>
            {/* <Typography
              letterSpacing={0.4}
              sx={{
                color: "#6b7280",
                fontWeight: 500,
                fontSize: "12px",
                mt: 1.3,
                ml: 0.8,
              }}
            >
              {`@${user?.username}`}
            </Typography> */}
          </Box>

          <DialogContent
            component="form"
            sx={{
              "& .MuiTextField-root": {
                width: 550,
                maxWidth: "100%",
                pr: 2,
                pb: 2,
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: {
                  fontSize: 13,
                },
              }}
              type="text"
              placeholder="Title (Optional)"
              className="title_input"
              onChange={(e) => handleFieldChange(e.target)}
              value={values?.title}
              name="title"
              style={{
                backgroundColor: theme ? "white" : "#313342",
                color: !theme ? "white" : "#313342",
                marginBottom: "12px",
              }}
            />

            <br />
            <TextField
              variant="standard"
              id="outlined-multiline-flexible"
              multiline
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: {
                  fontSize: 13,
                },
              }}
              type="text"
              placeholder="What's on your mind (Required)"
              value={values?.description}
              onChange={(e) => handleFieldChange(e.target)}
              name="description"
              error={errors.description ? true : false}
              helperText={values?.description === "" && errors.description}
            />
            {showUsersBox && suggestedUsers.length === 0 && (
              <Box
                sx={{
                  width: "200px",
                  display: "grid",
                  boxShadow: "0px 4px 4px #F7F6F9",
                  borderRadius: "8px",
                  p: 1,
                }}
              >
                <Box>
                  {[
                    {
                      First_Name: "Kunal Anand",
                      Image:
                        "https://lh3.googleusercontent.com/a/AGNmyxaT83O_LltKP2qnJBVHTDasI0d_oRL0fwgrsGE97g=s96-c",
                      username: "kunalanand",
                    },
                    {
                      First_Name: "Kesar chawala",
                      username: "kesC8c",
                      Image:
                        "http://res.cloudinary.com/djfgbivby/image/upload/v1676034464/g0namfhsu4zmdqh4bn0s.jpg",
                    },
                    {
                      First_Name: "Md.Khan",
                      Image:
                        "https://randomuser.me/api/portraits/med/women/89.jpg",

                      username: "md.A16",
                    },
                  ].map((user) => (
                    <Box
                      sx={{
                        backgroundColor: "#F7F6F9",
                        borderRadius: "8px",
                        p: 0.5,
                        mb: 0.5,
                      }}
                      onClick={() => {
                        let arr = values?.description.split(" ");
                        arr = arr.slice(0, arr.length - 1);
                        setFieldValue(
                          "description",
                          `${arr.join(" ")} @${user?.username}`
                        );
                        setTaggedUser([...taggedUser, user.username]);
                        setShowUsersBox(false);
                      }}
                      key={user.id}
                    >
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Avatar
                          sx={{
                            width: 26,
                            height: 26,
                            border: "1px solid #D8D1E3",
                          }}
                          src={user.Image}
                        />
                        <Box>
                          <Typography
                            letterSpacing={0.4}
                            sx={{ fontSize: 12, fontWeight: 600 }}
                          >
                            {user.First_Name}
                          </Typography>
                          <Typography
                            letterSpacing={0.4}
                            sx={{ fontSize: 12, mt: -0.5 }}
                          >
                            {" "}
                            {user.username}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {showUsersBox && suggestedUsers.length > 0 && (
              <Box
                sx={{
                  width: "200px",
                  display: "grid",
                  boxShadow: "0px 4px 4px #F7F6F9",
                  borderRadius: "8px",
                  p: 1,
                }}
              >
                <>
                  {suggestedUsers.slice(0, 3).map((user) => (
                    <Box
                      sx={{
                        backgroundColor: "#F7F6F9",
                        borderRadius: "8px",
                        p: 0.5,
                        mb: 0.5,
                      }}
                      onClick={() => {
                        let arr = values?.description.split(" ");
                        arr = arr.slice(0, arr.length - 1);
                        setFieldValue(
                          "description",
                          `${arr.join(" ")} @${user?.username}`
                        );
                        setTaggedUser(() =>
                          !taggedUser?.includes(user.username)
                            ? [...taggedUser, user.username]
                            : taggedUser
                        );
                        setShowUsersBox(false);
                      }}
                      key={user.id}
                    >
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Avatar
                          sx={{
                            width: 26,
                            height: 26,
                            border: "1px solid #D8D1E3",
                          }}
                          src={user.userpic_url || ImgPlaceholder}
                        />

                        <Box>
                          <Typography
                            letterSpacing={0.4}
                            sx={{ fontSize: 12, fontWeight: 600 }}
                          >
                            {" "}
                            {`${capitalFirstLetter(
                              user.First_Name
                            )} ${capitalFirstLetter(user.last_name)}`}
                          </Typography>
                          <Typography
                            letterSpacing={0.4}
                            sx={{ fontSize: 12, mt: -0.5 }}
                          >
                            {" "}
                            {user.username}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </>
              </Box>
            )}
            {showStockBox && suggestedStocks.length === 0 && (
              <Box
                sx={{
                  width: "150px",
                  display: "grid",
                  boxShadow: "0px 4px 4px #F7F6F9",
                  borderRadius: "8px",
                  p: 1,
                }}
              >
                {showStockBox && suggestedStocks.length === 0 && (
                  <>
                    {[
                      { SYMBOLS: "ICT" },
                      { SYMBOLS: "TCS" },
                      { SYMBOLS: "AARTIIND" },
                    ].map((stock) => (
                      <Box
                        sx={{
                          backgroundColor: "#F7F6F9",
                          borderRadius: "8px",
                          p: 0.5,
                          mb: 0.5,
                        }}
                        onClick={() => {
                          let arr = values?.description.split(" ");
                          // console.log("desc val", arr);
                          arr = arr.slice(0, arr.length - 1);
                          // console.log("desc val", arr);
                          setFieldValue(
                            "description",
                            `${arr.join(" ")} $${stock.SYMBOLS}`
                          );
                          setTaggedStock(() =>
                            !taggedStock.includes(stock.SYMBOLS)
                              ? [...taggedStock, stock.SYMBOLS]
                              : taggedStock
                          );
                          setShowStockBox(false);
                        }}
                        key={stock.id}
                      >
                        <Typography
                          letterSpacing={0.4}
                          sx={{ fontSize: 12, fontWeight: 600, pl: 2, mt: 0.4 }}
                        >
                          {stock.SYMBOLS}
                        </Typography>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            )}
            {showStockBox && suggestedStocks.length > 0 && (
              <Box
                sx={{
                  width: "150px",
                  display: "grid",
                  boxShadow: "0px 4px 4px #F7F6F9",
                  borderRadius: "8px",
                  p: 1,
                }}
              >
                {showStockBox && suggestedStocks.length > 0 && (
                  <>
                    {suggestedStocks.slice(0, 3).map((stock) => (
                      <Box
                        sx={{
                          backgroundColor: "#F7F6F9",
                          borderRadius: "8px",
                          p: 0.5,
                          mb: 0.5,
                        }}
                        onClick={() => {
                          let arr = values?.description.split(" ");
                          // console.log("desc val", arr);
                          arr = arr.slice(0, arr.length - 1);
                          // console.log("desc val", arr);
                          setFieldValue(
                            "description",
                            `${arr.join(" ")} $${stock.SYMBOLS}`
                          );
                          setTaggedStock(() =>
                            !taggedStock.includes(stock.SYMBOLS)
                              ? [...taggedStock, stock.SYMBOLS]
                              : taggedStock
                          );
                          setShowStockBox(false);
                        }}
                        key={stock.id}
                      >
                        <Typography
                          letterSpacing={0.4}
                          sx={{ fontSize: 12, fontWeight: 600, pl: 2, mt: 0.4 }}
                        >
                          {stock.SYMBOLS}
                        </Typography>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          {values?.imagePreview ? (
            <Box className="image_preview_div" sx={{ ml: 1.5 }}>
              <img
                src={values?.imagePreview || ""}
                className="image_preview"
                alt="image_preview"
              />
              <Button disableRipple onClick={handleDeleteImage}>
                <CloseIcon
                  sx={{
                    color: !theme ? "white" : "black",
                    fontSize: "18px",
                    mt: -14,
                    ml: -5,
                  }}
                />
              </Button>
            </Box>
          ) : (
            ""
          )}
          {values?.tags?.length > 0 && (
            <Box className="tagsDiv" sx={{ ml: 2.3, marginBottom: "10px" }}>
              {values?.tags?.map((tag, idx) => (
                <Chip
                  key={`tag-index-${idx}`}
                  sx={{ fontSize: "14px" }}
                  label={tag}
                  variant="outlined"
                  onDelete={() => handleDelete(tag)}
                />
              ))}
            </Box>
          )}
          {previousUrl ? (
            isYoutubeUrl(previousUrl) ? (
              <Stack alignItems="center">
                <ReactPlayer width="95%" controls={true} url={previousUrl} />
              </Stack>
            ) : (
              <LinkPreviewCard linkDetails={linkPreviewData} />
            )
          ) : null}
          <Box
            sx={{
              display: "flex",
              p: 2,
              ml: 0.5,
              bottom: 0,
              justifyContent: "space-between",
              ":hover": {
                color: "#5D20D2",
              },
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <label htmlFor="image-upload">
                <Chip
                  sx={{ fontSize: "14px" }}
                  icon={
                    <ImageRoundedIcon
                      style={{
                        color: "var(--fundu_color)",

                        fontSize: "20px",
                      }}
                    />
                  }
                  label="Image"
                  clickable
                />
              </label>

              <AddTags />
            </Box>

            <input
              type="file"
              id="image-upload"
              hidden
              accept="image/png, image/jpeg"
              onChange={(e) => validateImg(e, setFieldValue)}
            />

            <Box sx={{ right: 0, mr: 0 }}>
              <LoadingButton
                disableShrink
                loading={createLoading}
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "16px",
                  border: "none !important",
                  fontSize: 12,
                }}
                onClick={
                  from === "CREATE" ? handleCreate : handleEditPostConfirmation
                }
              >
                Post
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

CreateEditPostPopup.propTypes = {
  from: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setOpen: PropTypes.func,
  post_data: PropTypes.object.isRequired,
  close: PropTypes.func,
};

export default CreateEditPostPopup;
