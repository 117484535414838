import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
  Box,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../../Firebase";
import { doRequest, getRequest } from "../../../services/request";
import { Link, useNavigate } from "react-router-dom";

const SignupForm = () => {
  const [loading, setLoading] = useState(false);
  const [usernameExists, setUsernameExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (usernameExists) {
      setFormData({
        ...formData,
        username: "",
      });
      setLoading(false);
    } else if (emailExists) {
      setFormData({
        ...formData,
        email: "",
      });
      setLoading(false);
    } else if (passwordLengthError) {
      setFormData({
        ...formData,
        password: "",
        confirmPassword: "",
      });
      setLoading(false);
    }
  }, [usernameExists, emailExists, passwordLengthError]);

  useEffect(() => {
    if (
      formData.username !== "" &&
      formData.email !== "" &&
      formData.password !== ""
    ) {
      setUsernameExists(false);
      setEmailExists(false);
      setPasswordLengthError(false);
    } else if (usernameExists && formData.username === "") {
      setTimeout(() => {
        setUsernameExists(false);
      }, 5000);
    } else if (emailExists && formData.email === "") {
      setTimeout(() => {
        setEmailExists(false);
      }, 5000);
    } else if (passwordLengthError && formData.password === "") {
      setTimeout(() => {
        setPasswordLengthError(false);
      }, 5000);
    }

    if (formData.password !== formData.confirmPassword) {
      setPasswordError("password and confirm password should be same");
    } else {
      setPasswordError("");
    }
  }, [formData]);

  const checkUserNameAndEmail = async () => {
    let response = await getRequest({
      url: `/isUser/${formData.username}`,
    });
    if (response?.data.user) {
      setUsernameExists(true);
      setErrorMessage(
        "Username already exists, please login or try different one"
      );
      return true;
    }
    let response2 = await getRequest({
      url: `users/is-user-exists/${formData.email}`,
    });
    if (response2?.data.isExists) {
      setEmailExists(true);
      setErrorMessage(
        "email already exists, please login or try different one"
      );
      return true;
    }

    return false;
  };

  const createUser = async (email, password) => {
    setLoading(true);
    try {
      let isEmailUsernameExists = await checkUserNameAndEmail();
      if (isEmailUsernameExists) return;

      let user = await createUserWithEmailAndPassword(auth, email, password);
      const authUser = getAuth();
      await updateProfile(authUser.currentUser, {
        displayName: formData.firstName + " " + formData.lastName,
      });
      let userDetails = {
        First_Name: formData.firstName,
        last_name: formData.lastName,
        username: formData.username,
        email: email,
        Password: password,
      };
      let saveData = await doRequest({
        url: `users/sign-up`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userDetails),
      });
      navigate("/welcome");
      setLoading(false);
    } catch (e) {
      console.log("error creating user", e);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password.length < 6) {
      setErrorMessage("password should be more than 6 characters long");
      setPasswordLengthError(true);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("password should be same");
      setPasswordLengthError(true);
      return;
    }

    // Add your signup logic here, e.g., sending a request to a server
    createUser(formData.email, formData.password);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Create Account
      </Typography>
      {(usernameExists || emailExists || passwordLengthError) && (
        <Snackbar
          open={true}
          autoHideDuration={5000}
          // onClose={handleClose}
          message={errorMessage}
          // action={action}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <TextField
          label="First Name"
          variant="outlined"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          margin="normal"
          required
          fullWidth
          size="small"
        />
        <TextField
          label="Last Name"
          variant="outlined"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          margin="normal"
          required
          fullWidth
          size="small"
        />
        <TextField
          label="Username"
          variant="outlined"
          name="username"
          value={formData.username}
          onChange={handleChange}
          margin="normal"
          required
          fullWidth
          size="small"
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          required
          fullWidth
          size="small"
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          margin="normal"
          required
          fullWidth
          size="small"
        />
        <TextField
          label="Confirm Password"
          variant="outlined"
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          margin="normal"
          required
          fullWidth
          size="small"
        />
        {passwordError !== "" && (
          <Box
            sx={{
              fontSize: "12px",
              textAlign: "center",
              color: "red",
              mt: "2px",
              mb: "10px",
            }}
          >
            {passwordError}
          </Box>
        )}

        {loading ? (
          <Button fullWidth>
            <CircularProgress size={20} />
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<LockIcon />}
          >
            Sign Up
          </Button>
        )}
      </form>
      <Typography
        sx={{
          marginBlock: 1,
          textAlign: "center",
        }}
      >
        Already have an account <Link to="/welcome">Login</Link>{" "}
      </Typography>
    </Container>
  );
};

export default SignupForm;
