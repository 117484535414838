import React, { useContext, useState } from "react";
import { Box, Button, Card } from "@mui/material";
import { Cookies } from "react-cookie";
import { ThemeContext } from "../.././../context/ThemeContext";
import MySidebar from "../../common/components/my-sidebar";
import SearchBar from "../../common/components/search-bar";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Newlogo from "./../../../assets/askfunduLogo.png";

import { useLocation, useNavigate } from "react-router-dom";
import MobileMessageIcon from "../../mobile-appointment";
import LoginBanner from "../../login-banner";
import RoomSearchBox from "../../rooms-new/room-search";

const MobileDefault = ({ handleScroll, middleContent }) => {
  const { theme } = useContext(ThemeContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState(false);
  const [showRoom, setShowRoom] = useState(false);
  const location = useLocation();
  const isWelcomeRoute = location.pathname === "/welcome";
  const isPreferedRoute = location.pathname === "/preferred-tags";
  const isRoomRoute = location.pathname === "/rooms";

  return (
    <>
      <Box
        onScroll={handleScroll}
        sx={{
          backgroundColor: "#f7f6f9",
          height: "100vh",
          width: "100wh",
          position: "relative",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {isWelcomeRoute || isPreferedRoute ? (
          <Card
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              position: "fixed",
              height: 50,
              zIndex: "400",
              boxShadow: "none",
              border: "1px solid #e0e0e0",
            }}
          >
            <img
              style={{}}
              onClick={() => {
                navigate("/");
              }}
              src={Newlogo}
              alt="logo"
            />
          </Card>
        ) : (
          <Card
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              position: "fixed",
              height: 50,
              zIndex: "400",
              boxShadow: "none",
              border: "1px solid #e0e0e0",
            }}
          >
            <MySidebar />
            <img
              style={{}}
              onClick={() => {
                navigate("/");
              }}
              src={Newlogo}
              alt="logo"
            />
            {isRoomRoute ? (
              <Button
                sx={{
                  border: "none",
                  color: "black",
                }}
                onClick={() => setShowRoom(!showRoom)}
              >
                {showRoom ? <CloseIcon /> : <SearchIcon />}
              </Button>
            ) : (
              <Button
                sx={{
                  border: "none",
                  color: "black",
                }}
                onClick={() => setShowPage(!showPage)}
              >
                {showPage ? <CloseIcon /> : <SearchIcon />}
              </Button>
            )}
          </Card>
        )}

        {isRoomRoute && showRoom && (
          <Box
            sx={{
              mt: 7,
              p: 1,
              zIndex: "400",
              mb: -6,
            }}
          >
            <RoomSearchBox />
          </Box>
        )}
        {!isRoomRoute && showPage && (
          <Box
            sx={{
              mt: 9,
              p: 1,
              zIndex: "400",
              mb: -6,
            }}
          >
            <SearchBar />
          </Box>
        )}

        <Box sx={{ mt: 6, p: 1 }}>{middleContent}</Box>
      </Box>
      {!isWelcomeRoute && !isPreferedRoute && (
        <Box
          sx={{
            height: "0",
            position: "fixed",
            zIndex: "1000",
            bottom: "0",
            right: "0",
            overflow: "visible",
            display: "flex",
            flexDirection: "row-reverse",
            flexWrap: "nowrap",
            alignItems: "flex-end",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <MobileMessageIcon />
        </Box>
      )}

      {!user && !isWelcomeRoute && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: "100000",
          }}
        >
          <LoginBanner />
        </Box>
      )}
    </>
  );
};

export default MobileDefault;
