import { useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { backend_url } from "../../http-common";

export default function Fyers() {
  const location = useLocation();
  const navigate = useNavigate();
  const cookie = new Cookies();
  const user = cookie.get("user");
  const searchParams = new URLSearchParams(location.search);
  const state = searchParams.get("state");
  const auth_code = searchParams.get("auth_code");
  const [cookies, setCookies] = useCookies(["user"]);

  // const fetchAuthcode = async () => {
  //   try {
  //     let res = await fetch("https://api.fyers.in/api/v2/validate-authcode", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         grant_type: "authorization_code",
  //         code_verifier: `https://api.fyers.in/api/v2/generate-authcode?client_id=SIIGBOFHOC-100&redirect_uri=https://web.askfundu.com/fyers/auth&response_type=code&state=${user.username}`,
  //         code: auth_code,
  //       }),
  //     });
  //     let data = await res.json();
  //     sendCodeToBackend(data);
  //   } catch (e) {
  //     console.log("something went wrong", e.message);
  //     navigate(`/user/${user?.username}`);
  //   }
  // };

  const sendCodeToBackend = async () => {
    let data = {
      auth_code: auth_code,
      username: user.username,
    };
    console.log(state, "this is fyers username");
    try {
      let res = await fetch(`${backend_url}/users/auth/fyers`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      let rdata = await res.json();
      if (rdata.msg === "done") {
        user = {
          ...user,
          isFyersConnected: true,
        };
        setCookies("user", JSON.stringify(user), {
          path: "/",
        });
      }
      if (!user) {
        navigate("/welcome");
      } else {
        if (user?.isExpert) {
          navigate(`/rooms/${user?.username}`);
        } else {
          navigate(`/user/${user?.username}`);
        }
      }
    } catch (e) {
      console.log("something went wrong in sending code to backend", e.message);
      if (!user) {
        navigate("/welcome");
      } else {
        if (user?.isExpert) {
          navigate(`/rooms/${user?.username}`);
        } else {
          navigate(`/user/${user?.username}`);
        }
      }
    }
  };

  useEffect(() => {
    sendCodeToBackend();
    // Do something with the queryParamValue
    console.log(state, auth_code, "searching for query");
  }, [location.search]);

  return <>redirecting...</>;
}
