import { getRequest } from "../../services/request";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

export const fetchLoggedInUser = createAsyncThunk(
  "users/fetchLoggedInUser",
  async (arg, { getState }) => {
    const {
      user: { username },
    } = getState();
    let response = await getRequest({
      url: `/get-user-by-name/${username}`,
    });
    return response?.data?.response[0];
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    username: "",
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    pushUserLikedPost: (state, action) => {
      state.user = {
        ...state.user,
        liked_posts: [...state.user.liked_posts, action.payload],
      };
    },
    popUserLikedPost: (state, action) => {
      state.user = {
        ...state.user,
        liked_posts: state.user.liked_posts.filter(
          (item) => item !== action.payload
        ),
      };
    },
    pushUserDislikedPost: (state, action) => {
      state.user = {
        ...state.user,
        disliked_posts: [...state.user.disliked_posts, action.payload],
      };
    },
    popUserDislikedPost: (state, action) => {
      state.user = {
        ...state.user,
        disliked_posts: state.user.disliked_posts.filter(
          (item) => item !== action.payload
        ),
      };
    },
    clearUser: (state, action) => {
      state.user = {};
      state.username = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoggedInUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const {
  setUsername,
  pushUserLikedPost,
  pushUserDislikedPost,
  popUserLikedPost,
  popUserDislikedPost,
  clearUser,
} = userSlice.actions;
export default userSlice.reducer;
