import PreferredTags from "../Components/preferred-tags";
import AlertPageRoute from "./alert-page/AlertPageRoute";
import AllNewsPageRoute from "./all-news-page/AllNewsPageRoute";
import AllSuggestionPageRoute from "./all-suggestion-page/AllSuggestionPageRoute";
import AppointmentPageMainRoute from "./appointment-page/AppointmentPageRoute";
import CopyRightPolicyRoute from "./copyright-policy/CopyRightPolicy";
import FilterPostsPageRoute from "./filter-posts-page/FilterPostsPageRoute";
import FilterStocksPageRoute from "./filter-stocks-page/FilterStocksPageRoute";
import HelpPageRoute from "./help-page/HelpPageRoute";
import IndicesDetailsPageRoute from "./indices-details-page/IndicesDetailsPageRoute";
import LandingPageRoute from "./landing-page/LandingPageRoute";
import LoginLandingPageRoute from "./login-landing/LoginLandingPageRoute";
import MostPopularStockPageRoute from "./most-popular-stock-page/MostPopularStockPageRoute";
import NewsDetailsPageRoute from "./news-details-page/NewsDetailsPageRoute";
import NotFoundPageRoute from "./not-found-page/NotFoundPageRoute";
import PostDetailsMainPageRoute from "./post-details-main-page/PostDetailsMainPageRoute";
import PredictionPageRoute from "./prediction-page/PredictionPageRoute";
import PreferredTagsRoute from "./preferred-tags-page/PreferredTagsRoute";
import PrivacyPolicyRoute from "./privacy-policy/PrivacyPolicyRoute";
import RoomProfileRoute from "./room-profile-details-page/RoomProfileRoute";
import RoomsPageRoute from "./rooms-page/RoomPageRoute";
import SavedPageRoute from "./saved-page/SavedPageRoute";
import SeeAllResultsRoute from "./see-all-results/SeeAllResultsRoute";
import SettingPageRoute from "./setting-page/SettingPageRoute";
import SignupPageRoute from "./signup-page/SignupPageRoute";
import StocksDetailsPageRoute from "./stocks-details-page/StocksDetailsPageRoute";
import SuggestionPageRoute from "./suggestion-page/SuggestionPageRoute";
import TermsOfServicesRoute from "./terms-of-services/TermsOfServicesRoute";
import TrendingPageRoute from "./trending-page/TrendingPageRoute";
import UserProfilePageRoute from "./user-profile-page/UserProfilePageRoute";
import FyersAuth from "./fyers/FyersPage";
import ServicePageRoute from "./services-page/ServicePageRoute";
import AddServicesPageRoute from "./services-page/AddServicesPageRoute";
import CareateSlotPageRoute from "./services-page/CareateSlotPageRoute";
import EmailConfirmationPageRoute from "./email-confirmation/EmailConfirmationPageRoute";
import PasswordResetPageRoute from "./password-reset/PasswordResetPage";
import RiskProfileRoute from "./Risk-Profile/RiskProfileRoute";

const ROUTES = [
  LandingPageRoute,
  AlertPageRoute,
  ...AllNewsPageRoute,
  FilterStocksPageRoute,
  FilterPostsPageRoute,
  ...MostPopularStockPageRoute,
  NewsDetailsPageRoute,
  PostDetailsMainPageRoute,
  SavedPageRoute,
  ...SeeAllResultsRoute,
  SettingPageRoute,
  StocksDetailsPageRoute,
  ...SuggestionPageRoute,
  UserProfilePageRoute,
  LoginLandingPageRoute,
  SignupPageRoute,
  NotFoundPageRoute,
  AllSuggestionPageRoute,
  CopyRightPolicyRoute,
  TermsOfServicesRoute,
  PrivacyPolicyRoute,
  HelpPageRoute,
  RoomsPageRoute,
  PreferredTagsRoute,
  TrendingPageRoute,
  RoomProfileRoute,
  AppointmentPageMainRoute,
  IndicesDetailsPageRoute,
  PredictionPageRoute,
  FyersAuth,
  ServicePageRoute,
  AddServicesPageRoute,
  CareateSlotPageRoute,
  EmailConfirmationPageRoute,
  PasswordResetPageRoute,
  RiskProfileRoute,
];

export default ROUTES;
