import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const BasicCard = (props) => {
  return (
    <div>
      <Card
        sx={{
          maxWidth: "100%",
          mb: 2,
          boxShadow: "none",
          border: "1px solid #e0e0e0",
          mt: -2.8,
        }}
      >
        <CardContent sx={{ display: "flex" }}>
          <Box>
            <Avatar src={props.Image} sx={{ width: 80, height: 80 }} />
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              <Box width="24px">
                <Box>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.9974 10.8141L23.0807 13.2974L21.9974 8.61406L25.6641 5.48073L20.8474 5.06406L18.9974 0.664062L17.1141 5.06406L12.3307 5.48073L15.9641 8.61406L14.8307 13.2974L18.9974 10.8141ZM23.9974 37.3307H13.9974V15.6641H23.9974V37.3307ZM0.664062 27.3307V37.3307H10.6641V27.3307H0.664062ZM7.33073 33.9974H3.9974V30.6641H7.33073V33.9974ZM27.3307 20.6641V37.3307H37.3307V20.6641H27.3307ZM33.9974 33.9974H30.6641V23.9974H33.9974V33.9974Z"
                      fill="#FFC700"
                    />
                  </svg>
                </Box>
                <Box textAlign="center">
                  <Typography
                    letterSpacing={0.4}
                    variant="body1"
                    component="div"
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    24
                  </Typography>
                </Box>
              </Box>
              <Box width="24px">
                <Box>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.9974 10.8141L23.0807 13.2974L21.9974 8.61406L25.6641 5.48073L20.8474 5.06406L18.9974 0.664062L17.1141 5.06406L12.3307 5.48073L15.9641 8.61406L14.8307 13.2974L18.9974 10.8141ZM23.9974 37.3307H13.9974V15.6641H23.9974V37.3307ZM0.664062 27.3307V37.3307H10.6641V27.3307H0.664062ZM7.33073 33.9974H3.9974V30.6641H7.33073V33.9974ZM27.3307 20.6641V37.3307H37.3307V20.6641H27.3307ZM33.9974 33.9974H30.6641V23.9974H33.9974V33.9974Z"
                      fill="#AAAAAA"
                    />
                  </svg>
                </Box>
                <Box textAlign="center">
                  <Typography
                    letterSpacing={0.4}
                    variant="body1"
                    component="div"
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    36
                  </Typography>
                </Box>
              </Box>
              <Box width="24px">
                <Box>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.9974 10.8141L23.0807 13.2974L21.9974 8.61406L25.6641 5.48073L20.8474 5.06406L18.9974 0.664062L17.1141 5.06406L12.3307 5.48073L15.9641 8.61406L14.8307 13.2974L18.9974 10.8141ZM23.9974 37.3307H13.9974V15.6641H23.9974V37.3307ZM0.664062 27.3307V37.3307H10.6641V27.3307H0.664062ZM7.33073 33.9974H3.9974V30.6641H7.33073V33.9974ZM27.3307 20.6641V37.3307H37.3307V20.6641H27.3307ZM33.9974 33.9974H30.6641V23.9974H33.9974V33.9974Z"
                      fill="#FF9A62"
                    />
                  </svg>
                </Box>
                <Box textAlign="center">
                  <Typography
                    letterSpacing={0.4}
                    variant="body1"
                    component="div"
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    48
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box width="100%" marginLeft={3}>
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Typography letterSpacing={0.4}>{props.name}</Typography>
                <Chip label="Pro" sx={{ p: 1 }} />
              </Box>
              <Typography
                letterSpacing={0.4}
                gutterBottom
                fontWeight="fontWeightBold"
                component="div"
                fontSize={14}
              >
                Research & Analysis
              </Typography>
              <Typography
                letterSpacing={0.4}
                variant="body2"
                color="text.secondary"
                style={{ fontSize: "12px" }}
                component="div"
              >
                Interested In - Buy & hold
              </Typography>
              <Typography
                letterSpacing={0.4}
                variant="body2"
                color="text.secondary"
                style={{ fontSize: "12px" }}
                component="div"
              >
                Knowledge of - Swing trade
              </Typography>
            </Box>
            <Box marginTop={3} marginLeft={2}>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  borderRadius: "50px",
                  fontSize: "11px",
                  textTransform: "none",
                  backgroundColor: "var(--fundu_color)",
                  ":hover": {
                    transition: "0.2s ease-in",
                    backgroundColor: "#3c1485",
                  },
                }}
              >
                Chat
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default BasicCard;
