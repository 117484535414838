import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const ResultsNotFound = ({ searchInput }) => {
  return (
    <Box
      sx={{
        height: 500,
        width: 470,
        mt: 2,
        mb: 2,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        border: "1px solid #e0e0e0",
      }}
      className="list-group"
    >
      <Typography
        letterSpacing={0.4}
        sx={{
          fontWeight: 600,
          fontSize: "18px",
          color: "#372B47",
        }}
      >{`No results found for "${searchInput}"`}</Typography>
      <Typography letterSpacing={0.4} p={5}>
        Sorry, we couldn't find any result for this search. Try finding using
        something else.
      </Typography>
    </Box>
  );
};

ResultsNotFound.propTypes = {
  searchInput: PropTypes.string.isRequired,
};

export default ResultsNotFound;
