import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import React, { useState, useContext, useEffect } from "react";
import { Cookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { doRequest, getRequest } from "../../../../services/request";
import AddStocksLists from "../add-stocks-lists";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";

const AddStocksPopup = ({ fetchWatchlistStocks }) => {
  const [open, setOpen] = useState(false);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [spottedDate, setSpottedDate] = useState("");
  const [spottedPrice, setSpottedPrice] = useState("");
  const [targetPrice, setTargetPrice] = useState("");
  const [inputText, setInputText] = useState();
  const [searchedStocksData, setSearchedStocksData] = useState([]);
  const { addedStocks = [] } = useContext(StockNewsContext);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const navigate = useNavigate();

  const getSearchedStocks = async (value) => {
    const response = await getRequest({
      url: `/get-searched-stocks?search=${value}`,
    });
    setSearchedStocksData(response.data?.stocks);
    localStorage.setItem(
      "searchedStocks",
      JSON.stringify(response.data?.stocks)
    );
  };

  useEffect(() => {
    // Set the default value of 'spottedDate' to the current date
    const currentDate = new Date().toISOString().split("T")[0];
    setSpottedDate(currentDate);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isIncluded = (symbol) => {
    if (!Array.isArray(addedStocks)) {
      return false; // Handle the case where addedStocks is not an array
    }

    for (let stock of addedStocks) {
      if (stock && stock.SYMBOLS === symbol) {
        return true;
      }
    }
    return false;
  };

  const handleAddStocks = async (e, stock) => {
    if (selectedStocks.length === 0) {
      // Handle the case where no stock is selected
      return;
    }

    const selectedStock = selectedStocks[0];
    let payload = {
      SYMBOLS: selectedStock.SYMBOLS,
      spottedDate: spottedDate,
      spottedPrice: spottedPrice,
      targetPrice: targetPrice,
      // shortName: stock.shortName,
    };
    payload = JSON.stringify(payload);
    // console.log("bhai kuch jaa raha hai kya", payload);

    doRequest({
      url: `/stocks/add-watchlist/${user?.username}`,
      body: payload,
      method: "POST",
    })
      .then((res) => {
        fetchWatchlistStocks();
        handleClose();
      })
      .catch((e) => {
        console.log("err", e.message);
      });
  };

  const handleSerched = (stock) => {
    if (!isIncluded(stock.SYMBOLS)) {
      // Clear the selected stocks array before adding a new stock
      setSelectedStocks([stock]);
      setInputText(""); // Clear the input after selecting a stock
    }
  };

  const handleRemoveStocks = (stock) => {
    setSelectedStocks(
      selectedStocks.filter((item) => item.SYMBOLS !== stock.SYMBOLS)
    );
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputText(input);
    // Optionally, you can update the searched stocks here based on the input.
    getSearchedStocks(input);
  };

  return (
    <div style={{ display: "inline-block" }}>
      {/* {user?.username === username && ( */}
      <Button
        onClick={() => {
          if (!user) {
            localStorage.setItem("urlOfLastPage", window.location.href);
            localStorage.setItem("clickedInnerForUser", user?.username);
            window.location.href = "/welcome";
          } else {
            handleClickOpen();
          }
        }}
        sx={{
          border: "none",
          backgroundColor: "transparent",
          textTransform: "none",
        }}
      >
        <AddIcon sx={{ marginRight: "4px" }} />
        Add stocks
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Stock Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the following information:
          </DialogContentText>

          <TextField
            label="Search and Select Stocks"
            value={inputText}
            fullWidth
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  {selectedStocks.map((stock, idx) => (
                    <Chip
                      key={idx}
                      label={stock.longName}
                      onDelete={() => handleRemoveStocks(stock)}
                      color="primary"
                    />
                  ))}
                </InputAdornment>
              ),
            }}
          />
          {inputText && (
            <Box
              sx={{
                height: 200,
                mt: 1,
                overflowY: "scroll",
                overflowX: "hidden",
                zIndex: 9999,
                position: "absolute",
                backgroundColor: "white",
                border: "1px solid #e0e0e0",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#5D20D2",
                  borderRadius: "4px",
                },
              }}
            >
              {searchedStocksData.map((stock, idx) => (
                <Typography key={idx} fontSize={12} pl={2} pr={2}>
                  {`${stock.longName} (${stock.SYMBOLS})`}
                  <IconButton
                    onClick={() => {
                      if (isIncluded(stock.SYMBOLS)) {
                        handleRemoveStocks(stock);
                      } else {
                        handleSerched(stock);
                      }
                    }}
                  >
                    {isIncluded(stock.SYMBOLS) ? (
                      <RemoveCircleIcon />
                    ) : (
                      <AddCircleIcon style={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                </Typography>
              ))}
            </Box>
          )}

          <br />
          <br />

          <TextField
            label="Spotted Date"
            type="date"
            fullWidth
            size="small"
            value={spottedDate}
            onChange={(e) => setSpottedDate(e.target.value)}
          />
          <br />
          <br />
          <TextField
            size="small"
            label="Spotted Price"
            type="number"
            fullWidth
            value={spottedPrice}
            onChange={(e) => setSpottedPrice(e.target.value)}
          />
          <br />
          <br />
          <TextField
            size="small"
            label="Target Price"
            type="number"
            fullWidth
            value={targetPrice}
            onChange={(e) => setTargetPrice(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddStocks} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddStocksPopup;
