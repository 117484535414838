import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";
import { readableImage } from "../../../common-functions/FormatDate";
import { Link } from "react-router-dom";
import { ColorModeContext } from "../../../../DarkModeContext/DarkModeContext";
import StocksDetailsPopUp from "../../../NewStocks";

export const DefaultStocksCard = ({ stock, button }) => {
  const navigate = useNavigate();
  const { mode } = useContext(ColorModeContext);
  const { selectedTagsStocks } = useContext(StockNewsContext);

  return (
    <>
      {stock.longName && stock.SYMBOLS && (
        <Card
          sx={{
            p: 2,
            boxShadow: "none",
            border: "1px solid #e0e0e0",
          }}
          style={{ width: "100%" }}
        >
          <Link
            // to={`/stock/${stock.SYMBOLS}`}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Grid
              container
              spacing={1}
              columns={20}
              justifyContent="space-between"
              textAlign={"center"}
              alignItems={"center"}
            >
              <Grid item xs={8} md={6}>
                {stock.longName && stock.SYMBOLS && (
                  <Grid sx={{ gap: 1, display: "grid", textAlign: "left" }}>
                    <Typography
                      letterSpacing={0.4}
                      variant="p"
                      sx={{ fontSize: "14px", fontWeight: "700" }}
                    >
                      <StocksDetailsPopUp stocks={stock.SYMBOLS} />
                    </Typography>
                    <Typography
                      letterSpacing={0.4}
                      variant="p"
                      sx={{ fontSize: "12px" }}
                    >
                      {stock.longName}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {stock.longName && stock.SYMBOLS && (
                <Grid item xs={4} md={4}>
                  <Chip
                    sx={{
                      marginTop: "5px",
                    }}
                    label={stock.Sector}
                    variant="outlined"
                  />
                </Grid>
              )}
              {stock.longName && stock.SYMBOLS && (
                <Grid item xs={4} md={4} textAlign="right">
                  <img
                    src={readableImage(stock.graph?.data)}
                    alt="graph"
                    style={{ width: "80px", height: "50px" }}
                  />
                </Grid>
              )}

              {stock.longName && stock.SYMBOLS && (
                <Grid item xs={4} md={4} textAlign="right">
                  <Typography letterSpacing={0.4} sx={{ fontSize: 14 }}>
                    {"\u20B9"} {stock.live_stock_data?.LAST}
                  </Typography>
                </Grid>
              )}

              <Box>{button}</Box>
            </Grid>
          </Link>
        </Card>
      )}
    </>
  );
};
export default DefaultStocksCard;
