import React, { useContext } from "react";
import PostBefore from "../profile-page/PostBefore";
import { ThemeContext } from "../../context/ThemeContext";
import UserPosts from "../user-posts-tabs/components/user-posts";
import { Cookies } from "react-cookie";
import { Box } from "@mui/material";

const PostDetailsPage = ({ data, fetchPost }) => {
  const { theme } = useContext(ThemeContext);
  const cookie = new Cookies();
  const user = cookie.get("user");

  return (
    <Box>
      {data.length === 0 ? (
        <PostBefore />
      ) : (
        <UserPosts data={data} fetchPost={fetchPost} />
      )}
    </Box>
  );
};

export default PostDetailsPage;
