import { lazy } from "react";

const AllNewsPageRoute = [
  {
    path: "/news",
    component: lazy(() => import("./index")),
  },
  {
    path: "/news/positive",
    component: lazy(() => import("./index")),
  },
  {
    path: "/news/negative",
    component: lazy(() => import("./index")),
  },
  {
    path: "/news/neutral",
    component: lazy(() => import("./index")),
  },
];

export default AllNewsPageRoute;
