import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { COMET_CHAT_REST_KEY } from "../../services/constants";

export const fetchMessage = createAsyncThunk(
  "messages/fetchMessage",
  async (arg, { getState }) => {
    const { uid, unread, hideReplies, perPage, limit, apikey, type } =
      getState().cometchatMessages;
    let response = await axios.get(
      `https://2369283ed2432262.api-us.cometchat.io/v3/users/${uid}/messages?unread=${unread}&hideReplies=${hideReplies}&type=${type}&perPage=${perPage}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          apikey,
        },
      }
    );
    return response?.data?.data;
  }
);

const messageSlice = createSlice({
  name: "cometchatMessages",
  initialState: {
    data: [],
    uid: "",
    prevUid: "",
    unread: false,
    hideReplies: true,
    perPage: 1000,
    limit: 1000,
    type: "text",
    apikey: COMET_CHAT_REST_KEY,
    loading: false,
    error: false,
  },
  reducers: {
    updateUid: (state, action) => {
      if (state.data.length > 0 || (state.prevUid !== "" && state.uid !== "")) {
        state.prevUid = state.uid;
      }
      state.uid = action.payload.username;
    },
    resetMessageData: (state, action) => {
      state.prevUid = "";
      state.uid = "";
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessage.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchMessage.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { updateUid, resetMessageData } = messageSlice.actions;
export default messageSlice.reducer;
