import React from "react";
import { useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import AllMostPopularStocks from "../../all-stocks-tab/all-stocks";

function useFilterStocksTabs() {
  const { tag } = useParams();
  const { stocksData, selectedTagsStocks } = useContext(StockNewsContext);

  const buy = stocksData.filter((item) => item["Technical Rating"] === "Buy");
  const sell = stocksData.filter((item) => item["Technical Rating"] === "Sell");
  const neutral = stocksData.filter(
    (item) => item["Technical Rating"] === "Neutral"
  );

  const tabs = useMemo(
    () => [
      {
        name: "All stocks",
        component: (
          <AllMostPopularStocks
            data={
              tag !== null
                ? stocksData.filter((el) => {
                    return el.Sector === selectedTagsStocks;
                  })
                : stocksData
            }
          />
        ),
      },
      {
        name: "Buy",
        component: (
          <AllMostPopularStocks
            data={
              tag !== null
                ? buy.filter((el) => {
                    return el.Sector === selectedTagsStocks;
                  })
                : buy
            }
          />
        ),
      },
      {
        name: "Sell",
        component: (
          <AllMostPopularStocks
            data={
              tag !== null
                ? sell.filter((el) => {
                    return el.Sector === selectedTagsStocks;
                  })
                : sell
            }
          />
        ),
      },
      {
        name: "Neutral",
        component: (
          <AllMostPopularStocks
            data={
              tag !== null
                ? neutral.filter((el) => {
                    return el.Sector === selectedTagsStocks;
                  })
                : neutral
            }
          />
        ),
      },
    ],
    [buy, neutral, selectedTagsStocks, sell, stocksData, tag]
  );

  return {
    tabs,
  };
}

export default useFilterStocksTabs;
