import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import useViewPort from "../../hooks/useViewPort";
import useAllStocksTabs from "./hook/useAllStocksTabs";
import SearchStocks from "../stock-details/stocks-search";
import { useMediaQuery } from "@mui/material";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "black",
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AllStocksTab({ loading }) {
  const [value, setValue] = useState(0);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { width } = useViewPort();
  const { tabs } = useAllStocksTabs(loading);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 1:
        navigate("/stocks/indices");
        break;

      default:
        navigate("/stocks");
    }
  };
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Box
        sx={{
          mb: 2,
          height: 49,
          border: isMobile ? "" : "1px solid #e0e0e0",
          borderRadius: "4px",
        }}
        style={theme ? light : dark}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ border: "none" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {tabs?.map((tab, index) => {
                  return (
                    <Tab
                      key={`stocks-tab-${index}`}
                      label={tab?.name}
                      sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Box mt={0.2} mr={0}>
              <SearchStocks />
            </Box>
          </Box>

          {tabs?.map((tab, index) => {
            return (
              <TabPanel
                key={`Stock-Tab-${index}`}
                value={value}
                index={index}
                className="tabPanel_mostbought"
                style={{ marginTop: "10px" }}
              >
                {tab.component}
                <br />
                <br />
              </TabPanel>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
