import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export default function FuLoader({ height, }) {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      height={height || "100vh"}
    >
      <CircularProgress sx={{ color: "#5D20D2" }} disableShrink />
    </Box>
  );
}
