import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

const useFilterData = (tag) => {
  const { selectedTags, userPosts, followingPosts } = useContext(ThemeContext);

  const filterData = (post) => {
    const result = post?.filter((item) => {
      if (tag === "") {
        return item;
      } else {
        return item.Tag.includes(tag);
      }
    });
    return result;
  };

  const filterPostsByTags = filterData(userPosts);
  const filterPostsByFollowing = filterData(followingPosts);

  return { filterPostsByTags, filterPostsByFollowing };
};

export default useFilterData;
