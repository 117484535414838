import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import DefaultNewsCard from "../layout/base/default";
import { backend_url } from "../../http-common";
import ForPopUpDefaultNewsCard from "../layout/base/default/ForPopUp";

export const RelatedNews = ({ symbol }) => {
  const itemsPerPage = 10;
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchNews = async (currentPage) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${backend_url}/stocks/news/reccom/${symbol}?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );

      if (response.ok) {
        const responseData = await response.json();
        const data = responseData.news;

        if (data.length === 0) {
          setHasMore(false);
        } else {
          setNewsData([...newsData, ...data]);
          setPage(currentPage);
        }
      } else {
        console.error("API response:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews(1); // Initial load with the first page
  }, [symbol]);

  const loadMoreNews = () => {
    fetchNews(page + 1); // Load the next page
  };

  return (
    <>
      {newsData.slice(0, page * itemsPerPage).map((news, idx) => (
        <Box key={idx}>
          <ForPopUpDefaultNewsCard news={news} />
        </Box>
      ))}

      {loading && <CircularProgress />}

      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          mt: 2,
        }}
      >
        {hasMore && !loading && (
          <Button variant="contained" color="primary" onClick={loadMoreNews}>
            Load More
          </Button>
        )}
      </Box>
    </>
  );
};

export default RelatedNews;
