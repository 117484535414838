import React, { useContext, useState, memo, useEffect } from "react";
import "../../index.css";
import { Box } from "@mui/material";
import { ThemeContext } from "../../../../context/ThemeContext";
import Typography from "@mui/material/Typography";
import FuLoader from "../../../shared/FuLoader";
import AllPostCard from "./components/AllPostCard";

const UserPosts = ({
  data,
  clipped = false,
  search,
  loading,
  getData,
  fetchPost,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const { selectedTags } = useContext(ThemeContext);

  useEffect(() => {
    if (tooltipIsOpen) {
      const timeoutId = setTimeout(() => setTooltipIsOpen(false), 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [tooltipIsOpen]);

  return (
    <Box>
      {selectedTags && data.length === 0 && (
        <Typography letterSpacing={0.4}>
          No Posts Available In This Tag.
        </Typography>
      )}
      {data?.map((item, idx) => (
        <AllPostCard
          key={`Allpost-card-${idx}`}
          item={item}
          fetchPost={fetchPost}
          idx={idx}
          clipped={clipped}
          search={search}
          getData={getData}
          data={data}
        />
      ))}

      {loading && <FuLoader height={"30px"} />}
    </Box>
  );
};

export default memo(UserPosts);
