import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, Button, Grid, Modal } from "@mui/material";
import Stack from "@mui/material/Stack";
import { formatDate } from "../../../common-functions/FormatDate";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { MdVisibility } from "react-icons/md";
import NewsShareDialog from "../../../fundu-news/news-share";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";
import { doRequest } from "../../../../services/request";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import { isBiggerWord } from "../../../common-functions/helperFunctions";

export const DefaultNewsCard = ({
  news,
  setMobileNewsNumber,
  isMobileNewsCard = false,
  isPopup = false,
}) => {
  const navigate = useNavigate();
  const [ifClicked, setIfClicked] = useState(false);
  const { savedNews, addSavedNews, removeSavedNews } =
    useContext(StockNewsContext);
  const { i18n } = useTranslation();
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const checkIfSaved = () => {
    let exists = false;
    savedNews?.forEach((item) => {
      if (item._id === news._id) {
        exists = true;
      }
    });
    return exists;
  };

  const updateViews = async (id) => {
    if (ifClicked) return;
    doRequest({ url: `/news/update-views/${id}` });
    setIfClicked(true);
  };

  const ifUserLanguageEnglish = () => {
    return i18n.language === "en";
  };

  const showLessSummary = (arr, isGreater) => {
    if (isGreater) {
      let temp = [];
      for (let i = 0; i < arr.length - 1; i++) {
        temp.push(arr[i]);
      }
      return temp;
    }
    return arr;
  };

  const handleButtonClick = () => {
    let url;
    switch (news.Sentiment) {
      case "Positive":
        url = "/news/positive";
        break;
      case "Negative":
        url = "/news/negative";
        break;
      case "Neutral":
        url = "/news/neutral";
        break;
      default:
        break;
    }
    if (url) {
      navigate(url);
    }
  };

  let mainBoxStyle = isMobileNewsCard
    ? { width: "100%", height: "100vh" }
    : { width: "100%" };

  return (
    <Box sx={mainBoxStyle}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "100vh",
            width: "50vw",
            bgcolor: "background.paper",
            p: 1,
            pt: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 8,
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          <DefaultNewsCard news={news} isPopup={true} />
        </Box>
      </Modal>
      <Helmet>
        <link
          rel="canonical"
          href={`/${news["Headline"]?.slice(0, 25)}`}
        ></link>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            headline: news["Headline"],
            image: news?.Image_link,
            author: {
              "@type": "Organization",
              name: news?.Domain,
            },
            publisher: {
              "@type": "Organization",
              name: "https://web.askfundu.com",
              logo: {
                "@type": "ImageObject",
                url: "https://web.askfundu.com/static/media/askfunduLogo.8d6f3f280186de132173.png",
              },
            },
            datePublished: formatDate(news.Created_at),
          })}
        </script>
      </Helmet>
      <Card
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          border: "1px solid #e0e0e0",
        }}
      >
        <Grid container spacing={0} columns={16}>
          <Grid item xs={16} md={8}>
            <Box p={1}>
              <Box
                sx={{ position: "relative", width: "100%" }}
                onClick={() => {
                  updateViews(news._id);
                  // navigate(`/news/${news._id}`);
                }}
              >
                <img className="news_img" src={news?.Image_link} />
              </Box>

              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                mt={1}
                textAlign={"center"}
              >
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    pt: 0.6,
                  }}
                >
                  {formatDate(news.Created_at)}
                  <p style={{ marginLeft: "10px" }}>
                    (<MdVisibility fontSize={18} /> {news?.viewsCount || 0}{" "}
                    views)
                  </p>
                </Typography>

                <Button
                  sx={{
                    textTransform: "none",
                    pl: 1,
                    pr: 1,
                    fontSize: 12,
                    height: "27px",
                    borderRadius: "8px",
                  }}
                  onClick={handleButtonClick}
                  className={`
                    ${
                      news.Sentiment ===
                      (i18n.language === "en" ? "Positive" : "सकारात्मक")
                        ? "positive_news"
                        : null
                    }
                    ${
                      news.Sentiment ===
                      (i18n.language === "en" ? "Negative" : "नकारात्मक")
                        ? "negative_news"
                        : null
                    }
                    ${
                      news.Sentiment ===
                      (i18n.language === "en" ? "Neutral" : "तटस्थ")
                        ? "neutral_news"
                        : null
                    }
                  `}
                >
                  {news?.Sentiment}
                </Button>
              </Stack>

              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Box fontSize={12}>
                  <Typography
                    letterSpacing={0.4}
                    sx={{ fontSize: "12px", fontWeight: 600 }}
                  >
                    {" "}
                    Published by:{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href={news?.Link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {news?.Domain}{" "}
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <NewsShareDialog newsid={news._id} />
                  <Box mt={0.5}>
                    {checkIfSaved() ? (
                      <BookmarkIcon
                        onClick={() => {
                          if (user) {
                            removeSavedNews(news._id);
                          } else {
                            window.location.href = "/welcome";
                          }
                        }}
                        style={{ fontSize: 20 }}
                      />
                    ) : (
                      <BookmarkBorderIcon
                        style={{ fontSize: 20 }}
                        onClick={() => {
                          if (user) {
                            addSavedNews(news._id);
                          } else {
                            window.location.href = "/welcome";
                          }
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={16}
            md={8}
            justifyContent={"space-between"}
            alignItems={"space-between"}
            onClick={() => {
              updateViews(news._id);
              // navigate(`/news/${news._id}`);
            }}
          >
            <Box p={1}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#372B47",
                }}
              >
                {news?.Headline}
              </Typography>
              {news?.Summary ? (
                Array.isArray(news.Summary) ? (
                  <>
                    <ul
                      style={{
                        fontSize: 16,
                        marginLeft: -12,
                      }}
                    >
                      {!isPopup && news.Summary.join(" ").split(" ").length > 80
                        ? showLessSummary(isBiggerWord(news.Summary), true).map(
                            (item, idx) => (
                              <li key={`summary-${idx}`}>
                                <Typography
                                  letterSpacing={0.4}
                                  sx={{ fontSize: 14 }}
                                >
                                  {item}
                                </Typography>
                              </li>
                            )
                          )
                        : isBiggerWord(news.Summary).map((item, idx) => (
                            <li key={`summary-${idx}`}>
                              <Typography
                                letterSpacing={0.4}
                                sx={{ fontSize: 14 }}
                              >
                                {item}
                              </Typography>
                            </li>
                          ))}
                    </ul>
                    {!isPopup &&
                      news.Summary.join(" ").split(" ").length > 80 && (
                        <p
                          style={{
                            display: "flex",
                            marginTop: "1rem",
                            marginLeft: "1.3rem",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={handleOpen}
                        >
                          see more...
                        </p>
                      )}
                  </>
                ) : (
                  <Typography letterSpacing={0.4} sx={{ fontSize: 14 }}>
                    {news?.Summary}
                  </Typography>
                )
              ) : (
                <Typography
                  letterSpacing={0.4}
                  sx={{ fontSize: 14 }}
                >{`${news?.Article.substring(0, 250)}.`}</Typography>
              )}
            </Box>

            {/* <Box className="tagsDiv" p={1}>
              {news?.Keywords?.map((keyword, idx) => (
                <button key={keyword + idx} style={{ fontSize: "12px" }}>
                  {keyword}
                </button>
              ))}
            </Box> */}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default DefaultNewsCard;
