import React from "react";
import { LeftSidePannelWithLogo } from "../../Components/login-components/components/leftside-panel-with-logo";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="loginLangdingContainer">
      <LeftSidePannelWithLogo />
      <div className="loginLandingRight">
        <div className="NotFoundNewPage">
          <h1>Error 404 </h1>
          <br />
          <h3>Opps! Something went wrong</h3>
          <br />
          <h6>Sorry, we can't seem to find the page you are looking for....</h6>
          <br />
          <Link to="/" className="nav-link align-middle px-0 ">
            <button>Go Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
