import React from "react";
import DefaultStocksCard from "../../layout/stocks/default";
import { Box, Typography } from "@mui/material";

const PortfolioStocks = ({ data }) => {
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography
          letterSpacing={0.4}
          fontSize={18}
          fontWeight={600}
          mt={2}
          ml={2}
        >
          Stocks
        </Typography>
      </Box>
      {data.length > 0 ? (
        <Box
          sx={{
            boxShadow: "none",
            border: "1px solid #e0e0e0",
          }}
        >
          {data.map((stock, idx) => (
            <DefaultStocksCard stock={stock} key={idx} />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "1rem",
            mb: "2rem",
          }}
        >
          No Stocks Found
        </Box>
      )}
    </Box>
  );
};

export default PortfolioStocks;
