import React, { useContext, useEffect, useState } from "react";
import "../../index.css";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  capitalFirstLetter,
  checkFollow,
} from "../../../common-functions/FormatDate";
import { backend_url } from "../../../../http-common";
import { Link } from "react-router-dom";
import ImgPlaceholder from "../../../../assets/image_placeholder.png";
import { Cookies } from "react-cookie";
import { Avatar, Typography } from "@mui/material";
import FuButton from "../../../shared/FuButton";

export const Following = ({ followings, handleClose }) => {
  const {
    theme,
    followingUsers,
    myFollowings,
    getFollowingPosts,
    handleFollow,
  } = useContext(ThemeContext);
  const [data, setData] = useState([]);

  const cookie = new Cookies();
  const user = cookie.get("user");

  useEffect(() => {
    if (followingUsers.length > 0) fetchUser(followingUsers);
  }, []);

  const fetchUser = (usernameArray) => {
    usernameArray?.forEach((item, idx) => {
      let modifiedData = {
        first_name: item?.userId?.First_Name,
        last_name: item?.userId?.last_name,
        user_pic: item?.userId?.userpic_url,
        username: item?.userId?.username,
        isFollowing: false,
      };

      setData((d) => [...d, modifiedData]);
    });
  };

  const sortArrayOfObject = (array) => {
    array?.sort(function (a, b) {
      if (a.first_name < b.first_name) {
        return -1;
      }
      if (a.first_name > b.first_name) {
        return 1;
      }
      return 0;
    });

    return array;
  };

  return (
    <div>
      <div className={`${theme ? "div_light" : "div_dark"}`}>
        {data?.length === 0 || followingUsers.length == 0 ? (
          <div>No followings</div>
        ) : (
          sortArrayOfObject([
            ...new Map(data.map((item) => [item["username"], item])).values(),
          ]).map((item, idx) => (
            <div className="follower_main_div" key={`followings-popup-${idx}`}>
              <div className="follower_name_div">
                <div className="follower_image_div">
                  <Avatar
                    src={item.user_pic || ImgPlaceholder}
                    alt="img"
                    sx={{
                      width: 42,
                      height: 42,
                    }}
                  />
                </div>
                <div className="grid_div">
                  <div>
                    <Link
                      to={`/user/${item.username}`}
                      style={{
                        color: "black",
                        textDecoration: "none",
                      }}
                      onClick={handleClose}
                    >
                      <Typography fontSize={14}>
                        {`${capitalFirstLetter(
                          item.first_name
                        )} ${capitalFirstLetter(item.last_name)}`}
                      </Typography>

                      <Typography
                        fontSize={12}
                        color={"#c5c7c7"}
                        mt={-0.7}
                      >{`@${item.username}`}</Typography>
                    </Link>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "5px" }}>
                <FuButton
                  className="following_btn"
                  onClick={() => handleFollow(idx, item.username)}
                >
                  {checkFollow(myFollowings, item.username)
                    ? "Following"
                    : "Follow"}
                </FuButton>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
export default Following;
