import { React, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import AddTags from "../create-post/components/add-tags";
import { ThemeContext } from "../../context/ThemeContext";
import { capitalFirstLetter } from "../common-functions/FormatDate";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Cookies } from "react-cookie";
import { Avatar, Box, Button, Chip, TextField } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { v4 as uuidv4 } from "uuid";
import { backend_url } from "../../http-common";
import Dropdown from "../create-prediction/components/dropdown";
import LoadingButton from "@mui/lab/LoadingButton";
import ImgPlaceholder from "../.././assets/image_placeholder.png";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { uploadImage } from "../common-functions/Common";
import AddTagsChart from "./components/add-tags-chart";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

export default function CreateChart() {
  const { setShowAlertBox, setUserPosts, getPosts } = useContext(ThemeContext);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [open, setOpen] = useState(false);
  const [queryTitle, setQueryTitle] = useState("");
  const [queryQuestion, setQueryQuestion] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateImg = (e) => {
    const file = e.target.files[0];
    if (file.size >= 1048576) {
      return alert("Max file size is 1MB.");
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const createBotCommentOnQuery = async (post_unique_id, post_text) => {
    // let answer = await fetch(`https://news-chatbot-nl6iavcada-uc.a.run.app/`)
    const form = {
      comment_unique_id: uuidv4(),
      comment_text: post_text,
      comment_post_id: post_unique_id,
      comment_user_id: "bot",
      Comment_created_at: Date.now(),
      comment_type: 2,
      userDetails: {
        First_Name: "bot",
        last_name: "bot",
        username: "bot",
      },
    };

    const payloadjson = JSON.stringify(form);
    await fetch(`${backend_url}/add-comment/${post_unique_id}`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    });
  };

  const handleCreateQuery = async () => {
    let uuidforpost = uuidv4();
    let url;
    if (image) {
      url = await uploadImage(image);
    }
    const userD = {
      First_Name: `${user?.First_Name}`,
      Followers: `${user?.Followers}`,
      Followings: `${user?.Followings}`,
      last_name: `${user?.last_name}`,
      username: `${user?.username}`,
      userpic_url: `${user?.userpic_url}`,
      id: `${user?.id}`,
    };

    const form = {
      unique_id: uuidforpost,
      user: `${user?.username}`,
      Title: queryTitle,
      Image: url,
      Tag: selectedTags,
      query_question: queryQuestion,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
      Created_at: Date.now(),
      post_type: 3,
    };

    const newObj = {
      unique_id: uuidv4(),
      user: `${user?.username}`,
      Title: queryTitle,
      Image: url,
      Tag: selectedTags,
      query_question: queryQuestion,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
      Created_at: Date.now(),
      post_type: 3,
      userDetails: userD,
    };

    setUserPosts((d) => [newObj, ...d]);

    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/create-post`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log("response", response);
        const newStatus = {
          status: response.status,
          from: "Query",
          as: "Created",
        };
        getPosts();
        // setCreateLoading(false);
        setOpen(false);
        setQueryTitle("");
        setQueryQuestion("");
        setSelectedTags([]);
        // resetForm();
        setShowAlertBox(() => newStatus);
        // createBotCommentOnQuery(uuidforpost, queryQuestion)
      })
      .catch((err) => console.log(err));
  };

  const areFieldsEmpty = () => {
    if (queryTitle === "" || queryQuestion === "") {
      return true;
    }
    return false;
  };

  const handleDeleteImage = () => {
    setImagePreview(null);
  };

  const handleDelete = (tag) => {
    const index = selectedTags?.indexOf(tag);
    selectedTags?.splice(index, 1);
    setSelectedTags([...selectedTags]);
  };

  // console.log("selectedTags", selectedTags);

  return (
    <div className={`Chart ${theme ? "div_light" : "div_dark"}`}>
      <Button
        onClick={handleClickOpen}
        sx={{
          textTransform: "none",

          color: "#676767",
          "& .MuiButton-startIcon": { marginRight: "5px", color: "#e16745" },
        }}
        startIcon={<QuestionMarkIcon fontSize="small" />}
      >
        {t("Ask")}
      </Button>

      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: "16px",
                textAlign: "center",
                mt: 1,
                fontWeight: 700,
                color: !theme ? "white" : "black",
              }}
            >
              Raise Your Query
            </Typography>
            <Button onClick={handleClose}>
              <CloseIcon
                sx={{
                  color: !theme ? "white" : "black",
                  marginLeft: "50px",
                }}
              />
            </Button>
          </Box>
        </DialogTitle>
        <Divider color="black" />

        <Box
          sx={{
            display: "flex",
            pl: 1.5,
            ml: 1,
            mt: 2,
          }}
        >
          <Avatar
            alt="profile"
            style={{
              border: "0.1px solid white",
            }}
            src={user?.userpic_url || ImgPlaceholder}
            sx={{ width: 48, height: 48 }}
          />
          <Typography
            letterSpacing={0.4}
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#372B47",
              ml: 1.2,
              mt: 1,
            }}
          >
            {`${capitalFirstLetter(user?.First_Name)} ${capitalFirstLetter(
              user?.last_name
            )}`}
          </Typography>
          <Typography
            letterSpacing={0.4}
            sx={{
              color: "#6b7280",
              fontWeight: 500,
              fontSize: "12px",
              mt: 1.3,
              ml: 0.8,
            }}
          >
            {`@${user?.username}`}
          </Typography>
        </Box>

        <DialogContent
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <TextField
            variant="standard"
            id="outlined-multiline-flexible"
            maxRows={2}
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                fontSize: 13,
              },
            }}
            sx={{
              width: { xs: 200, md: 400 },
              "& .MuiTextField-root": {
                fontWeight: 600,
                fontSize: 10,
                color: "#372B47",
              },
            }}
            type="text"
            placeholder="Query Name (Required)"
            onChange={(e) => setQueryTitle(e.target.value)}
            value={queryTitle}
            required="true"
          />
          <br />
          <br />
          <TextField
            variant="standard"
            id="outlined-multiline-flexible"
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                fontSize: 13,
              },
            }}
            sx={{
              width: { xs: 300, md: 400 },
            }}
            placeholder="Query Description (Required)"
            oninvalid="this.setCustomValidity(' please Enter Your Query here')"
            oninput="this.setCustomValidity('')"
            value={queryQuestion}
            onChange={(e) => setQueryQuestion(e.target.value)}
          />
        </DialogContent>

        {imagePreview ? (
          <Box className="image_preview_div" sx={{ ml: 1.5 }}>
            <img
              src={imagePreview}
              className="image_preview"
              alt="image_preview"
            />
            <Button disableRipple onClick={handleDeleteImage}>
              <CloseIcon
                sx={{
                  color: !theme ? "white" : "black",
                  fontSize: "18px",
                  mt: -14,
                  ml: -5,
                }}
              />
            </Button>
          </Box>
        ) : (
          ""
        )}

        {selectedTags?.length > 0 && (
          <Box className="tagsDiv" sx={{ ml: 2.3, marginBottom: "10px" }}>
            {selectedTags?.map((tag, idx) => (
              <Chip
                key={`create-chart-${idx}`}
                label={tag}
                variant="outlined"
                onDelete={() => handleDelete(tag)}
              />
            ))}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            p: 2,
            ml: 0.5,
            mt: 2,
            justifyContent: "space-between",
            ":hover": {
              color: "#5D20D2",
            },
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <label htmlFor="image-upload">
              <Chip
                sx={{ fontSize: "14px" }}
                icon={
                  <ImageRoundedIcon
                    style={{
                      color: "var(--fundu_color)",
                      fontSize: "20px",
                    }}
                  />
                }
                label="Image"
                clickable
              />
            </label>

            <AddTagsChart
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </Box>

          <input
            type="file"
            id="image-upload"
            hidden
            accept="image/png, image/jpeg"
            onChange={(e) => validateImg(e)}
          />
          <Box sx={{ right: 0, mr: 0 }}>
            <LoadingButton
              disableShrink
              // loading={createLoading}
              onClick={handleCreateQuery}
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                border: "none !important",
              }}
              disabled={areFieldsEmpty()}
            >
              Post
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
