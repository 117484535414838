import { React, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import ImgPlaceholder from "../../../../assets/image_placeholder.png";
import { ThemeContext } from "../../../../context/ThemeContext";
import { capitalFirstLetter } from "../../../common-functions/FormatDate";
import Typography from "@mui/material/Typography";
import { Cookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import { backend_url } from "../../../../http-common";
import { Avatar, Box, Chip, TextField, Tooltip } from "@mui/material";
import Dropdown from "../../../create-prediction/components/dropdown";
import LoadingButton from "@mui/lab/LoadingButton";
import { TbCirclePlus } from "react-icons/tb";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { uploadImage, validateImg } from "../../../common-functions/Common";
import AddTagsChart from "../../../create-chart/components/add-tags-chart";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CreatePollPopup = ({ open, handleClose, setOpen }) => {
  const { setShowAlertBox, setUserPosts, getPosts } = useContext(ThemeContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([{ 0: "" }, { 1: "" }]);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  // const user = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(sessionStorage.getItem("user"));
  const cookie = new Cookies();
  const user = cookie.get("user");

  const handleAddOption = () => {
    let lastOption = options.length + 1;
    if (options.length < 4) {
      setOptions(() => [...options, { [lastOption]: "" }]);
    }
  };

  const validateImg = (e) => {
    const file = e.target.files[0];
    if (file.size >= 1048576) {
      return alert("Max file size is 1MB.");
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveOption = (id) => {
    let newOption = options.filter((item, idx) => id !== idx);
    setOptions(() => [...newOption]);
  };

  const handleChange = (e, idx) => {
    let update = options.map((item, index) => {
      if (index === idx) {
        return {
          [idx]: e.target.value,
        };
      } else {
        return item;
      }
    });
    setOptions(update);
  };

  const handleCreatePoll = async () => {
    let url;
    if (image) {
      url = await uploadImage(image);
    }
    const userD = {
      First_Name: `${user?.First_Name}`,
      Followers: `${user?.Followers}`,
      Followings: `${user?.Followings}`,
      last_name: `${user?.last_name}`,
      username: `${user?.username}`,
      userpic_url: `${user?.userpic_url}`,
      id: `${user?.id}`,
    };

    const form = {
      unique_id: uuidv4(),
      user: `${user?.username}`,
      // Title: title,
      poll_question: description,
      poll_options_text: options.map((item, idx) => item[idx]),
      poll_options_count: new Array(options.length).fill(0),
      Image: url,
      Tag: selectedTags,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
      Created_at: Date.now(),
      post_type: 2,
    };

    const newObj = {
      unique_id: uuidv4(),
      user: `${user?.username}`,
      // Title: title,
      poll_question: description,
      poll_options_text: options.map((item, idx) => item[idx]),
      poll_options_count: new Array(options.length).fill(0),
      Image: url,
      Tag: selectedTags,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
      Created_at: Date.now(),
      post_type: 2,
      userDetails: userD,
    };

    setUserPosts((d) => [newObj, ...d]);

    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/create-post`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const newStatus = {
          status: response.status,
          from: "Poll",
          as: "Created",
        };
        getPosts();
        // setCreateLoading(false);
        setOpen(false);
        // resetForm();
        setDescription("");
        setOptions([{ 0: "" }, { 1: "" }]);
        setImage(null);
        setImagePreview(null);
        setSelectedTags([]);
        setShowAlertBox(() => newStatus);
      })
      .catch((err) => console.log(err));
  };

  const optionsAreEmpty = () => {
    let empty = false;
    options.forEach((item) => {
      if (item[Object.keys(item)[0]] === "") {
        empty = true;
      }
    });
    return empty;
  };

  const areFieldsEmpty = () => {
    if (description === "" || optionsAreEmpty()) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteImage = () => {
    setImagePreview(null);
  };

  const handleDelete = (tag) => {
    const index = selectedTags?.indexOf(tag);
    selectedTags?.splice(index, 1);
    setSelectedTags([...selectedTags]);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: "18px",
                textAlign: "center",
                fontWeight: 700,
                color: !theme ? "white" : "black",
              }}
            >
              Create a Poll
            </Typography>

            <CloseIcon style={{ color: "black" }} onClick={handleClose} />
          </Box>
        </DialogTitle>
        <Divider color="black" />
        <Divider style={{ backgroundColor: "black" }} />

        <Box
          sx={{
            display: "flex",
            pl: 2,
            ml: 1,
            mt: 2,
          }}
        >
          <Avatar
            alt="profile"
            style={{
              border: "0.1px solid white",
            }}
            src={user?.userpic_url || ImgPlaceholder}
            sx={{ width: 48, height: 48 }}
          />
          <Typography
            letterSpacing={0.4}
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#372B47",
              ml: 1.2,
              mt: 1,
            }}
          >
            {`${capitalFirstLetter(user?.First_Name)} ${capitalFirstLetter(
              user?.last_name
            )}`}
          </Typography>
          {/* <Typography
            letterSpacing={0.4}
            sx={{
              color: "#6b7280",
              fontWeight: 500,
              fontSize: "12px",
              mt: 1.3,
              ml: 0.8,
            }}
          >
            {`@${user?.username}`}
          </Typography> */}
        </Box>
        <DialogContent
          component="form"
          sx={{
            "& .MuiTextField-root": { width: "50ch", pr: 2, pb: 2 },
            overflowX: "hidden",
            overflowY: "scroll",
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            msOverflowStyle: "none", // Hide scrollbar in Internet Explorer
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            variant="standard"
            id="outlined-multiline-flexible"
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                fontSize: 13,
              },
            }}
            type="text"
            name="description"
            placeholder="What's on your mind (Required)"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </DialogContent>

        <form>
          {options.map((item, idx) => (
            <div style={{ display: "flex" }} key={`post-create-form-${idx}`}>
              <input
                type="text"
                id={idx}
                placeholder={`Option ${idx + 1}`}
                style={{
                  width: "85.5%",
                  height: "40px",
                  backgroundColor: theme ? "#F7F6F9" : "#242631",
                  border: "#F7F6F9",
                  borderRadius: "5px",
                  marginBottom: "20px",
                  marginLeft: "22px",
                  fontSize: "12px",
                  padding: "10px",
                }}
                onChange={(e) => handleChange(e, idx)}
                value={item[idx]}
                required
              />

              {(idx === 2 || idx === 3) && (
                <Button
                  onClick={() => handleRemoveOption(idx)}
                  style={{
                    marginBottom: "18px",
                    backgroundColor: theme ? "white" : "#313342",
                  }}
                >
                  <CloseIcon
                    style={{
                      color: !theme ? "white" : "black",
                    }}
                  />
                </Button>
              )}
            </div>
          ))}
          <Box
            sx={{
              display: "flex",
              p: 2,
              ml: 0.5,

              justifyContent: "space-between",
              ":hover": {
                color: "#5D20D2",
              },
            }}
          >
            <Typography letterSpacing={0.4} fontSize={12}>
              *You need to have at least 2 non empty options
            </Typography>
            <TbCirclePlus
              onClick={handleAddOption}
              className="link_trade"
              style={
                options.length === 4
                  ? {
                      backgroundColor: theme ? "white" : "#313342",
                      marginLeft: "22px",
                      display: "none",
                      color: !theme ? "#9772FF" : "var(--fundu_color)",
                    }
                  : {
                      marginLeft: "22px",
                      display: "block",
                      fontWeight: "900",
                      color: !theme ? "#9772FF" : "var(--fundu_color)",
                      backgroundColor: theme ? "white" : "#313342",
                    }
              }
            />
          </Box>

          {imagePreview ? (
            <Box className="image_preview_div" sx={{ ml: 1.5 }}>
              <img
                src={imagePreview}
                className="image_preview"
                alt="image_preview"
              />
              <Button disableRipple onClick={handleDeleteImage}>
                <CloseIcon
                  sx={{
                    color: !theme ? "white" : "black",
                    fontSize: "18px",
                    mt: -14,
                    ml: -5,
                  }}
                />
              </Button>
            </Box>
          ) : (
            ""
          )}

          {selectedTags?.length > 0 && (
            <Box className="tagsDiv" sx={{ ml: 2.3, marginBottom: "10px" }}>
              {selectedTags?.map((tag, idx) => (
                <Chip
                  label={tag}
                  variant="outlined"
                  onDelete={() => handleDelete(tag)}
                  key={`create-poll-popup-chip-${idx}`}
                />
              ))}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              p: 2,
              ml: 0.5,
              mt: 2,
              justifyContent: "space-between",
              ":hover": {
                color: "#5D20D2",
              },
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <label htmlFor="image-upload">
                <Chip
                  sx={{ fontSize: "14px" }}
                  icon={
                    <ImageRoundedIcon
                      style={{
                        color: "var(--fundu_color)",

                        fontSize: "20px",
                      }}
                    />
                  }
                  label="Image"
                  clickable
                />
              </label>

              <AddTagsChart
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
              />
            </Box>

            <input
              type="file"
              id="image-upload"
              hidden
              accept="image/png, image/jpeg"
              onChange={(e) => validateImg(e)}
            />

            <Box sx={{ right: 0, mr: 0 }}>
              <LoadingButton
                disableShrink
                // loading={createLoading}
                variant="contained"
                onClick={handleCreatePoll}
                sx={{
                  textTransform: "none",
                  borderRadius: "16px",
                  border: "none !important",
                }}
                disabled={areFieldsEmpty()}
              >
                Post
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default CreatePollPopup;
