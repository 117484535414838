import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Cookies } from "react-cookie";

import { backend_url } from "../../http-common";
import { FaRegFilePdf } from "react-icons/fa";

const Corporation = ({ symbol }) => {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [data, setData] = useState([]);

  const fetchStock = () => {
    fetch(`${backend_url}/get-stocks-fundamental/${symbol}`)
      .then((res) => res.json())
      .then((d) => {
        setData(() => [d.stockFundamentals]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchStock();
  }, [symbol]);

  return (
    <div className="watchlist">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                Stock
              </TableCell>
              <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                Sector
              </TableCell>
              <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>
                Details
              </TableCell>
              <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>Date</TableCell>
              <TableCell sx={{ fontSize: 12, fontWeight: 600 }}>PDF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((stock, idx) => (
              <TableRow key={idx}>
                <TableCell>{stock.SYMBOLS}</TableCell>
                <TableCell>{stock.Sector}</TableCell>
                {stock.announcement_data?.map((announcement, index) => (
                  <React.Fragment key={index}>
                    <TableCell>{announcement?.announcement_desc}</TableCell>
                    <TableCell>{announcement?.announcement_date}</TableCell>
                    <TableCell>
                      <a href={announcement?.announcement_link} target="_blank">
                        <FaRegFilePdf style={{ fontSize: 20 }} />
                      </a>
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Corporation;
