import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { Button, Divider, Typography } from "@mui/material";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";
import { getAuthenticatedRequest, getRequest } from "../../../services/request";
import { CometChat } from "@cometchat-pro/chat";
import { CometChatUI } from "../../../cometchat-pro-react-ui-kit/CometChatWorkspace/src";
import CloseIcon from "@mui/icons-material/Close";
import useMakeCometChatProfile from "../../rooms-new/hooks/useMakeCometChatProfile";
import FuLoader from "../../shared/FuLoader";
import {
  convertTimeTo12HourFormat,
  hasTimePassed,
  isFifteenMinutesAway,
} from "../../common-functions/FormatDate";
import { useTranslation } from "react-i18next";

export const MessageMobilePage = ({ handleclose }) => {
  const [translate, setTranslate] = useState(false);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [isUserExpert, setIsUserExpert] = useState(false);
  const [isCometLoggedIn, setIsCometLoggedIn] = useState(false);
  const [chatWithcometChatUser, setChatWithCometChatUser] =
    useState("imgovind24");
  const [connectingTochat, setConnectingToChat] = useState(false);
  const { loginCommetChatUser, logoutCommetChatUser } =
    useMakeCometChatProfile();
  const { t } = useTranslation();

  const cookie = new Cookies();
  const user = cookie.get("user");
  const updateTranslate = () => {
    setTranslate(!translate);
  };

  const loginForCometChat = (expert_username) => {
    setConnectingToChat(true);
    loginCommetChatUser(user)
      .then((res) => {
        console.log(res, "cometchat user loggedin");
        setIsCometLoggedIn(true);
        setConnectingToChat(false);
        setChatWithCometChatUser(expert_username);
      })
      .catch((e) => {
        setIsCometLoggedIn(false);
        setConnectingToChat(false);
      });
  };

  const logoutForCometChat = () => {
    logoutCommetChatUser().then((res) => {
      console.log("logout from cometchat", res);
      setChatWithCometChatUser(null);
    });
    setIsCometLoggedIn(false);
  };

  const getBookedSlotsByUser = async () => {
    try {
      let currUser = await getRequest({
        url: `/get-user-by-name/${user.username}`,
      });
      if (!currUser?.data?.response[0]?.isExpert) {
        setIsUserExpert(false);
        let response = await getAuthenticatedRequest({
          url: `/experts/get-booked-slot-by-username/${user.username}`,
        });
        setBookedSlots(response?.data?.slots);
        // console.log("data completed", response);
      } else {
        setIsUserExpert(true);
        let response = await getAuthenticatedRequest({
          url: `/experts/get-expert-slot-by-username/${user.username}`,
        });
        setBookedSlots(response?.data?.slots);
      }
    } catch (e) {
      setBookedSlots([]);
    }
  };

  useEffect(() => {
    getBookedSlotsByUser();
  }, []);

  return (
    <Box
      sx={{
        height: "calc(90vh)",
        zIndex: 10000000000,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        // width: "100vw",
        width: "100%",
        // ml: 2,
        mt: 2,
        mr: 2,
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        border: "1px solid #9C9B9E",
        boxShadow: "0px 4px 4px #777E90",
        borderRadius: "8px 8px 0px 0px",
        animation: "fade-in 167ms cubic-bezier(.4,0,1,1)",
        overflowY: "scroll",
        scrollbarWidth: "none", // Hide scrollbar in Firefox
        msOverflowStyle: "none", // Hide scrollbar in Internet Explorers
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box
        display="flex"
        p={1.5}
        gap={1}
        top={0}
        justifyContent={"space-between"}
      >
        <Box display="flex" gap={2}>
          <Avatar
            src={user?.userpic_url}
            sx={{
              width: 40,
              height: 40,
              // border: "1px solid #D8D1E3"
            }}
          />
          <Typography
            letterSpacing={0.4}
            sx={{ fontWeight: 600, mt: 1, fontSize: 14, color: "#372B47" }}
          >
            {t("Appointment")}
          </Typography>
        </Box>

        <IconButton aria-label="settings" onClick={handleclose}>
          <CloseIcon
            sx={{
              color: "black",
              fontSize: 20,
            }}
          />
        </IconButton>
      </Box>

      <Divider color={"black"} />
      {isCometLoggedIn && (
        <Typography
          letterSpacing={0.4}
          onClick={logoutForCometChat}
          sx={{ width: "100%" }}
        >
          <CloseIcon
            sx={{
              color: "black",
              fontSize: 16,
              ml: 46,
            }}
          />
        </Typography>
      )}

      {connectingTochat ? (
        <FuLoader />
      ) : isCometLoggedIn ? (
        <Box sx={{ height: "100vh", width: "100wh", textAlign: "center" }}>
          <CometChatUI chatWithUser={chatWithcometChatUser} />
        </Box>
      ) : (
        <Box sx={{ height: "70vh", width: "100wh", textAlign: "center" }}>
          {bookedSlots?.map((item) => (
            <Box
              key={item._id}
              sx={{
                m: 2,
              }}
            >
              {item?.booked_slots
                ?.filter(
                  (slotT) =>
                    !hasTimePassed(
                      isUserExpert ? item?.booking_day : item?.booked_date,
                      slotT.timing?.to.join(":")
                    )
                )
                .map((slot) => (
                  <Box
                    key={slot._id}
                    sx={{
                      background: "white",
                      p: 2,
                      mb: 2,
                      border: "3px solid transparent",
                      borderRadius: "8px", // Update to specify the correct value for border radius
                      borderImage:
                        "linear-gradient(45deg, rgba(93, 32, 210, 1), rgba(216, 19, 233, 1)) 1",
                      borderImageSlice: 1,
                    }}
                  >
                    <Typography
                      letterSpacing={0.4}
                      fontSize={14}
                      fontWeight={600}
                      display={"flex"}
                    >
                      {isUserExpert ? "Booked: " : "Expert: "}{" "}
                      <Link
                        to={`/user/${
                          isUserExpert
                            ? slot?.booked_by_user
                            : slot.expert_username
                        }`}
                        style={{
                          textDecoration: "none",
                          display: "flex",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 500,
                            marginLeft: "10px",
                            color: "var(--fundu_color)",
                          }}
                        >
                          {isUserExpert
                            ? slot?.booked_by_user
                            : slot.expert_username}
                        </p>
                      </Link>
                    </Typography>
                    <Typography
                      letterSpacing={0.4}
                      fontSize={14}
                      fontWeight={600}
                      display={"flex"}
                    >
                      Timings:{" "}
                      <p style={{ fontWeight: 500, marginLeft: "10px" }}>
                        {convertTimeTo12HourFormat(slot.timing?.from.join(":"))}{" "}
                        - {convertTimeTo12HourFormat(slot.timing?.to.join(":"))}
                        {/* {slot.timing?.from.join(":")} -{" "}
                        {slot.timing?.to.join(":")} */}
                      </p>
                    </Typography>
                    <Typography
                      letterSpacing={0.4}
                      fontSize={14}
                      fontWeight={600}
                      display={"flex"}
                    >
                      Booked Date:{" "}
                      <p style={{ fontWeight: 500, marginLeft: "10px" }}>
                        {isUserExpert ? item.booking_day : item?.booked_date}
                      </p>
                    </Typography>

                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#5D20D2",
                          color: "white",
                          fontSize: 12,
                          textTransform: "none",
                          pl: 2,
                          pr: 2,
                        }}
                        disabled={
                          !isFifteenMinutesAway(
                            `${
                              isUserExpert
                                ? item?.booking_day
                                : item?.booked_date
                            } ${slot.timing?.from.join(":")}`
                          )
                        }
                        onClick={() =>
                          loginForCometChat(
                            isUserExpert
                              ? slot?.booked_by_user
                              : slot.expert_username
                          )
                        }
                      >
                        Join
                      </Button>
                    </Box>
                  </Box>
                ))}
            </Box>
          ))}

          {bookedSlots.length === 0 && (
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "var(--fundu_color)",
                textAlign: "center",
                mt: 23,
                p: 3,
              }}
            >
              Appointment will be available soon
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
export default MessageMobilePage;
