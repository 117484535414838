import { CometChat } from "@cometchat-pro/chat";
import {
  COMET_CHAT_APP_ID,
  COMET_CHAT_REGION,
  COMET_CHAT_REST_KEY,
} from "./services/constants";

export const COMET_CHAT_ERROR_CODE = {
  AUTH_ERR_EMPTY_AUTH_TOKEN: "AUTH_ERR_EMPTY_AUTH_TOKEN",
  ERR_UID_NOT_FOUND: "ERR_UID_NOT_FOUND",
};

export default function cometchatInit() {
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(COMET_CHAT_REGION)
    .build();
  CometChat.init(COMET_CHAT_APP_ID, appSetting).then(
    () => {
      console.log("Initialization completed successfully");
      // You can now call login function.
    },
    (error) => {
      console.log("Initialization failed with error:", error);
      // Check the reason for error and take appropriate action.
    }
  );
}

// export async function cometchatLoginCheck(username) {
//   try {
//     await CometChat.getUser(username);
//     return true;
//   } catch (e) {
//     return COMET_CHAT_ERROR_CODE[e.code] || false;
//   }
// }

// export async function createCommetChatUser(usertomake) {
//   let uid = usertomake?.username;
//   let name = usertomake?.First_Name + " " + usertomake?.last_name;
//   let avtar = usertomake?.userpic_url;

//   let user = new CometChat.User(uid);
//   try {
//     user.setName(name);
//     user.setAvatar(avtar);
//     await CometChat.createUser(user, COMET_CHAT_AUTH_KEY);
//     return true;
//   } catch (error) {
//     return false;
//   }
// }

// export async function loginCommetChatUser(usertomake) {
//   let uid = usertomake?.username;

//   try {
//     await CometChat.login(uid, COMET_CHAT_AUTH_KEY);
//     return true;
//   } catch (e) {
//     return COMET_CHAT_ERROR_CODE[e.code] || false;
//   }
// }

export const cometchatusernameCheck = (username) => {
  if (username.includes(".")) {
    const [f, s] = username.split(".");
    const num = f.length + s.length;
    username = f + "_" + num + "_" + s;
  }
  return username;
};

export async function checkCometchatUserExists(username) {
  username = cometchatusernameCheck(username);
  try {
    let data = await fetch(
      `https://${COMET_CHAT_APP_ID}.api-${COMET_CHAT_REGION}.cometchat.io/v3/users/${username}`,
      {
        headers: {
          "Content-Type": "application/json",
          apikey: COMET_CHAT_REST_KEY,
        },
      }
    );
    data = await data.json();
    if (data?.error) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}
