import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  capitalFirstLetter,
  checkFollow,
} from "../common-functions/FormatDate";
import copy from "copy-to-clipboard";
import { ThemeContext } from "../../context/ThemeContext";
import { backend_url } from "../../http-common";
import PostBefore from "../profile-page/PostBefore";
import Chip from "@mui/material/Chip";
import FollowerPopup from "../follower-following-popup";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import { styled } from "@mui/material/styles";
import { Cookies } from "react-cookie";
import { Avatar, Box, Button, Card, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BsCheck } from "react-icons/bs";
import AboutEdit from "../rooms-new/Rooms_Profile_Details/profile_tabs_mobile";
import About from "./About";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
}));
const UserDetails = ({ handleActiveFollow, handleActiveFollowing }) => {
  const {
    theme,
    fetchMyFollowings,
    myFollowings,
    fetchFollowers,
    userFollowersCount,
    handleFollow,
  } = useContext(ThemeContext);
  const { username } = useParams();
  const { stocksData } = React.useContext(StockNewsContext);

  // console.log(stocksData, "stocksssssss");
  const [data, setData] = useState([]);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = () => {
      fetch(`${backend_url}/get-user-by-name/${username}`)
        .then((res) => res.json())
        .then((d) => {
          setData(() => d.response);
          fetchFollowers({ username });
        })
        .catch((err) => console.log(err));
    };
    fetchUser();
  }, [username]);
  useEffect(() => {
    fetchMyFollowings();
  }, []);

  const location = useLocation();
  const path = location.pathname;

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        border: "1px solid #e0e0e0",
      }}
    >
      {data?.length === 0 ? (
        <PostBefore />
      ) : (
        data?.map((item, idx) => (
          <Card
            sx={{
              boxShadow: "none",
            }}
          >
            <Box sx={{ height: 100, p: 1, backgroundColor: "black" }}>
              <Chip
                label="Pro"
                onClick={handleClick}
                sx={{
                  color: "white",
                  p: 0.5,
                  height: "20px",
                  fontSize: "12px",
                  backgroundColor: "#5D20D2",
                }}
              />
              <br />
              <Avatar
                src={
                  item.userpic_url ||
                  "https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&w=600"
                }
                alt="profile_pic"
                sx={{
                  width: 100,
                  height: 100,
                  border: "1px solid #D8D1E3",
                  mt: 0.2,
                  ml: 3,
                }}
              />
            </Box>
            <div
              style={{
                width: "100%",
                backgroundColor: "#b8b7bb",
                height: "1px",
              }}
            ></div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 5,
                pl: 2,
              }}
            >
              <Box>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#372B47",
                  }}
                >
                  {`${capitalFirstLetter(item.First_Name)} ${capitalFirstLetter(
                    item.last_name
                  )}`}
                </Typography>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 12,
                  }}
                >
                  {/* <q>The best option is to invest.</q> */}
                  <About />
                </Typography>
              </Box>
              <Box sx={{ display: "grid" }}>
                <Box mt={-4} mr={2}>
                  {user && user.username !== item.username && (
                    <Button
                      sx={{
                        backgroundColor: "var(--fundu_color)",
                        fontSize: "12px",
                        color: "white",
                        marginLeft: 3,
                        borderRadius: "29px",
                        textTransform: "none",

                        ":hover": {
                          backgroundColor: "white",
                          color: "#5D20D2",
                          border: " 1px solid var(--fundu_color)",
                        },
                      }}
                      style={
                        user?.username === item?.username
                          ? { visibility: "hidden" }
                          : { display: "block" }
                      }
                      onClick={() => handleFollow(idx, item.username)}
                    >
                      {checkFollow(myFollowings, item.username) ? (
                        <BsCheck fontSize={22} />
                      ) : (
                        t("Follow")
                      )}
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    fontWeight: "600",
                  }}
                >
                  <button
                    style={{
                      color: "#5D20D2",
                      border: "none",
                      backgroundColor: !theme ? "#313342" : "white",
                      fontWeight: "600",
                      height: "20px",
                      marginTop: "5px",
                    }}
                  >
                    {userFollowersCount || 0}
                  </button>
                  <p style={{ marginLeft: "-6px", marginTop: "-0.5px" }}>
                    <FollowerPopup />
                  </p>
                </Box>
              </Box>
            </Box>

            <Box>
              <Grid container spacing={2} columns={12} sx={{ p: 2 }}>
                <Grid item xs={4}>
                  <Item
                    sx={{
                      border: "1px solid #e0e0e0",
                      boxShadow: "none",
                      pt: 1,
                    }}
                  >
                    <Grid container spacing={0.2} columns={12} mb={2}>
                      <Grid item xs={4}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.9974 10.8141L23.0807 13.2974L21.9974 8.61406L25.6641 5.48073L20.8474 5.06406L18.9974 0.664062L17.1141 5.06406L12.3307 5.48073L15.9641 8.61406L14.8307 13.2974L18.9974 10.8141ZM23.9974 37.3307H13.9974V15.6641H23.9974V37.3307ZM0.664062 27.3307V37.3307H10.6641V27.3307H0.664062ZM7.33073 33.9974H3.9974V30.6641H7.33073V33.9974ZM27.3307 20.6641V37.3307H37.3307V20.6641H27.3307ZM33.9974 33.9974H30.6641V23.9974H33.9974V33.9974Z"
                            fill="#FFC700"
                          />
                        </svg>
                      </Grid>
                      <Grid item xs={3} sx={{ fontWeight: 600 }}>
                        35
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{ fontSize: 14, mr: 1, fontWeight: 600 }}
                      >
                        {t("Gold")}
                      </Grid>
                    </Grid>
                    {stocksData.slice(0, 3).map((stock, idx) => (
                      <Grid container spacing={3} columns={12}>
                        <Grid item xs={6}>
                          <Typography
                            letterSpacing={0.4}
                            sx={{
                              fontSize: 12,
                              fontWeight: 600,
                              color: "#5d20d2",
                            }}
                          >
                            {stock.SYMBOLS}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography letterSpacing={0.4} sx={{ fontSize: 10 }}>
                            09/11/22
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item
                    sx={{
                      border: "1px solid #e0e0e0",
                      boxShadow: "none",
                      pt: 1,
                    }}
                  >
                    <Grid container spacing={0.2} columns={12} mb={2}>
                      <Grid item xs={4}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.9974 10.8141L23.0807 13.2974L21.9974 8.61406L25.6641 5.48073L20.8474 5.06406L18.9974 0.664062L17.1141 5.06406L12.3307 5.48073L15.9641 8.61406L14.8307 13.2974L18.9974 10.8141ZM23.9974 37.3307H13.9974V15.6641H23.9974V37.3307ZM0.664062 27.3307V37.3307H10.6641V27.3307H0.664062ZM7.33073 33.9974H3.9974V30.6641H7.33073V33.9974ZM27.3307 20.6641V37.3307H37.3307V20.6641H27.3307ZM33.9974 33.9974H30.6641V23.9974H33.9974V33.9974Z"
                            fill="#AAAAAA"
                          />
                        </svg>
                      </Grid>
                      <Grid item xs={3} sx={{ fontWeight: 600 }}>
                        23
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{ fontSize: 14, mr: 1, fontWeight: 600 }}
                      >
                        {t("Silver")}
                      </Grid>
                    </Grid>
                    {stocksData.slice(3, 6).map((stock, idx) => (
                      <Grid container spacing={3} columns={12}>
                        <Grid item xs={6}>
                          <Typography
                            letterSpacing={0.4}
                            sx={{
                              fontSize: 12,
                              fontWeight: 600,
                              color: "#5d20d2",
                            }}
                          >
                            {" "}
                            {stock.SYMBOLS}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography letterSpacing={0.4} sx={{ fontSize: 10 }}>
                            09/11/22
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item
                    sx={{
                      border: "1px solid #e0e0e0",
                      boxShadow: "none",
                      pt: 1,
                    }}
                  >
                    <Grid container spacing={0.2} columns={12} mb={2}>
                      <Grid item xs={4}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.9974 10.8141L23.0807 13.2974L21.9974 8.61406L25.6641 5.48073L20.8474 5.06406L18.9974 0.664062L17.1141 5.06406L12.3307 5.48073L15.9641 8.61406L14.8307 13.2974L18.9974 10.8141ZM23.9974 37.3307H13.9974V15.6641H23.9974V37.3307ZM0.664062 27.3307V37.3307H10.6641V27.3307H0.664062ZM7.33073 33.9974H3.9974V30.6641H7.33073V33.9974ZM27.3307 20.6641V37.3307H37.3307V20.6641H27.3307ZM33.9974 33.9974H30.6641V23.9974H33.9974V33.9974Z"
                            fill="#FF9A62"
                          />
                        </svg>
                      </Grid>
                      <Grid item xs={3} sx={{ fontWeight: 600 }}>
                        35
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{ fontSize: 14, mr: 1, fontWeight: 600 }}
                      >
                        {t("Bronze")}
                      </Grid>
                    </Grid>
                    {stocksData.slice(5, 8).map((stock, idx) => (
                      <Grid container spacing={1} columns={12}>
                        <Grid item xs={5} ml={1}>
                          <Typography
                            letterSpacing={0.4}
                            sx={{
                              fontSize: 12,
                              fontWeight: 600,
                              color: "#5d20d2",
                            }}
                          >
                            {" "}
                            {stock.SYMBOLS}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography letterSpacing={0.4} sx={{ fontSize: 10 }}>
                            09/11/22
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Card>
        ))
      )}
    </Card>
  );
};

export default UserDetails;
