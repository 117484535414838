import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../../assets/askfunduLogo.png";

const CopyrightPolicy = () => {
  return (
    <div className="Privacy_div">
      <div className="privacy_first_div">
        <img
          style={{
            height: "80px",
            width: "250px",

            marginBottom: "-20px",
          }}
          src={logo}
          alt="logo"
        />
        <h1>Refund Policy</h1>
        <div className="privacy_subhead">Updated 12/05/2023</div>
      </div>
      <div className="Privacy_second_div">
        <h1>Copyright Policy</h1>
        <br />
        <p>
          At AskFundu, we strive to provide a seamless and satisfying experience
          for our users. We understand that circumstances may arise where a
          session booked with an expert cannot take place as scheduled. In such
          cases, we have implemented a refund policy to ensure that our users
          are protected.
        </p>
        <br />
        <br />
        <h1>Refund Eligibility:</h1>
        <p>
          Users who have booked a session with an expert on AskFundu and
          experience a situation where the expert does not join the session are
          eligible for a refund.
        </p>
        <br />
        <h1>Refund Process:</h1>

        <p> To request a refund, users must follow these steps:</p>
        <p>
          1. Send an email to info@askfundu.com within 48 hours of the scheduled
          session.
        </p>
        <p>2. Include the following details in the email:</p>
        <ul>
          <li>User's name</li>
          <li>Session booking details (date, time, and expert's name)</li>
          <li>Reason for the refund request</li>
        </ul>
        <p>
          3. Our customer support team will review the refund request and
          respond within a reasonable timeframe.
        </p>
        <h1>Refund Approval:</h1>
        <p>
          Refunds for eligible cases will be approved at the discretion of
          AskFundu. Factors such as the reason for the expert's absence and any
          mitigating circumstances will be taken into consideration.
        </p>
        <br />
        <h1>Refund Processing:</h1>
        <p>
          If the refund request is approved, the refund will be processed using
          the original payment method used for the session booking. Please note
          that the processing time may vary depending on the payment provider
          and it may take up to 15 business days for the refund to reflect in
          the user's account.
        </p>
        <br />
        <h1>Exceptions:</h1>

        <p>
          Please note that the refund policy applies specifically to situations
          where the expert does not join the scheduled session. It does not
          cover cases where the user cancels or reschedules the session.
        </p>

        <br />
        <h1>Contact Information:</h1>
        <p>
          If you have any questions or need further assistance regarding the
          refund policy, please reach out to our customer support team at
          info@askfundu.com. We are here to help and provide the necessary
          support.
        </p>
        <br />
        <h1>Disclaimer:</h1>
        <p>
          AskFundu reserves the right to modify or update the refund policy at
          any time without prior notice. Users are encouraged to review the
          refund policy periodically for any changes.
        </p>
      </div>
    </div>
  );
};
export default CopyrightPolicy;
