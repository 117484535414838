import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

export const MobileDefaultNewsCard = ({ news }) => {
  return (
    <Card
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        border: "none",
        pr: 1,
        mt: 1,
      }}
    >
      <ul>
        <li>
          <Typography fontSize={14}>{news["Headline"]}</Typography>
        </li>
      </ul>
    </Card>
  );
};
export default MobileDefaultNewsCard;
