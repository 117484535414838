import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../../context/ThemeContext";
import MobileTabsDropdown from "../../mobile-tabs-dropdown";
import { BREAKPOINT } from "../../../constants/constants";
import useViewPort from "../../../hooks/useViewPort";
import useFilterStocksTabs from "../hooks/useFilterStocksTabs";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "black",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FilterStocks = () => {
  const [value, setValue] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { width } = useViewPort();
  const { tabs } = useFilterStocksTabs();

  return (
    <Box
      sx={{
        mt: 1,
        mb: 2,
        height: 49,
        border: "1px solid #e0e0e0",
        borderRadius: "4px",
      }}
    >
      {width < BREAKPOINT ? (
        <MobileTabsDropdown tabs={tabs} />
      ) : (
        <Box
          sx={{ width: "100%", mt: 2 }}
          className={theme ? "rightPanel_mainDiv" : "rightPanel_theme_change"}
        >
          <Box sx={{ border: "none" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {tabs?.map((tab, index) => {
                return (
                  <Tab
                    key={`filter-stocks-tabs-${index}`}
                    label={tab?.name}
                    sx={{ textTransform: "none", fontSize: "16px" }}
                    {...a11yProps(index)}
                    className={
                      theme
                        ? "tab_different_styles"
                        : "tab_different_styles_theme"
                    }
                  />
                );
              })}
            </Tabs>
          </Box>
          {tabs?.map((tab, index) => {
            return (
              <TabPanel
                key={`filter-stocks-tabs2-${index}`}
                value={value}
                index={index}
                className="tabPanel_mostbought"
                style={{ marginTop: "20px" }}
              >
                {tab.component}
                <br />
                <br />
              </TabPanel>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default FilterStocks;
