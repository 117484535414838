import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { ThemeContext } from "../context/ThemeContext";
import { Cookies } from "react-cookie";
import { doRequest, getRequest } from "../services/request";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const StockNewsContext = createContext();

export const StockNewsContextProvider = ({ children }) => {
  const [selectedTagsNews, setSelectedTagsNews] = useState("");
  const [selectedTagsStocks, setSelectedTagsStocks] = useState("");
  const [stocksData, setStocksData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [searchedPostsData, setSearchedPostsData] = useState([]);
  const [indicesdata, setIndicesData] = useState([]);
  const [portfolioStocksData, setFortfolioStocksData] = useState([]);
  const [userPortfolioInvestment, setUserPortfolioInvestment] = useState([]);

  const [openConfirmationBox, setOpenConfirmationBox] = useState({
    status: false,
    text: "",
    fnc: "",
    id: "",
    from: "",
  });

  const [addedStocks, setAddedStocks] = useState([]);
  const { isTop, isRecent, filterValue } = useContext(ThemeContext);
  const [savedPosts, setSavedPosts] = useState([]);
  const [savedNews, setSavedNews] = useState([]);
  const [predictionGameData, setPredictionGameData] = useState({});
  const [mobileNewsNumber, setMobileNewsNumber] = useState(0);
  const [isUserPredicted, setIsUserPredicted] = useState(false);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { username } = useParams();
  const { i18n } = useTranslation();

  const getNews = useCallback(
    async (
      setLastList,
      setPrevPage,
      currPage,
      setLoading,
      setScroll,
      scroll,
      shouldChangeOnMobile = false
    ) => {
      if (shouldChangeOnMobile) {
        scroll = true;
      }
      let url = `/get-all-news?pageSize=10&pageNo=${currPage || 1}&lan=${
        i18n.language
      }`;
      const response = await getRequest({
        url: url,
      });

      if (!response.data?.news.length) {
        setLastList(true);
        return;
      }

      if (scroll) {
        if (shouldChangeOnMobile) {
          setNewsData((d) => [...d, ...response.data?.news]);
        } else {
          currPage && setPrevPage(currPage);
          setNewsData((d) => [...d, ...response.data?.news]);
          currPage && setLoading(false);
          currPage && setScroll(false);
        }
      } else {
        setNewsData(() => [...response.data?.news]);
      }
    },
    [isRecent, i18n.language]
  );

  useEffect(() => {
    getNews();
  }, [getNews, isRecent, i18n.language, user && user.username]);

  const getStocks = async (
    setLastList,
    setPrevPage,
    currPage,
    setLoading,
    setScroll
  ) => {
    const response = await getRequest({
      url: `/stocks/get-all-stocks?pageSize=20&pageNo=${currPage || 1}`,
    });

    if (!response.data?.stocks.length) {
      setLastList(true);
      return;
    }
    currPage && setPrevPage(currPage);
    setStocksData([...stocksData, ...response.data?.stocks]);
    currPage && setLoading(false);
    currPage && setScroll(false);
  };

  useEffect(() => {
    const fetchStocksData = async () => {
      await getStocks();
    };

    const timeoutId = setTimeout(() => {
      fetchStocksData();
    }, 2000);
    const intervalId = setInterval(() => {
      fetchStocksData();
    }, 120000);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  const getWishlist = async (loginUser) => {
    console.log(loginUser);
    const response = await getRequest({
      url: `/get-wishlist/${loginUser}`,
    });
    setAddedStocks(() => response.data?.wishlist || []);
  };

  const getSavedItems = async () => {
    if (!user) return;
    if (!user.username) {
      return;
    }
  };

  const getSavedPosts = async () => {
    if (!user) return;
    if (!user.username) {
      return;
    }
    const response = await getRequest({
      url: `/users/get-saved-posts/${user.username}`,
    });
    setSavedPosts(response.data?.savedItems[0]?.posts);
  };

  const removeSavedPost = async (post_id) => {
    if (!user) return;
    if (!user.username) {
      return;
    }
    let payload = JSON.stringify({
      post_id: post_id,
    });
    await doRequest({
      url: `/users/remove-saved-posts`,
      method: "POST",
      body: payload,
    });
    getSavedPosts();
  };

  const addSavedPost = async (post_id) => {
    if (!user) return;
    if (!user.username) {
      return;
    }
    let payload = JSON.stringify({
      post_id: post_id,
    });
    await doRequest({
      url: `/users/create-saved-posts/${user.username}`,
      method: "POST",
      body: payload,
    });
    getSavedPosts();
  };

  // saved news
  const getSavedNews = async () => {
    if (!user) return;
    if (!user.username) {
      return;
    }
    const response = await getRequest({
      url: `/users/get-saved-news/${user.username}`,
    });
    setSavedNews(response.data?.savedItems[0]?.news);
  };

  const addSavedNews = async (news_id) => {
    if (!user) return;
    if (!user.username) {
      return;
    }
    let payload = JSON.stringify({
      news_id: news_id,
    });
    await doRequest({
      url: `/users/create-saved-news/${user.username}`,
      method: "POST",
      body: payload,
    });
    getSavedNews();
  };

  const removeSavedNews = async (news_id) => {
    if (!user) return;
    if (!user.username) {
      return;
    }
    let payload = JSON.stringify({
      news_id: news_id,
    });
    await doRequest({
      url: `/users//remove-saved-news`,
      method: "POST",
      body: payload,
    });
    getSavedNews();
  };

  useEffect(() => {
    getWishlist();
    getSavedItems();
    getSavedPosts();
    getSavedNews();
  }, [user && user?.username]);

  const filteredStocksByTags = stocksData?.filter((el) => {
    if (selectedTagsStocks === "") {
      return el;
    } else {
      return el.Sector === selectedTagsStocks;
    }
  });

  const filteredNewsByTags = newsData?.filter((el) => {
    if (selectedTagsNews === "") {
      return el;
    } else {
      return el.Category_Label === selectedTagsNews;
    }
  });

  // getting searched posts here
  const fetchsearchPosts = async (inputText) => {
    if (inputText) {
      const response = await getRequest({
        url: `/get-searched-posts?search=${inputText}`,
      });
      setSearchedPostsData(response.data?.searchedPosts);
    }
  };

  useEffect(() => {
    function fetchData() {
      getRequest({ url: `/stocks/get-all-indices-data/` })
        .then((res) => {
          setIndicesData(res?.data.stocks);
        })
        .catch((e) => {
          setIndicesData([]);
        });
    }
    fetchData();
  }, []);

  const fetchPredictionGameData = async () => {
    try {
      let data = await getRequest({ url: "/game/game-data" });
      setPredictionGameData(data?.data?.todayData);
    } catch (e) {
      console.log(e);
      setPredictionGameData({});
    }
  };

  const fetchIsUserPredicted = () => {
    getRequest({ url: `/game/is-predicted/${user?.username}` })
      .then((res) => {
        setIsUserPredicted(res.data.isPredicted);
      })
      .catch((err) => {
        setIsUserPredicted(false);
      });
  };

  useState(() => {
    fetchIsUserPredicted();
    fetchPredictionGameData();
  }, [user?.username]);

  const fetchUserPortfolioStocks = async (username) => {
    let data = await getRequest({
      url: `/stocks/get-portfolio-stocks/${username}`,
    });
    // console.log("stocks data", data);
    setFortfolioStocksData(data.data.allStocks);
    setUserPortfolioInvestment(data.data.allData);
  };

  return (
    <StockNewsContext.Provider
      value={{
        stocksData,
        newsData,
        selectedTagsNews,
        setSelectedTagsNews,
        selectedTagsStocks,
        setSelectedTagsStocks,
        filteredStocksByTags,
        filteredNewsByTags,
        openConfirmationBox,
        setOpenConfirmationBox,
        addedStocks,
        setAddedStocks,
        getWishlist,
        savedNews,
        savedPosts,
        setSavedNews,
        setSavedPosts,
        getStocks,
        // setStocksData,
        getNews,
        removeSavedPost,
        addSavedPost,
        fetchsearchPosts,
        searchedPostsData,
        setSearchedPostsData,
        addSavedNews,
        removeSavedNews,
        savedNews,
        indicesdata,
        setIndicesData,
        mobileNewsNumber,
        setMobileNewsNumber,
        predictionGameData,
        isUserPredicted,
        fetchIsUserPredicted,
        fetchUserPortfolioStocks,
        portfolioStocksData,
        userPortfolioInvestment,
      }}
    >
      {children}
    </StockNewsContext.Provider>
  );
};
