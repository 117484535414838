import { React, useContext } from "react";
import Alert from "../../Components/Alert";
import Suggestion from "../../Components/common/components/suggestion";
import { ThemeContext } from "../../context/ThemeContext";
import { Box } from "@mui/material";
import { Cookies } from "react-cookie";
import LayoutDefault from "../../Components/layout/page/default";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";

const AlertPage = () => {
  const { theme } = useContext(ThemeContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { width } = useViewPort();

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      {width < BREAKPOINT ? (
        <MobileDefault middleContent={<Alert />} />
      ) : (
        <LayoutDefault
          mainContent={<Alert />}
          rightPanelContent={
            <div>
              {user && <Suggestion />}

              <MostPopularStocksRight />
              <TrendingNewsCard />
            </div>
          }
        />
      )}
    </Box>
  );
};

export default AlertPage;
