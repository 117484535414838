import React, { useContext } from "react";
import FilterPosts from "../../Components/filter-posts-main/filter-posts";
import Suggestion from "../../Components/common/components/suggestion";
import { ThemeContext } from "../../context/ThemeContext";
import { tags } from "../../Components/common-functions/FormatDate";
import CommonSlider from "../../Components/common-functions/CommonSlider";
import { useParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";
import useScroll from "../../Components/common-functions/useScroll";

const FilterPostsMain = () => {
  const { theme } = useContext(ThemeContext);
  const { tag } = useParams();
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { width } = useViewPort();

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <div>
              <br></br>
              <FilterPosts />
            </div>
          }
        />
      ) : (
        <LayoutDefault
          mainContent={
            <div>
              <CommonSlider value={tags} selectedTag={tag} />
              <br></br>
              <FilterPosts />
            </div>
          }
          rightPanelContent={
            <div>
              {user ? <Suggestion /> : ""}s
              <MostPopularStocksRight />
              <TrendingNewsCard />
            </div>
          }
        />
      )}
    </Box>
  );
};

export default FilterPostsMain;
