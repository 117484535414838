import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Cookies } from "react-cookie";
import LoginBanner from "../../../login-banner";
import SearchBar from "../../../common/components/search-bar";
import TopSlider from "../../../common/components/top-slider";
import MySidebar from "../../../common/components/my-sidebar";

const LayoutDefault = ({
  mainContent,
  rightPanelContent,
  handleScroll,
  Banner,
}) => {
  const drawerWidth = 180;
  const cookie = new Cookies();
  const user = cookie.get("user");
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Box
        style={{
          display: "flex",
          height: "100vh",
          bgcolor: "background.default",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "" : 160,
          }}
        >
          <MySidebar />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            pt: 1,
            justifyContent: "center",
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Box display={"flex"} mb={2}>
            <SearchBar />

            <TopSlider />
          </Box>

          <Box
            className="mainBodyContent"
            onScroll={handleScroll}
            justifyContent="center"
            id="scrolltop"
          >
            {/* <Box
              onScroll={handleScroll}
              sx={{
                mt: 1,
                ml: `calc((100% - ${drawerWidth}px)*0.12)`,
                minWidth: "calc((100% - ${drawerWidth}px)*0.12)",
                maxWidth: "calc((100% - ${drawerWidth}px)*0.64)",
              }}
            >
              {Banner}
            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: `calc((100% - ${drawerWidth}px)*0.55)`,
                  maxWidth: 840,
                  minWidth: 550,
                  mr: `calc((100% - ${drawerWidth}px)*0.04)`,
                }}
              >
                {mainContent}
              </Box>

              <Box
                sx={{
                  width: `calc((100% - ${drawerWidth}px)*0.32)`,
                  maxWidth: 450,
                  minWidth: 320,
                }}
              >
                {rightPanelContent}
              </Box>
            </Box>
          </Box>
        </Box>

        {!user && (
          <Box
            sx={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              zIndex: "100000",
            }}
          >
            <LoginBanner />
          </Box>
        )}
      </Box>
    </>
  );
};

export default LayoutDefault;
