import { React, useContext, useState } from "react";
import Suggestion from "../../Components/common/components/suggestion";
import SeeAllResultsTabs from "../../Components/see-all-result";
import { ThemeContext } from "../../context/ThemeContext";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import { Cookies } from "react-cookie";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";
import useScroll from "../../Components/common-functions/useScroll";

const SeeAllResults = () => {
  const [whichTab, setWhichTab] = useState("User");
  const { theme } = useContext(ThemeContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { width } = useViewPort();

  const handleWichTabChange = (tabValue) => {
    setWhichTab(tabValue);
  };

  const paginate = (setLastList, setPrevPage, currPage) => {
    // console.log("scrolling tab", whichTab, "page", currPage);
  };

  const {
    handleScroll: { handleUsersTabScroll },
  } = useScroll(paginate);
  const {
    handleScroll: { handlePostsTabScroll },
  } = useScroll(paginate);
  const {
    handleScroll: { handleNewsTabScroll },
  } = useScroll(paginate);
  const {
    handleScroll: { handleStocksTabScroll },
  } = useScroll(paginate);

  const getWhichTabToScroll = () => {
    switch (whichTab) {
      case 0:
        return handleUsersTabScroll;
      case 1:
        return handlePostsTabScroll;
      case 2:
        return handleNewsTabScroll;
      case 3:
        return handleStocksTabScroll;
    }
  };

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <div>
              <SeeAllResultsTabs handleWichTabChange={handleWichTabChange} />
              <br></br>
            </div>
          }
        />
      ) : (
        <LayoutDefault
          mainContent={
            <div>
              <SeeAllResultsTabs handleWichTabChange={handleWichTabChange} />
              <br></br>
            </div>
          }
          rightPanelContent={
            <div>
              {user ? <Suggestion /> : ""}
              <MostPopularStocksRight />
              <TrendingNewsCard />
            </div>
          }
        />
      )}
    </Box>
  );
};

export default SeeAllResults;
