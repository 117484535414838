import { lazy } from "react";

const SeeAllResultsRoute = [
  {
    path: "/result/post/*",
    component: lazy(() => import("./index")),
  },
  {
    path: "/result/news/*",
    component: lazy(() => import("./index")),
  },
  {
    path: "/result/stocks/*",
    component: lazy(() => import("./index")),
  },
  {
    path: "/result/users/*",
    component: lazy(() => import("./index")),
  },
];

export default SeeAllResultsRoute;

// /result/users?search="s"
