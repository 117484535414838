import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeContext } from "../../context/ThemeContext";
import FollowingTabs from "./components/following-tabs";

const FollowerFollowingPopup = () => {
  const { theme, addTagsSelected, setAddTagsSelected, followUsers } =
    useContext(ThemeContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTags = (e, tags) => {
    let isChecked = e.target.checked;

    if (isChecked) {
      setAddTagsSelected((d) => [...d, tags]);
    } else {
      let idx = addTagsSelected.indexOf(tags);

      addTagsSelected.splice(idx, 1);
      setAddTagsSelected(() => [...addTagsSelected]);
    }
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        onClick={handleClickOpen}
        sx={{ fontSize: 16, textTransform: "none" }}
      >
        Followers
      </Button>

      <Dialog open={open} onClose={handleClose} align="center">
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              letterSpacing={0.4}
              sx={{ fontSize: 16, fontWeight: 600 }}
            >
              Following-Followers
            </Typography>

            <CloseIcon
              onClick={handleClose}
              style={{
                color: "black",
              }}
            />
          </Box>
        </DialogTitle>
        <Divider color={"black"} />
        <DialogContent className="dialog_content">
          <div>
            <FollowingTabs handleClose={handleClose} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FollowerFollowingPopup;
