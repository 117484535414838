import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeContextProvider } from "./context/ThemeContext";
import { ViewportProvider } from "./viewport_context/viewPortContext";
import { StockNewsContextProvider } from "./context_for_stocks_news/StocksNewsContext";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import CssBaseline from "@mui/material/CssBaseline";
// import { initializeApp } from "firebase/app";
import { GOOGLE_CLIENT_ID } from "./constants/constants";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { I18nextProvider } from "react-i18next";
// import AnalyticsProvider from "react-segment-analytics";
import { createClient, AnalyticsProvider } from "@segment/analytics-react";
import i18n from "./translation/initialization";
import cometchatInit from "./cometchat-init";

const segmentClient = createClient({
  writeKey: "DNLxBz1sSxiLHUpVxYdiTiyMOErwavxQ",
});

cometchatInit();

const theme = createTheme({
  palette: {
    primary: {
      main: "#5D20D2",
    },
    secondary: {
      main: "#313342",
    },
  },
  typography: {
    fontFamily: ["'Montserrat', sans-serif"].join(","),
    fontSize: [14],
    h1: {
      fontSize: "24px",
      fontWeight: 600,
      color: "#272727",
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      color: "#272727",
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
      color: "#272727",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AnalyticsProvider client={segmentClient}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <BrowserRouter>
            <ThemeContextProvider>
              <StockNewsContextProvider>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <CookiesProvider>
                      <ViewportProvider>
                        <App />
                      </ViewportProvider>
                    </CookiesProvider>
                  </ThemeProvider>
                </GoogleOAuthProvider>
              </StockNewsContextProvider>
            </ThemeContextProvider>
          </BrowserRouter>
        </Provider>
      </I18nextProvider>
    </AnalyticsProvider>
  </React.StrictMode>
);
serviceWorkerRegistration.register();
// initializeApp();
reportWebVitals();
