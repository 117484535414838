import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../context/ThemeContext";
import UserPosts from "./components/user-posts";
import DropdownRecent from "../common/components/dropdown-recent";
import { Cookies } from "react-cookie";
import FilterPostDropdown from "./components/filter-post-dropdown";
import AmazonVouterCard from "./components/amazon-vouter-card";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "black",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserPostsTabs({ data, isFetching, loading }) {
  const [value, setValue] = React.useState(0);
  const { theme, followingPosts, userPosts, setCurrentPostsTab } =
    useContext(ThemeContext);
  const posts = useSelector((state) => state?.posts.posts);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const handleChange = (event, newValue) => {
    setCurrentPostsTab(event.target.innerText);
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mb: 2,
        height: 49,
        border: "1px solid #e0e0e0",
        borderRadius: "4px",
      }}
      style={theme ? light : dark}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ border: "none" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={t("Home")}
              sx={{ textTransform: "none", fontSize: 16, fontWeight: 600 }}
              {...a11yProps(1)}
            />

            {user && (
              <Tab
                label={t("Following")}
                sx={{ textTransform: "none", fontSize: 16, fontWeight: 600 }}
                {...a11yProps(0)}
              />
            )}
          </Tabs>
        </Box>
        <Box mt={0.8} sx={{ display: "flex", gap: 1 }}>
          <FilterPostDropdown />
          <DropdownRecent />
        </Box>
      </Box>

      <TabPanel value={value} index={0} style={{ marginTop: "15px" }}>
        {posts.length > 3 ? (
          <>
            <UserPosts
              data={posts.slice(0, 3)}
              clipped={true}
              loading={loading}
            />
            <AmazonVouterCard />
            <UserPosts data={posts.slice(3)} clipped={true} loading={loading} />
          </>
        ) : (
          <UserPosts data={posts} clipped={true} loading={loading} />
        )}
      </TabPanel>

      {user && (
        <TabPanel value={value} index={1}>
          <br />
          <UserPosts clipped={true} data={followingPosts} loading={loading} />
        </TabPanel>
      )}
    </Box>
  );
}
