import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import success from "../../../../assets/success.jpeg";
import { Cookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import CustomizedSlider from "../range-slider";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";
import { doRequest, getRequest } from "../../../../services/request";
import { useMediaQuery } from "@mui/material";

const typographyStyle = {
  fontSize: 16,
  backgroundImage:
    "linear-gradient(to right, rgba(93, 32, 210, 1), rgba(196, 32, 210, 1))",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 800,
};

const AmazonVouterCard = () => {
  const { predictionGameData, isUserPredicted, fetchIsUserPredicted } =
    useContext(StockNewsContext);
  const [count, setCount] = useState(predictionGameData?.LAST);
  const [min, setMin] = useState(predictionGameData?.LAST - 2000);
  const [max, setMax] = useState(predictionGameData?.LAST + 2000);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [data, setData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  function fetchData() {
    getRequest({ url: `/game/results` })
      .then((res) => {
        setData(res?.data?.results);
        // console.log("hello result", res);
      })
      .catch((e) => {
        setData([]);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);
  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count === 0) return;
    setCount(count - 1);
  };

  const makeAPrediction = async () => {
    try {
      let response = await doRequest({
        url: "/game/predict",
        method: "POST",
        body: JSON.stringify({ prediction_number: count }),
      });
      setShowSuccess(true);
      fetchIsUserPredicted();
      // console.log("predicted", response);
    } catch (e) {
      console.log("something went wrong");
    }
  };
  function isPredictionTimeOrNot() {
    let now = moment().tz("Asia/Kolkata"); // Get the current date and time in the Asia/Kolkata timezone
    let currentHour = now.hour(); // Get the current hour
    // Check if the current time is between today after 17:00 and tomorrow before 12:00
    let isBetween = false;
    if (currentHour >= 17) {
      isBetween = true;
    } else if (currentHour < 12) {
      isBetween = true;
    } else if (currentHour === 12 && now.minute() === 0) {
      isBetween = true;
    }

    // Return the result
    return isBetween;
  }

  function isWeekend() {
    const now = moment.tz("Asia/Kolkata");
    const dayOfWeek = now.day();
    const hourOfDay = now.hour();

    // Friday = 5, Saturday = 6, Sunday = 0
    if (dayOfWeek === 5 && hourOfDay >= 17) {
      return true;
    } else if (dayOfWeek === 6) {
      return true;
    } else if (dayOfWeek === 0 && hourOfDay < 17) {
      return true;
    } else {
      return false;
    }
  }
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Card
      sx={{
        pr: 1,
        pl: 0,
        backgroundImage: `url(${"https://i.ibb.co/3CGfcwz/cardwinnerbg.jpg"})`,
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
        maxHeight: "100%",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        boxShadow: "none",
        border: "1px solid #e0e0e0",
        borderBottom: "none",
        mb: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            borderRight: isMobile ? "" : "0.2px solid rgb(211, 216, 231)",
          }}
        >
          <Link to={"/prediction-game"} style={{ textDecoration: "none" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100px",
              }}
            >
              <img
                style={{ width: "150px", maxWidth: "120px", height: "100px" }}
                src={"https://i.ibb.co/LYJsKYS/amazonv.png"}
                alt="scale"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                pt: 2,
                pr: 2,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #5D20D2 11.99%, #C420D2 91.37%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontSize: 20,
                    fontWeight: "800", // Updated fontWeight to be a string value
                  }}
                  mr={0.9}
                >
                  {" "}
                  AMAZON VOUCHERS{" "}
                </Typography>
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    mt: 0.8,
                    color: "#372B47",
                  }}
                >
                  {" "}
                  up for grabs!
                </Typography>
              </Box>
            </Box>
          </Link>
          <Box display={"flex"} justifyContent={"center"} textAlign={"center"}>
            <img
              style={{ Width: "115px", height: "115px" }}
              src={"https://i.ibb.co/8K2XHqK/challenge.png"}
              alt="scale"
            />

            <Typography
              letterSpacing={0.4}
              fontWeight={600}
              mt={6}
              ml={2}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #5D20D2 -1.82%, #E933E1 100%)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Predict Nifty Close
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            textAlign={"center"}
            mt={-3}
            ml={2}
          >
            <CustomizedSlider
              setCount={setCount}
              count={count}
              min={min}
              max={max}
            />
          </Box>

          <Box
            display={"flex"}
            gap={4}
            sx={{
              justifyContent: "center",
              mb: 2,
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                background: "white",
                borderRadius: "8px",
                height: 30,
                boxShadow: " 0px 4px 4px #D8D1E3",
                ":hover": {
                  background: "white",
                  borderRadius: "8px",
                  height: 30,

                  boxShadow: " 0px 4px 4px #D8D1E3",
                },
              }}
            >
              {" "}
              <button
                style={{
                  border: "none",
                  background: "white",
                  fontWeight: 800,
                  width: 40,
                }}
                onClick={decrement}
              >
                -
              </button>
              <Typography
                letterSpacing={0.4}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  fontSize: 14,
                  marginTop: "7px",
                }}
              >
                {count}
              </Typography>
              <button
                style={{
                  border: "none",
                  background: "white",
                  fontWeight: 800,
                  width: 40,
                }}
                onClick={increment}
              >
                +
              </button>
            </Button>

            <Button
              sx={{
                background: "#5d20d2",
                boxShadow: "0px 4px 4px rgba(137, 21, 254, 0.25)",
                borderRadius: "8px",
                height: 30,
                pl: 2,
                pr: 2,
                color: "white",
                width: 130,
                textTransform: "none",
                ":disabled": {
                  color: "gray",
                  cursor: "not-allowed",
                  backgroundColor: "white",
                },
              }}
              onClick={() => {
                if (user) {
                  makeAPrediction();
                } else {
                  window.location.href = "/welcome";
                }
              }}
              disabled={
                isUserPredicted || !isPredictionTimeOrNot() || isWeekend()
              }
            >
              {isUserPredicted ? "Submitted" : "Submit"}
            </Button>
          </Box>
        </Grid>
        {!isMobile && (
          <>
            {data?.slice(0, 1).map((data, idx) => (
              <Grid item xs={12} sm={4} mt={5} key={`Amazon-voucher-${idx}`}>
                <Box
                  sx={{
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="https://media.tenor.com/R5NPrrGczSkAAAAi/winner-winning.gif"
                    style={{ width: "230px", height: "110px" }}
                    alt="Gift in a Box"
                  />
                  <Typography letterSpacing={0.4} sx={typographyStyle} mt={1}>
                    {data?.day} Prediction
                  </Typography>

                  <Box>
                    <Link
                      to={`/user/${data?.userDetails?.username}`}
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        color: "black",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          mt: 1,
                          mb: 2,
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            border: "1px solid #D8D1E3",
                          }}
                          src={data?.userDetails?.userpic_url}
                        />
                        <Box ml={2} mt={1}>
                          <Typography
                            letterSpacing={0.4}
                            fontSize={16}
                            fontWeight={600}
                          >
                            {" "}
                            {data?.userDetails?.First_Name +
                              " " +
                              data?.userDetails?.last_name}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {data?.slice(0, 1).map((data, idx) => (
        <>
          {showSuccess && (
            <Dialog
              open={showSuccess}
              onClose={() => setShowSuccess(false)}
              sx={{ zIndex: "9999999" }}
            >
              <DialogContent>
                <Box
                  sx={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ height: "100px", width: "100px" }}
                    src={success}
                    alt="Success"
                  />
                </Box>
                <Typography
                  letterSpacing={0.4}
                  fontSize={20}
                  fontWeight={600}
                  align="center"
                  mt={2}
                >
                  Successfully submitted!
                </Typography>

                <Typography
                  letterSpacing={0.4}
                  sx={{
                    justifyContent: "center",
                    textAlign: "center",
                    width: 400,
                  }}
                >
                  Your Prediction for {data?.day} market closing has been
                  successfully submitted. Stay tuned to know the result at 4 PM
                  Tomorrow.
                </Typography>

                <Box
                  sx={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Button
                    sx={{
                      backgroundImage:
                        "linear-gradient(90deg, #5D20D2 -4.65%, #D220CB 103.49%)",
                      color: "white",
                      textTransform: "none",
                      borderRadius: 16,
                      pl: 2,
                      pr: 2,
                    }}
                    onClick={() => setShowSuccess(false)}
                  >
                    Go Home
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </>
      ))}
    </Card>
  );
};

export default AmazonVouterCard;
