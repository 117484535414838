import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../../context/ThemeContext";
import UserPosts from "../../user-posts-tabs/components/user-posts";
import DropdownRecent from "../../common/components/dropdown-recent";
import { useParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import useFilterData from "../../common-functions/useFilterData";
import { getAuthenticatedRequest, getRequest } from "../../../services/request";
import useSessionToken from "../../../hooks/useSessionToken";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "black",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FilterPosts = () => {
  const [value, setValue] = useState(0);
  const { theme, followingPosts, userPosts, selectedTags } =
    useContext(ThemeContext);
  const { tag } = useParams();
  const { filterPostsByTags, filterPostsByFollowing } = useFilterData(tag);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [data, setData] = useState([]);
  const [tagdata, setTagdata] = useState([]);
  const { token } = useSessionToken();

  const getTagPosts = async (filterValue, currPage) => {
    const url = `/posts/tagged-content/${tag}?pageSize=10&filter=${
      filterValue || ""
    }&pageNo=${currPage || 1}`;
    const response = await getRequest({
      url,
    });

    const res = response.data?.posts?.map((item) => ({
      ...item,
      isFollowing: false,
      isUpvoted: false,
      isDownvoted: false,
      showMore: false,
    }));
    setData((d) => [...d, ...res]);
  };
  // console.log("hello tags", data)

  useEffect(() => {
    getTagPosts();
  }, []);

  const getFollowingTagsPosts = async (
    filterValue,
    currPage,
    token,
    payload
  ) => {
    const url = `/posts/following-tagged-content/${tag}?pageSize=10&filter=${
      filterValue || ""
    }&pageNo=${currPage || 1}`;

    const response = await getAuthenticatedRequest({
      url,
    });

    const res = response.data?.posts?.map((item) => ({
      ...item,
      isFollowing: false,
      isUpvoted: false,
      isDownvoted: false,
      showMore: false,
    }));

    setTagdata((d) => [...d, ...res]);
  };

  useEffect(() => {
    getFollowingTagsPosts();
  }, []);

  return (
    <div className="slaider_div_new_new" style={theme ? light : dark}>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ border: "none" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Home"
                sx={{ textTransform: "none", fontSize: 16 }}
                {...a11yProps(1)}
              />

              {user && (
                <Tab
                  label="Following"
                  sx={{ textTransform: "none", fontSize: 16 }}
                  {...a11yProps(0)}
                />
              )}
            </Tabs>
          </Box>
          <Box mt={0.8}>
            <DropdownRecent />
          </Box>
        </Box>

        <TabPanel
          value={value}
          index={0}
          className="tabPanel_mostbought"
          style={{ marginTop: "20px" }}
        >
          <UserPosts data={data} />
        </TabPanel>

        <TabPanel
          value={value}
          index={1}
          className="tabPanel_mostbought"
          style={{ marginTop: "20px" }}
        >
          <UserPosts
            data={tagdata}
            // data={selectedTags ? postsByFollowing : followingPosts}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default FilterPosts;
