import { React, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import NewsDetails from "../../Components/news-details/news-details";
import NewsDetailsTab from "../../Components/news-details";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import Suggestion from "../../Components/common/components/suggestion";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";
import { Cookies } from "react-cookie";
import useScroll from "../../Components/common-functions/useScroll";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";

const NewsDetailsPage = () => {
  const { theme } = useContext(ThemeContext);
  const { getNews } = useContext(StockNewsContext);
  const { handleScroll, loading } = useScroll(getNews);
  const { width } = useViewPort();
  const cookie = new Cookies();
  const user1 = cookie.get("user");
  return (
    <>
      <Box className={theme ? "main_light" : "main_dark"}>
        {width < BREAKPOINT ? (
          <MobileDefault
            middleContent={
              <>
                <NewsDetails />
                <br />
                <NewsDetailsTab />
              </>
            }
          />
        ) : (
          <LayoutDefault
            mainContent={
              <div>
                <NewsDetails />
                <NewsDetailsTab loading={loading} />
                <br />
              </div>
            }
            rightPanelContent={
              <div>
                {user1 && <Suggestion />}
                <MostPopularStocksRight />
                <TrendingNewsCard />
                <br />
                <br />
                <br />
                <br />
              </div>
            }
            handleScroll={handleScroll}
          />
        )}
      </Box>
    </>
  );
};

export default NewsDetailsPage;
