import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import Typewriter from "typewriter-effect";

import { useTranslation } from "react-i18next";

const LoginBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const location = useLocation();

  const messages = [
    "We're on a mission to empower Indian retail investors – LogIn for market insights.",
    "Join our mission to empower Indian retail investors. LogIn now to stay market-aware.",
    "Our determination is to empower Indian retail investors. Stay market-aware, LogIn now.",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentMessageIndex < messages.length - 1) {
        setCurrentMessageIndex(currentMessageIndex + 1);
      } else {
        // Reset to the first message when it reaches the end
        setCurrentMessageIndex(0);
      }
    }, 5000); // 500,000 milliseconds = 500 seconds

    return () => {
      clearInterval(interval);
    };
  }, [currentMessageIndex]);

  const highlightedMessage = messages[currentMessageIndex].replace(
    /LogIn/g,
    '<span style="color: #5d20d2; ">LogIn</span>'
  );

  const handleLoginClick = () => {
    // Navigate to the /login route when "LogIn" is clicked
    navigate(`/welcome`, { state: { from: location.pathname } });
  };

  return (
    <Box className="login-banner">
      <Grid container textAlign="center" justifyContent={"center"}>
        {isMobile ? (
          <Box maxHeight={100}>
            <Typewriter
              options={{
                strings: [
                  "We're on a mission to empower Indian retail investors – LogIn for market insights.",
                  "Join our mission to empower Indian retail investors. LogIn now to stay market-aware.",
                  "Our determination is to empower Indian retail investors. Stay market-aware, LogIn now.",
                ],
                pauseFor: 2000,
                autoStart: true,
                loop: true,
              }}
            />
            <Button
              onClick={() => {
                navigate(`/welcome`);
              }}
              sx={{
                color: "white",
                backgroundColor: "#5d20d2",
                borderRadius: "8px",
                textTransform: "none",
                width: 80,
                fontSize: 12,
                mb: 1,
                ":hover": {
                  color: "white",
                  backgroundColor: "#5d20d2",
                  borderRadius: "8px",
                  textTransform: "none",
                },
              }}
            >
              {t("Login Now")}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              justifyContent: "center",
            }}
          >
            <Grid display={"flex"}>
              <Typography
                letterSpacing={0.4}
                fontWeight={600}
                mt={1}
                onClick={handleLoginClick}
              >
                <Typewriter
                  options={{
                    strings: [
                      "We're on a mission to empower Indian retail investors – LogIn for market insights.",
                      "Join our mission to empower Indian retail investors. LogIn now to stay market-aware.",
                      "Our determination is to empower Indian retail investors. Stay market-aware, LogIn now.",
                    ],
                    pauseFor: 2000,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Typography>
            </Grid>

            <Button
              onClick={() => {
                navigate(`/welcome`);
              }}
              sx={{
                color: "white",
                backgroundColor: "#5d20d2",
                borderRadius: "8px",
                textTransform: "none",
                width: 100,
                mb: 1,
                ":hover": {
                  color: "white",
                  backgroundColor: "#5d20d2",
                  borderRadius: "8px",
                  textTransform: "none",
                },
              }}
            >
              {t("Login Now")}
            </Button>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default LoginBanner;
