import React, { useCallback, useContext } from "react";
import { Line } from "@ant-design/plots";
import Chart from "@qognicafinance/react-lightweight-charts"; // new chart library
import { useState } from "react";
import { useEffect } from "react";
import { backend_url } from "../../http-common";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import { ThemeContext } from "../../context/ThemeContext";
import { capitalFirstLetter } from "../common-functions/FormatDate";
import FuLoader from "../shared/FuLoader";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

const LineChart = ({
  headline,
  profileUsername,
  indicesPage = false,
  symbol,
}) => {
  const [data, setData] = useState([]);
  // const { symbol } = useParams();
  const [current, setCurrent] = useState("All");
  const { stocksData, setAddedStocks } = useContext(StockNewsContext);
  const { addedStocks = [] } = useContext(StockNewsContext);
  const { setShowAlertBox } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [linechartData, setLinechartData] = useState([]);
  useEffect(() => {
    asyncFetch();
  }, [symbol, current]);

  let filterStock = stocksData?.filter((el) => el.SYMBOLS === symbol);

  // Memoize the asyncFetch function
  const asyncFetch = useCallback(() => {
    setLoading(true); // Show loader during data fetch
    const url = indicesPage
      ? `${backend_url}/stocks/stock-indices-series/${symbol}/${current}`
      : `${backend_url}/stock-time-series/${symbol}/${current}`;

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        const rev_data = json.new_res?.reverse();
        setData(rev_data);
        setLinechartData(rev_data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("fetch data failed", error);
        setLoading(false);
      });
  }, [symbol, current, indicesPage]);

  useEffect(() => {
    asyncFetch();
  }, [asyncFetch]);

  const graphColor = data
    ? data[data.length - 1]?.LAST - data[data.length - 2]?.LAST
    : "";

  const config = {
    data,
    color: graphColor < 0 ? "#ff6899" : "#58bd7d",
    padding: "auto",

    xField: "Date",
    yField: "LAST",
    xAxis: {
      tickCount: 5,
    },
  };

  let options = {
    alignLabels: true,

    timeScale: {
      rightOffset: 12,
      barSpacing: 3,
      lockVisibleTimeRangeOnResize: true,
      rightBarStaysOnScroll: true,
      borderVisible: false,
      borderColor: "#fff000",
      visible: true,
      timeVisible: true,
      secondsVisible: true,
      fixLeftEdge: true,
      fixRightEdge: true,

      tickMarkFormatter: (time, tickIndex, ticks) => {
        let date;

        if (current === "1D") {
          date = new Date(time * 1000).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
            hour: "2-digit",
            minute: "2-digit",
          });
        } else if (current === "1W") {
          date = new Date(time * 1000);
          const day = date.toLocaleDateString("en-US", {
            timeZone: "Asia/Kolkata",
          });
          let temp = date.toLocaleDateString("en-US", {
            timeZone: "Asia/Kolkata",
            month: "numeric",
            day: "numeric",
          });
          date = `${temp}`;
        } else {
          time = `${time.year}-${time.month}-${time.day}`;
          date = new Date(time);
          date = date.toLocaleDateString("en-IN", {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
        }

        return date;
      },
    },
    watermark: {
      visible: true,
      fontSize: 34,
    },
  };

  let areaSeriesOptions = {
    lineColor:
      linechartData[0]?.value - linechartData[linechartData.length - 1]?.value >
      0
        ? "#ff6899"
        : "#58bd7d",
    lineWidth: 1,
    autoScale: true,
    autoWidth: true,
    topColor:
      linechartData[0]?.value - linechartData[linechartData.length - 1]?.value >
      0
        ? "#f7dae3"
        : "#d2fce1",
    bottomColor:
      linechartData[0]?.value - linechartData[linechartData.length - 1]?.value >
      0
        ? "#ffeff4"
        : "#e1f7e9",
  };

  const time_btns = ["1D", "1W", "1M", "6M", "1Y", "All"];

  const handleChangeTime = (item) => {
    setCurrent(() => item);
  };

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {loading ? (
        <div style={{ marginTop: "-200px" }}>
          <FuLoader />
        </div>
      ) : (
        <div
          style={{
            border: "1px solid #d6d7db",
            padding: isMobile ? "" : "10px",
            marginBottom: 20,
          }}
        >
          {headline && (
            <>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: "50px",
                }}
              >
                <h1>{headline}</h1>
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginTop: "50px",
                  marginBottom: "32px",
                }}
              >
                {capitalFirstLetter(profileUsername)}'s portfolio performance
                from {`"${data[0] ? data[0]["Date"] : null}"`} to
                {` "${data[0] ? data[data.length - 1]["Date"] : null}"`}
              </div>
            </>
          )}

          <Chart
            options={options}
            areaSeries={[
              {
                data: linechartData,
                options: areaSeriesOptions,
              },
            ]}
            autoWidth
            height={isMobile ? 260 : 360}
          />

          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {symbol &&
              time_btns.map((item, idx) => (
                <button
                  style={{
                    borderRadius: "8px",
                    fontSize: "12px",
                    border: "1px solid #cecece",
                    backgroundColor: current === item ? "#5D20D2" : "white",
                    color: current === item ? "white" : "black",
                  }}
                  onClick={() => handleChangeTime(item)}
                  key={`line-chart-btn-${idx}`}
                >
                  {item}
                </button>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LineChart;
