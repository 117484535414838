import React from "react";
import "./index.css";
import { LeftSidePannelWithLogo } from "../../Components/login-components/components/leftside-panel-with-logo";
import { Grid, Box, Typography, Stack } from "@mui/material";
import SignupForm from "./Components/SignupForm";

const Signup = () => {
  return (
    <Grid className="loginLangdingContainer">
      <LeftSidePannelWithLogo />
      <Box className="loginLandingContentBox">
        <Grid>
          <SignupForm />
        </Grid>
      </Box>
    </Grid>
  );
};

export default Signup;
