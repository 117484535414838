import React, { useContext, useEffect, useRef, useState } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { ThemeContext } from "../../../../context/ThemeContext";
import SuggestionBox from "../../../search-result";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { getRequest } from "../../../../services/request";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0.5rem",
  backgroundColor: "white",
  boxShadow: "none",
  border: "1px solid #e0e0e0",
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  color: "#a0a0a6",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),

    fontSize: "10px",

    [theme.breakpoints.up("sm")]: {
      width: "110ch",
    },
    [theme.breakpoints.up("xs")]: {
      width: "42ch",
      fontSize: "0.7rem",
    },

    "@media (max-width:290px)": {
      fontSize: "0.5rem",
      width: "35ch",
    },
    "@media (max-width:400px)": {
      fontSize: "0.6rem",
      width: "40ch",
    },
  },
}));

const SearchBar = () => {
  const { fetchsearchPosts, searchedPostsData } = useContext(StockNewsContext);
  const [inputText, setInputText] = useState("");
  const [searchedNewsData, setSearchedNewsData] = useState([]);
  const [searchedUsersData, setSearchedUsersData] = useState([]);
  const [searchedStocksData, setSearchedStocksData] = useState([]);
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const timeoutIdRef = useRef(null);

  const getSearchedNews = async (searchTerm) => {
    const response = await getRequest({
      url: `/get-searched-news?search=${searchTerm}`,
    });
    setSearchedNewsData(response.data?.searchedNews);
    localStorage.setItem(
      "searchedNews",
      JSON.stringify(response.data?.searchedNews)
    );
  };

  const getSearchedUsers = async (searchTerm) => {
    const response = await getRequest({
      url: `/get-searched-users?search=${searchTerm}`,
    });
    setSearchedUsersData(response.data?.searchedUsers);
    localStorage.setItem(
      "searchedUsers",
      JSON.stringify(response.data?.searchedUsers)
    );
  };

  const getSearchedPosts = async (searchTerm) => {
    fetchsearchPosts(searchTerm);
  };

  const getSearchedStocks = async (searchTerm) => {
    const response = await getRequest({
      url: `/get-searched-stocks?search=${searchTerm}`,
    });
    setSearchedStocksData(response.data?.stocks);
  };

  useEffect(() => {
    localStorage.removeItem("searchedStocks");
    localStorage.setItem("searchedStocks", JSON.stringify(searchedStocksData));
  }, [searchedStocksData]);

  useEffect(() => {
    // Clean up the timeout on component unmount
    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, []);

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);

    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      if (lowerCase.length > 0) {
        getSearchedNews(lowerCase);
        getSearchedUsers(lowerCase);
        getSearchedPosts(lowerCase);
        getSearchedStocks(lowerCase);
      } else {
        setSearchedNewsData([]);
        setSearchedUsersData([]);
        fetchsearchPosts("");
        setSearchedStocksData([]);
      }
    }, 500);
  };

  const containerRef = useRef();
  const isDesktop = useMediaQuery("(min-width: 960px)");

  useEffect(() => {
    document.addEventListener("click", onDocumentClick);
    return () => document.removeEventListener("click", onDocumentClick);
  }, []);

  const onDocumentClick = (event) => {
    if (!containerRef.current) return;
    if (!containerRef.current.contains(event.target)) {
      setInputText("");
    }
  };

  return (
    <Box ref={containerRef}>
      <Search component="form">
        <SearchIconWrapper>
          <SearchIcon
            style={{
              marginLeft: "2px",
              color: "rgb(112, 112, 123)",
              height: 18,
            }}
          />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={t("Search for post, news, stock and user")}
          onChange={inputHandler}
          width={isDesktop ? "400px" : "100%"}
        />
      </Search>
      <Box className="suggestion_box_div">
        {inputText && (
          <SuggestionBox
            searchInput={inputText}
            searchedNewsData={searchedNewsData}
            searchedUsersData={searchedUsersData}
            searchedPostsData={searchedPostsData}
            searchedStocksData={searchedStocksData}
          />
        )}
      </Box>
    </Box>
  );
};

export default SearchBar;
