import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";
import Box from "@mui/material/Box";
import { readableImage } from "../../../common-functions/FormatDate";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const TopSlider = () => {
  const { stocksData } = useContext(StockNewsContext);
  const { indicesdata } = useContext(StockNewsContext);
  const navigate = useNavigate();

  const interleavedData = [];
  const maxLength = Math.max(stocksData.length, indicesdata.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < stocksData.length) {
      interleavedData.push(stocksData[i]);
    }
    if (i < indicesdata.length) {
      interleavedData.push(indicesdata[i]);
    }
  }
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2800,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    useCSS: true,
    adaptiveHeight: true,
    variableWidth: true,
  };

  return (
    <Slider {...settings}>
      {interleavedData.map((item, idx) => {
        if (item.SYMBOLS) {
          return (
            <Box sx={{ paddingLeft: 2 }} key={`interleavedData_${idx}`}>
              <Card
                key={idx}
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "none",
                }}
                onClick={() => {
                  navigate(`/stock/${item.SYMBOLS}`);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    p: 0.6,
                    pl: 0.8,
                    gap: 2,
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: 12,
                      fontWeight: 600,
                      pt: 0.6,
                      color: "#372B47",
                    }}
                  >
                    {item.SYMBOLS}
                  </Typography>
                  <Typography
                    letterSpacing={0.4}
                    onClick={() => {
                      navigate(`/stock/${item.SYMBOLS}`);
                    }}
                    sx={{ fontSize: 12, cursor: "pointer", pt: 0.6 }}
                  >
                    {"\u20B9"} {item.live_stock_data?.LAST}
                  </Typography>

                  <img
                    style={{
                      width: "38px",
                      minHeight: "22px",
                      position: "relative",
                    }}
                    src={readableImage(item.graph?.data)}
                    alt="graph"
                  />
                </Box>
              </Card>
            </Box>
          );
        } else {
          return (
            <Box sx={{ paddingLeft: 2 }} key={`interleavedData_${idx}`}>
              <Card
                key={idx}
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate(`/indices/${item.Index}`);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    p: 0.6,
                    pl: 0.8,
                    gap: 2,
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: 12,
                      fontWeight: 600,
                      pt: 0.6,
                      color: "#372B47",
                    }}
                  >
                    {item.Index}
                  </Typography>
                  <Typography
                    letterSpacing={0.4}
                    onClick={() => {
                      navigate(`/stock/${item.Index}`);
                    }}
                    sx={{ fontSize: 12, cursor: "pointer", pt: 0.6 }}
                  >
                    {"\u20B9"} {item.live_stock_data?.LAST}
                  </Typography>

                  <img
                    style={{
                      width: "38px",
                      minHeight: "22px",
                      position: "relative",
                    }}
                    src={readableImage(item.graph?.data)}
                    alt="graph"
                  />
                </Box>
              </Card>
            </Box>
          );
        }
      })}
    </Slider>
  );
};

export default TopSlider;
