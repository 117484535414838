import React, { useState } from "react";
import Messages from "../Appointment/appointment-box";
import MessageMobilePage from "./message-mobile-page";
import { BsFillChatTextFill } from "react-icons/bs";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Chat from "./../../assets/Chat.png";
import { FaCommentAlt } from "react-icons/fa";

const MobileMessageIcon = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleclose = () => {
    setIsOpen(false);
  };
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {!isOpen && (
        <Box
          onClick={handleClick}
          mb={1}
          mr={1}
          borderRadius={"24px 0px 24px 24px"}
          sx={{
            backgroundImage:
              "linear-gradient(180deg, #F598FF 0%, #FBD5FF 100%)",
            height: 50,
            width: 50,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaCommentAlt
            style={{ color: "#763BE7", marginTop: "14px", fontSize: 20 }}
          />
        </Box>
      )}
      {isOpen && (
        <div style={{ width: "100vw" }}>
          <MessageMobilePage onClose={handleClick} handleclose={handleclose} />
        </div>
      )}
    </>
  );
};

export default MobileMessageIcon;
