import { Box, Card } from "@mui/material";
import React, { useState } from "react";
import { BiPlus } from "react-icons/bi";
import SelectBrokerPopup from "./select-broker-popup";
import { Cookies } from "react-cookie";
import { useParams } from "react-router-dom";

const LinkBroker = () => {
  const cookie = new Cookies();
  const user = cookie.get("user");
  const { username } = useParams();
  return (
    <>
      {user?.username === username && (
        <Card
          sx={{
            p: 1,
            mt: 2,
            display: "flex",
            justifyContent: "center",
            boxShadow: "none",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
          }}
        >
          <Box>
            <SelectBrokerPopup />
          </Box>
        </Card>
      )}
    </>
  );
};
export default LinkBroker;
