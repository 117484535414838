import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputBase, styled, useMediaQuery } from "@mui/material";
import { updateSearch, fetchRoomsExpert } from "../../../store/roomSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0.5rem",
  backgroundColor: "white",
  border: "1px solid #e0e0e0",
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  color: "#a0a0a6",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    fontSize: "12px",

    [theme.breakpoints.up("sm")]: {
      width: "110ch",
    },
    [theme.breakpoints.up("xs")]: {
      width: "42ch",
      fontSize: "0.7rem",
    },

    "@media (max-width:290px)": {
      fontSize: "0.5rem",
      width: "35ch",
    },
    "@media (max-width:400px)": {
      fontSize: "0.6rem",
      width: "40ch",
    },
  },
}));

const RoomSearchBox = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSearchTextChange = (e) => {
    const payload = {
      search: e.target.value,
    };
    dispatch(updateSearch(payload));
    dispatch(fetchRoomsExpert());
  };
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <Box width={isDesktop ? "200px" : "100%"}>
      <Search component="form">
        <SearchIconWrapper>
          <SearchIcon
            style={{
              marginLeft: "2px",
              color: "rgb(112, 112, 123)",
              height: 17,
            }}
          />
        </SearchIconWrapper>
        <StyledInputBase
          onChange={onSearchTextChange}
          placeholder={t("Search")}
          width="100%"
        />
      </Search>
    </Box>
  );
};

export default RoomSearchBox;
