import React, { useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../../context/ThemeContext";
import UserPosts from "../../user-posts-tabs/components/user-posts";
import { Button } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProfileDetailsTabs({ data, isFetching }) {
  const [value, setValue] = React.useState(0);
  const { theme, userPosts } = useContext(ThemeContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSeeMoreClick = () => {
    window.location.href = "/"; // Replace "/" with the home page URL
  };

  return (
    <Box
      sx={{
        width: "100%",
        mb: 2,
        height: 49,
        border: "1px solid #e0e0e0",
        borderRadius: "4px",
        backgroundColor: "white",
      }}
    >
      {/* ------------------tabs box----------- */}
      <Box sx={{ border: "none" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Related Posts"
            sx={{ textTransform: "none", fontSize: "16px" }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel
        value={value}
        index={0}
        className="tabPanel_mostbought"
        style={{ marginTop: "18px" }}
      >
        <UserPosts data={userPosts.slice(0, 5)} />

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            sx={{
              background: "linear-gradient(90deg, #5D20D2 0%, #C71094 100%)",
              boxShadow: "0px 4px 4px rgba(137, 21, 254, 0.25)",
              borderRadius: "8px",
              height: 30,
              pl: 2,
              pr: 2,
              color: "white",
              width: 130,
              textTransform: "none",
            }}
            onClick={handleSeeMoreClick}
          >
            See More
          </Button>
        </Box>
      </TabPanel>
    </Box>
  );
}
