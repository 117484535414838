import React, { useContext } from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { Box } from "@mui/material";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";

const DountChart = () => {
  const { userPortfolioInvestment } = useContext(StockNewsContext);
  const sortedData = userPortfolioInvestment.sort(
    (a, b) => b.investment - a.investment
  );

  const top5Data = sortedData.slice(0, 5);

  const otherSectors = sortedData.slice(5);

  const restInvestment = otherSectors.reduce(
    (acc, item) => acc + item.investment,
    0
  );

  const chartData = [...top5Data];

  if (otherSectors.length > 0) {
    const otherData = {
      sector: "Others",
      investment: restInvestment,
    };
    chartData.push(otherData);
  }
  const tooltipRender = (e) => {
    if (e.dataItem && e.dataItem.category) {
      return (
        <div>
          <div>{e.dataItem.category}</div>
          <div>{`Investment: $${e.dataItem.value}`}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <Box>
      {chartData && chartData.length > 0 ? (
        <Chart
          style={{
            width: "100%",
            boxShadow: "none",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
          }}
        >
          <ChartTooltip render={tooltipRender} />
          <ChartSeries>
            <ChartSeriesItem
              type="donut"
              data={chartData}
              categoryField="sector"
              field="investment"
            />
          </ChartSeries>
          <ChartLegend
            position="right"
            orientation="vertical"
            fontSize={"16px"}
            itemMargin={{ right: 40 }}
          />
        </Chart>
      ) : (
        <Box
          sx={{
            backgroundColor: "white",
            textAlign: "center",
            width: "100%",
          }}
        >
          have no stock in the portfolio
        </Box>
      )}
    </Box>
  );
};

export default DountChart;
