import React, { useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { getNewsSummaryLanguage } from "../../../common-functions/getLanguage";
import { useTranslation } from "react-i18next";

const ResultForNews = ({ searchInput, searchedNewsData, news }) => {
  const { i18n } = useTranslation();
  const [showAllResults, setShowAllResults] = useState(false);
  const [numResults, setNumResults] = useState(4);

  const toggleShowResults = () => {
    setShowAllResults(!showAllResults);
    if (!showAllResults) {
      setNumResults(searchedNewsData.length);
    } else {
      setNumResults(4);
    }
  };
  const ifUserLanguageEnglish = () => {
    return i18n.language === "en";
  };
  return (
    <Box>
      {searchInput.length !== 0 &&
        searchedNewsData?.slice(0, numResults).map((news, idx) => (
          <Box
            sx={{
              fontSize: 14,
              pl: 5,
              p: 1,
              ml: 2,
              color: "black",
            }}
          >
            {ifUserLanguageEnglish()
              ? news["Headline"]
              : news[`${getNewsSummaryLanguage(i18n.language)}headline`]}
          </Box>
        ))}

      {searchInput.length !== 0 && searchedNewsData?.length === 0 && (
        <Box
          sx={{
            p: 1,
            pl: 3,
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          No news found
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {searchInput.length !== 0 && (
          <Button
            onClick={toggleShowResults}
            sx={{
              textTransform: "none",
              fontSize: 14,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {showAllResults ? "Show Less" : "Show More"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

ResultForNews.propTypes = {
  searchInput: PropTypes.string.isRequired,
};

export default ResultForNews;
