import React from "react";
import { Card, Typography, Box, Avatar, Button, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const PostBefore = () => {
  return (
    <Card>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton variant="circular" width={48} height={48} />
          <Box sx={{ ml: 1.5, width: "100%" }}>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={120} height={16} />
          </Box>
        </Box>

        <Skeleton variant="text" width="100%" height={24} sx={{ mt: 1.5 }} />

        <Divider />

        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mt: 2 }}
        />

        <Skeleton variant="text" width="100%" height={16} sx={{ mt: 2 }} />

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={80} height={20} sx={{ ml: 1 }} />
          </Box>

          <Box>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default PostBefore;
