import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { doRequest, getRequest } from "../../services/request";

export default function About({ setExpertProfileForCreatingCometChat }) {
  const { username } = useParams();
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [editing, setEditing] = useState(false);
  const [aboutText, setAboutText] = useState("");
  const [about, setAbout] = useState([]);

  const fetchData = () => {
    getRequest({ url: `/experts/get-expert-by-id/${username}` })
      .then((res) => {
        const expertData = res.data.experts[0]; // Assuming there's only one expert returned
        setAbout(expertData ? expertData.about : "");
        setAboutText(expertData?.about || "");
      })
      .catch((e) => {
        setAbout("");
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = () => {
    setEditing(true);
    setAboutText(about[0]?.about || about);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();

    const updatedAbout = {
      username: user?.username,
      about: aboutText,
    };

    let payload = JSON.stringify(updatedAbout);
    doRequest({
      url: "/experts/edit-expert-profile",
      body: payload,
      method: "POST",
    })
      .then((response) => {
        fetchData();
        setEditing(false);
      })
      .catch((error) => {
        console.error("Error updating about:", error);
      });
  };

  const handleCancelClick = () => {
    setEditing(false);
  };
  return (
    <Box
      pr={3}
      width={"100%"}
      sx={{
        borderTop: "none",
      }}
    >
      <Box sx={{ justifyContent: "space-between", display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            letterSpacing={0.4}
            sx={{
              whiteSpace: "pre-line",
              fontSize: 14,
              textAlign: "justify",
              lineHeight: 1.3,
            }}
          >
            {about}
          </Typography>
        </Box>
        {user && user.username === username && (
          <IconButton onClick={handleEditClick} sx={{ height: 15, mr: 0.6 }}>
            <EditIcon style={{ fontSize: 16, color: "black" }} />
          </IconButton>
        )}
      </Box>
      <div>
        {editing ? (
          <div
            style={{
              justifyContent: "space-between",
            }}
          >
            <TextField
              value={aboutText}
              onChange={(event) => setAboutText(event.target.value)}
              size="small"
              multiline
              fullWidth
            />
            <Box display={"flex"} gap={3} mt={2}>
              <Button
                onClick={handleSaveClick}
                style={{
                  height: 30,
                  border: "1px solid #5D20D2",
                  textTransform: "none",
                  borderRadius: 12,
                  color: "white",
                  backgroundColor: "#5D20D2",
                }}
              >
                Save
              </Button>
              <Button
                onClick={handleCancelClick}
                style={{
                  height: 30,
                  border: "1px solid #5D20D2",
                  textTransform: "none",
                  borderRadius: 12,
                  color: "white",
                  backgroundColor: "#5D20D2",
                }}
              >
                Cancle
              </Button>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </div>

      <br />
    </Box>
  );
}
