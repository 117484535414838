import { React } from "react";
import CreateEditPostPopup from "../create-edit-post-popup";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

const schema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string().required("Description is a required field"),
});

const SocialPostForm = ({
  open,
  setOpen,
  handleClose,
  post_data,
  from,
  close,
}) => {
  const initialFormValues = {
    title: post_data?.Title || "",
    description: post_data?.Text || "",
    tags: post_data?.Tag || [],
    image: null,
    imagePreview: post_data?.Image || null,
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialFormValues}
      onSubmit={(values) => {
        // console.log("values", values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
      }) => (
        <CreateEditPostPopup
          open={open}
          handleClose={handleClose}
          from={from}
          setOpen={setOpen}
          post_data={post_data}
          close={close}
        />
      )}
    </Formik>
  );
};

SocialPostForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleClose: PropTypes.func,
  post_data: PropTypes.object.isRequired,
  from: PropTypes.string,
  close: PropTypes.func,
};

export default SocialPostForm;
