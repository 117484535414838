import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../context/ThemeContext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getRequest } from "../../services/request";
import { useEffect } from "react";
import StockDetails from "./stock-details";
import AllStocks from "../all-stocks-tab/all-stocks";
import RelatedNews from "../NewStocks/RelatedNews";
import Corporation from "../NewStocks/Corporate";

const dark = {
  backgroundColor: "#313342",
  color: "white",
};

const light = {
  backgroundColor: "white",
  color: "black",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography letterSpacing={0.4}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StocksDetailsTab({ symbol }) {
  const [value, setValue] = React.useState(0);
  const { theme, userPosts } = React.useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [stockdata, setStockData] = useState([]);

  const fetchData = async () => {
    const response = await getRequest({
      url: `/posts/get-post-by-tags?SYMBOLS=${symbol}`,
    });
    setData(response.data?.posts);
  };

  const fetchStocksData = async () => {
    const response = await getRequest({
      url: `/stocks/get-related-stocks?SYMBOLS=${symbol}`,
    });
    setStockData(response.data?.stocks);
  };

  useEffect(() => {
    fetchData();
  }, [symbol]);

  useEffect(() => {
    fetchStocksData();
  }, [symbol]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        boxShadow: "none",
        border: "1px solid #e0e0e0",
        height: 48,
      }}
      style={theme ? light : dark}
    >
      <Box>
        <Box sx={{ border: "none", width: "88vw" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={t("About")}
              sx={{ textTransform: "none", fontSize: 16, fontWeight: 600 }}
              {...a11yProps(0)}
            />
            <Tab
              label={t("Stocks")}
              sx={{ textTransform: "none", fontSize: 16, fontWeight: 600 }}
              {...a11yProps(1)}
            />
            <Tab
              label={t("News")}
              sx={{ textTransform: "none", fontSize: 16, fontWeight: 600 }}
              {...a11yProps(2)}
            />

            <Tab
              label={t("Corporate")}
              sx={{ textTransform: "none", fontSize: 16, fontWeight: 600 }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <StockDetails data={data} symbol={symbol} />
          <br />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AllStocks data={stockdata} />

          <br />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <RelatedNews symbol={symbol} />
          <br />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <br />
          <Corporation symbol={symbol} />
          <br />
        </TabPanel>
      </Box>
    </Box>
  );
}
