import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import SocialPostForm from "../../../common/components/social-post-form";
import { StockNewsContext } from "../../../../context_for_stocks_news/StocksNewsContext";

const EditPost = ({ post_data, close, post_type }) => {
  const [open, setOpen] = useState(false);
  const { setOpenConfirmationBox } = useContext(StockNewsContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    close();
  };

  const confirmationBox = () => {
    setOpen(false);
    let update = {
      status: true,
      text: "Editing this post type is not supported at the moment.",
      fnc: handleOtherPost,
      id: false,
      from: "edit",
      type: 0,
    };
    setOpenConfirmationBox(update);
    close();
  };

  const handleOtherPost = () => {
    // setOpen(false);
    close();
  };

  return (
    <Box>
      <button
        style={{
          width: "100%",
          textAlign: "center",
          background: "none",
          border: "none",
        }}
        type="button"
        className="btn btn-light"
        onClick={post_type === 1 ? handleClickOpen : confirmationBox}
      >
        Edit
      </button>
      <SocialPostForm
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
        post_data={post_data}
        close={close}
      />
    </Box>
  );
};

EditPost.propTypes = {
  post_data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  post_type: PropTypes.number.isRequired,
};

export default EditPost;
