import React, { useContext } from "react";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { Box, Typography } from "@mui/material";
import DefaultStocksCard from "../../layout/stocks/default";
import { Stack } from "@mui/system";
import FuLoader from "../../shared/FuLoader";
import { Cookies } from "react-cookie";
const AllStocks = ({ data, loading }) => {
  const { selectedTagsStocks } = useContext(StockNewsContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const sortedStocksData = [...data].sort(
    (a, b) => b.live_stock_data?.most_popular - a.live_stock_data?.most_popular
  );
  return (
    <>
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        sx={{
          pt: 1,
          pb: 15,
        }}
      >
        {selectedTagsStocks && data.length === 0 && (
          <Typography letterSpacing={0.4} variant="h5" color="text.secondary">
            No Stocks are available in this category label
          </Typography>
        )}
        {sortedStocksData.map((stock, idx) => (
          <Box key={`all-stocks-${idx}`} width={"100%"}>
            <DefaultStocksCard stock={stock} />
          </Box>
        ))}
        {loading && <FuLoader height={"30px"} />}
      </Stack>
    </>
  );
};

export default AllStocks;
