import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const ResultForStocks = ({ searchInput, searchedStocksData }) => {
  const navigate = useNavigate();

  const [showAllResults, setShowAllResults] = useState(false);
  const [numResults, setNumResults] = useState(4);

  const toggleShowResults = () => {
    setShowAllResults(!showAllResults);
    if (!showAllResults) {
      setNumResults(searchedStocksData.length);
    } else {
      setNumResults(4);
    }
  };
  return (
    <Box>
      {searchInput.length !== 0 &&
        searchedStocksData?.slice(0, numResults)?.map((stock, idx) => (
          <Grid
            container
            spacing={2}
            pl={2}
            p={2}
            onClick={() => {
              navigate(`/stock/${stock.SYMBOLS}`);
            }}
          >
            <Grid item xs={10} display="grid" ml={1}>
              <Typography
                letterSpacing={0.4}
                variant="p"
                sx={{ fontSize: "14px" }}
              >
                {stock.longName}
              </Typography>
              <Typography
                letterSpacing={0.4}
                variant="p"
                sx={{ fontSize: "12px", mt: 0.9, fontWeight: "700" }}
              >
                {stock.SYMBOLS}
              </Typography>
            </Grid>
          </Grid>
        ))}

      {searchInput.length !== 0 && searchedStocksData?.length === 0 && (
        <Box
          sx={{
            p: 1,
            pl: 3,
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          No stock found
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {searchInput.length !== 0 && (
          <Button
            onClick={toggleShowResults}
            sx={{
              textTransform: "none",
              fontSize: 14,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {showAllResults ? "Show Less" : "Show More"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

ResultForStocks.propTypes = {
  searchInput: PropTypes.string.isRequired,
};

export default ResultForStocks;
