import { React, useEffect, useState, useContext } from "react";
import "../index.css";
import { useNavigate, useParams } from "react-router-dom";
import { backend_url } from "../../../http-common";
import DefaultNewsCard from "../../layout/base/default";
import { useTranslation } from "react-i18next";

const NewsDetails = () => {
  const { newsId } = useParams();
  const [news, setNews] = useState();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    fetchNews();
  }, [newsId]);

  const fetchNews = () => {
    const userLanguage = i18n.language || "en";
    fetch(`${backend_url}/getNewsById/${newsId}?lan=${userLanguage}`)
      .then((res) => res.json())
      .then((d) => {
        setNews(() => d.news[0]);
      })

      .catch((err) => console.log(err));
  };

  return <div>{news ? <DefaultNewsCard news={news} /> : null}</div>;
};

export default NewsDetails;
