import { Alert } from "@mui/material";
import React from "react";
// import "../../index.css";

const AlertComponent = ({ message }) => {
  return (
    <div
      className="alert_wrong"
      style={{ color: message === "Username is available" ? "green" : "red" }}
    >
      {/* <Alert
        variant="filled"
        severity={
          message === "invalid password"
            ? "error"
            : message === "invalid user"
            ? "info"
            : "success"
        }
      >
        {message}
      </Alert> */}
      {message ? message : "Wrong Password"}
    </div>
  );
};

export default AlertComponent;
