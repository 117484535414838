import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SelectLanguage({
  languageModalopen,
  handleLanguageModalOpen,
  handleLanguageModalClose,
}) {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language || "en");

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18next", i18n.language);
    handleLanguageModalClose();
  };

  return (
    <div>
      <Modal
        open={languageModalopen}
        onClose={handleLanguageModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            letterSpacing={0.4}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {t("Choose a Language")}
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <FormControl>
              <RadioGroup
                value={language}
                onChange={handleChange}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="en"
                  control={<Radio />}
                  label="English"
                />
                <FormControlLabel
                  value="hi"
                  control={<Radio />}
                  label="हिन्दी"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              sx={{
                background: "linear-gradient(90deg, #5D20D2 0%, #C71094 100%)",
                boxShadow: "0px 4px 4px rgba(137, 21, 254, 0.25)",
                borderRadius: "8px",
                height: 30,
                pl: 2,
                pr: 2,
                color: "white",
                width: 130,
                textTransform: "none",
              }}
              onClick={() => changeLanguage(language)}
            >
              {t("Select")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
