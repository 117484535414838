export const uploadImage = async (image) => {
  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", "h41kkssr");

  try {
    let res = await fetch(
      `https://api.cloudinary.com/v1_1/djfgbivby/image/upload`,
      {
        method: "post",
        body: data,
      }
    );
    const urlData = await res.json();

    return urlData.url;
  } catch (error) {}
};

export const validateImg = (e, setFieldValue, setImage, setImagePreview) => {
  const file = e.target.files[0];
  if (file.size >= 1048576) {
    return alert("Max file size is 1MB.");
  } else {
    setFieldValue("image", file);
    setFieldValue("imagePreview", URL.createObjectURL(file));
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  }
};
