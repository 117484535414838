import { STATUSES } from "../../constants/constants";
import { backend_url } from "../../http-common";
import { getRequest } from "../../services/request";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchRoomsExpert = createAsyncThunk(
  "rooms/fetchExperts",
  async (arg, { getState }) => {
    const {
      rooms: { roomsList, filter, pageSize, search },
    } = getState();
    let response = await getRequest({
      url: `/experts/get-experts?page=${1}&search=${search}&language=${
        filter.language
      }&knowledge=${filter.knowledge}&experties=${filter.experties}&rating=${
        filter.ratings
      }&isSebiRegistered=${filter.isSebiRegistered}`,
    });
    return response?.data?.experts;
  }
);

const roomSlice = createSlice({
  name: "room",
  initialState: {
    roomsList: [],
    filter: {
      categories: [],
      experties: [],
      language: [],
      knowledge: [],
      ratings: "All",
      isSebiRegistered: "",
      isCertified: "",
    },
    search: "",
    pageSize: 20,
    pageNumber: 1,
    Sort: "All",
  },
  reducers: {
    // deletePost(state, action) {
    //   let new_state = state.data.filter((item) => item._id !== action.payload);
    //   state.data = new_state;
    //   return state;
    // // },
    updatePageNumber: (state, action) => {
      state.pageNumber += 1;
    },
    updateLanguage: (state, action) => {
      state.filter.language = action.payload;
    },
    updateFilter: (state, action) => {
      state.filter.language = action.payload.languages;
      state.filter.knowledge = action.payload.knowledge;
      state.filter.experties = action.payload.experties;
      state.filter.ratings = action.payload.ratings;
      state.filter.isSebiRegistered = action.payload.isSebiRegistered;
      state.filter.isCertified = action.payload.isCertified;
    },
    updateSearch: (state, action) => {
      state.search = action.payload.search;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchPosts.pending, (state, action) => {
      //   state.status = STATUSES.LOADING;
      // })
      .addCase(fetchRoomsExpert.fulfilled, (state, action) => {
        state.roomsList = action.payload;
      });
    // .addCase(fetchPosts.rejected, (state, action) => {
    //   state.status = STATUSES.ERROR;
    // });
  },
});

export const {
  addRoomLists,
  updatePageNumber,
  updateLanguage,
  updateFilter,
  updateSearch,
} = roomSlice.actions;
export default roomSlice.reducer;
