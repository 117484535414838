import React, { useMemo } from "react";
import { AiOutlineStock } from "react-icons/ai";
import { BiNews } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Badge } from "@mui/material";
import { BsLaptop } from "react-icons/bs";

function UseMenu() {
  const { t, i18n } = useTranslation();
  // console.log(t("Social"));
  const MENU = useMemo(
    () => [
      {
        title: t("Social"),
        icon: <IoIosPeople />,
        link: "/",
      },
      {
        title: t("News"),
        icon: <BiNews />,
        link: "/news",
      },
      {
        title: t("Stocks"),
        icon: <AiOutlineStock />,
        link: "/stocks",
      },
      {
        title: (
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                left: `${37}px`,
                marginLeft: `${-3}px`,
                backgroundColor: "#dc3545",
                height: "16px",
                width: "30px",
                fontSize: "10px",
              },
            }}
            badgeContent={"New"}
          >
            {t("Rooms")}
          </Badge>
        ),
        icon: <BsLaptop />,
        link: "/rooms",
      },
    ],
    [i18n?.language]
  );
  return useMemo(() => {
    return {
      MENU,
    };
  }, [MENU, i18n?.language]);
}

export default UseMenu;
