import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const FuButton = ({ onclick, children, ...props }) => {
  return (
    <Button
      {...props}
      aria-label="open drawer"
      edge="start"
      sx={{
        float: "right",
        backgroundColor: "var(--fundu_color)",
        fontSize: "12px",
        color: "white",
        borderRadius: "29px",
        textTransform: "none",
        letterSpacing: 0.4,
        ":hover": {
          backgroundColor: "white",
          color: "#5D20D2",
          border: " 1px solid var(--fundu_color)",
        },
      }}
    >
      {children}
    </Button>
  );
};
FuButton.propTypes = {
  children: PropTypes.node,
  onclick: PropTypes.func,
};

export default FuButton;
