export const stocks = [
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Bitcoin",
      stock_syb: "BTC",
      stock_graph: "https://i.ibb.co/RTmXtS2/graph1.jpg",
      stock_percent:"23.08%",
      stock_move: "+1.45%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",
    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/ethereum-8-645838.png",
      stock_name: "Ethereum",
      stock_syb: "ETH",
      stock_graph: "https://i.ibb.co/RTmXtS2/graph1.jpg",
      stock_percent:"23.08%",
      stock_move: "+2.45%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",
    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Binance",
      stock_syb: "BNB",
      stock_graph: "https://i.ibb.co/85NQFJ6/graph2.jpg",
      stock_percent:"23.08%",
      stock_move: "-0.35%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",

    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Tether",
      stock_syb: "USDT",
      stock_graph: "https://i.ibb.co/RTmXtS2/graph1.jpg",
      stock_percent:"23.08%",
      stock_move: "+3.55%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",

    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Solana",
      stock_syb: "SOL",
      stock_graph: "https://i.ibb.co/85NQFJ6/graph2.jpg",
      stock_percent:"23.08%",
      stock_move: "-1.25%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",

    },
    {
        stock_img:
          "https://cdn.iconscout.com/icon/free/png-64/ethereum-8-645838.png",
        stock_name: "Ethereum",
        stock_syb: "ETH",
        stock_graph: "https://i.ibb.co/RTmXtS2/graph1.jpg",
        stock_percent:"23.08%",
        stock_move: "+2.45%",
      },
      {
        stock_img:
          "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
        stock_name: "Binance",
        stock_syb: "BNB",
        stock_graph: "https://i.ibb.co/85NQFJ6/graph2.jpg",
        stock_percent:"23.08%",
        stock_move: "-0.35%",
      },
      {
        stock_img:
          "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
        stock_name: "Tether",
        stock_syb: "USDT",
        stock_graph: "https://i.ibb.co/RTmXtS2/graph1.jpg",
        stock_percent:"23.08%",
        stock_move: "+3.55%",
      },
      {
        stock_img:
          "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
        stock_name: "Solana",
        stock_syb: "SOL",
        stock_graph: "https://i.ibb.co/85NQFJ6/graph2.jpg",
        stock_percent:"23.08%",
        stock_move: "-1.25%",
      },
  ];
  

  export const stock = [
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Bitcoin",
      stock_syb: "BTC",
      stock_graph: "https://i.ibb.co/9ncdS5P/Whats-App-Image-2022-10-12-at-4-15-26-PM.jpg",
      stock_move: "+1.45%",
      dark_logo: "https://i.ibb.co/PzPkY14/Whats-App-Image-2022-10-10-at-6-12-06-PM.jpg",
  
    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/ethereum-8-645838.png",
      stock_name: "Ethereum",
      stock_syb: "ETH",
      stock_graph: "https://i.ibb.co/9ncdS5P/Whats-App-Image-2022-10-12-at-4-15-26-PM.jpg",
      stock_move: "+2.45%",
      dark_logo: "https://i.ibb.co/PzPkY14/Whats-App-Image-2022-10-10-at-6-12-06-PM.jpg",
  
  
    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Binance",
      stock_syb: "BNB",
      stock_graph: "https://i.ibb.co/Qb2Bp0k/Whats-App-Image-2022-10-12-at-4-15-26-PM-1.jpg",
      stock_move: "-0.35%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",
  
    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Tether",
      stock_syb: "USDT",
      stock_graph: "https://i.ibb.co/9ncdS5P/Whats-App-Image-2022-10-12-at-4-15-26-PM.jpg",
      stock_move: "+3.55%",
      dark_logo: "https://i.ibb.co/PzPkY14/Whats-App-Image-2022-10-10-at-6-12-06-PM.jpg",
  
    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "Solana",
      stock_syb: "SOL",
      stock_graph: "https://i.ibb.co/Qb2Bp0k/Whats-App-Image-2022-10-12-at-4-15-26-PM-1.jpg",
      stock_move: "-1.25%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",
  
    },
    {
      stock_img:
        "https://cdn.iconscout.com/icon/free/png-64/bitcoin-3628064-3030445.png",
      stock_name: "XRP",
      stock_syb: "XRP",
      stock_graph: "https://i.ibb.co/Qb2Bp0k/Whats-App-Image-2022-10-12-at-4-15-26-PM-1.jpg",
      stock_move: "-5.15%",
      dark_logo: "https://i.ibb.co/MSwcSpd/Whats-App-Image-2022-10-10-at-6-12-07-PM.jpg",
  
  
    },
  ];
  

  export const stocksTags = [
    "Energy Minerals",
    "Technology Services",
    "Consumer Non-Durables",
    "Communications",
    "Distribution Services",
    "Finance",
    "Medical",
    "Oil_and_Energy",
    "Retail_and_Wholesale",
    "Technology",
    "Transportation",
    "Utilities",
  ];
  