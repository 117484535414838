import React, { useContext, useState, useEffect } from "react";
import "../../../index.css";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../../../context/ThemeContext";
import Dropdownmaterial from "../../../../common/components/dropdown-material";
import ReactPlayer from "react-player"; // for youtube
import { BiMessageDetail } from "react-icons/bi";
import Queryimg from "../../../../../assets/Queryimg.jpeg";
import Card from "@mui/material/Card";
import {
  formatDate,
  capitalFirstLetter,
  time_difference,
  checkFollow,
} from "../../../../common-functions/FormatDate";
import ImgPlaceholder from "../../../../../assets/image_placeholder.png";
import Typography from "@mui/material/Typography";
import { Cookies } from "react-cookie";
import PostComments from "../../post-comments";
import UpvoteDownvoteButtons from "../../upvote-downvote-buttons";
import { BsDot } from "react-icons/bs";
import PostPoll from "../../post-poll";
import PostPrediction from "../../post-prediction";
import LinkPreviewCard from "../../../../common/components/create-edit-post-popup/LinkPreviewCard";
import ShareDialog from "../../share-popup";
import Close from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
import { doRequest } from "../../../../../services/request";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import FuLoader from "../../../../shared/FuLoader";

const useStyles = makeStyles({
  divider: {
    backgroundColor: "#f7f6f9",
    color: "#f7f6f9",
    opacity: 0.8,
  },
});

export default function AllPostCard({
  item,
  fetchPost,
  idx,
  clipped,
  search,
  getData,
  data,
  loading,
}) {
  // console.log("item me kya hai", item);

  const [comment, setComment] = useState({ id: "", status: false });
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [showMore, setShowMore] = useState({ id: "", status: false });
  const { setLinkUser, myFollowings, theme, handleFollow } =
    useContext(ThemeContext);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const [showFullImage, setShowFullImage] = useState(false);
  const [fullImage, setFullImage] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);
  const [translatedText, setTranslatedText] = useState({});
  const { i18n } = useTranslation();
  const classes = useStyles();

  const handleImageClick = (image) => {
    setShowFullImage(true);
    setFullImage(image);
  };
  const handleComment = (id, e) => {
    const update = { id: id, status: !comment.status };
    setComment(() => update);
  };

  const handleShowMore = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const update = { id: id, status: !showMore.status };
    setShowMore(update);
  };

  const isYoutubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // function is for highlighting text
  const makeTextHighlighted = (textToHighlight) => {
    return (
      <>
        {textToHighlight?.split(" ").map((text) =>
          text?.startsWith("www.") ? (
            <a
              style={{
                textDecoration: "none",
              }}
              href={`http://${text}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                style={{
                  color: "#5D20D2",
                }}
                onMouseOver={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              >
                {" "}
                {text}{" "}
              </span>
            </a>
          ) : text?.startsWith("https:") ? (
            <a
              style={{
                textDecoration: "none",
              }}
              href={text}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                style={{
                  color: "#5D20D2",
                }}
                onMouseOver={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              >
                {" "}
                {text}{" "}
              </span>
            </a>
          ) : text?.startsWith("http:") ? (
            <a
              style={{
                textDecoration: "none",
              }}
              href={text}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                style={{
                  color: "#5D20D2",
                }}
                onMouseOver={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              >
                {" "}
                {text}{" "}
              </span>
            </a>
          ) : text?.includes("@") ? (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={`/user/${text.split("@")[1].split(",")[0]}`}
            >
              <span
                style={{
                  color: "#5d20d2",
                }}
              >
                {" "}
                {text}{" "}
              </span>
            </Link>
          ) : text?.includes("$") ? (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={`/stock/${text.split("$")[1].split(",")[0]}`}
            >
              <span
                style={{
                  color: "#ec6391",
                }}
              >
                {" "}
                {text}{" "}
              </span>
            </Link>
          ) : (
            " " + text + " "
          )
        )}
      </>
    );
  };

  useEffect(() => {
    if (tooltipIsOpen) {
      const timeoutId = setTimeout(() => setTooltipIsOpen(false), 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [tooltipIsOpen]);

  const translateText = async (
    title = "",
    description = "",
    query_ques,
    post_type
  ) => {
    if (post_type === 3) {
      description = query_ques;
    }
    let payload = {
      obj_to_translate: {
        title,
        description,
      },
      target_language: i18n.language,
    };
    payload = JSON.stringify(payload);
    let tranlation = await doRequest({
      url: "/translate-all",
      body: payload,
      method: "POST",
    });
    setIsTranslated(true);
    setTranslatedText(tranlation.data.results);
  };

  return (
    <>
      <Card
        className="notranslate"
        sx={{
          p: 2,
          pb: 0.7,
          pr: 1,
          mb: 0.7,
          boxShadow: "none",
          border: "1px solid #e0e0e0",
        }}
        key={idx}
      >
        {item?.reshared_by ? (
          <Box className="reshared_div">
            Reshared by
            <Link
              to={`/user/${item?.reshared_by}`}
              style={{ textDecoration: "none", marginLeft: "4px" }}
            >{`@${item?.reshared_by}`}</Link>
            <Typography letterSpacing={0.4} sx={{ display: "inline" }}>
              &nbsp; {`${time_difference(item?.Created_at).join("")} ago`}
            </Typography>
            <hr />
          </Box>
        ) : null}

        <Box key={idx}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Link
                to={
                  item?.userDetails.isExpert
                    ? `/rooms/${item.user}`
                    : `/user/${item.user}`
                }
                style={{
                  textDecoration: "none",
                  display: "flex",
                }}
                onClick={() => setLinkUser(item.user)}
              >
                <Avatar
                  src={item.userDetails?.userpic_url || ImgPlaceholder}
                  alt={item.user + "_profile"}
                  sx={{
                    width: 40,
                    height: 40,
                    border: "1px solid #D8D1E3",
                  }}
                />
              </Link>{" "}
              <Box sx={{ width: "100%", display: "grid" }}>
                <Box display={"flex"} ml={1.5}>
                  <Link
                    to={
                      item?.userDetails.isExpert
                        ? `/rooms/${item.user}`
                        : `/user/${item.user}`
                    }
                    style={{
                      textDecoration: "none",
                      display: "flex",
                    }}
                    onClick={() => setLinkUser(item.user)}
                  >
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#372B47",
                      }}
                    >
                      {`${capitalFirstLetter(
                        item.userDetails?.First_Name
                      )} ${capitalFirstLetter(item.userDetails?.last_name)}`}
                    </Typography>
                  </Link>
                  {user && user.username !== item.user && (
                    <button
                      style={{
                        backgroundColor: theme ? "white" : "#313342",
                        color: !theme ? "#9772FF" : "var(--fundu_color)",
                      }}
                      className="fol_button_user"
                      onClick={() => handleFollow(idx, item.user)}
                    >
                      {checkFollow(myFollowings, item.user) ? null : (
                        <BsDot
                          style={{ fontSize: "23px", marginLeft: "-5px" }}
                        />
                      )}
                      {checkFollow(myFollowings, item.user) ? "" : "Follow"}
                    </button>
                  )}
                </Box>
                <Box display={"flex"} ml={1} mt={-1}>
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: "12px",
                      ml: 0.5,
                      color: "#8b908f",
                      mt: 0.3,
                    }}
                  >{`@${item.user} `}</Typography>
                  <BsDot
                    style={{
                      color: "#8b908f",
                      fontSize: "23px",
                      marginLeft: "-5px",
                    }}
                  />
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: "12px",
                      color: "#8b908f",
                      mt: 0.3,
                      ml: -0.5,
                    }}
                  >
                    {`${time_difference(item.Created_at).join("")}`}
                  </Typography>
                  <BsDot
                    style={{
                      color: "#8b908f",
                      fontSize: "23px",
                      marginLeft: "-5px",
                    }}
                  />
                  <Typography
                    letterSpacing={0.4}
                    sx={{
                      fontSize: "12px",
                      color: "#8b908f",
                      mt: 0.3,
                      ml: -0.5,
                    }}
                  >
                    {formatDate(item.Created_at)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ mt: -1.5 }}>
              <Dropdownmaterial
                postData={item}
                postId={item.unique_id}
                post_id={item._id}
                getData={getData}
                post_type={item.post_type}
              />
            </Box>
          </Box>
        </Box>
        <Helmet>
          {/* <title>{item?.Title} - askFundu</title> */}
          <link rel="canonical" href={`${item?.Title || item?.Text}`}></link>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Article",
              headline: item?.Title,
              image: item?.Image || "",
              author: {
                "@type": "user",
                name: item.user,
              },
              publisher: {
                "@type": "Organization",
                name: "https://askfundu.com",
                logo: {
                  "@type": "ImageObject",
                  url: "",
                },
              },
              datePublished: formatDate(item.Created_at),
            })}
          </script>
        </Helmet>
        {item.post_type === 3 && <img src={Queryimg} alt="img" />}
        <Typography
          letterSpacing={0.4}
          sx={{ fontSize: 14, fontWeight: 600, mt: 1.2, color: "#372B47" }}
          variant="h2"
        >
          {item.Title}
        </Typography>

        {item.post_type === 4 && (
          <PostPrediction
            isOverUnder={item.isOverUnder}
            prediction_duration={item.prediction_duration}
            post_id={item._id}
            prediction_price={item.prediction_price}
            prediction_stock={item.prediction_stock}
            postId={item.unique_id}
          />
        )}

        {item.post_type === 2 && (
          <PostPoll
            poll_question={item.poll_question}
            poll_options_text={item.poll_options_text}
            postId={item.unique_id}
            post_id={item._id}
            poll_options_count={item.poll_options_count}
          />
        )}
        {/* <Link
          to={`/post/${item.unique_id}`}
          style={{
            textDecoration: "none",
            color: !theme ? "white" : "black",
          }}
        > */}
        {item.post_type === 3 && (
          <>
            <Typography
              letterSpacing={0.4}
              sx={{ fontSize: 14, userSelect: "text" }}
            >
              {item.query_question}
            </Typography>
            {isTranslated && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{ fontSize: 14, fontWeight: 600, mt: 1.2 }}
                >
                  {translatedText?.title}
                </Typography>
                <Typography
                  letterSpacing={0.4}
                  sx={{ fontSize: 14, userSelect: "text" }}
                >
                  {translatedText?.description}
                </Typography>
              </>
            )}
          </>
        )}
        {/* </Link> */}

        {!(item.post_type === 3) && (
          <Box>
            {clipped && item.Text?.length > 300 ? (
              <Box>
                {item.unique_id === showMore.id && showMore.status ? (
                  <Typography letterSpacing={0.4}>
                    {/* <Link
                      to={`/post/${item.unique_id}`}
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: !theme ? "white" : "black",
                      }}
                    > */}
                    <Typography
                      letterSpacing={0.4}
                      sx={{
                        whiteSpace: "pre-line",
                        fontSize: 14,
                        userSelect: "text",
                        letterSpacing: 0.7,
                      }}
                    >
                      {makeTextHighlighted(item?.Text)}
                    </Typography>
                    {/* </Link> */}
                  </Typography>
                ) : (
                  <Typography letterSpacing={0.4} sx={{ fontSize: 14 }}>
                    {/* <Link
                      to={`/post/${item.unique_id}`}
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: !theme ? "white" : "black",
                      }}
                    > */}
                    {makeTextHighlighted(item.Text.substring(0, 300))}
                    <Button
                      disableRipple
                      variant="text"
                      sx={{
                        textTransform: "none",
                        display: "inline",
                        fontWeight: 600,
                        fontSize: 14,
                        pb: 1,
                      }}
                      onClick={(e) => handleShowMore(e, item.unique_id)}
                    >
                      ...see more
                    </Button>
                    {/* </Link> */}
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                {/* <Link
                  to={`/post/${item.unique_id}`}
                  style={{
                    textDecoration: "none",
                    fontSize: "14px",
                    color: !theme ? "white" : "black",
                  }}
                > */}
                <Typography
                  letterSpacing={0.4}
                  sx={{
                    whiteSpace: "pre-line",
                    fontSize: 14,
                    userSelect: "text",
                  }}
                >
                  {makeTextHighlighted(item?.Text)}
                </Typography>
                {/* </Link> */}
              </>
            )}
          </Box>
        )}

        {/* Below is for translated text */}
        {isTranslated && (
          <>
            {!(item.post_type === 3) && (
              <>
                <Typography
                  letterSpacing={0.4}
                  sx={{ fontSize: 14, fontWeight: 600, mt: 1.2 }}
                  variant="h2"
                >
                  {translatedText?.title}
                </Typography>
                {clipped && item.Text?.length > 300 ? (
                  <Box>
                    {item.unique_id === showMore.id && showMore.status ? (
                      <Typography letterSpacing={0.4}>
                        <Link
                          to={`/post/${item.unique_id}`}
                          style={{
                            textDecoration: "none",
                            fontSize: "14px",
                            color: !theme ? "white" : "black",
                          }}
                        >
                          <Typography
                            letterSpacing={0.4}
                            sx={{
                              whiteSpace: "pre-line",
                              fontSize: 14,
                              userSelect: "text",
                            }}
                          >
                            {makeTextHighlighted(translatedText?.description)}
                          </Typography>
                        </Link>
                      </Typography>
                    ) : (
                      <Typography letterSpacing={0.4} sx={{ fontSize: 14 }}>
                        <Link
                          to={`/post/${item.unique_id}`}
                          style={{
                            textDecoration: "none",
                            fontSize: "14px",
                            color: !theme ? "white" : "black",
                          }}
                        >
                          {makeTextHighlighted(translatedText?.description)}
                          <Button
                            disableRipple
                            variant="text"
                            sx={{
                              textTransform: "none",
                              display: "inline",
                              fontWeight: 600,
                              fontSize: 14,
                              pb: 1,
                            }}
                            onClick={(e) => handleShowMore(e, item.unique_id)}
                          >
                            ...see more
                          </Button>
                        </Link>
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <>
                    <Link
                      to={`/post/${item.unique_id}`}
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: !theme ? "white" : "black",
                      }}
                    >
                      <Typography
                        letterSpacing={0.4}
                        sx={{
                          whiteSpace: "pre-line",
                          fontSize: 14,
                          userSelect: "text",
                        }}
                      >
                        {makeTextHighlighted(translatedText?.description)}
                      </Typography>
                    </Link>
                  </>
                )}
              </>
            )}
          </>
        )}
        {/* Above is for translated text */}

        {item?.newsDetails ? (
          isYoutubeUrl(item?.newsDetails?.link) ? (
            <ReactPlayer
              width="100%"
              controls={true}
              url={item?.newsDetails?.link}
            />
          ) : (
            <a href={item?.newsDetails?.link} target="_blank">
              <LinkPreviewCard linkDetails={item?.newsDetails} />
            </a>
          )
        ) : null}

        {item && item.Image ? (
          <>
            <Box
              className="posts_img"
              onClick={() => handleImageClick(item.Image)}
            >
              <img src={item.Image} alt="img" />
            </Box>
            <Dialog
              sx={{ height: "100vh" }}
              open={showFullImage}
              onClose={() => setShowFullImage(false)}
            >
              <Button
                sx={{ ml: "88%" }}
                onClick={() => setShowFullImage(false)}
              >
                <Close />{" "}
              </Button>
              <DialogContent mt={-4}>
                <img
                  style={{
                    display: "block",
                    height: "auto",
                    width: "auto",
                    minWidth: "18rem",
                    maxHeight: "calc(100vh - 6rem)",
                    maxWidth: "calc(100vw - 60rem)",
                    objectFit: "contain",
                    marginTop: "-25px",
                  }}
                  src={fullImage}
                  alt="img"
                />
              </DialogContent>
            </Dialog>
          </>
        ) : null}

        {item.Tag?.length > 0 && (
          <Box className="tagsDiv" sx={{ mb: 1 }}>
            {item.Tag.map((tag, idx) => (
              <Link
                key={`link-tag-${idx}`}
                to={user ? `/tagged-content/${tag}` : "/welcome"}
                style={{ textDecoration: "none" }}
              >
                <button style={{ fontSize: "12px" }}>{tag}</button>
              </Link>
            ))}
          </Box>
        )}

        {item.post_type !== 2 && (
          <>
            {!isTranslated ? (
              <Typography
                letterSpacing={0.4}
                sx={{
                  fontSize: "14px",
                  color: "#5d20d2",
                  cursor: "pointer",
                }}
                onClick={() =>
                  translateText(
                    item.Title,
                    item?.Text,
                    item?.query_question,
                    item.post_type
                  )
                }
              >
                {t("Translate")}
              </Typography>
            ) : null}

            <Divider className={classes.divider} />

            <Box
              sx={{
                ml: -1.5,
                color: !theme ? "white" : "black",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <UpvoteDownvoteButtons
                  data={data}
                  postId={item.unique_id}
                  post_id={item._id}
                  upvote={item?.upvote_count}
                  downvote={item?.downvote_count}
                  comment_count={item?.Comment_count}
                  username={item?.userDetails?.username}
                />
                <Button
                  style={{
                    color: !theme ? "white" : "black",
                  }}
                  onClick={(e) => handleComment(item.unique_id, e)}
                >
                  <Box>
                    {item.post_type === 3 ? (
                      <BiMessageDetail
                        style={{
                          fontSize: "20px",
                          color: "#3a4044",
                          marginTop: "-5px",
                        }}
                      />
                    ) : (
                      <BiMessageDetail
                        style={{
                          fontSize: "20px",
                          color: "#3a4044",
                          marginTop: "-5px",
                        }}
                      />
                    )}
                  </Box>
                  <Typography ml={0.5}> {item?.comment_count}</Typography>
                </Button>
              </Box>

              <Box>
                <ShareDialog unique_id={item.unique_id} />
              </Box>
            </Box>

            {item.unique_id === comment.id && comment.status && (
              <PostComments
                postId={item.unique_id}
                from={item.post_type === 3 ? "query" : ""}
                fetchPost={fetchPost}
              />
            )}
          </>
        )}
      </Card>

      {loading && <FuLoader height={"30px"} />}
    </>
  );
}
