import React, { useContext, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Box } from "@mui/system";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import { StockNewsContext } from "../../../../../../context_for_stocks_news/StocksNewsContext";
import { ThemeContext } from "../../../../../../context/ThemeContext";
import { backend_url } from "../../../../../../http-common";

const CommentDropdown = ({
  commentId,
  commentData,
  setCommentData,
  updateCommentCount,
  handleOpenEditCommentInput,
  fetchPost,
}) => {
  const { setOpenConfirmationBox } = useContext(StockNewsContext);
  const { setShowAlertBox } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 40;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteCommentConfirmation = (id) => {
    let update = {
      status: true,
      text: "Do you want to delete this comment?",
      fnc: handleDeleteComment,
      id: id,
    };
    setOpenConfirmationBox(update);
  };

  function handleDeleteComment(id) {
    let newCommentArray = commentData.filter(
      (item) => item.comment_unique_id !== id
    );
    setCommentData(() => newCommentArray);
    updateCommentCount(commentData.length - 1);
    handleDeleteCommentBackend(id);
    fetchPost();
  }

  const handleDeleteCommentBackend = (id) => {
    fetch(`${backend_url}/delete-comment/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        let newStatus = {
          status: res.status,
          from: "Comment",
          as: "Deleted",
        };
        setShowAlertBox(() => newStatus);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenEditCommentInput(commentId);
          }}
        >
          <BiEdit
            style={{
              fontSize: "20px",
              marginLeft: "10px",
            }}
          />
          <Typography letterSpacing={0.4} ml={1} mt={1}>
            Edit
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            handleDeleteCommentConfirmation(commentId);
          }}
        >
          <RiDeleteBin6Line
            style={{
              fontSize: "20px",
              marginLeft: "10px",
            }}
          />
          <Typography letterSpacing={0.4} ml={1} mt={1}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

CommentDropdown.propTypes = {
  commentId: PropTypes.string.isRequired,
  commentData: PropTypes.array.isRequired,
  setCommentData: PropTypes.func.isRequired,
  updateCommentCount: PropTypes.func.isRequired,
  handleOpenEditCommentInput: PropTypes.func,
  fetchPost: PropTypes.func,
};

export default CommentDropdown;
