import React, { useState } from "react";
import ResultsNotFound from "./components/result-not-found";
import ResultForUsers from "./components/result-for-users";
import ResultForPosts from "./components/result-for-posts";
import ResultForStocks from "./components/result-for-stocks";
import ResultForNews from "./components/result-for-news";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SuggestionBox = ({
  searchInput,
  searchedNewsData,
  searchedPostsData,
  searchedStocksData,
  searchedUsersData,
  label,
}) => {
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState("users");

  const displayResultUsers =
    searchedUsersData?.length > 100 ? "100+" : searchedUsersData?.length;

  const displayResultPosts =
    searchedUsersData?.length > 100 ? "100+" : searchedPostsData?.length;

  const displayResultNews =
    searchedUsersData?.length > 100 ? "100+" : searchedNewsData?.length;

  const displayResultStocks =
    searchedUsersData?.length > 100 ? "100+" : searchedStocksData?.length;

  const handleSectionToggle = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <>
      {!searchedUsersData?.length &&
      !searchedPostsData?.length &&
      !searchedStocksData?.length &&
      !searchedNewsData?.length ? (
        <ResultsNotFound searchInput={searchInput} />
      ) : (
        <Box
          sx={{
            height: 520,
            mt: 2,
            mb: 2,
            zIndex: "10000",
            boxShadow: "none",
            border: "1px solid #e0e0e0",
            width: isDesktop ? 470 : "100%",
          }}
          className="list-group"
        >
          <Box
            sx={{
              fontSize: 17,
              height: 50,
              p: 2,
              pl: 3,
              cursor: "context-menu",
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
            }}
            onClick={() => handleSectionToggle("users")}
          >
            <Typography letterSpacing={0.4} fontWeight={600}>
              <span style={{ color: "var(--fundu_color)" }}>{searchInput}</span>{" "}
              in Users ({displayResultUsers})
            </Typography>

            <ExpandMoreIcon
              onClick={(e) => {
                e.stopPropagation();
                handleSectionToggle("users");
              }}
            />
          </Box>
          <Divider
            style={{
              background: "black",
              margin: 5,
            }}
          />
          {openSection === "users" && (
            <Box>
              <ResultForUsers
                searchInput={searchInput}
                searchedUsersData={searchedUsersData}
              />
            </Box>
          )}

          <Box
            sx={{
              fontSize: 17,
              height: 50,
              p: 2,
              pl: 3,
              cursor: "context-menu",
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
            }}
            onClick={() => handleSectionToggle("posts")}
          >
            <Typography letterSpacing={0.4} fontWeight={600}>
              <span style={{ color: "var(--fundu_color)" }}>{searchInput}</span>{" "}
              in Posts ({displayResultPosts})
            </Typography>

            <ExpandMoreIcon
              onClick={(e) => {
                e.stopPropagation();
                handleSectionToggle("posts");
              }}
            />
          </Box>
          <Divider
            style={{
              background: "black",
              margin: 5,
            }}
          />

          {openSection === "posts" && (
            <Box>
              <ResultForPosts
                searchInput={searchInput}
                searchedPostsData={searchedPostsData}
              />
            </Box>
          )}

          <Box
            sx={{
              fontSize: 17,
              height: 50,
              p: 2,
              pl: 3,
              cursor: "context-menu",
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
            }}
            onClick={() => handleSectionToggle("news")}
          >
            <Typography letterSpacing={0.4} fontWeight={600}>
              <span style={{ color: "var(--fundu_color)" }}>{searchInput}</span>{" "}
              in News ({displayResultNews})
            </Typography>

            <ExpandMoreIcon
              onClick={(e) => {
                e.stopPropagation();
                handleSectionToggle("news");
              }}
            />
          </Box>
          <Divider
            style={{
              background: "black",
              margin: 5,
            }}
          />
          {openSection === "news" && (
            <Box>
              <ResultForNews
                searchInput={searchInput}
                searchedNewsData={searchedNewsData}
              />
            </Box>
          )}

          <Box
            sx={{
              fontSize: 17,
              height: 50,
              p: 2,
              pl: 3,
              cursor: "context-menu",
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
            }}
            onClick={() => handleSectionToggle("stocks")}
          >
            <Typography letterSpacing={0.4} fontWeight={600}>
              <span style={{ color: "var(--fundu_color)" }}>{searchInput}</span>{" "}
              in Stocks ({displayResultStocks})
            </Typography>

            <ExpandMoreIcon
              onClick={(e) => {
                e.stopPropagation();
                handleSectionToggle("stocks");
              }}
            />
          </Box>
          <Divider
            style={{
              background: "black",
              margin: 5,
            }}
          />
          {openSection === "stocks" && (
            <Box>
              <ResultForStocks
                searchInput={searchInput}
                searchedStocksData={searchedStocksData}
              />
            </Box>
          )}

          {searchInput && (
            <Box>
              <Box
                sx={{
                  fontSize: "14px",
                  height: 50,
                  pt: 2,
                  ml: 2,
                  pb: 2,
                  color: "var(--fundu_color)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/result/users?q=${searchInput}`);
                }}
              >
                <AiOutlineSearch sx={{ fontSize: "18px" }} /> See all results
              </Box>
              <Divider
                style={{
                  background: "black",
                  margin: 5,
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default SuggestionBox;
