import React, { useContext, useEffect, useState } from "react";
import LineChart from "../line-chart";
import TimeSeries from "./time-series/TimeSeries";
import { getRequest } from "../../services/request";
import { useParams } from "react-router-dom";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";

const Portfolio = ({ profileUsername }) => {
  const headline = "Portfolio performance";
  const { fetchUserPortfolioStocks } = useContext(StockNewsContext);
  const { username } = useParams();

  useEffect(() => {
    fetchUserPortfolioStocks(username);
  }, [username]);

  return (
    <div style={{ marginTop: "25px", marginBottom: "25px" }}>
      <TimeSeries headline={headline} profileUsername={profileUsername} />
    </div>
  );
};
export default Portfolio;
