import React, { useContext, useMemo } from "react";
import Before from "../../suggestion-tabs/components/Before";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { CardActionArea, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { readableImage } from "../../common-functions/FormatDate";
import { useTranslation } from "react-i18next";

const MostPopularStocksRight = () => {
  const { stocksData } = useContext(StockNewsContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const sortedStocksData = useMemo(() => {
    return stocksData?.sort(
      (a, b) =>
        b.live_stock_data?.most_popular - a.live_stock_data?.most_popular
    );
  }, [stocksData]);

  return (
    <>
      <Card
        sx={{
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          boxShadow: "none",
          border: "1px solid #e0e0e0",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          p={1}
          pl={2}
          pr={2}
          borderBottom="1px solid #e0e0e0"
        >
          <Typography
            letterSpacing={0.4}
            sx={{ fontSize: 16, fontWeight: 600, color: "#372B47" }}
          >
            {t("Most Popular Stocks")}
          </Typography>

          <Typography
            letterSpacing={0.4}
            sx={{
              cursor: "pointer",
              fontSize: 12,
              color: "var(--fundu_color)",
            }}
            onClick={() => {
              navigate(`/stocks`);
            }}
          >
            {t("See All")}
          </Typography>
        </Stack>

        {sortedStocksData.length === 0 && <Before />}
        {sortedStocksData.slice(0, 5).map((stock, idx) => (
          <CardActionArea>
            <Grid
              container
              spacing={2}
              key={`most-popular-stocks-${idx}`}
              onClick={() => {
                navigate(`/stock/${stock.SYMBOLS}`);
              }}
              pl={2}
              pr={2}
              pt={1}
            >
              <Grid item xs={5} display="grid">
                <Typography
                  letterSpacing={0.4}
                  variant="p"
                  sx={{ fontSize: "12px", fontWeight: "600", color: "#372B47" }}
                >
                  {stock.SYMBOLS}
                </Typography>
                <Typography letterSpacing={0.4} sx={{ fontSize: "12px" }}>
                  {stock.longName}
                </Typography>
              </Grid>
              <Grid item xs>
                <img
                  src={readableImage(stock.graph?.data)}
                  alt="graph"
                  style={{ width: "70px", height: "50px" }}
                />
              </Grid>
              <Grid
                item
                xs
                ml={2}
                sx={{
                  mt: "5px",
                  fontSize: "14px",
                }}
              >
                {"\u20B9"} {stock.live_stock_data?.LAST}
              </Grid>
            </Grid>
          </CardActionArea>
        ))}
      </Card>
    </>
  );
};

export default MostPopularStocksRight;
