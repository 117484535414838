import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import AllStocksTab from "../../Components/all-stocks-tab";
import { useParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { Box } from "@mui/material";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import useScroll from "../../Components/common-functions/useScroll";
import MobileDefault from "../../Components/layout/mobile";
import { BREAKPOINT } from "../../constants/constants";
import useViewPort from "../../hooks/useViewPort";
import { Helmet } from "react-helmet";
import LayoutMerge from "../../Components/layout/page/merge";

const MostPopularStockPage = () => {
  const { theme } = useContext(ThemeContext);
  const { getStocks } = useContext(StockNewsContext);
  const { width } = useViewPort();
  const { handleScroll, loading } = useScroll(getStocks);

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      <Helmet>
        <title>
          Unlock the Potential of Your Trades with Our Advanced Stock Scanners
          and Comprehensive Data Analysis
        </title>
        <description>
          Get ahead in the stock market with our cutting-edge scanners and
          in-depth analysis. Our advanced tools help you identify profitable
          trades, track market trends, and make informed decisions. With
          real-time data and expert insights, our comprehensive platform gives
          you the edge you need to succeed.
        </description>
      </Helmet>
      {width < BREAKPOINT ? (
        <MobileDefault
          middleContent={
            <div>
              <AllStocksTab loading={loading} />
            </div>
          }
          handleScroll={handleScroll}
        />
      ) : (
        <LayoutMerge
          mainContent={
            <div>
              <AllStocksTab loading={loading} />
            </div>
          }
          handleScroll={handleScroll}
        />
      )}
    </Box>
  );
};

export default MostPopularStockPage;
