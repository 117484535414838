import React from "react";
import "../index.css";
import { capitalFirstLetter } from "../../common-functions/FormatDate";
import Skeleton from "@mui/material/Skeleton";
import { Avatar, Box, Typography } from "@mui/material";
import BasicCard from "../../layout/basiccard";

const Viewprofile = ({ user }) => {
  return (
    <BasicCard
      avatar={
        <div>
          <Avatar
            alt="profile"
            style={{
              border: "0.1px solid white",
            }}
            src={user?.userpic_url}
            sx={{ width: 48, height: 48 }}
          />
        </div>
      }
      name={
        <Box>
          {user ? (
            <Typography
              letterSpacing={0.4}
              sx={{ fontSize: 16, fontWeight: "600", mt: 0.6 }}
            >
              {`${capitalFirstLetter(user?.First_Name)} ${capitalFirstLetter(
                user?.last_name
              )}`}
            </Typography>
          ) : (
            <Skeleton
              variant="text"
              width={100}
              style={{ marginLeft: "10px" }}
            />
          )}
          {user ? (
            <Typography
              letterSpacing={0.4}
              sx={{ color: "#cecece", fontSize: 14, mt: -0.6 }}
            >{`@${user?.username}`}</Typography>
          ) : (
            <Skeleton
              variant="text"
              style={{ marginLeft: "10px" }}
              width={100}
            />
          )}
        </Box>
      }
    />
  );
};

export default Viewprofile;
