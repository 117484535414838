import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../Firebase";
import { Link } from "react-router-dom";

const SignIn = ({ loginFromBackend }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (errorMessage !== "") {
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      setFormData({
        email: "",
        password: "",
      });
    }
  }, [errorMessage]);

  const login = async () => {
    try {
      let user = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      loginFromBackend(formData.email);
    } catch (error) {
      console.log("error code", error.code);
      if (error.code === "auth/invalid-login-credentials") {
        setErrorMessage(
          "Email or Password is incorrect, please provide valid details!"
        );
      } else if (error.code === "auth/invalid-email") {
        setErrorMessage("please provide valid details!");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <Container maxWidth="xs">
      {errorMessage !== "" && (
        <Snackbar open={true} autoHideDuration={5000} message={errorMessage}>
          <Alert severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          name="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          required
          size="small"
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          margin="normal"
          required
          size="small"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<LockIcon />}
        >
          Login
        </Button>
      </form>
      <Typography
        sx={{
          marginBlock: 1,
        }}
      >
        Don't have an account <Link to="/signup">Sign Up</Link>{" "}
      </Typography>
    </Container>
  );
};

export default SignIn;
