import { Typography, Button, Box, Divider, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { backend_url } from "../../../../http-common";
import { Cookies } from "react-cookie";
import Progressbar from "./components/progressbar";
import { Link } from "react-router-dom";
import { formatDate } from "../../../common-functions/FormatDate";

const PostPrediction = ({
  isOverUnder,
  prediction_duration,
  prediction_price,
  prediction_stock,
  postId,
}) => {
  const [data, setData] = useState(null);
  const [agreePercent, setAgreePercent] = useState(null);
  const [totalPrediction, setTotalPrediction] = useState(null);

  const cookie = new Cookies();
  const user = cookie.get("user");

  const getPostPrediction = useCallback(() => {
    fetch(
      `${backend_url}/get-post-prediction?postId=${postId}&userId=${user?.username}`
    )
      .then((res) => res.json())
      .then((d) => {
        if (d.response !== null) {
          setData(d.response);
        }
      })
      .catch((err) => console.log(err));
  }, [postId, user?.username]);

  const getAgreePercentage = useCallback(() => {
    fetch(`${backend_url}/total-prediction/${postId}`)
      .then((res) => res.json())
      .then((d) => {
        if (d.result !== null) {
          setAgreePercent(d.result?.agreePercentage);
          setTotalPrediction(d.result?.totalPrediction);
        }
      })
      .catch((err) => console.log(err));
  }, [postId]);

  useEffect(() => {
    getPostPrediction();
    getAgreePercentage();
  }, [getAgreePercentage, getPostPrediction]);

  const handleCreatePrediction = (value) => {
    const form = {
      unique_id: uuidv4(),
      prediction_post_id: postId,
      prediction_user_id: user?.username,
      isAgree: value,
      created_at: Date.now(),
    };

    const payloadjson = JSON.stringify(form);

    fetch(`${backend_url}/create-post-prediction`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        getPostPrediction();
        getAgreePercentage();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F7F6F9",
        p: 2,
        borderRadius: "8px",
      }}
    >
      <Typography letterSpacing={0.4}>Prediction:</Typography>
      <Grid container ml={0.1} gap={0.5}>
        <Link
          to={`/stock/${prediction_stock}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          {" "}
          <Grid
            sx={{ color: "var(--fundu_color)" }}
          >{`${prediction_stock}`}</Grid>
        </Link>
        <Grid>{`will be: ${isOverUnder}`}</Grid>
        <Grid>
          <strong>{`₹${prediction_price}`}</strong>
        </Grid>
        <Grid>{`share on ${formatDate(prediction_duration)}`}</Grid>
      </Grid>
      <Divider color={"black"} />
      {agreePercent !== null &&
      user &&
      data?.prediction_post_id === postId &&
      data?.prediction_user_id === user?.username ? (
        <>
          <Progressbar agreePercent={agreePercent !== null && agreePercent} />
          <Typography letterSpacing={0.4}>
            {totalPrediction === 1
              ? `${totalPrediction} vote`
              : `${totalPrediction} votes`}
          </Typography>
        </>
      ) : user &&
        data?.prediction_post_id !== postId &&
        data?.prediction_user_id !== user?.username ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mt: 1.5,
            }}
          >
            <Button
              variant="text"
              onClick={() => handleCreatePrediction(true)}
              sx={{
                textTransform: "none",
                border: "1px solid #58BD7D",
                color: "#58BD7D",
                height: "25px",
                mr: 2,
                width: "100%",
                boderRadius: "8px",
              }}
            >
              Agree
            </Button>
            <Button
              variant="text"
              onClick={() => handleCreatePrediction(false)}
              sx={{
                textTransform: "none",
                border: "1px solid #D33535",
                color: "#D33535",
                height: "25px",
                mr: 2,
                width: "100%",
                boderRadius: "8px",
              }}
            >
              Disagree
            </Button>
          </Box>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default PostPrediction;
