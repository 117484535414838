import { createContext, useCallback, useEffect, useState } from "react";
import { backend_url } from "../http-common";
import { Cookies } from "react-cookie";
import axios from "axios";
import {
  doRequest,
  getAuthenticatedRequest,
  getRequest,
} from "../services/request";
import { getLanguageCode } from "../Components/common-functions/getLanguage";
import { googleLogout } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser, fetchLoggedInUser, setUsername } from "../store/userSlice";

export const ThemeContext = createContext();
export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(true);
  const [open, setOpen] = useState(false);
  const [isRecent, setIsRecent] = useState(true); // Set default value to true
  const [isTop, setIsTop] = useState(false);
  const [isRecentNews, setIsRecentNews] = useState(true);
  const [isTopNews, setIsTopNews] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);
  const [linkUser, setLinkUser] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [userPosts, setUserPosts] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [selectedTags, setSelectedTags] = useState("");
  const [followUsers, setFollowUsers] = useState([]);
  const [followingUsers, setFollowingUsers] = useState([]);
  const [followUsersData, setFollowUsersData] = useState([]);
  const [followingUsersData, setFollowingUsersData] = useState([]);
  const [myFollowings, setMyFollowings] = useState([]);
  const [userFollowersCount, setUserFollowersCount] = useState(0);
  const [userFollowingsCount, setUserFollowingsCount] = useState(0);
  const [addTagsSelected, setAddTagsSelected] = useState([]);
  const [showAlertBox, setShowAlertBox] = useState({
    status: "",
    from: "",
    as: "",
  });
  const [userVotes, setUserVotes] = useState([]);
  const [checked, setChecked] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [alertsData, setAlertsData] = useState([]); // this is old alerts needs to be depricated
  const [unread, setUnread] = useState(0); // this is old alerts needs to be depricated
  const [followingPosts, setFollowingPosts] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [currentPostsTab, setCurrentPostsTab] = useState("Home");
  const [alertCount, setAlertCount] = useState(0);
  const [allAlerts, setAllAlerts] = useState([]);
  const cookie = new Cookies();
  const user = cookie.get("user");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [
    isExpirationFunctionAlreadyCalledFirstTime,
    setIsExpirationFunctionAlreadyCalledFirstTime,
  ] = useState(false);
  const dispatch = useDispatch();

  const getUsers = useCallback(async () => {
    const response = await getRequest({
      url: `/get-all-users`,
    });
    const res = response.data?.response.map((item) => ({
      ...item,
      isFollowing: false,
    }));
    setUsersData(() => res);
  }, []);

  const getPosts = useCallback(
    async (
      setLastList,
      setPrevPage,
      currPage,
      setLoading,
      setScroll,
      scroll
    ) => {
      const response = await getRequest({
        url: `/posts/get-all-posts?page=${currPage || 1}&filter=${
          filterValue || "All"
        }&top=${isTop}`,
      });

      if (!response?.data?.data?.posts.length) {
        setLastList(true);
        return;
      }

      if (scroll) {
        currPage && setPrevPage(currPage);
        const res = response.data?.data?.posts?.map((item) => ({
          ...item,
          isFollowing: false,
          isUpvoted: false,
          isDownvoted: false,
          showMore: false,
        }));
        setUserPosts((d) => [...d, ...res]);
        currPage && setLoading(false);
        currPage && setScroll(false);
      } else {
        const res = response?.data?.data?.posts?.map((item) => ({
          ...item,
          isFollowing: false,
          isUpvoted: false,
          isDownvoted: false,
          showMore: false,
        }));
        setUserPosts(() => [...res]);
      }
    },
    [filterValue, isTop]
  );

  // console.log("user who is loggedIn", user);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    // getPosts();
  }, [getPosts, filterValue, isTop, getUsers]);

  const fetchMyFollowings = () => {
    // console.log("seeeing if user is here", user);
    if (user) {
      axios
        .get(`${backend_url}/users/get-followings/${user.username}`)
        .then((res) => {
          setMyFollowings(res.data.data.following);
          // console.log("followings", res)
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  };

  useEffect(() => {
    fetchMyFollowings();
  }, [user?.username]);

  // useEffect(() => {
  //   getFilteredPosts();
  // }, [getFilteredPosts]);

  // console.log("filterValue", filterValue);

  // const stringToArray = (string) => {
  //   let tagsArr = string.replace(/'/g, '"');
  //   tagsArr = JSON.parse(tagsArr);
  //   return tagsArr;
  // };

  const fetchFollowers = (usertoget) => {
    // console.log("login user", usertoget);
    if (usertoget?.username != undefined) {
      let fetchArr = [
        axios.get(`${backend_url}/users/get-followers/${usertoget?.username}`),
        axios.get(`${backend_url}/users/get-followings/${usertoget?.username}`),
      ];
      Promise.all(fetchArr)
        .then((res) => {
          // console.log("response for all promise", res);
          setUserFollowersCount(res[0].data.followers_count);
          setUserFollowingsCount(res[1].data.following_count);
          setFollowUsers(res[0].data.data.followers);
          setFollowingUsers(res[1].data.data.following);
        })
        .catch((e) => {
          setFollowUsers(0);
          setFollowingUsers(0);
        });
    }
  };

  const getFollowingPosts = useCallback(
    async (
      setLastList,
      setPrevPage,
      currPage,
      setLoading,
      setScroll,
      scroll,
      filter
    ) => {
      if (user) {
        const response = await getAuthenticatedRequest({
          url: `/posts/get-following-posts?page=${currPage || 1}&filter=${
            filterValue || "All"
          }&top=${isTop}`,
        });

        // console.log("following posts", response);
        const res = response?.data?.data?.posts?.map((item) => ({
          ...item,
          isFollowing: false,
          isUpvoted: false,
          isDownvoted: false,
          showMore: false,
          // userDetails: response?.data?.data?.userDetails?.filter((item2) => {
          //   if (item.user == item2?.userId.username) return item2;
          // })[0]?.userId,
        }));
        // setFollowingPosts(res);

        if (response?.data?.data?.posts?.length == 0) {
          setLastList(true);
          return;
        }

        if (scroll) {
          currPage && setPrevPage(currPage);
          const res = response?.data?.data?.posts?.map((item) => ({
            ...item,
            isFollowing: false,
            isUpvoted: false,
            isDownvoted: false,
            showMore: false,
          }));

          setFollowingPosts((prev) => [...prev, ...res]);
          currPage && setLoading(false);
          currPage && setScroll(false);
        } else {
          const res = response?.data?.data?.posts?.map((item) => ({
            ...item,
            isFollowing: false,
            isUpvoted: false,
            isDownvoted: false,
            showMore: false,
            // userDetails: response?.data?.data?.userDetails?.filter((item2) => {
            //   if (item.user == item2?.userId.username) return item2;
            // })[0]?.userId,
          }));
          setFollowingPosts(res);
        }
      }
    },
    [filterValue, isTop, user?.username]
  );

  useEffect(() => {
    if (user) {
      getFollowingPosts();
    }
  }, [user?.username, myFollowings, isTop, filterValue]);

  // console.log("followingPosts", followingPosts);

  // for deletion but needs to be checked where it is used
  // const fetchUser = (usernameArray) => {
  //   let arr = stringToArray(usernameArray);

  //   arr?.forEach((item, idx) => {
  //     fetch(`${backend_url}/get-user-by-name/${item}`)
  //       .then((res) => res.json())
  //       .then((d) => {
  //         let obj = d.response[0];

  //         let modifiedData = {
  //           first_name: obj["First_Name"],
  //           last_name: obj.last_name,
  //           user_pic: obj.userpic_url,
  //           username: obj.username,
  //         };

  //         setFollowUsersData((d) => [...d, modifiedData]);
  //       })
  //       .catch((err) => console.log(err));
  //   });
  // };
  // // for deletion but needs to be checked where it is used
  // const fetchFollowingUser = (usernameArray) => {
  //   let arr = stringToArray(usernameArray);
  //   arr?.forEach((item, idx) => {
  //     fetch(`${backend_url}/get-user-by-name/${item}`)
  //       .then((res) => res.json())
  //       .then((d) => {
  //         let obj = d.response[0];

  //         let modifiedData = {
  //           first_name: obj["First_Name"],
  //           last_name: obj.last_name,
  //           user_pic: obj.userpic_url,
  //           username: obj.username,
  //         };

  //         setFollowingUsersData((d) => [...d, modifiedData]);
  //       })
  //       .catch((err) => console.log(err));
  //   });
  // };

  const [data, setData] = useState({
    name: "",
    email: "",
    username: "",
    profilePic: "",
  });

  const handleOpenNav = () => {
    setOpen(() => !open);
  };

  const handleTheme = () => {
    setTheme(!theme);
  };

  const handleFollow = async (id, username) => {
    // console.log("id and username in handlefollow", id, username);

    let followed = false;
    myFollowings.forEach((item) => {
      if (item?.userId?.username == username) {
        followed = true;
        return;
      }
    });

    if (followed) {
      let res = await doRequest({
        url: "/users/unfollow",
        method: "POST",
        body: JSON.stringify({ username }),
      });
      // console.log("unfollowed res", res);
      fetchMyFollowings();
      fetchFollowers({ username });
    } else {
      let res = await doRequest({
        url: "/users/follow",
        method: "POST",
        body: JSON.stringify({ username }),
      });
      // console.log("followed res", res);
      fetchMyFollowings();
      fetchFollowers({ username });
    }
  };

  // for deletion but needs to be checked where it is used
  const handleFollowUser = (id, username) => {
    let newData = usersData.map((item, idx) => {
      if (id === idx) {
        return { ...item, isFollowing: !item.isFollowing };
      }

      return item;
    });

    setUsersData(newData);

    if (!followingUsers.includes(username)) {
      followingUsers.push(username);
      updateFollowing();
    } else {
      let userIdx = followingUsers.indexOf(username);
      followingUsers.splice(userIdx, 1);
      updateFollowing();
    }
  };

  // for deletion but needs to be checked where it is used
  const updateFollowing = () => {
    const payloadjson = JSON.stringify(followingUsers);
    // console.log("payload", payloadjson);
    fetch(`${backend_url}/update-following/${user.username}`, {
      method: "PUT",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        getFollowingPosts(user?.username);
      })
      .catch((err) => console.log(err));
  };

  const checkUserVotes = (userVotes, postId) => {
    let res = userVotes?.filter((item) => item.vote_post_id === postId);

    if (res.length !== 0) {
      return res[0].vote_status;
    } else {
      return false;
    }
  };

  // this function needs to be deleted just check where it is called till now
  const fetchAllAlerts = async () => {};

  const newFetchAllAlerts = async () => {
    let data = await getAuthenticatedRequest({ url: "/alert/get" });
    let tempAlerts = data?.data?.alerts;
    tempAlerts = tempAlerts?.sort((a, b) => +b.updated_at - +a.updated_at);
    setAllAlerts(tempAlerts);
  };
  const fetchAlertsCount = async () => {
    if (!user.username) {
      return;
    }
    let response = await getAuthenticatedRequest({ url: `/alert/count` });
    setAlertCount(response?.data?.count);
  };

  useEffect(() => {
    let timerId = null;
    if (user) {
      if (timerId == null) {
        fetchAlertsCount();
      }
      timerId = setInterval(() => {
        fetchAlertsCount();
      }, 300000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [user?.username]);

  const fetchAllTags = () => {
    axios.get(`${backend_url}/get-all-tags`).then((response) => {
      setTagsData(response.data.response.tags);
    });
  };

  // calling authentication request for user if and reseting if needed
  useEffect(() => {
    // Function to check token expiration
    const checkExpiration = async () => {
      let token = sessionStorage.getItem("token"); // Retrieve token from local storage

      if (!token) {
        googleLogout();
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("token");
        cookie.remove("user");
        navigate("/");
        dispatch(clearUser());
        // path === "/" && navigate(0);
      }

      try {
        let res = await fetch(`${backend_url}/users/authenticate-user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user,
          }),
        });
        let data = await res.json();
        if (data.type === "S1") {
          // do not do anything here it is for skipping on ok
        } else if (data.type === "S2") {
          sessionStorage.setItem("token", data.token);
        } else {
          googleLogout();
          sessionStorage.removeItem("isLoggedIn");
          sessionStorage.removeItem("token");
          cookie.remove("user");
          navigate("/");
          dispatch(clearUser());
          // path === "/" && navigate(0);
        }
      } catch (e) {
        googleLogout();
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("token");
        cookie.remove("user");
        navigate("/");
        dispatch(clearUser());
        // path === "/" && navigate(0);
      }
    };
    let interval;

    if (user && user.username) {
      // Run the checkExpiration function initially
      if (path === "/" && !isExpirationFunctionAlreadyCalledFirstTime) {
        checkExpiration();
        setIsExpirationFunctionAlreadyCalledFirstTime(true);
      }

      // Set up interval to periodically check token expiration
      interval = setInterval(checkExpiration, 120000); // Check every 3 minutes
      // 120000 2 minutes
    }

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [user]);

  useEffect(() => {
    if (user?.username) {
      dispatch(setUsername(user.username));
      dispatch(fetchLoggedInUser());
    }
  }, [user]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        handleTheme,
        open,
        handleOpenNav,
        data,
        setData,
        isRecent,
        setIsRecent,
        isTop,
        setIsTop,
        linkClicked,
        setLinkClicked,
        linkUser,
        setLinkUser,
        selectedTags,
        setSelectedTags,
        followUsers,
        followingUsers,
        setFollowingUsers,
        followUsersData,
        followingUsersData,
        followingPosts,
        userPosts,
        newsData,
        addTagsSelected,
        setAddTagsSelected,
        handleFollow,
        showAlertBox,
        setShowAlertBox,
        handleFollowUser,
        setUserPosts,
        setNewsData,
        userVotes,
        setUserVotes,
        checkUserVotes,
        // getPostVotes,
        fetchFollowers,
        usersData,
        setUsersData,
        checked,
        setChecked,
        unread,
        setUnread,
        getPosts,
        fetchAllAlerts,
        alertsData,
        setAlertsData,
        fetchAllTags,
        tagsData,
        setTagsData,
        isRecentNews,
        setIsRecentNews,
        isTopNews,
        setIsTopNews,
        getFollowingPosts,
        setFilterValue,
        filterValue,
        // getFilteredPosts,
        fetchMyFollowings,
        myFollowings,
        userFollowersCount,
        userFollowingsCount,
        setCurrentPostsTab,
        currentPostsTab,
        alertCount,
        allAlerts,
        newFetchAllAlerts,
        fetchAlertsCount,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
