import { React, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import SuggestionTab from "../../Components/suggestion-tabs";
import { Box } from "@mui/material";
import LayoutDefault from "../../Components/layout/page/default";
import MostPopularStocksRight from "../../Components/all-stocks-tab/most-popular-stocks-right";
import TrendingNewsCard from "../../Components/news-details/trending-news-card";

const SuggestionPage = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Box className={theme ? "main_light" : "main_dark"}>
      <LayoutDefault
        mainContent={
          <div>
            <SuggestionTab />
          </div>
        }
        rightPanelContent={
          <div>
            <MostPopularStocksRight />
            <TrendingNewsCard />
          </div>
        }
      />
    </Box>
  );
};

export default SuggestionPage;
