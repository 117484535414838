import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import OutlinedInput from "@mui/material/OutlinedInput";

function MobileTabsDropdown({ tabs }) {
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.name);

  const handleChange = (event) => {
    setSelectedTab(event.target.value);
  };

  const selectedTabComponent = tabs.find((tab) => tab.name === selectedTab);

  return (
    <>
      <FormControl sx={{ minWidth: 120 }} fullWidth maxHeight={100}>
        <Select
          input={<OutlinedInput />}
          value={selectedTab}
          onChange={handleChange}
          sx={{ height: 50, p: 2 }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {tabs?.map((tab, index) => (
            <MenuItem value={tab?.name} key={index}>
              {tab?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <>{selectedTabComponent?.component}</>
    </>
  );
}

MobileTabsDropdown.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default MobileTabsDropdown;
