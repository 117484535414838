import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { StockNewsContext } from "../../../context_for_stocks_news/StocksNewsContext";
import { readableImage } from "../../common-functions/FormatDate";

export const AllIndices = () => {
  const { indicesdata } = useContext(StockNewsContext);

  return (
    <Box>
      {indicesdata.map((stock, idx) => (
        <Card
          sx={{
            p: 2,
            mb: 2,
            boxShadow: "none",
            border: "1px solid #e0e0e0",
          }}
          style={{ width: "100%" }}
          key={`all-indices-${idx}`}
        >
          <Link
            to={`/indices/${stock.Index}`}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Grid
              container
              spacing={1}
              columns={16}
              justifyContent="space-between"
              textAlign={"center"}
              alignItems={"center"}
            >
              <Grid item xs={4} md={4}>
                <Grid sx={{ gap: 1, display: "grid", textAlign: "left" }}>
                  <Typography
                    letterSpacing={0.4}
                    variant="p"
                    sx={{ fontSize: "14px", fontWeight: "700" }}
                  >
                    {stock.Index}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} md={4}>
                <Chip
                  sx={{
                    marginTop: "5px",
                  }}
                  label={stock.Index.split(" ").slice(1).join(" ")}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} md={4} textAlign="right">
                <img
                  src={readableImage(stock.graph?.data)}
                  alt="graph"
                  style={{ width: "80px", height: "50px" }}
                />
              </Grid>

              <Grid item xs={4} md={4} textAlign="right">
                <Typography letterSpacing={0.4} sx={{ fontSize: 14 }}>
                  {"\u20B9"} {stock.live_stock_data?.LAST}
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Card>
      ))}
    </Box>
  );
};
export default AllIndices;
