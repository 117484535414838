import { React, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { capitalFirstLetter } from "../common-functions/FormatDate";
import { Cookies } from "react-cookie";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import ImgPlaceholder from "../.././assets/image_placeholder.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import OverDropdown from "./components/over-dropdown";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { StockNewsContext } from "../../context_for_stocks_news/StocksNewsContext";
import { v4 as uuidv4 } from "uuid";
import { backend_url } from "../../http-common";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { uploadImage } from "../common-functions/Common";
import AddTagsChart from "../create-chart/components/add-tags-chart";
import { useTranslation } from "react-i18next";

export default function CreatePrediction() {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [over, setOver] = useState("Over");
  const [description, setDescription] = useState("");
  const [predictPrice, setPredictPrice] = useState("");
  const [stockName, setStockName] = useState("");
  const [date, setDate] = useState(dayjs(""));
  const { stocksData } = useContext(StockNewsContext);
  const { setShowAlertBox, setUserPosts, getPosts } = useContext(ThemeContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const cookie = new Cookies();
  const user = cookie.get("user");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateImg = (e) => {
    const file = e.target.files[0];
    if (file.size >= 1048576) {
      return alert("Max file size is 1MB.");
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleCreatePrediction = async () => {
    let url;
    if (image) {
      url = await uploadImage(image);
    }
    const form = {
      unique_id: uuidv4(),
      user: `${user?.username}`,
      Title: title,
      Text: description,
      prediction_stock: stockName,
      prediction_price: predictPrice,
      Image: url,
      Tag: selectedTags,
      isOverUnder: over,
      post_type: 4,
      Created_at: Date.now(),
      prediction_duration: date,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
    };

    const userD = {
      First_Name: `${user?.First_Name}`,
      Followers: `${user?.Followers}`,
      Followings: `${user?.Followings}`,
      last_name: `${user?.last_name}`,
      username: `${user?.username}`,
      userpic_url: `${user?.userpic_url}`,
      id: `${user?.id}`,
    };

    const newObj = {
      unique_id: uuidv4(),
      user: `${user?.username}`,
      Title: title,
      Text: description,
      prediction_stock: stockName,
      prediction_price: predictPrice,
      Image: url,
      Tag: selectedTags,
      isOverUnder: over,
      post_type: 4,
      Created_at: Date.now(),
      prediction_duration: date,
      Up_vote: 0,
      Down_vote: 0,
      Comment_count: 0,
      userDetails: userD,
    };

    setUserPosts((d) => [newObj, ...d]);

    const payloadjson = JSON.stringify(form);
    fetch(`${backend_url}/create-post`, {
      method: "POST",
      body: payloadjson,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const newStatus = {
          status: response.status,
          from: "Prediction",
          as: "Created",
        };
        getPosts();
        setOpen(false);
        setTitle("");
        setDescription("");
        setPredictPrice("");
        setStockName("");
        setOver("Over");
        setDate(dayjs(""));
        setImage(null);
        setImagePreview(null);
        setSelectedTags([]);

        setShowAlertBox(() => newStatus);
      })
      .catch((err) => console.log(err));
  };

  const handleChangeOver = (event) => {
    setOver(event.target.value);
  };

  const handleDeleteImage = () => {
    setImagePreview(null);
  };

  const areFieldsEmpty = () => {
    if (
      title === "" ||
      description === "" ||
      predictPrice === "" ||
      stockName === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleDelete = (tag) => {
    const index = selectedTags?.indexOf(tag);
    selectedTags?.splice(index, 1);
    setSelectedTags([...selectedTags]);
  };
  const { t } = useTranslation();

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{
          textTransform: "none",
          color: "#676767",
          "& .MuiButton-startIcon": { marginRight: "5px", color: "#5f9b41" },
        }}
        startIcon={<CurrencyRupeeIcon fontSize="small" />}
      >
        {t("Prediction")}
      </Button>

      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: "18px",
                textAlign: "center",
                mt: 1,
                fontWeight: 700,
                color: !theme ? "white" : "black",
              }}
            >
              Create a Prediction
            </Typography>
            <Button onClick={handleClose} disableRipple>
              <CloseIcon
                sx={{
                  color: !theme ? "white" : "black",
                  marginLeft: "50px",
                }}
              />
            </Button>
          </Box>
        </DialogTitle>
        <Divider color="black" />

        <Box
          sx={{
            display: "flex",
            pl: 1.5,
            ml: 1,
            mt: 2,
          }}
        >
          <Avatar
            alt="profile"
            style={{
              border: "0.1px solid white",
            }}
            src={user?.userpic_url || ImgPlaceholder}
            sx={{ width: 42, height: 42 }}
          />
          <Typography
            letterSpacing={0.4}
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#372B47",
              ml: 1.2,
              mt: 1,
            }}
          >
            {`${capitalFirstLetter(user?.First_Name)} ${capitalFirstLetter(
              user?.last_name
            )}`}
          </Typography>
          {/* <Typography
            letterSpacing={0.4}
            sx={{
              color: "#6b7280",
              fontWeight: 500,
              fontSize: "12px",
              mt: 1.3,
              ml: 0.8,
            }}
          >
            {`@${user?.username}`}
          </Typography> */}
        </Box>
        <DialogContent
          sx={{
            width: 600,
            maxWidth: "100%",
            height: 700,
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            msOverflowStyle: "none", // Hide scrollbar in Internet Explorer
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <TextField
            variant="standard"
            id="outlined-multiline-flexible"
            maxRows={2}
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                fontSize: 13,
              },
            }}
            sx={{
              "& .MuiTextField-root": { fontWeight: 600 },
            }}
            type="text"
            placeholder="Title (Required)"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
          <br />

          <TextField
            variant="standard"
            id="outlined-multiline-flexible"
            placeholder="What's on your mind (Required)"
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                fontSize: 13,
                width: 300,
              },
            }}
            sx={{ mt: 2 }}
            type="text"
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography
            letterSpacing={0.4}
            style={{ marginLeft: "22px", fontSize: 14, fontWeight: 600 }}
          >
            Predict price for -
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={stocksData}
            getOptionLabel={(option) => option.SYMBOLS}
            inputValue={stockName}
            onInputChange={(event, newInputValue) => {
              setStockName(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Stock"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "36px",
                    width: 150,
                    textAlign: "center",
                    pt: 0.2,
                    fontSize: 13,
                    backgroundColor: theme ? "#F7F6F9" : "#242631",
                    justifyContent: "center",
                    display: "flex",
                  },
                }}
              />
            )}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "36px",
                width: 150,
                mr: 2,

                backgroundColor: theme ? "#F7F6F9" : "#242631",
              },
            }}
          />
        </Box>
        <br />
        <Divider color={"black"} mb={2} />
        <br />

        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography
            letterSpacing={0.4}
            style={{
              marginLeft: "22px",
              marginTop: "20px",
              fontSize: 14,
              fontWeight: 600,
              color: "#372B47",
            }}
          >
            I believe the stock will be:
          </Typography>
          <Box display={"grid"}>
            <Box sx={{ mr: 1.5 }}>
              <OverDropdown
                handleChange={handleChangeOver}
                over={over}
                style={{ fontSize: "14px" }}
              />
            </Box>
            <br />

            <TextField
              type="number"
              placeholder="
              0.00"
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "36px",
                  width: 150,
                  mr: 2,
                  backgroundColor: theme ? "#F7F6F9" : "#242631",
                },
              }}
              inputProps={{
                style: {
                  fontSize: 13,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mt: 0.6 }}>
                    ₹
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ mt: 0.6 }}>
                    /share
                  </InputAdornment>
                ),
              }}
              required
              onChange={(e) => setPredictPrice(e.target.value)}
            />
          </Box>
        </Box>
        <br />
        <Divider color={"black"} mb={2} />

        <br />
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography
            letterSpacing={0.4}
            style={{ marginLeft: "22px", fontSize: 14, fontWeight: 600 }}
          >
            On (Date)-
          </Typography>
          <Box sx={{ mr: 1.9 }}>
            {" "}
            {/* <Dropdown
              required
              handleChange={handleChangeDuration}
              duration={duration}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{}}
                placeholder="Date"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "36px",
                        width: 150,
                        fontSize: 12,
                        backgroundColor: theme ? "#F7F6F9" : "#242631",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        {imagePreview ? (
          <Box className="image_preview_div" sx={{ ml: 1.5 }}>
            <img
              src={imagePreview}
              className="image_preview"
              alt="image_preview"
            />
            <Button disableRipple onClick={handleDeleteImage}>
              <CloseIcon
                sx={{
                  color: !theme ? "white" : "black",
                  fontSize: "18px",
                  mt: -14,
                  ml: -5,
                }}
              />
            </Button>
          </Box>
        ) : (
          ""
        )}

        {selectedTags?.length > 0 && (
          <Box className="tagsDiv" sx={{ ml: 2.3, marginBottom: "10px" }}>
            {selectedTags?.map((tag, idx) => (
              <Chip
                key={`prediction-${idx}`}
                label={tag}
                variant="outlined"
                onDelete={() => handleDelete(tag)}
              />
            ))}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            p: 2,
            ml: 0.5,
            mt: 2,
            justifyContent: "space-between",
            ":hover": {
              color: "#5D20D2",
            },
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <label htmlFor="image-upload">
              <Chip
                sx={{ fontSize: 14 }}
                icon={
                  <ImageRoundedIcon
                    style={{
                      color: "var(--fundu_color)",

                      fontSize: "22px",
                    }}
                  />
                }
                label="Image"
                clickable
              />
            </label>

            <AddTagsChart
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </Box>

          <input
            type="file"
            id="image-upload"
            hidden
            accept="image/png, image/jpeg"
            onChange={(e) => validateImg(e)}
          />
          <Box sx={{ right: 0, mr: 0 }}>
            <LoadingButton
              disableShrink
              onClick={handleCreatePrediction}
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                border: "none !important",
              }}
              disabled={areFieldsEmpty()}
            >
              Post
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
