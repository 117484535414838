import React, { useContext } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { useTranslation } from "react-i18next";

const DropdownRecent = () => {
  const { setIsRecent, setIsTop, isTop, isRecent, theme } =
    useContext(ThemeContext);
  const { t } = useTranslation();

  const handleRecent = () => {
    setIsRecent(true);
    setIsTop(false);
  };

  const handleTop = () => {
    setIsTop(true);
    setIsRecent(false);
  };

  return (
    // -----recent sort dropdown ----
    <div
      className="dropdown"
      style={{
        color: !theme ? "white" : "#242631",
        boxShadow: "none",
      }}
    >
      <button
        className="btn dropdown-toggle"
        type="button"
        id="recent_droptn_id"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{
          width: "90px",
          height: "30px",
          textAlign: "right",
          border: "none",
          alignItems: "end",
          color: !theme ? "white" : "#313342",
          backgroundColor: theme ? "white" : "#313342",
        }}
      >
        {isRecent ? t("Recent") : isTop ? t("Top") : t("Recent")}
      </button>

      {/* -----recent deopdown button ----- */}
      <ul
        className="dropdown-menu"
        id="dropdown_new"
        style={{
          backgroundColor: "white",
          color: "#313342",
        }}
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <button
            style={{
              backgroundColor: theme ? "white" : "#242631",
              color: !theme ? "white" : "black",
            }}
            type="button"
            className="btn btn-light"
            id="recent_droptn_id"
            onClick={handleRecent}
          >
            {t("Recent")}
          </button>

          {/* ------------recent button for sort resent post ---- */}
        </li>
        <li>
          <button
            style={{
              backgroundColor: theme ? "white" : "#242631",

              color: !theme ? "white" : "black",
            }}
            type="button"
            className="btn btn-light"
            id="recent_droptn_id"
            onClick={handleTop}
          >
            {t("Top")}
          </button>

          {/* ---------------------top button for sort top post sort by date --- */}
        </li>
      </ul>
    </div>
  );
};

export default DropdownRecent;
