import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    lng: localStorage.getItem("i18next"),
    fallbackLng: "en",
    debug: false,
    react: { useSuspense: false },
    backend: {
      loadPath: "./locales/{{lng}}/translation.json",
    },
  });

export default i18n;
