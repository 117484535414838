import { React } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import CreatePollPopup from "../common/components/create-poll-popup";

export default function CreatePoll({ open, handleClose, setOpen }) {
  const initialFormValues = {
    Title: "",
    poll_question: "",
    poll_options_text: [],
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={(values) => {
        // console.log("values", values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
      }) => (
        <CreatePollPopup
          open={open}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      )}
    </Formik>
  );
}
