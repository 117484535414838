import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeContext } from "../../../../context/ThemeContext";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { capitalFirstLetter } from "../../../common-functions/FormatDate";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useFormikContext } from "formik";

const AddTags = ({ editTags, selectedTags, setSelectedTags }) => {
  const { theme, tagsData } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setFieldValue("tags", []);
    setOpen(false);
  }, [setFieldValue]);

  const handleDone = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(
    (tag) => {
      if (values?.tags?.includes(tag)) {
        const index = values?.tags?.indexOf(tag);
        values?.tags?.splice(index, 1);
        setFieldValue("tags", [...values?.tags]);
      } else {
        setFieldValue("tags", [...values?.tags, tag]);
      }
    },
    [setFieldValue, values]
  );

  return (
    <>
      <Chip
        label="Add tags"
        sx={{ fontSize: "14px" }}
        onClick={handleClickOpen}
      />

      <Dialog open={open} onClose={handleClose} style={{ textAlign: "center" }}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              letterSpacing={0.4}
              sx={{
                fontSize: 18,
                fontWeight: 700,
                color: !theme ? "white" : "black",
              }}
            >
              Add Tags
            </Typography>
            <CloseIcon
              onClick={handleClose}
              style={{
                color: "black",
              }}
            />
          </Box>
        </DialogTitle>
        <Divider color={"black"} />
        <DialogContent>
          {tagsData &&
            Object.keys(tagsData).map((category, index) => (
              <Box key={index}>
                <DialogContentText
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#372B47",
                    mt: 1,
                    pb: 1,
                  }}
                >
                  {capitalFirstLetter(category)}
                </DialogContentText>
                {tagsData[category].map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    variant={
                      values?.tags?.includes(tag) ? "filled" : "outlined"
                    }
                    values={values?.tags}
                    onClick={() => handleClick(tag)}
                    sx={{
                      marginRight: "8px",
                      marginBottom: "8px",
                      borderRadius: "0.5rem",
                      fontSize: 12,
                      backgroundColor: values?.tags?.includes(tag)
                        ? "#5d20d2"
                        : "default",
                      color: values?.tags?.includes(tag) ? "white" : "black",
                    }}
                  />
                ))}
              </Box>
            ))}
        </DialogContent>
        <Divider />
        <DialogTitle>
          {values?.tags?.length > 6 && (
            <Typography letterSpacing={0.4} color="red">
              {" "}
              You can add maximum six tags only.{" "}
            </Typography>
          )}

          <Button
            variant="outlined"
            className="add_tags_done"
            onClick={handleDone}
            sx={{
              color: "white",
              border: " 1px solid var(--fundu_color)",
              background: "var(--fundu_color)",

              ":hover": {
                backgroundColor: "white",
                color: "#5D20D2",
                border: " 1px solid var(--fundu_color)",
              },
            }}
            disabled={
              values?.tags?.length > 6 || values?.tags?.length === 0
                ? true
                : false
            }
          >
            Done
          </Button>
        </DialogTitle>
      </Dialog>
    </>
  );
};

AddTags.propTypes = {
  editTags: PropTypes.array,
};

export default AddTags;
