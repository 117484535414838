import * as React from "react";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const FuIconButton = ({ onclick, children, ...props }) => {
  return (
    <IconButton
      {...props}
      aria-label="open drawer"
      edge="start"
      sx={{ mr: 2, display: { sm: "none" } }}
    >
      {children}
    </IconButton>
  );
};
FuIconButton.propTypes = {
  children: PropTypes.node,
  onclick: PropTypes.func,
};

export default FuIconButton;
