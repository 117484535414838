import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Before = () => {
  return (
    <div>
      <Stack spacing={1}>
        <div className="imgs1">
          <div className="imgs2">
            <Skeleton variant="circular" width={50} height={50} />
            <div>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.25rem" }}
                width={80}
              />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={50} />
            </div>
          </div>
          <Skeleton
            variant="rounded"
            width={90}
            height={30}
            className="follbtn"
          />
        </div>
        <div className="imgs1">
          <div className="imgs2">
            <Skeleton variant="circular" width={50} height={50} />
            <div>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.25rem" }}
                width={80}
              />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={50} />
            </div>
          </div>
          <Skeleton
            variant="rounded"
            width={90}
            height={30}
            className="follbtn"
          />
        </div>
        <div className="imgs1">
          <div className="imgs2">
            <Skeleton variant="circular" width={50} height={50} />
            <div>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.25rem" }}
                width={80}
              />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={50} />
            </div>
          </div>
          <Skeleton
            variant="rounded"
            width={90}
            height={30}
            className="follbtn"
          />
        </div>
        <div className="imgs1">
          <div className="imgs2">
            <Skeleton variant="circular" width={50} height={50} />
            <div>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.25rem" }}
                width={80}
              />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={50} />
            </div>
          </div>
          <Skeleton
            variant="rounded"
            width={90}
            height={30}
            className="follbtn"
          />
        </div>
      </Stack>
    </div>
  );
};

export default Before;
