import React from "react";
import "./index.css";
import logo from "../../assets/askfunduLogo.png";
import { Typography } from "@mui/material";

const TermsOfServices = () => {
  return (
    <div className="Privacy_div">
      <div className="privacy_first_div">
        <img
          style={{
            height: "80px",
            width: "250px",

            marginBottom: "-20px",
          }}
          src={logo}
          alt="logo"
        />
        <h1>Terms of Service</h1>
        <div className="privacy_subhead">Updated 12/05/2023</div>
      </div>
      <div className="Privacy_second_div">
        <Typography letterSpacing={0.4} variant="h1">
          Terms of Service
        </Typography>
        <br />
        <Typography letterSpacing={0.4}>
          askFundu, Inc. (“askFundu,” “we,” “us,” or “our”) welcomes you. We
          invite you to access and use our proprietary askFundu platform (the
          “Platform”) designed to allow users to track and share knowledge about
          investments. The Platform is made available to you via our website
          located at www.askFundu.com (the “Website”) and our mobile application
          (the “App”). To make these Terms of Service (the “Terms”) easier to
          read, the Website, the App, the Platform and the services offered by
          askFundu through the Platform are collectively called the “Services”.
          Please read these Terms and our Privacy Policy ( the “Privacy Policy”)
          carefully because they govern your use of the Services.
        </Typography>

        <h1>1. AGREEMENT TO TERMS.</h1>
        <Typography letterSpacing={0.4}>
          By using our Services, you acknowledge that you have read, understood,
          and agree to be legally bound by these Terms. If you do not agree to
          any of these Terms, you may not access or use the Services.
        </Typography>
        <br />
        <h1>2.PRIVACY POLICY.</h1>
        <Typography letterSpacing={0.4}>
          Please review our Privacy Policy, which also governs your use of the
          Services, for information on how we collect, use and share your
          information.
        </Typography>

        <br />
        <h1>3.CHANGES TO THESE TERMS OR THE SERVICES.</h1>
        <Typography letterSpacing={0.4}>
          We reserve the right, at our sole discretion, to modify, discontinue,
          or terminate the Services, or to modify these Terms, at any time and
          without prior notice. If we modify these Terms, we will post the
          modification on the Website and the App and/or provide you with other
          communications. By continuing to access or use the Services after we
          have posted a modification to the Terms, you are indicating that you
          agree to be bound by the modified Terms. If the modified Terms are not
          acceptable to you, your only recourse is to cease using the Services.
        </Typography>
        <br />
        <h1>4.DESCRIPTION OF SERVICES.</h1>
        <Typography letterSpacing={0.4}>
          The Services allow users to share investing knowledge by posting their
          insights and sharing their investment transactions with the community
          as well as chatting about their investments, including securities,
          commodities, futures, and certain crypto- or virtual currencies
          (“Virtual Currencies”). The Services connect to participating
          third-party broker-dealers and virtual asset exchanges (each, a
          “Third-Party Broker/Exchange”), which also allow users to buy, sell,
          and invest together.
        </Typography>
        <br />
        <h1>5. USERS.</h1>
        <Typography letterSpacing={0.4}>
          a. The Services are available for individuals aged 18 years or older
          and capable of forming a binding contract with askFundu, and not
          otherwise barred from using the Services under applicable law.
        </Typography>
        <Typography letterSpacing={0.4}>
          b. In order to access and use the Services, you must register with us
          and become a member (“Member”). As a Member you will be able to: (i)
          create a profile; (ii) create and join groups and channels for
          exchanging investing ideas with other Members (“Groups”); (iii) link
          to certain Third-Party Brokers/Exchanges with whom you have an account
          in order to buy and sell securities, commodities, futures, and Virtual
          Currencies (collectively, “Investments”) by using your Third-Party
          Brokers/Exchanges; (iv) track your Investments; and (v) share your
          Investment information and post commentary and other content .
        </Typography>
        <br />
        <h1>
          6. SERVICES ACCOUNT AND MATERIALS; BROKERAGE ACCOUNT; TRANSACTIONS.
        </h1>
        <Typography letterSpacing={0.4}>
          a. Services Account. If you wish to register as a Member other than
          through a third-party login provider (e.g., Facebook), during the
          registration process we will ask you to create an account, which
          includes a sign-in name (“Sign-In Name”), a password (“Password”). We
          may also ask you to provide additional information that will assist in
          authenticating your identity when you log-in in the future (“Unique
          Identifiers”). When creating your account, you must provide true,
          accurate, current, and complete information. Each Sign-In Name and
          corresponding Password can be used by only one user. You are solely
          responsible for the confidentiality and use of your Sign-In Name,
          Password, and Unique Identifiers, as well as for any use, misuse, or
          communications entered through the Services using one or more of them.
          You will promptly inform us of any need to deactivate a Password or
          Sign-In Name, or change any Unique Identifier. askFundu will not be
          liable for any loss or damage caused by any unauthorized use of your
          account.
        </Typography>
        <br />

        <Typography letterSpacing={0.4}>
          b. Brokerage Account. In order to use certain features of the
          Services, including authorizing Transactions (as defined below),
          Members must have an active account with a participating Third-Party
          Broker/Exchange (a “Brokerage Account”). Your Brokerage Account is
          subject to a separate agreement directly between you and the
          applicable Third-Party Broker. Under no circumstances will we have any
          liability for your Brokerage Account. You further acknowledge that we
          have no control over Third-Party Brokers/Exchanges and that you may be
          charged fees by the Third-Party Broker/Exchange. We are not
          responsible for any Third-Party Broker/Exchange fees. You are solely
          responsible for your use of the Third-Party Brokers/Exchanges’
          services, and you agree to comply with all terms and conditions
          applicable to such services as required by the applicable Third-Party.
        </Typography>
        <br />

        <Typography letterSpacing={0.4}>
          c. Transactions. The Services allow Members to purchase and sell
          certain Investments through their Brokerage Account (each, a
          “Transaction”). To enable you to make Transactions through the
          Services, you will be required to link your Brokerage Account to the
          Services. askFundu processes Transaction requests according to the
          instructions it receives from its Members. By initiating a Transaction
          on the Services, you hereby authorize askFundu to communicate with the
          applicable Third-Party Broker/Exchange on your behalf and to send a
          message of the Transaction through to your Brokerage Account.
          Acceptance and execution of any Transaction is in the sole control and
          discretion of your Third-Party Broker/Exchange. Once a Transaction has
          been completed, the Investments will be placed in (or removed from)
          your Brokerage Account, and will be reflected in your Services
          account. askFundu does not collect any funds, execute any
          Transactions, or otherwise act as a broker-dealer or cryptocurrency
          exchange, money transmitter, money services business, bank account,
          brokerage account, or other financial account. askFundu merely
          communicates your purchase authorizations to Third-Party
          Broker/Exchange that complete the Transactions on your behalf.
          Although you may be able to find information about investment
          opportunities through the Services, we are not a party to any
          Transaction regarding any such opportunity. We strongly recommend that
          in connection with each Transaction, Members conduct their own due
          diligence. All messages sent using the Services are self-directed by
          you to your Third-Party Broker/Exchange . All orders are executed by
          Third-Party Brokers/Exchanges where Members have an existing account,
          and askFundu is not responsible for the order routing or execution of
          any transaction. Any confirmations of such Transactions will be issued
          solely by the Member’s Third-Party Broker/Exchange. Members are solely
          responsible for any and all orders placed by them, and understand that
          all orders are unsolicited and based on their own investment
          decisions. <br /> <br />
          askFundu AND ITS AFFILIATES, EMPLOYEES, AGENTS, PRINCIPALS, OR
          REPRESENTATIVES DO NOT PROVIDE RECOMMENDATIONS OF ANY TRANSACTION OR
          ORDER, PROVIDE INVESTMENT ADVICE, ENDORSE ANY INVESTMENTS, PRODUCE OR
          PROVIDE RESEARCH TO ANY USER REGARDING ANY INVESTMENT, TRANSACTION OR
          ORDER, OR EXECUTE ANY TRANSACTIONS. (d)Materials. YOU UNDERSTAND AND
          AGREE THAT THE SERVICES INCLUDE INFORMATION AND MATERIALS PROVIDED BY
          OTHER MEMBERS. ALL STATEMENTS AND/OR OPINIONS EXPRESSED IN SUCH
          MATERIALS ARE SOLELY THE OPINIONS OF THE MEMBER PROVIDING SUCH
          MATERIALS. WE ARE NOT RESPONSIBLE, OR LIABLE TO YOU OR ANY THIRD
          PARTY, FOR THE CONTENT OR ACCURACY OF ANY SUCH MATERIALS. In addition:
          We make no attempt to confirm, and do not confirm, any Member’s
          purported identity. You are solely responsible for determining the
          identity and suitability of other Members with whom you may interact
          by means of the Services; We make no representations, offer no
          assurances, and do not investigate any Member’s background, history,
          course of dealing, morality, or character, and you understand and
          agree that you assume the risk of any encounter or interaction with
          any other Member of the Services. We encourage all Members to
          communicate directly with other Members through the tools available on
          the Services and to review their profile pages for more information;
          and We do not endorse any Members.
        </Typography>
        <br />
        <h1>9.askFundu’S INTELLECTUAL PROPERTY.</h1>
        <Typography letterSpacing={0.4}>
          askFundu and its licensors exclusively own all right, title and
          interest in and to the Services, including all associated intellectual
          property rights. You acknowledge that the Services are protected by
          copyright, trademark, and other laws of the United States and foreign
          countries. You agree not to remove, alter or obscure any copyright,
          trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the Services.
        </Typography>
        <br />

        <h1>10. FEEDBACK.</h1>
        <Typography letterSpacing={0.4}>
          We welcome feedback, comments and suggestions for improvements to the
          Services (“Feedback”). If you provide us with any Feedback, you grant
          to us a non-exclusive, transferable, worldwide, perpetual,
          irrevocable, fully-paid, royalty-free license, with the right to
          sublicense, under any and all intellectual property rights that you
          own or control to use, copy, modify, create derivative works based
          upon and otherwise exploit the Feedback for any purpose.
        </Typography>

        <br />

        <h1>10. RIGHTS AND TERMS FOR APPS.</h1>
        <Typography letterSpacing={0.4}>
          1. App License. If you comply with these Terms, askFundu grants to you
          a limited non-exclusive, non-transferable license, with no right to
          sublicense, to download and install the App on your personal
          computers, mobile handsets, tablets, wearable devices, and/or other
          devices and to run the App solely for your own personal non-commercial
          purposes. Except as expressly permitted in these Terms, you may not:
          (i) copy, modify or create derivative works based on the App; (ii)
          distribute, transfer, sublicense, lease, lend or rent the App to any
          third party; (iii) reverse engineer, decompile or disassemble the App
          (unless applicable law permits, despite this limitation); or (iv) make
          the functionality of the App available to multiple users through any
          means.
        </Typography>
        <br />
        <Typography letterSpacing={0.4}>
          2. Additional Information: Apple App Store. This Section 12(b) applies
          to any App that you acquire from the Apple App Store or use on an iOS
          device. Apple has no obligation to furnish any maintenance and support
          services with respect to the App. In the event of any failure of the
          App to conform to any applicable warranty, you may notify Apple, and
          Apple will refund the App purchase price to you (if applicable) and,
          to the maximum extent permitted by applicable law, Apple will have no
          other warranty obligation whatsoever with respect to the App. Apple is
          not responsible for addressing any claims by you or any third party
          relating to the App or your possession and use of it, including, but
          not limited to: (i) product liability claims; (ii) any claim that the
          App fails to conform to any applicable legal or regulatory
          requirement; and (iii) claims arising under consumer protection or
          similar legislation. Apple is not responsible for the investigation,
          defense, settlement and discharge of any third-party claim that your
          possession and use of the App infringe that third party’s intellectual
          property rights. Apple and its subsidiaries, are third-party
          beneficiaries of these Terms, and upon your acceptance of the Terms,
          Apple will have the right (and will be deemed to have accepted the
          right) to enforce these Terms against you as a third-party beneficiary
          thereof. You represent and warrant that (i) you are not located in a
          country that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a terrorist-supporting country;
          and (ii) you are not listed on any U.S. Government list of prohibited
          or restricted parties. You must also comply with any applicable
          third-party terms of service when using the App
        </Typography>
        <br />
        <h1>DMCA/COPYRIGHT POLICY.</h1>

        <Typography letterSpacing={0.4}>
          askFundu respects copyright law and expects its users to do the same.
          It is askFundu’s policy to terminate in appropriate circumstances
          account holders who repeatedly infringe or are believed to be
          repeatedly infringing the rights of copyright holders. Please see
          askFundu’s Copyright Policy at www.askFundu.com/copyright, for further
          information.
        </Typography>

        <br />
        <h1>CONTACT INFORMATION.</h1>
        <Typography letterSpacing={0.4}>
          If you have any questions or complaints about these Terms or the
          Services, please contact fundU at info@askfundu.com.
        </Typography>
      </div>
    </div>
  );
};
export default TermsOfServices;
