export const formatDate = (date) => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const Month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  const months_name = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${day} ${months_name[Month]} ${year}`;
};

export const fetchUser = (username) => {
  let res;
  fetch(`https://fundu.herokuapp.com/get-user-by-name/${username}`)
    .then((res) => res.json())
    .then((d) => {
      res = d.response;
    })
    .catch((err) => console.log(err));

  return res;
};

export const tags = [
  "Options",
  "Buy & Hold",
  "Growth",
  "Fundamental",
  "Bullish",
  "Long",
  "Dividend",
  "Earnings",
  "Day Trade",
  "Momentum",
  "Swing Trade",
  "Bearish",
  "Hold",
  "BTFD",
  "HODL",
  "YOLO",
  "Equity",
  "Penny Stock",
  "Small cap",
  "Mid cap",
  "Large cap",
  "Blue Chip",
  "Construction",
  "Basic Materials",
  "Retail and Wholesale",
  "Transportation",
  "Multi-Sector Conglomerates",
  "Business Services",
  "Computer and Technology",
  "Medical",
  "Finance",
  "Consumer Discretionary",
  "Industrial Products",
  "Utilities",
  "Aerospace",
  "Consumer Staples",
  "Oils and Energy",
  "Auto, Tires and Trucks",
  "Technology",
  "Internet",
  "Data",
  "Saas",
  "Cements",
  "Energy",
  "IPO",
  "Transportation",
  "Real Estate",
  "Macro",
  "Agriculture",
  "Socially Responsible (ESG)",
  "Industrials",
  "Chart Art",
  "Meme",
  "Web3",
  "Cryptocurrency",
  "Defi",
  "Metaverse",
  "NFT",
  "Currency",
  "Minor Pair",
  "Major Pair",
  "Exotic Pair",
  "Commodity",
  "Bullion",
  "Base Metals",
  "Energy",
  "Agricultural Commodities",
  "Currency- General",
  "Minor Pair",
  "Major Pair",
  "Exotic Pair",
];

export const capitalFirstLetter = (string) => {
  const firstLetter = string && string[0].toUpperCase();
  const restLetter = string && string.substring(1).toLowerCase();
  return firstLetter + restLetter;
};

const conciseTime = (arr) => {
  if (arr[1] === "sec" && arr[0] >= 60) {
    let temp = Math.floor(arr[0] / 60);
    arr = [temp, "min"];
    return conciseTime(arr);
  } else if (arr[1] === "min" && arr[0] >= 60) {
    let temp = Math.floor(arr[0] / 60);
    arr = [temp, "h"];
    return conciseTime(arr);
  } else if (arr[1] === "h" && arr[0] >= 24) {
    let temp = Math.floor(arr[0] / 24);
    arr = [temp, "d"];
    return conciseTime(arr);
  } else if (arr[1] === "d" && arr[0] >= 7) {
    let temp = Math.floor(arr[0] / 7);
    arr = [temp, "w"];
    return conciseTime(arr);
  } else if (arr[1] === "w" && arr[0] >= 4) {
    let temp = Math.floor(arr[0] / 4);
    arr = [temp, "M"];
    return conciseTime(arr);
  } else if (arr[1] === "M" && arr[0] >= 12) {
    let temp = Math.floor(arr[0] / 12);
    arr = [temp, "y"];
    return conciseTime(arr);
  } else {
    return arr;
  }
};

export const time_difference = (date1) => {
  let newtime = new Date(date1);
  let diff = (new Date() - new Date(newtime)) / 1000;
  let sec = Math.abs(Math.round(diff));
  let arr = [sec, "sec"];
  return conciseTime(arr);
};

export const currentDate = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  if (month + 1 <= 9) {
    month = `0${month + 1}`;
  }
  if (day <= 9) {
    day = `0${day}`;
  }
  let result = `${year}-${month + 1}-${day}`;
  return result;
};

export const checkFollow = (myFollowings, currentUser) => {
  let isFollowed = false;
  if (myFollowings?.length > 0) {
    myFollowings?.forEach((item) => {
      if (item?.userId?.username == currentUser) {
        isFollowed = true;
        return;
      }
    });
  }
  return isFollowed;
};

export const formatDate1 = (date) => {
  let temp = new Date(date);
  let tempDate = temp.toDateString().split(" ");
  let tempTime = temp.toLocaleString().split(",");
  let res1 = `${tempDate[1]} ${tempDate[2]}, ${tempDate[3]}, `;
  let time = tempTime[1].trim().split(":");
  if (time.length === 2) {
    time[0] = "0" + time[0];
  }
  let ampm = time[2].split(" ")[1];
  let res2 = `${time[0]}:${time[1]} ${ampm}`;
  return res1 + res2;
};

// converting time from 24-hour format to 12-hour format
export function convertTimeTo12HourFormat(time) {
  // Extract hours and minutes from time
  const [hours, minutes] = time.split(":");

  // Convert hours to 12-hour format
  let hours12 = parseInt(hours);
  let ampm = "AM";
  if (hours12 >= 12) {
    hours12 -= 12;
    ampm = "PM";
  }
  if (hours12 === 0) {
    hours12 = 12;
  }
  // Combine hours, minutes, and AM/PM indicator
  return `${hours12}:${minutes} ${ampm}`;
}

// this function will return true and false if the time remaining is fifteen minutes
export function isFifteenMinutesAway(dateTimeString) {
  // Parse the date string into a Date object
  const [dateString, timeString] = dateTimeString.split(" ");
  const [day, month, year] = dateString.split("-");
  const [hours, minutes] = timeString.split(":");
  const dateTime = new Date(year, month - 1, day, hours, minutes);

  // Calculate the difference in milliseconds between now and the target date/time
  const timeDiff = dateTime.getTime() - Date.now();

  // Check if the time difference is less than 15 minutes (in milliseconds)
  if (timeDiff < 15 * 60 * 1000) {
    return true;
  } else {
    return false;
  }
}

// checking if time has passed
export function hasTimePassed(dateString, timeString) {
  // Parse the date string into a Date object
  const [day, month, year] = dateString.split("-");
  const date = new Date(year, month - 1, day);

  // Parse the time string into hours and minutes
  const [hours, minutes] = timeString.split(":");

  // Set the time of the Date object to the specified time
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  // Check if the specified time has passed
  if (date < Date.now()) {
    return true;
  } else {
    return false;
  }
}

// is before 12 today
export function isBeforeNoon() {
  let now = new Date(); // Get the current date and time
  let hours = now.getHours(); // Get the current hour
  return hours >= 12 && hours <= 17; // Return true if the hour is less than 12
}

// Function of converting readable image data.
export const readableImage = (imgStr) => {
  const base64Flag = "data:image/svg+xml;base64,";
  return base64Flag + imgStr;
};

export function isResultTime() {
  let now = new Date(); // Get the current date and time
  let hours = now.getHours(); // Get the current hour
  return hours >= 16 && hours <= 17; // Return true if the hour is less than 17 and greater than 16
}

// return true if it is weekend
export function isWeekend() {
  const now = new Date();
  const dayOfWeek = now.getDay();
  const hourOfDay = now.getHours();
  if (dayOfWeek === 5 && hourOfDay >= 17) {
    return true;
  } else if (dayOfWeek === 6) {
    return true;
  } else if (dayOfWeek === 0 && hourOfDay < 17) {
    return true;
  } else {
    return false;
  }
}

// given date in DD-MM-YYYY format returns day of that date
export function getDayOfWeek(dateStr) {
  const dateParts = dateStr.split("-");
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months in JavaScript are zero-indexed (0-11)
  const year = parseInt(dateParts[2], 10);

  const dateObj = new Date(year, month, day);
  const dayOfWeek = dateObj
    .toLocaleDateString("en-US", { weekday: "long" })
    .toLocaleLowerCase();

  return dayOfWeek;
}

export function formatDateSlot(dateString) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const parts = dateString.split("-");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-based
  const year = parseInt(parts[2], 10);

  const date = new Date(year, month, day);
  const dayOfWeek = daysOfWeek[date.getDay()];
  const monthName = monthsOfYear[date.getMonth()];

  return {
    weekDay: dayOfWeek,
    month: monthName,
    day,
  };
}
